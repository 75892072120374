import { Reservation, ReservationStatus, VenueListReservationsFilter } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const statuses = Object.values(ReservationStatus);

const venueListReservationsQuery = /* GraphQL */ `
  query venueListReservations($filter: VenueListReservationsFilter!) {
    venueListReservations(filter: $filter) {
      items {
        actionDate
        canSubmitRequirements
        checkInDateTime
        createdAt
        updatedAt
        id
        instagramTag
        marketID
        marketName
        name
        noShowStatus
        note
        offerCategory
        offerEndTime
        offerID
        offerStartTime
        offerTitle
        offerValue
        effectiveStartDateTime
        reservationDate
        status
        timeZoneId
        userID
        user {
          firstname
          lastname
          tags
        }
        userPicture {
          thumbnail
        }
        venueID
        venueName
        validation
      }
    }
  }
`;

export const venueListReservationsApi = async (
  filter: Partial<VenueListReservationsFilter>,
): Promise<Reservation[]> => {
  const { status } = filter;

  if (!status) {
    filter.status = statuses;
  }

  const result = await executeGql<Reservation[]>(venueListReservationsQuery, { filter });
  return processGqlResult<Reservation[]>('venueListReservations.items', result);
};
