import * as React from 'react';
import { Link } from 'react-router-dom';

import { Box, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import Button from '../../../components/Button/Button';
import BaseBorder from './BaseBorder';

type DescriptionLineProps = {
  title: string;
};

const DescriptionLine: React.FC<React.PropsWithChildren<DescriptionLineProps>> = ({
  children,
  title,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <Typography fontSize='15px' fontWeight='500'>{title}</Typography>
    {children}
  </Box>
);

const EmptyReceived: React.FC = () => {
  return (
    <BaseBorder sx={{ p: 0 }}>
      <Box component='img' src='/png/empty-content-received.png' />
      <Box sx={{ p: 2, pb: 3}}>
        <Typography fontWeight='600' fontSize='18px'>You haven't received any content...</Typography>
        <Typography variant='body2' fontWeight='400' fontSize='15px'>
          To increase your chances of receiving content, you can:
        </Typography>

        <Stack spacing={1} sx={{ mt: 5 }}>
          <DescriptionLine title='Create public offers'>
            <Button variant='outlined' size='small' component={Link} to='/offers/create' fontWeight='500' fontSize='15px'>
              Create
            </Button>
          </DescriptionLine>

          <Divider />
          <DescriptionLine title='Invite users with collabs'>
            <Button variant='contained' size='small' component={Link} to='/collabs' fontWeight='500' fontSize='15px'>
              Collabs
            </Button>
          </DescriptionLine>
        </Stack>
      </Box>
    </BaseBorder>
  );
};

export default EmptyReceived;
