import { User, VenueGetUserFilter } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueGetUserQuery = /* GraphQL */ `
  query VenueGetUser($filter: VenueGetUserFilter!) {
    venueGetUser(filter: $filter) {
      id
      email
      suspensions {
        id
        userID
        startDate
        endDate
        reason
        detail
        paymentAmount
        status
        lastSynced
        createdAt
      }
      penalties {
        id
        userID
        reservationID
        type
        status
        createdAt
        currency
        paymentAmount
        timeZoneId
      }
      reservations {
        id
        marketName
        marketID
        offerID
        offerCategory
        offerTitle
        offerValue
        offerStartTime
        offerEndTime
        offerEndDateTime
        timeZoneId
        userID
        name
        venueID
        venueName
        venueAddress1
        venueAddress2
        venueCity
        venueCountry
        note
        status
        reservationDate
        actionDate
        checkInDateTime
        createdAt
        updatedAt
        canSubmitRequirements
        requirementsSubmitted
        instagramTag
        noShowStatus
        currency
        payoutAmount
        validation
        penaltyType
        effectiveStartDateTime
        effectiveEndDateTime
        offerPostType
        postLink
        inviteID
        privateOffer
      }
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      picture {
        thumbnail
        small
        medium
        large
        video
      }
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      market {
        id
        name
        totalActiveVenues
        totalVenues
        totalActiveModels
        totalUsers
        percentActiveModels
        totalActiveOffers
        totalSpots
        totalReservations
        percentBookedLastFewDays
        percentBookedAllTime
        valueActiveOfferSpots
        totalReach
        stateProvince
        countryCode
        country
        currency
        status
        unitSystem
        createdAt
        tipEnabled
      }
      currentCity
      location {
        lat
        lon
      }
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      editedBy {
        id
        email
        firstname
        lastname
        group
        picture
        createdAt
      }
      reviewedBy {
        id
        email
        firstname
        lastname
        group
        picture
        createdAt
      }
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancerBodyProfile {
        height
        waist
        bust
        hips
      }
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
    }
  }
`;

export const venueGetUserApi = async (filter: VenueGetUserFilter): Promise<User> => {
  const result = await executeGql<User>(venueGetUserQuery, { filter });
  return processGqlResult<User>('venueGetUser', result);
};
