import * as React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { data } from '../../../../../pages/SubscriptionPlans/components/Plans/data';
import { Plan } from '../../../../../pages/SubscriptionPlans/types';
import Benefit from './Benefit';

export type DowngradeBenefitProps = {
  plan: Plan;
};

const DowngradeBenefit: React.FC<DowngradeBenefitProps> = ({ plan }) => {
  const pd = React.useMemo(() => data[plan], [plan]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 300,
        height: '100%',
        p: 4,
        background: 'transparent url(/png/subscription-downgrade.png) 0% 0% no-repeat padding-box',
        backgroundSize: 'cover',
      }}
    >
      <Box>
        <Typography
          variant='h6'
          fontWeight='bold'
          textAlign='center'
          sx={{ marginBlock: 2, px: 4 }}
        >
          You will lose the following benefits
        </Typography>

        <Stack spacing={1}>
          {pd.benefits.map((x, i) => (
            <Benefit key={i} text={x.title} color={pd.benefitColor} />
          ))}
        </Stack>
      </Box>

      <Box display='flex' justifyContent='center'>
        {pd.title}
      </Box>
    </Box>
  );
};

export default DowngradeBenefit;
