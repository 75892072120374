import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

import { venueCreateOfferApi } from '../../api/offers/venueCreateOfferApi';
import { venueGetOfferApi } from '../../api/offers/venueGetOfferApi';
import { venueListOffersApi } from '../../api/offers/venueListOffersApi';
import { venueUpdateOfferApi } from '../../api/offers/venueUpdateOfferApi';
import {
  Offer,
  OfferStatus,
  VenueCreateOfferInput,
  VenueListOffersFilter,
} from '../../graphql/API';
import { VenueUpdateOfferInput } from '../../graphql/API';
import { update } from '../../redux/features/offer/offerSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { QueryResult } from './types';

const PAGE_LIMIT = 50;

type MutationResult<T> = UseMutationResult<void, unknown, T, unknown>;

export const useListOfferQuery = (
  filters?: Partial<VenueListOffersFilter>,
): QueryResult<Offer[]> => {
  const venueState = useAppSelector((state) => state.venue.value);
  const dispatch = useAppDispatch();

  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery<Offer[]>({
    initialPageParam: 0,
    queryKey: ['list-offers', venueState?.id, filters],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, venueID] = queryKey as [string, string, Partial<VenueListOffersFilter>];
      const offset = <number>pageParam * PAGE_LIMIT;

      return venueListOffersApi({
        ...filters,
        statuses: !filters?.status
          ? [OfferStatus.active, OfferStatus.pending, OfferStatus.cancelled, OfferStatus.inactive]
          : undefined,
        ...(filters?.status === OfferStatus.active && { type: 'instance' }),
        limit: PAGE_LIMIT,
        offset,
        venueID,
        sortBy: 'updatedAt',
        sortDirection: 'desc',
      });
    },
    getNextPageParam: (lastGroup, groups) =>
      lastGroup.length === PAGE_LIMIT ? groups.length : undefined,
    placeholderData: keepPreviousData,
    enabled: !!venueState?.id,
    refetchOnWindowFocus: false,
  });

  const flatData = React.useMemo(() => data?.pages.flatMap((x) => x) ?? [], [data]);

  React.useEffect(() => {
    dispatch(update(flatData));
  }, [dispatch, flatData]);

  return { data: flatData, isFetching, isLoading, fetchNextPage, hasNextPage };
};

export const useOfferDetailQuery = (offerId?: string) =>
  useQuery<Offer>({
    queryKey: ['offer-detail', offerId],
    queryFn: async ({ queryKey }) => {
      const [, offerID] = queryKey as [string, string];

      return venueGetOfferApi(offerID);
    },
    placeholderData: keepPreviousData,
  });

export const useCreateOfferQuery = (): MutationResult<VenueCreateOfferInput> => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  return useMutation({
    mutationFn: venueCreateOfferApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['list-offers'] });
      navigate('/offers');
    },
  });
};

export const useUpdateOfferQuery = (
  venueID: string | undefined,
): MutationResult<VenueUpdateOfferInput> => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (input) => {
      await venueUpdateOfferApi({ ...input, venueID });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['list-offers'] });
      queryClient.invalidateQueries({ queryKey: ['offer-detail', variables.id] });
      navigate('/offers');
    },
  });
};
