import * as React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ShowFnOutput } from 'mui-modal-provider';

import { venueCancelSubscriptionApi } from '../../../../api/stripe/venueCancelSubscriptionApi';
import { VenueSubscriptionItem } from '../../../../graphql/API';
import { extractSubscriptionPlan } from '../../../../pages/SubscriptionPlans/common/helper';
import Benefit from '../../../../pages/SubscriptionPlans/components/Plans/Benefit';
import { data } from '../../../../pages/SubscriptionPlans/components/Plans/data';
import { SubscriptionConditionKind } from '../../../../pages/SubscriptionPlans/types';
import { StripePriceWithMetadata } from '../../../../redux/features/subscription/types';
import Button from '../../../Button/Button';
import LoadingButton from '../../../Button/LoadingButton';
import { BaseDialogProps } from '../../Base';

export type CancelSubscriptionProps = {
  price: StripePriceWithMetadata | undefined;
  subscription: VenueSubscriptionItem | undefined;
  subscriptionKind: SubscriptionConditionKind;
  modalRef: React.MutableRefObject<ShowFnOutput<BaseDialogProps> | undefined>;
};

const CancelSubscription: React.FC<CancelSubscriptionProps> = ({
  subscription,
  subscriptionKind,
  modalRef,
}) => {
  const mutation = useMutation({
    mutationFn: venueCancelSubscriptionApi,
    onSuccess: () => {
      window.location.href = `/plans?message_type=${subscriptionKind}&subscriptionEnd=${subscriptionEnd}&plan=${planData?.titleText}`;
    },
  });

  const planData = React.useMemo(() => {
    if (!subscription?.sku) {
      return;
    }
    const [plan] = extractSubscriptionPlan(subscription.sku);

    const pd = data[plan];

    return pd;
  }, [subscription?.sku]);

  const subscriptionEnd = React.useMemo(
    () => dayjs(subscription?.expirationDate).format('DD/MM/YYYY'),
    [subscription?.expirationDate],
  );

  return (
    <Box>
      <Box sx={{ marginY: 4 }}>
        <Typography
          variant='h6'
          sx={{
            marginBottom: 2,
            display: 'flex',
          }}
        >
          {planData?.title} &nbsp;Plan Benefits
        </Typography>

        <Stack spacing={2}>
          {planData?.benefits.map((x, i) => (
            <Benefit key={i} title={x.title} color={planData.benefitColor} />
          ))}
        </Stack>
      </Box>

      <Typography variant='h6' sx={{ mt: 3 }}>
        We're sorry to see you go
      </Typography>

      <Typography component='div'>
        Are you sure you want to cancel the subscription? You'll lose all the premium benefits after
        cancellation.
        <Box component='div' sx={{ marginTop: 2, fontWeight: '600' }}>
          Your plan remains active until &nbsp;
          {subscriptionEnd}
        </Box>
      </Typography>

      <Stack spacing={2} direction='row' sx={{ mt: 6 }}>
        <Button
          size='large'
          variant='outlined'
          sx={{ px: 4 }}
          onClick={() => modalRef.current?.destroy()}
        >
          Keep the subscription
        </Button>
        <LoadingButton
          size='large'
          variant='text'
          loading={mutation.isPending}
          onClick={() => {
            subscription?.subscriptionId && mutation.mutate(subscription.subscriptionId);
          }}
        >
          Cancel subscription
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default CancelSubscription;
