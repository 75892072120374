import * as React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

import CollectionsIcon from '@mui/icons-material/Collections';
import MuiBadge from '@mui/material/Badge';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Tab, { TabProps, TabTypeMap } from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

import {
  OfferCategory,
  ReservationStatus,
  UserAccessRequestResultValue,
} from '../../../graphql/API';
import useAccessRequestQuery from '../../../hooks/reactQuery/useAccessRequestQuery';
import useReservationQuery from '../../../hooks/reactQuery/useReservationQuery';
import useVenueId from '../../../hooks/useVenueId';
import { extractSubscriptionPlan } from '../../../pages/SubscriptionPlans/common/helper';
import { useAppSelector } from '../../../redux/hooks';
import { CollabsIcon, HomeIcon, KeyIcon, OffersIcon, ReservationIcon } from '../../Icons';

const menus = [
  { path: '', matched: 'NA', icon: <HomeIcon />, label: 'Dashboard' },
  { path: 'collabs', matched: '/collabs', icon: <CollabsIcon />, label: 'Collabs' },
  { path: 'access-requests', matched: '/access-requests', icon: <KeyIcon />, label: 'Access' },

  {
    path: 'reservations',
    matched: '/reservations',
    icon: <ReservationIcon sx={{ fontSize: 26 }} aria-label='reservations' />,
    label: 'Reservations',
  },
  { path: 'content', matched: '/content', icon: <CollectionsIcon />, label: 'Content' },
  { path: 'offers', matched: '/offers', icon: <OffersIcon />, label: 'Offers' },
];

type LinkTabProps = TabProps<typeof Link, LinkProps>;
type LinkTabType = OverridableComponent<TabTypeMap<LinkTabProps>>;

const a11yProps = (index: number) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

const StyledTabs = styled(MuiTabs)(({ theme }) => ({
  '.MuiButtonBase-root.Mui-selected': { color: theme.custom.colors.brandPurple },
  '.MuiTabs-indicator': { left: 0, width: 4, backgroundColor: theme.custom.colors.brandPurple },
}));

const Badge = styled(MuiBadge)(({ theme }) => ({
  '.MuiBadge-badge': {
    right: theme.spacing(4),
    background:
      'transparent linear-gradient(25deg, #FFD47B 0%, #FF778E 46%, #B97AFF 100%) 0% 0% no-repeat padding-box',
  },
}));

const StyledTab = styled(Tab)<LinkTabProps>(({ theme }) => ({
  fontSize: '1rem',
  justifyContent: 'flex-start',
  minHeight: theme.spacing(6),
  '.MuiSvgIcon-root': {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(0.5),
    ':not([aria-label="reservation"])': {
      fontSize: 24,
    },
  },
})) as LinkTabType;

const LinkTab: React.FC<LinkTabProps> = (props) => <StyledTab component={Link} {...props} />;

const Tabs: React.FC = () => {
  const venueID = useVenueId();
  const venue = useAppSelector((state) => state.venue.value);
  const { subscription } = useAppSelector((state) => state.subscription.value);
  const [badges, setBadges] = React.useState<string[]>([]);

  const { data } = useAccessRequestQuery({ status: UserAccessRequestResultValue.requested });
  const { data: reservationData } = useReservationQuery(
    { status: [ReservationStatus.reserved] },
    venueID,
  );

  const [value, setValue] = React.useState<number | false>(0);
  const { pathname } = useLocation();

  const handleChange = React.useCallback(
    (_: React.SyntheticEvent, newValue: number) => setValue(newValue),
    [],
  );

  React.useEffect(() => {
    const i = pathname !== '/' ? menus.findIndex((x) => pathname.startsWith(x.matched)) : 0;

    if (value !== i) {
      setValue(i > 0 ? i : false);
    }
  }, [value, pathname]);

  React.useEffect(() => {
    const b = data.length > 0 ? ['/access-requests'] : [];

    if (reservationData.length > 0) {
      b.push('/reservations');
    }

    setBadges(b);
  }, [data, reservationData]);

  const subscriptionPlan = React.useMemo(() => {
    const [plan] = extractSubscriptionPlan(subscription?.sku ?? '');

    return plan;
  }, [subscription]);

  console.log(subscriptionPlan);

  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      orientation='vertical'
      aria-label='Vertical tabs'
    >
      {menus.map((x, i) => (
        <LinkTab
          key={i}
          wrapped
          icon={
            <Badge color='primary' variant='dot' invisible={!badges.find((y) => y === x.matched)}>
              {x.icon}
            </Badge>
          }
          iconPosition='start'
          label={x.label}
          to={x.path}
          disabled={venue?.type === OfferCategory.brand && !subscriptionPlan}
          {...a11yProps(i)}
        />
      ))}
    </StyledTabs>
  );
};

export default Tabs;
