import { Invite, VenueListInvitesFilter } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueListInvitesQuery = /* GraphQL */ `
  query venueListInvites($filter: VenueListInvitesFilter!) {
    venueListInvites(filter: $filter) {
      items {
        id
        offerID
        userID
        user {
          name
          firstname
          lastname
          picture {
            thumbnail
          }
          tags
        }
        offer {
          title
        }
        status
        expiry
        createdAt
        updatedAt
      }
    }
  }
`;

export const venueListInvitesApi = async (filter: VenueListInvitesFilter): Promise<Invite[]> => {
  const result = await executeGql<Invite[]>(venueListInvitesQuery, { filter });
  return processGqlResult<Invite[]>('venueListInvites.items', result);
};
