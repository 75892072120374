import * as React from 'react';

import { Stack } from '@mui/material';

import { Offer, OfferCategory } from '../../../graphql/API';
import Step from './Step';

type StepperProps = {
  activeStep: number;
  offer?: Offer;
};

const LIST = ['Select an collab offer', 'Select dates', 'Checkout'];

const Stepper: React.FC<StepperProps> = ({ activeStep, offer }) => {
  const [list, setList] = React.useState(LIST);

  React.useEffect(() => {
    if (offer?.category === OfferCategory.event) {
      setList(['Select an collab offer', 'Checkout']);
    } else {
      setList(LIST);
    }
  }, [offer]);

  return (
    <Stack direction='row' spacing={1}>
      {list.map((x, i) => (
        <Step key={i} title={x} active={activeStep === i} />
      ))}
    </Stack>
  );
};

export default Stepper;
