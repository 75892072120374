import { BenefitTip, CreditOption } from './types';

export const options: CreditOption[] = [
  { credit: 3, price: 14.99 },
  {
    credit: 10,
    price: 39.99,
    creditType: 'popular',
    promotion: 'Save 33%',
  },
  {
    credit: 30,
    price: 99.99,
    creditType: 'convenient',
    promotion: 'Save 40%',
  },
];

export const mapCreditOption = {
  popular: {
    label: 'Most popular',
    color: '#37BB90',
    background: '#37BB90CC',
  },
  convenient: {
    label: 'Most convenient',
    color: '#D079DB',
    background:
      'transparent linear-gradient(34deg, #FFD47B 0%, #FF778E 46%, #B97AFF 100%) 0% 0% no-repeat padding-box;',
  },
};

export const benefitTips: BenefitTip[] = [
  {
    primaryText: '5 free credits per month',
    secondaryText: 'Use credits for more invites and downloads',
  },
  {
    primaryText: 'Premium support',
    secondaryText: 'Get faster, priority support',
  },
  {
    primaryText: 'All direct invites cost 1 credit',
    secondaryText: 'Send invites for just 1 credit',
  },
  {
    primaryText: 'Invites to Premium members are Free',
    secondaryText: 'Connect with Premium members at no cost',
  },
  {
    primaryText: 'Discover who saved your profile',
    secondaryText: "See who's interested in working with you",
  },
  {
    primaryText: 'Get notified of new members',
    secondaryText: 'Be the first to connect with new members',
  },
];
