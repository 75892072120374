import * as React from 'react';

import ListInvite from '../../List/ListInvite/ListInvite';
import BaseDialog, { BaseDialogProps } from '../Base';

const ListInviteDialog: React.FC<BaseDialogProps> = (props) => {
  return (
    <BaseDialog {...props}>
      <ListInvite />
    </BaseDialog>
  );
};

export default ListInviteDialog;
