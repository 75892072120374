import { BlockedUser, VenueListVenueBlockedUsersRequest } from '../../graphql/API';
import { venueListVenueBlockedUsers2 } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueListVenueBlockedUsers = async (
  filter: VenueListVenueBlockedUsersRequest,
): Promise<BlockedUser[]> => {
  const result = await executeGql(venueListVenueBlockedUsers2, { filter });
  return processGqlResult<BlockedUser[]>('venueListVenueBlockedUsers2.items', result);
};
