import * as React from 'react';

import Chip, { ChipProps } from '@mui/material/Chip';

type StatusLabelProps = { type: StatusType; status?: string | null; emptyBackground?: boolean };

type StatusType = 'offer' | 'reservation' | 'invite' | 'userCategory' | 'post';

type StatusMapResult = { colorCode: ChipProps['color']; text: string; border?: boolean };

type StatusMapDict = Record<string, StatusMapResult>;
type StatusMap = Record<string, StatusMapDict>;

type ColorResult = { color: string; backgroundColor: string };

type ColorMap = Record<Exclude<ChipProps['color'], undefined>, ColorResult>;

type ChipStatusColor = Omit<StatusMapResult, 'colorCode'> & { colorMap: ColorResult };

const colorMap: ColorMap = {
  default: { color: 'black', backgroundColor: '' },
  primary: { color: '', backgroundColor: '#000' },
  secondary: { color: '#848484', backgroundColor: '#F6F6F6' },
  success: { color: '#37BB90', backgroundColor: '#B5EEDB' },
  warning: { color: '#955CCC', backgroundColor: '' },
  error: { color: '#DC5564', backgroundColor: '#FFE1E5' },
  info: { color: '', backgroundColor: '' },
};

const statusMap: StatusMap = {
  offer: {
    active: { colorCode: 'success', text: 'Active' },
    cancelled: { colorCode: 'error', text: 'Cancelled' },
    pending: { colorCode: 'default', text: 'Pending' },
    rejected: { colorCode: 'error', text: 'Rejected' },
    deleted: { colorCode: 'error', text: 'Deleted' },
    ended: { colorCode: 'secondary', text: 'Ended' },
    inactive: { colorCode: 'secondary', text: 'Inactive' },
  },
  reservation: {
    cancelled: { colorCode: 'error', text: 'Canceled' },
    checkedin: { colorCode: 'success', text: 'Checked In' },
    deleted: { colorCode: 'error', text: 'Deleted' },
    disputed: { colorCode: 'error', text: 'Disputed' },
    noshow: { colorCode: 'secondary', text: 'No Show' },
    noshowprocessed: { colorCode: 'secondary', text: 'No Show' },
    reserved: { colorCode: 'default', text: 'Reserved' },
  },
  invite: {
    accepted: { colorCode: 'success', text: 'Accepted' },
    declined: { colorCode: 'error', text: 'Declined' },
    expired: { colorCode: 'secondary', text: 'Expired', border: true },
    pending: { colorCode: 'secondary', text: 'Pending' },
    available: { colorCode: 'secondary', text: 'Pending' },
  },
  userCategory: {
    'BP LIFESTYLE': { colorCode: 'success', text: 'Lifestyle' },
    'BP SELECT': { colorCode: 'warning', text: 'Beautypass Select' },
  },
  post: {
    pending: { colorCode: 'default', text: 'Pending' },
    'in review': { colorCode: 'secondary', text: 'In Review' },
    approved: { colorCode: 'success', text: 'Approved' },
    rejected: { colorCode: 'error', text: 'Rejected' },
  },
};

const getDefault = (status: StatusType, value: string | undefined | null): ChipStatusColor => {
  if (!value) {
    return { colorMap: colorMap['secondary'], text: 'NA' };
  }

  const { colorCode, ...restProps } = statusMap[status][value] ?? {
    color: 'secondary',
    text: value,
  };

  return { colorMap: colorMap[colorCode ?? 'secondary'], ...restProps };
};

const StatusLabel: React.FC<StatusLabelProps> = ({ type, status, emptyBackground }) => {
  const { colorMap, text, border } = React.useMemo(() => getDefault(type, status), [type, status]);

  return (
    status && (
      <Chip
        size='small'
        label={text}
        variant={border ? 'outlined' : 'filled'}
        sx={{
          color: colorMap.color,
          backgroundColor: !border ? colorMap.backgroundColor : 'undefined',
          borderRadius: 0.5,
          fontWeight: 500,
          ...(emptyBackground
            ? {
                backgroundColor: 'unset',
                '& .MuiChip-label': {
                  padding: 0,
                },
              }
            : undefined),
        }}
      />
    )
  );
};

export default StatusLabel;
