import * as React from 'react';

import { Box, Grid, Skeleton, Stack, Switch, Typography } from '@mui/material';

import dayjs from 'dayjs';
import { ShowFnOutput, useModal } from 'mui-modal-provider';

import LazyAvatar from '../../components/Avatar/LazyAvatar/LazyAvatar';
import Button from '../../components/Button/Button';
import SaveUserButton from '../../components/Button/SaveUserButton';
import { BaseDialogProps } from '../../components/Dialogs/Base';
import CancelReservationDialog from '../../components/Dialogs/CancelReservation/CancelReservationDialog';
import { EditReservationDateAndTimeDialog } from '../../components/Dialogs/EditReservationDateAndTime/EditReservationDateAndTimeDialog';
import ReportReservationsDialog from '../../components/Dialogs/ReportReservations/ReportReservationsDialog';
import FloatTopLeft from '../../components/FloatTopLeft/FloatTopLeft';
import GoBack from '../../components/GoBack/GoBack';
import InstagramTag from '../../components/InstagramTag/InstagramTag';
import LabelDetail from '../../components/LabelDetail/LabelDetail';
import SkeletonWithChild from '../../components/SkeletonWithChild/SkeletonWithChild';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import { OfferCategory, ReservationStatus, UserUpdateStatus } from '../../graphql/API';
import { useQueryUser } from '../../hooks/reactQuery/useQueryUser';
import useVenueId from '../../hooks/useVenueId';
import { useAppSelector } from '../../redux/hooks';
import { calculateReservationPostStatus } from '../../utils/calculateReservationPostStatus';
import { datetimeWithTimezone, getTimeInTimezoneInDayjs } from '../../utils/date';
import { formatNumber } from '../../utils/formatter';
import { OfferCategoriesCallToConfirm } from '../../utils/offerAccess';
import useRequirement from '../OfferDetail/components/useRequirment';
import DateTime from './components/DateTime';
import Paper from './components/Paper';
import Requirements from './components/Requirements';
import { useUpdateReservationMutation } from './components/useMutation';
import { useQueryReservation } from './components/useQuery';

const ReservationDetail: React.FC = () => {
  const venueId = useVenueId();
  const venue = useAppSelector((state) => state.venue.value);
  const { data, isLoading, refetch } = useQueryReservation();
  const { data: userData } = useQueryUser(venueId, data?.userID);
  const updateReservationMutation = useUpdateReservationMutation({ onSuccess: refetch });
  const requirements = useRequirement(data?.offer ?? undefined);
  const { showModal } = useModal();
  const complainModalRef = React.useRef<ShowFnOutput<BaseDialogProps>>();
  const cancelReservationModalRef = React.useRef<ShowFnOutput<BaseDialogProps>>();
  const editDateAndTimeModalRef = React.useRef<ShowFnOutput<BaseDialogProps>>();

  const [isStatusCheckedIn, setIsStatusCheckedIn] = React.useState(
    data?.status === ReservationStatus.checkedin,
  );

  React.useEffect(() => {
    setIsStatusCheckedIn(data?.status === ReservationStatus.checkedin);
  }, [data?.status]);

  const { offerStartTime, offerEndTime, checkedInTime, reservedDate } = React.useMemo(() => {
    const reservedDate = dayjs.utc(data?.effectiveStartDateTime);
    const effectiveStartTime = dayjs.utc(data?.effectiveStartDateTime).format('HH:mm');
    const effectiveEndTime = dayjs.utc(data?.effectiveEndDateTime).format('HH:mm');

    const timezoneId = data?.timeZoneId;
    return {
      offerStartTime: getTimeInTimezoneInDayjs(effectiveStartTime, timezoneId),
      offerEndTime: getTimeInTimezoneInDayjs(effectiveEndTime, timezoneId),
      checkedInTime: datetimeWithTimezone({
        date: data?.checkInDateTime,
        time: data?.offerEndDateTime,
        timezoneId,
      }),
      reservedDate,
    };
  }, [data]);

  const checkedInValue = React.useMemo(() => {
    const reservationStatus = data?.status;
    if (reservationStatus === ReservationStatus.checkedin) {
      return <DateTime value={checkedInTime.tz().dayjs() ?? dayjs()} />;
    }

    if (reservationStatus === ReservationStatus.noshow) {
      return 'User did not check in';
    }

    return 'Not checked in yet';
  }, [checkedInTime, data?.status]);

  const onCancelReservationDialogHandle = () => {
    cancelReservationModalRef.current = showModal(CancelReservationDialog, {
      modalRef: cancelReservationModalRef,
      reservationDetail: data,
      refetchReservationDetails: refetch,
    });
  };

  const onEditReservationDateAndTimeDialogHandle = () => {
    editDateAndTimeModalRef.current = showModal(EditReservationDateAndTimeDialog, {
      modalRef: editDateAndTimeModalRef,
      reservationDetail: data,
      refetchReservationDetails: refetch,
    });
  };

  const onCheckedInHandle = (checked: boolean) => {
    if (data?.id && checked) {
      updateReservationMutation.mutate({
        id: data?.id,
        status: UserUpdateStatus.checkedin,
      });
    }
    setIsStatusCheckedIn(true);
  };

  const refreshHandle = React.useCallback(() => {
    refetch();
  }, [refetch]);

  const offerCategory = data?.offer?.category || data?.offerCategory;

  const isEditable =
    (offerCategory &&
      OfferCategoriesCallToConfirm.includes(offerCategory) &&
      data.status === ReservationStatus.reserved) ||
    false;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: 'white',
        flex: 1,
        py: 4,
        pl: 10,
        pr: 6,
      }}
    >
      <FloatTopLeft>
        <GoBack />
      </FloatTopLeft>

      <Grid container spacing={2}>
        <Grid item lg={6} md={7} xs={7}>
          <Paper
            title='Reservation details'
            action={
              <Box display={'flex'} alignItems={'center'} gap={'12px'}>
                <Button
                  size='large'
                  variant='outlined'
                  disabled={isLoading}
                  onClick={() => {
                    if (!(venueId && data?.id && data?.user)) {
                      return;
                    }

                    complainModalRef.current = showModal(ReportReservationsDialog, {
                      title: 'Report a problem',
                      modalRef: complainModalRef,
                      payload: {
                        venueId: venueId,
                        reservationId: data.id,
                        userId: data.user.id,
                        name: `${data.user.firstname} ${data.user.lastname}`.trim(),
                        plan: venue?.premium ? 'PREMIUM' : 'BASIC',
                        venueName: venue?.name || '',
                        email: venue?.email || '',
                        venueLink: venueId,
                        type: 'Reservation report',
                      },
                      refreshHandle,
                      offerId: data?.offer?.id || '',
                    });
                  }}
                >
                  Report a problem
                </Button>
                {(venue?.type === OfferCategory.beauty ||
                  data?.status === ReservationStatus.noshowprocessed ||
                  data?.status === ReservationStatus.noshow) && (
                  <Button
                    variant='contained'
                    size='large'
                    onClick={onCancelReservationDialogHandle}
                  >
                    Cancel reservation
                  </Button>
                )}
              </Box>
            }
          >
            {isLoading ? (
              <>
                <Stack spacing={2}>
                  {[...Array(4)].map((_, i) => (
                    <LabelDetail
                      key={i}
                      label={<Skeleton width='80%' />}
                      description={<Skeleton width='100%' />}
                    />
                  ))}
                </Stack>
                <Stack spacing={2} mt={6}>
                  {[...Array(3)].map((_, i) => (
                    <LabelDetail
                      key={i}
                      label={<Skeleton width='80%' />}
                      description={<Skeleton width='100%' />}
                    />
                  ))}
                </Stack>
              </>
            ) : (
              <>
                <Stack spacing={2}>
                  <LabelDetail label='Offer' description={data?.offer?.title} />
                  <LabelDetail label='Description' description={data?.offer?.description} />
                  <LabelDetail
                    alignItems='center'
                    label='Offer time'
                    description={
                      offerStartTime && (
                        <DateTime
                          reservedDate={reservedDate}
                          value={offerStartTime ?? dayjs()}
                          endValue={offerEndTime}
                          showEditBtn={isEditable}
                          onEditHandle={onEditReservationDateAndTimeDialogHandle}
                        />
                      )
                    }
                  />
                  <LabelDetail
                    label='Requirements'
                    description={<Requirements values={requirements} />}
                  />
                  <LabelDetail
                    label='Reservation Status'
                    description={<StatusLabel status={data?.status} type='reservation' />}
                  />

                  <LabelDetail
                    label='Posting Status'
                    description={
                      <StatusLabel
                        status={calculateReservationPostStatus(
                          data?.checkInDateTime,
                          data?.offer?.postOnInstagramReels || data?.offer?.postOnInstagramFeed,
                          data?.offer?.postOnInstagramStories ||
                            data?.offer?.postOnInstagramStories3x,
                          data?.validation,
                        )}
                        type='post'
                      />
                    }
                  />
                </Stack>
                <Stack spacing={2} mt={6} position={'relative'}>
                  <LabelDetail label='Check in time' description={checkedInValue} />
                  <Box position={'absolute'} right={'5px'} top={'-24px'}>
                    <Switch
                      disabled={isStatusCheckedIn}
                      checked={isStatusCheckedIn}
                      onChange={(_, checked) => {
                        onCheckedInHandle(checked);
                      }}
                    />
                  </Box>
                </Stack>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item lg={4} md={5} xs={5}>
          <Paper
            title='Guest details'
            center
            action={
              <SaveUserButton saved={Boolean(userData?.savedByVenue)} userId={Number(data?.userID)}>
                Save Profile
              </SaveUserButton>
            }
          >
            <SkeletonWithChild variant='circular' loading={isLoading}>
              <LazyAvatar
                variant='circular'
                alt={String(data?.user?.name)}
                placeholderSrc={String(data?.userPicture?.thumbnail)}
                src={String(data?.userPicture?.medium)}
                sx={{
                  width: '15rem',
                  height: '15rem',
                }}
              />
            </SkeletonWithChild>

            <SkeletonWithChild loading={isLoading}>
              <Typography
                fontWeight='bold'
                variant='h5'
                mt={2}
              >{`${data?.user?.firstname} ${data?.user?.lastname}`}</Typography>
            </SkeletonWithChild>
            <SkeletonWithChild loading={isLoading}>
              <Typography component={Stack} direction='row' spacing={1}>
                <InstagramTag username={data?.user?.instagramHandle} sx={{ ml: 1 }} />

                <Box>·</Box>
                <Box>
                  <Box component='span' fontWeight='bold'>
                    {formatNumber(data?.user?.instagramFollowers ?? 0)}
                  </Box>
                  &nbsp;followers
                </Box>
              </Typography>
            </SkeletonWithChild>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReservationDetail;
