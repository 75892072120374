import * as React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';

import { Offer, OfferCategory } from '../../../graphql/API';
import Stepper from './Stepper';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Success from './components/Success';
import { useCreateInvite } from './context/CreateInvite.context';
import { EventStep, Step } from './context/types';

type StepPanelProps = {
  step: number;
  offer?: Offer;
};

const StepPanel: React.FC<StepPanelProps> = ({ step, offer }) => {
  if (offer && offer?.category === OfferCategory.event) {
    switch (step) {
      case EventStep.OfferSelection:
        return <Step1 />;
      case EventStep.Summary:
        return <Step3 />;
    }
  } else {
    switch (step) {
      case Step.OfferSelection:
        return <Step1 />;
      case Step.DateSelection:
        return <Step2 />;
      case Step.Summary:
        return <Step3 />;
    }
  }
  return null;
};

const CreateInvite: React.FC = () => {
  const { state, dispatch } = useCreateInvite();

  if (state.offer?.category === OfferCategory.event && state.step === 2) {
    return <Success />;
  }

  if (state.step === Step.Success) {
    return <Success />;
  }

  return (
    <Box>
      <Stepper activeStep={state.step} offer={state.offer} />
      {state.step > 0 && (
        <IconButton
          aria-label='delete'
          size='medium'
          color='primary'
          onClick={() => {
            dispatch({ type: 'prev' });
          }}
          sx={(theme) => ({ position: 'absolute', top: theme.spacing(1), right: theme.spacing(6) })}
        >
          <ArrowBackIcon fontSize='inherit' />
        </IconButton>
      )}

      <StepPanel step={state.step} offer={state.offer} />
    </Box>
  );
};

export default CreateInvite;
