import * as React from 'react';

import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import styled from '@emotion/styled';

import LoadingButton, { LoadingButtonProps } from '../../../../components/Button/LoadingButton';
import { StripePriceWithMetadata } from '../../../../redux/features/subscription/types';
import { State as SubscriptionState } from '../../context/types';
import { BrandPlan, LogicCondition, Plan, plans } from '../../types';
import Benefit from './Benefit';
import PlanPrice from './PlanPrice';
import { brandData, data, mapCondition } from './data';

export type PlanContentProps = {
  highlight: boolean;
  value: string;
  disabled: boolean;
  price: StripePriceWithMetadata | undefined;
  currentSubscription?: string;
  disableButton?: boolean;
  subscriptionState: SubscriptionState;
  onSubscriptionClick: (params: { value: Plan; logicCondition: LogicCondition }) => void;
  isBrand?: boolean;
};

const TooltipWithButton: React.FC<
  React.PropsWithChildren<{
    showTooptip: boolean;
    buttonProps: LoadingButtonProps;
  }>
> = ({ children, showTooptip, buttonProps }) => {
  const renderButton = React.useCallback(
    () => (
      <LoadingButton size='large' {...buttonProps}>
        {children}
      </LoadingButton>
    ),
    [buttonProps, children],
  );

  return showTooptip ? (
    <Tooltip
      arrow
      title='Please make changes to your current plan through the Beautypass app, as you initially purchased it there'
    >
      {renderButton()}
    </Tooltip>
  ) : (
    renderButton()
  );
};

const PlanContent: React.FC<PlanContentProps> = ({
  onSubscriptionClick,
  price,
  subscriptionState,
  disableButton,
  highlight,
  value,
  disabled,
  isBrand = false,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const style = React.useMemo(
    () =>
      highlight
        ? {
            border: '2px solid #B2B2B2',
            borderColor: disabled ? 'black' : 'green',
          }
        : undefined,
    [disabled, highlight],
  );

  const logicCondition = React.useMemo(
    () => mapCondition[subscriptionState.logicPlan][plans.indexOf(value as Plan)],
    [value, subscriptionState.logicPlan],
  );

  const d = React.useMemo(
    () => (isBrand ? brandData[value as BrandPlan] : data[value as Plan]),
    [value, isBrand],
  );

  const onClickHandler = React.useCallback(() => {
    const loading = onSubscriptionClick({ value: value as Plan, logicCondition });
    setIsLoading(Boolean(loading));
  }, [value, onSubscriptionClick, logicCondition]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.06)',
        border: '1px solid #E2E2E2',
        borderRadius: '12px',
        px: 3,
        py: 4,
        minHeight: '725px',
        position: 'relative',
        ...style,
      }}
    >
      <Typography
        component='div'
        variant='subtitle1'
        fontWeight={500}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '26px',
          minHeight: '42px',
        }}
      >
        {d.title}
        <span>{d.highlight}</span>
      </Typography>

      <Box sx={{ marginTop: '24px', marginBottom: '24px' }}>
        <PlanPrice price={price} />
      </Box>

      <Typography
        component='div'
        variant='subtitle1'
        sx={{
          marginTop: 5,
          marginBottom: '34px',
          display: 'flex',
          fontSize: '18px',
          fontWeight: '500',
        }}
      >
        {d.benefitTitle}
      </Typography>

      <Stack spacing={2}>
        {d.benefits.map((x, i) => (
          <Benefit key={i} color={d.benefitColor} {...x} />
        ))}
      </Stack>

      <Typography variant='body2' sx={{ marginTop: 3 }}>
        {d.tips}
      </Typography>

      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <TooltipWithButton
          showTooptip={!subscriptionState.isWebPlatform}
          buttonProps={{
            disabled: disableButton,
            size: 'large',
            variant: logicCondition.selected ? 'text' : 'outlined',
            loading: isLoading,
            onClick: subscriptionState.isWebPlatform ? onClickHandler : undefined,
            sx: {
              paddingX: '72px',
              paddingY: '10px',
              borderRadius: '8px',
              fontWeight: 500,
              fontSize: '18px',
              margin: logicCondition.selected ? '0 0 42px 0' : '16px 0',
              color: logicCondition.selected ? '#707070' : '',
              border: !logicCondition.selected ? '1.5px solid #111' : '',
            },
          }}
        >
          {logicCondition.label}
        </TooltipWithButton>
        <CancelAnytime>{value !== 'basic' ? 'Cancel anytime' : ''}</CancelAnytime>
      </Box>
    </Box>
  );
};

export default PlanContent;

const CancelAnytime = styled.span`
  display: inline-block;
  color: #5f5f60;
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
`;
