import { Link } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';

import { Invite, Offer } from '../../../graphql/API';
import { getUserCategory } from '../../../utils/user';
import UserAvatarCategory from '../../Avatar/UserAvatarCategory/UserAvatarCategory';
import Button from '../../Button/Button';
import StatusLabel from '../../StatusLabel/StatusLabel';
import { ListItem } from './types';

const mapToInviteItem = (data: Invite): ListItem => ({
  avatar: (
    <UserAvatarCategory
      src={data.user?.picture?.thumbnail || ''}
      userCategory={getUserCategory(data.user?.tags ?? [])}
    />
  ),
  primaryText: `${data.user?.firstname} ${data.user?.lastname}`,
  to: `/users/${data.userID}`,
  secondaryText: data.offer?.title || '',
  countText: undefined,
  actionSlot: <StatusLabel type='invite' status={data.status} />,
});

export const mapToInviteList = (data: Invite[]) => data.map(mapToInviteItem);

const mapToOfferItem = (data: Offer): ListItem => ({
  avatar: data.picture?.thumbnail || '',
  to: `/offers/${data.id}`,
  primaryText: data.title || '',
  secondaryText: 'Total checkins',
  countText: data.totalCheckins || 0,
  actionSlot: (
    <Button
      id={`btn-offer-edit${data.id}`}
      component={Link}
      to={`/offers/${data.id}/edit`}
      variant='contained'
      size='small'
      color='secondary'
      sx={{
        padding: '4px 10px',
        minWidth: 'unset',
      }}
    >
      <EditIcon fontSize='small' sx={{ color: 'black' }} />
    </Button>
  ),
});

export const mapToOfferList = (data: Offer[]) => data.map(mapToOfferItem);
