import * as React from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';

import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemText,
  List as MuiList,
  ListItem as MuiListItem,
  styled,
} from '@mui/material';

import { ListProps } from './types';

const StyledMuiList = styled(MuiList)(() => ({
  flex: '1',
  '& > .MuiListItem-root:last-of-type': {
    borderBottom: '1px solid #F2F2F2',
  },
}));

const ListItem = styled(MuiListItem)({
  '.MuiTypography-root': {
    fontSize: '15px',
  },
  '.MuiListItemText-primary': {
    fontWeight: 'bold',
  },
  borderBottom: '1px solid #eaeaea',
});

const SecondaryListItem = styled(ListItemText)({
  flex: '1',
  '.MuiListItemText-primary': {
    fontWeight: 'normal',
  },
  textAlign: 'left',
});

const PrimaryListItem = styled(ListItemText)({
  flex: '1.5 1',
});

const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({ children, ...restProps }) => (
  <Box
    component={RouterLink}
    sx={{
      color: '#1E55AA',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
      },
    }}
    {...restProps}
  >
    {children}
  </Box>
);

const List: React.FC<ListProps> = ({ items }) => (
  <StyledMuiList disablePadding>
    {items.map((x, i) => (
      <ListItem key={i} divider sx={{ px: 0, py: 0.5 }} secondaryAction={x.actionSlot}>
        <ListItemAvatar>
          {React.isValidElement(x.avatar) ? (
            x.avatar
          ) : (
            <Avatar variant='rounded' alt={String(x.primaryText)} src={String(x.avatar)} />
          )}
        </ListItemAvatar>
        <PrimaryListItem
          primary={
            x.to ? (
              <Link to={x.to} style={{ color: '#111111', fontSize: '15px', fontWeight: 400 }}>
                {x.primaryText}
              </Link>
            ) : (
              x.primaryText
            )
          }
          secondary={
            <>
              {x.secondaryText}
              {x.countText != undefined && (
                <Box sx={{ pl: 1 }} component='b' color='text.primary' fontWeight='bold'>
                  {x.countText}
                </Box>
              )}
            </>
          }
          secondaryTypographyProps={{ component: 'div' }}
        />
        {x.secondarySlot && <SecondaryListItem primary={x.secondarySlot} />}
      </ListItem>
    ))}
  </StyledMuiList>
);

export default List;
