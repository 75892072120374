import * as React from 'react';
import { Link } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';

import dayjs from 'dayjs';
import { type MRT_ColumnDef } from 'material-react-table';

import { Reservation } from '../../../graphql/API';
import { getUserCategory } from '../../../utils/user';
import UserAvatarCategory from '../../Avatar/UserAvatarCategory/UserAvatarCategory';
import Button from '../../Button/Button';
import StatusLabel from '../../StatusLabel/StatusLabel';

type UseColumnProps = {
  detail?: boolean;
};

const base: MRT_ColumnDef<Reservation>[] = [
  {
    accessorKey: 'userPicture.thumbnail',
    header: '',
    size: 80,
    enableSorting: false,
    Cell: ({ renderedCellValue, row }) =>
      renderedCellValue ? (
        <UserAvatarCategory
          src={String(row.original.userPicture?.thumbnail ?? '')}
          userCategory={getUserCategory(row.original.user?.tags ?? [])}
        />
      ) : null,
  },
  {
    accessorKey: 'modelName',
    accessorFn: (row) =>
      row.user?.firstname || row.user?.lastname
        ? `${row.user?.firstname} ${row.user?.lastname}`
        : undefined,
    header: 'Name',
    size: 150,
    Cell: ({ renderedCellValue, row }) => (
      <Box
        component={Link}
        to={`/users/${row.original.userID}`}
        sx={{
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
          },
          color: '#1E55AA',
        }}
      >
        {renderedCellValue}
      </Box>
    ),
  },
  {
    accessorKey: 'offerTitle',
    header: 'Offer',
    size: 150,
    Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
  },
  {
    accessorKey: 'effectiveStartDateTime',
    header: 'Offer Date',
    size: 200,
    Cell: ({ renderedCellValue }) => <>{dayjs(String(renderedCellValue)).format('LL')}</>,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 150,
    Cell: ({ renderedCellValue }) => (
      <StatusLabel type='reservation' status={String(renderedCellValue)} emptyBackground />
    ),
  },
  {
    accessorKey: 'validation',
    header: 'Posted',
    size: 150,
    Cell: ({ renderedCellValue }) => (
      <span>
        {renderedCellValue === 'validated' || renderedCellValue == 'validationUpdated' ? (
          <DoneIcon htmlColor='#37BB90' />
        ) : renderedCellValue === 'validationFailed' ? (
          <CloseIcon htmlColor='#DC5564' />
        ) : (
          '-'
        )}
      </span>
    ),
  },
];

const action: MRT_ColumnDef<Reservation> = {
  accessorKey: 'id',
  header: 'Action',
  size: 80,
  enableSorting: false,
  Cell: ({ renderedCellValue }) => (
    <Button
      component={Link}
      to={`/reservations/${renderedCellValue}`}
      variant='contained'
      size='small'
    >
      Details
    </Button>
  ),
};

const useColumn = ({ detail }: UseColumnProps): MRT_ColumnDef<Reservation>[] => {
  const columns = React.useMemo<MRT_ColumnDef<Reservation>[]>(
    () => (detail ? base.concat(action) : base),
    [detail],
  );

  return columns;
};

export default useColumn;
