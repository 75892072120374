export const environments = ['local', 'dev', 'production'] as const;

export type Environment = (typeof environments)[number];

export type AwsConfig = {
  aws_project_region: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_mobile_analytics_app_id: string;
  aws_mobile_analytics_app_region: string;
  Analytics: {
    /** MARK: otherwise it raises an error during local development */
    disabled: boolean;
  };
};

export type AppConfig = {
  stripKey: string;
  googleMapApiKey: string;
};
