import * as React from 'react';

import { DialogProps } from '@mui/material/Dialog';

import OfferPreviewCalendar from '../../Calendar/OfferPreviewCalendar/OfferPreviewCalendar';
import BaseDialog from '../Base';

const CalendarDialog: React.FC<DialogProps> = ({ ...props }) => {
  return (
    <BaseDialog height='80%' width='70%' {...props}>
      <OfferPreviewCalendar />
    </BaseDialog>
  );
};

export default CalendarDialog;
