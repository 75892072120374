export const subscriptionConditionKinds = [
  'cycle-change-increase',
  'cycle-change-decrease',
  'plan-upgrade',
  'plan-downgrade',
  'plan-cancel',
] as const;
export const plans = ['basic', 'starter', 'plus'] as const;
export const brandPlans = ['starter'] as const;
export const billingCycles = ['1', '6', '12'] as const;

export type SubscriptionConditionKind = (typeof subscriptionConditionKinds)[number];
export type Plan = (typeof plans)[number];
export type BrandPlan = (typeof brandPlans)[number];
export type BillingCycle = (typeof billingCycles)[number];

export type LogicCondition = {
  label: string;
  kind: SubscriptionConditionKind;
  selected: boolean;
};
