import * as React from 'react';

import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Box, Stack, Typography } from '@mui/material';

import SvgIcon from '../../Icons';

const SemiBold: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Box component='span' fontWeight='medium'>
    {children}
  </Box>
);

const TitleWithDescription: React.FC<React.PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => (
  <Box>
    <Typography sx={{fontSize:"20px", fontWeight:"600"}} marginBottom={1}>
      {title}
    </Typography>
    <Typography sx={{fontSize:"15px", fontWeight:"normal"}}>{children}</Typography>
  </Box>
);

const DescriptionBox: React.FC<React.PropsWithChildren<{ icon: React.ReactNode }>> = ({
  children,
  icon,
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#FAFAFA',
      borderRadius: 1,
      px: 3,
      py: 2,
    }}
  >
    {icon}
    <Typography>{children}</Typography>
  </Box>
);

const AccessRequestDesc: React.FC = () => (
  <Stack spacing={4} padding={2}>
    <TitleWithDescription title='What is access request?'>
      When you create a <Typography sx={{fontSize:"15px",fontWeight:"medium",display:"inline"}}>Public offer</Typography>, you can set the type of access that users
      have by choosing from three options
      <br />
      <Typography sx={{fontSize:"15px",fontWeight:"medium",display:"inline"}}>(Accept all / Permission required / Reject all)</Typography>
    </TitleWithDescription>

    <TitleWithDescription title='Permission required'>
      Choosing the <Typography sx={{fontSize:"15px",fontWeight:"medium",display:"inline"}}>'Permission required'</Typography> option will require users in that category to
      request access, and it will be up to you to decide whether to grant access to that user.
    </TitleWithDescription>

    <Stack spacing={2}>
      <DescriptionBox
        icon={
          <SvgIcon
            name='Key'
            sx={{
              fontSize: '20px',
              transform: 'rotate(45deg)',
              marginRight: 2,
              fill: '#37BB90',
            }}
          />
        }
      >
        <Box component='span' sx={{ color: '#37BB90', fontSize:'15px', fontWeight:'medium' }}>
          When you accept a user
        </Box>
        <Box component='span' sx={{ color: '#111111', fontSize:'15px', fontWeight:'regular' }}>
        , you grant them permission to reserve your offer
        </Box>
      </DescriptionBox>

      <DescriptionBox
        icon={
          <DoDisturbIcon
            sx={{
              fontSize: '20px',
              marginRight: 2,
              fill: '#D12D2D',
            }}
          />
        }
      >
        <Box component='span' sx={{ color: '#D12D2D',fontSize:'15px', fontWeight:'medium' }}>
          When you reject a user
        </Box>
        <Box component='span' sx={{ color: '#111111',fontSize:'15px', fontWeight:'regular' }}>
        , she will no longer see that offer
        </Box>
      </DescriptionBox>
    </Stack>
  </Stack>
);

export default AccessRequestDesc;
