import AddIcon from '@mui/icons-material/Add';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { ReactComponent as AttentionIcon } from '../assets/icons/attention-icon.svg';
import { ReactComponent as ChatIcon } from '../assets/icons/chat.svg';
import { ReactComponent as CollabOfferIcon } from '../assets/icons/collabOffer.svg';
import { ReactComponent as CountryIcon } from '../assets/icons/country.svg';
import { ReactComponent as EventOfferIcon } from '../assets/icons/event-offer.svg';
import { ReactComponent as HeartIcon } from '../assets/icons/heart.svg';
import { ReactComponent as InfoLightIcon } from '../assets/icons/info-light.svg';
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';
import { ReactComponent as MessageIcon } from '../assets/icons/message.svg';
import { ReactComponent as RecruitOfferIcon } from '../assets/icons/recuritingOffer.svg';
import { ReactComponent as SpecialOfferIcon } from '../assets/icons/specialOffer.svg';
import { ReactComponent as StatsIcon } from '../assets/icons/stats.svg';
import { ReactComponent as VideoIcon } from '../assets/icons/video.svg';

export default {
  ChatIcon,
  DoneAllIcon,
  CollabOfferIcon,
  RecruitOfferIcon,
  SpecialOfferIcon,
  PlaceRoundedIcon,
  AddIcon,
  PlayArrowIcon,
  AttentionIcon,
  StatsIcon,
  InfoIcon,
  VideoIcon,
  MessageIcon,
  HeartIcon,
  CountryIcon,
  EventOfferIcon,
  InfoLightIcon,
};
