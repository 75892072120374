import * as React from 'react';

import { Box } from '@mui/material';

import PageTitle from '../../components/PageTitle/PageTitle';
import ReservationsTable from '../../components/Tables/Reservation/Reservation';

const Reservation: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: 'white', flex: 1, p: 4 }}>
      <PageTitle title='Reservations' />

      <Box mt={2}>
        <ReservationsTable
          height='calc(100vh - 245px)'
          detail={true}
          filters={{
            sortBy: 'createdAt',
            sortDirection: 'desc',
          }}
        />
      </Box>
    </Box>
  );
};

export default Reservation;
