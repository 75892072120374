import * as React from 'react';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Lottie from 'lottie-react';

import mailSent from '../../../Icons/lottie/mailSent.json';
import { useCreateInvite } from '../context/CreateInvite.context';

const Success: React.FC = () => {
  const { mutation } = useCreateInvite();

  return mutation.isSuccess ? (
    <Box
      height='100%'
      display='flex'
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
    >
      <Stack spacing={2} mt={-10} alignItems='center'>
        <Box component={Lottie} animationData={mailSent} loop={true} sx={{ width: 150 }} />

        <Box>
          <Typography variant='h5' textAlign='center' fontWeight='bold'>
            Invitation sent!
          </Typography>
          <Typography
            component='div'
            variant='body2'
            textAlign='center'
            width='17rem'
            margin='auto'
          >
            We have notified our members. They have 24 hours to respond to your invitation
          </Typography>
        </Box>
      </Stack>
    </Box>
  ) : (
    <Box display='flex' justifyContent='center' height='100%' alignItems='center'>
      <CircularProgress />
    </Box>
  );
};

export default Success;
