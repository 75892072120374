/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type AgencyCreateRequest = {
  userID?: number | null,
  venueID?: number | null,
};

export type UserActionAgencyRequest = {
  inviteID: number,
  status: string,
  notificationID?: number | null,
};

export type AgencyMarkInviteSeenRequest = {
  inviteIDs: Array< number >,
};

export type AgencyInviteAgreementSignRequest = {
  inviteID: number,
};

export type ResetPasswordInput = {
  token?: string | null,
  pwd?: string | null,
};

export type AdminCreateMarketInput = {
  name: string,
  stateProvince: string,
  country: string,
  countryCode?: string | null,
  currency?: string | null,
  location: LocationInput,
  timezone: TimezoneInput,
  unitSystem?: UnitSystem | null,
  status: MarketStatus,
  tipEnabled?: boolean | null,
};

export type LocationInput = {
  lat: number,
  lon: number,
};

export type TimezoneInput = {
  dstOffset: number,
  rawOffset: number,
  timeZoneId: string,
  timeZoneName: string,
};

export enum UnitSystem {
  imperial = "imperial",
  metric = "metric",
}


export enum MarketStatus {
  active = "active",
  disabled = "disabled",
  archived = "archived",
}


export type AdminUpdateMarketInput = {
  id: string,
  name?: string | null,
  stateProvince?: string | null,
  country?: string | null,
  countryCode?: string | null,
  currency?: string | null,
  location?: LocationInput | null,
  timezone?: TimezoneInput | null,
  unitSystem?: UnitSystem | null,
  status?: MarketStatus | null,
  tipEnabled?: boolean | null,
};

export type AdminCreateVenueInput = {
  name: string,
  type: OfferCategory,
  phone?: string | null,
  email: string,
  password: string,
  venueImageKey?: string | null,
  contactFirstname?: string | null,
  contactLastname?: string | null,
  contactPosition?: string | null,
  location: LocationInput,
  address1: string,
  address2?: string | null,
  city: string,
  postalCode?: string | null,
  stateProvince: string,
  country: string,
  countryCode?: string | null,
  dependentLocality?: string | null,
  website?: string | null,
  instagramHandle?: string | null,
  description?: string | null,
  status: VenueStatus,
  approvalDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  marketID?: string | null,
  lastSeen?: string | null,
  id?: number | null,
  checkInRadius?: number | null,
  notes?: string | null,
  formattedAddress?: string | null,
  place_id?: string | null,
  isAgency?: boolean | null,
};

export enum OfferCategory {
  beauty = "beauty",
  drink = "drink",
  experience = "experience",
  fitness = "fitness",
  food = "food",
  event = "event",
  boutique = "boutique",
  unknown = "unknown",
  brand = "brand",
}


export enum VenueStatus {
  active = "active",
  inactive = "inactive",
  rejected = "rejected",
  pending = "pending",
  blocked = "blocked",
  deleted = "deleted",
}


export type AdminUpdateVenueInput = {
  id: string,
  name?: string | null,
  type?: OfferCategory | null,
  phone?: string | null,
  email?: string | null,
  venueImageKey?: string | null,
  contactFirstname?: string | null,
  contactLastname?: string | null,
  contactPosition?: string | null,
  location?: LocationInput | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  postalCode?: string | null,
  stateProvince?: string | null,
  country?: string | null,
  countryCode?: string | null,
  dependentLocality?: string | null,
  website?: string | null,
  instagramHandle?: string | null,
  profession?: Profession | null,
  description?: string | null,
  status?: VenueStatus | null,
  approvalDate?: string | null,
  lastSeen?: string | null,
  closedDates?: Array< string | null > | null,
  checkInRadius?: number | null,
  notes?: string | null,
  tags?: Array< string | null > | null,
  formattedAddress?: string | null,
  availableDownloads?: number | null,
  place_id?: string | null,
};

export enum Profession {
  actor = "actor",
  model = "model",
  blogger = "blogger",
  dj = "dj",
  photographer = "photographer",
  entrepreneur = "entrepreneur",
  athlete = "athlete",
  designer = "designer",
  chef = "chef",
  artist = "artist",
  musician = "musician",
  writer = "writer",
  influencer = "influencer",
}


export type AdminUpdateVenuesInput = {
  ids?: Array< string | null > | null,
  tags?: Array< string | null > | null,
};

export type VenueConnectVenueInput = {
  username: string,
  password: string,
};

export type VenueConnectVenueResponse = {
  __typename: "VenueConnectVenueResponse",
  connectedVenueID?: string | null,
  message?: string | null,
};

export type EditStaffValuesInput = {
  id: string,
  email: string,
  password: string,
  role: string,
  marketIds?: Array< string | null > | null,
};

export type AdminCreateOfferInput = {
  title: string,
  venueID: string,
  offerImageKey?: string | null,
  description?: string | null,
  category: OfferCategory,
  subCategory?: FoodCategories | null,
  availableTo: Array< Profession | null >,
  gender: Array< Gender | null >,
  offerValue: number,
  startDate: string,
  endDate?: string | null,
  startTime: string,
  endTime: string,
  spots: number,
  waitTime?: number | null,
  availableDays?: Array< DaysOfWeek | null > | null,
  dressCode?: string | null,
  includeSelfInPost?: boolean | null,
  includeServiceOrProductInPost?: boolean | null,
  instagramTag?: string | null,
  postOnInstagramFeed?: boolean | null,
  postOnInstagramStories?: boolean | null,
  postOnInstagramReels?: boolean | null,
  postOnInstagramStories3x?: boolean | null,
  tipType?: TipType | null,
  suggestedTip?: string | null,
  status: OfferStatus,
  createdAt?: string | null,
  updatedAt?: string | null,
  id?: number | null,
  additionalreq?: string | null,
  minFollowers?: number | null,
  confirmWithEmail?: boolean | null,
  confirmWithCall?: boolean | null,
  premium?: boolean | null,
  private?: boolean | null,
  lifestyleMembersAccess?: OfferAccessType | null,
  selectMembersAccess?: OfferAccessType | null,
  location?: LocationInput | null,
  address?: string | null,
};

export enum FoodCategories {
  Breakfast = "Breakfast",
  Lunch = "Lunch",
  Dinner = "Dinner",
  Snacks = "Snacks",
}


export enum Gender {
  female = "female",
  male = "male",
}


export enum DaysOfWeek {
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
  sunday = "sunday",
}


export enum TipType {
  percent = "percent",
  amount = "amount",
}


export enum OfferStatus {
  active = "active",
  cancelled = "cancelled",
  pending = "pending",
  rejected = "rejected",
  deleted = "deleted",
  ended = "ended",
  inactive = "inactive",
}


export enum OfferAccessType {
  acceptAll = "acceptAll",
  rejectAll = "rejectAll",
  permissionRequired = "permissionRequired",
}


export type AdminUpdateOfferInput = {
  id: string,
  title?: string | null,
  venueID?: string | null,
  description?: string | null,
  offerImageKey?: string | null,
  category?: OfferCategory | null,
  subCategory?: FoodCategories | null,
  availableTo?: Array< Profession | null > | null,
  gender?: Array< Gender | null > | null,
  offerValue?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  spots?: number | null,
  waitTime?: number | null,
  availableDays?: Array< DaysOfWeek | null > | null,
  dressCode?: string | null,
  includeSelfInPost?: boolean | null,
  includeServiceOrProductInPost?: boolean | null,
  instagramTag?: string | null,
  postOnInstagramFeed?: boolean | null,
  postOnInstagramStories?: boolean | null,
  postOnInstagramReels?: boolean | null,
  postOnInstagramStories3x?: boolean | null,
  tipType?: TipType | null,
  suggestedTip?: string | null,
  status?: OfferStatus | null,
  additionalreq?: string | null,
  minFollowers?: number | null,
  force?: boolean | null,
  confirmWithEmail?: boolean | null,
  confirmWithCall?: boolean | null,
  originalOfferID?: number | null,
  premium?: boolean | null,
  private?: boolean | null,
  lifestyleMembersAccess?: OfferAccessType | null,
  selectMembersAccess?: OfferAccessType | null,
  location?: LocationInput | null,
  address?: string | null,
};

export type AdminCreateUserInput = {
  email: string,
  phone?: string | null,
  firstname: string,
  lastname: string,
  birthdate: string,
  password: string,
  instagramHandle?: string | null,
  instagramFollowers?: number | null,
  profession: Profession,
  gender: Gender,
  agency?: string | null,
  motherAgency?: string | null,
  country?: string | null,
  location?: LocationInput | null,
  profileImageKey?: string | null,
  compositeCardFrontImageKey?: string | null,
  compositeCardBackImageKey?: string | null,
  acceptedCodeOfConduct?: boolean | null,
  status: UserStatus,
  lastSeen?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  approvalDate?: string | null,
  id?: string | null,
  tags?: Array< string | null > | null,
  agencyProfileUrl?: string | null,
  freelancerBodyProfile?: FreelancerBodyProfileInput | null,
  freelancerCloseUpImageKey?: string | null,
  freelancerWaistUpImageKey?: string | null,
  freelancerFullLengthImageKey?: string | null,
  freelancerProfileImageKey?: string | null,
  freelancer?: boolean | null,
  scoutingEnabled?: boolean | null,
  profileVideo?: string | null,
};

export enum UserStatus {
  active = "active",
  pending = "pending",
  blocked = "blocked",
  rejected = "rejected",
  suspended = "suspended",
  noinstagram = "noinstagram",
  deleted = "deleted",
}


export type FreelancerBodyProfileInput = {
  height: number,
  waist: number,
  bust: number,
  hips: number,
};

export type AdminUpdateUserInput = {
  id: string,
  email?: string | null,
  phone?: string | null,
  firstname?: string | null,
  lastname?: string | null,
  birthdate?: string | null,
  instagramHandle?: string | null,
  instagramFollowers?: number | null,
  profession?: Profession | null,
  gender?: Gender | null,
  agency?: string | null,
  motherAgency?: string | null,
  country?: string | null,
  location?: LocationInput | null,
  profileImageKey?: string | null,
  compositeCardFrontImageKey?: string | null,
  compositeCardBackImageKey?: string | null,
  acceptedCodeOfConduct?: boolean | null,
  status?: UserStatus | null,
  reactivationDate?: string | null,
  lastSeen?: string | null,
  requestInstagramVerification?: boolean | null,
  tags?: Array< string | null > | null,
  agencyProfileUrl?: string | null,
  freelancerBodyProfile?: FreelancerBodyProfileInput | null,
  freelancerCloseUpImageKey?: string | null,
  freelancerWaistUpImageKey?: string | null,
  freelancerFullLengthImageKey?: string | null,
  freelancerProfileImageKey?: string | null,
  freelancer?: boolean | null,
  noshowCredits?: number | null,
  instagramVerified?: boolean | null,
  webProfilePrivate?: boolean | null,
  tiktokHandle?: string | null,
  xHandle?: string | null,
  youtubeHandle?: string | null,
  scoutingEnabled?: boolean | null,
  profileVideo?: string | null,
};

export type AdminUpdateUsersInput = {
  ids?: Array< string | null > | null,
  status?: UserStatus | null,
  tags?: Array< string | null > | null,
};

export type AdminUpdateUserInstagramInput = {
  id: string,
  instagramHandle: string,
};

export type AdminInstagramScrapeInput = {
  instagramHandle: string,
};

export type AdminUpdatePasswordInput = {
  id: string,
  entityType: string,
  password: string,
};

export type AdminMergeUsersInput = {
  delUserID: number,
  keepUserID: number,
};

export type AdminUpdateReservationInput = {
  id: string,
  note?: string | null,
  requirementsSubmitted?: boolean | null,
  noShowStatus?: NoShowStatus | null,
  status?: ReservationStatus | null,
  checkInDateTime?: string | null,
  validation?: string | null,
  effectiveStartDateTime?: string | null,
  effectiveEndDateTime?: string | null,
};

export enum NoShowStatus {
  active = "active",
  archived = "archived",
}


export enum ReservationStatus {
  reserved = "reserved",
  checkedin = "checkedin",
  cancelled = "cancelled",
  noshow = "noshow",
  noshowprocessed = "noshowprocessed",
  disputed = "disputed",
  deleted = "deleted",
}


export type SupportReviewReservationInput = {
  ids: Array< string | null >,
  validation: ReservationReviewType,
  type?: Array< OfferRequirements | null > | null,
  warnings?: Array< ReservationWarningType | null > | null,
};

export enum ReservationReviewType {
  validated = "validated",
  validationFailed = "validationFailed",
}


export enum OfferRequirements {
  dressCode = "dressCode",
  includeSelfInPost = "includeSelfInPost",
  includeServiceOrProductInPost = "includeServiceOrProductInPost",
  instagramTag = "instagramTag",
  postOnInstagramFeed = "postOnInstagramFeed",
  postOnInstagramStories = "postOnInstagramStories",
  postOnInstagramReels = "postOnInstagramReels",
  postOnInstagramStories3x = "postOnInstagramStories3x",
  yelpReview = "yelpReview",
  tipStaff = "tipStaff",
  User_did_not_post = "User_did_not_post",
  Incomplete_requirements = "Incomplete_requirements",
  Unsuitable_photo_or_video = "Unsuitable_photo_or_video",
  Block_this_member = "Block_this_member",
  Other = "Other",
}


export enum ReservationWarningType {
  UserNotInPicture = "UserNotInPicture",
  StoryTooShort = "StoryTooShort",
  TagNotVisible = "TagNotVisible",
  StoryWithoutContext = "StoryWithoutContext",
  NoReposts = "NoReposts",
}


export type AdminCreatePenaltyInput = {
  userID: string,
  reservationID: string,
  type: Array< OfferRequirements | null >,
};

export type AdminUpdatePenaltyInput = {
  id: string,
  reservationID?: string | null,
  type?: Array< OfferRequirements | null > | null,
  status?: PenaltyStatus | null,
};

export enum PenaltyStatus {
  active = "active",
  archived = "archived",
}


export type AdminCreateSuspensionInput = {
  userID: string,
  durationInDays: number,
  startDate: string,
  endDate: string,
  reason: SuspensionReason,
  detail?: string | null,
  status: SuspensionStatus,
};

export enum SuspensionReason {
  penalty = "penalty",
  noshow = "noshow",
  admin = "admin",
  noinstagram = "noinstagram",
}


export enum SuspensionStatus {
  active = "active",
  archived = "archived",
}


export type AdminUpdateSuspensionInput = {
  id: string,
  durationInDays?: number | null,
  reason?: SuspensionReason | null,
  detail?: string | null,
  status?: SuspensionStatus | null,
};

export type AdminCreateCampaignInput = {
  title?: string | null,
  content?: string | null,
  selectedMarketIDList?: Array< number | null > | null,
  selectAllMarkets?: boolean | null,
  type: NotificationType,
  selectedUserIDList?: Array< number | null > | null,
  selectedVenueIDList?: Array< number | null > | null,
  selectedEntities?: Array< AdminCreateCampaignEntity | null > | null,
  date: string,
  offerID?: number | null,
  venueID?: number | null,
  screen?: string | null,
};

export enum NotificationType {
  email = "email",
  sms = "sms",
  push = "push",
}


export enum AdminCreateCampaignEntity {
  user = "user",
  venue = "venue",
}


export type AdminUpdateCampaignInput = {
  id: string,
  title?: string | null,
  content?: string | null,
  selectedMarketIDList?: Array< number | null > | null,
  selectAllMarkets?: boolean | null,
  type: NotificationType,
  selectedUserIDList?: Array< number | null > | null,
  selectedVenueIDList?: Array< number | null > | null,
  selectedEntities?: Array< AdminCreateCampaignEntity | null > | null,
  date: string,
  status?: AdminCampaignStatus | null,
  offerID?: number | null,
  venueID?: number | null,
  screen?: string | null,
};

export enum AdminCampaignStatus {
  created = "created",
  approved = "approved",
  sent = "sent",
}


export type AdminMarkUserComplaintInput = {
  id: string,
  status: ComplaintStatus,
  rejectionReason?: string | null,
};

export enum ComplaintStatus {
  pending = "pending",
  read = "read",
  archived = "archived",
  rejected = "rejected",
}


export type UserUpdateMutedVenueInput = {
  userID?: string | null,
  venueID?: string | null,
  muted?: boolean | null,
};

export type UserUpdateVenueFavouriteInput = {
  userID?: string | null,
  venueID?: string | null,
  favourite?: boolean | null,
};

export type UserVenueRateInput = {
  venueID: string,
  reservationID: number,
  feedback?: string | null,
  positive: boolean,
};

export type UserCreateUserInput = {
  email: string,
  phone?: string | null,
  firstname: string,
  lastname: string,
  birthdate: string,
  password: string,
  instagramHandle: string,
  instagramFollowers: number,
  profession: Profession,
  gender: Gender,
  agency: string,
  motherAgency?: string | null,
  country?: string | null,
  location?: LocationInput | null,
  profileImageKey: string,
  compositeCardFrontImageKey?: string | null,
  compositeCardBackImageKey?: string | null,
  platform?: string | null,
  tncVersion?: string | null,
  agencyProfileUrl?: string | null,
  freelancerBodyProfile?: FreelancerBodyProfileInput | null,
  freelancerCloseUpImageKey?: string | null,
  freelancerWaistUpImageKey?: string | null,
  freelancerFullLengthImageKey?: string | null,
  freelancerProfileImageKey?: string | null,
  freelancer?: boolean | null,
  scoutingEnabled?: boolean | null,
  profileVideo?: string | null,
  tiktokHandle?: string | null,
  xHandle?: string | null,
  youtubeHandle?: string | null,
};

export type UserUpdateUserInput = {
  id: string,
  phone?: string | null,
  firstname?: string | null,
  lastname?: string | null,
  country?: string | null,
  location?: LocationInput | null,
  profileImageKey?: string | null,
  compositeCardFrontImageKey?: string | null,
  compositeCardBackImageKey?: string | null,
  acceptedCodeOfConduct?: boolean | null,
  status?: UserStatus | null,
  platform?: string | null,
  tncVersion?: string | null,
  reservationRewardClaimed?: boolean | null,
  agency?: string | null,
  agencyProfileUrl?: string | null,
  motherAgency?: string | null,
  webProfilePrivate?: boolean | null,
  tiktokHandle?: string | null,
  xHandle?: string | null,
  youtubeHandle?: string | null,
  scoutingEnabled?: boolean | null,
  profileVideo?: string | null,
  instagramHandle?: string | null,
  freelancerCloseUpImageKey?: string | null,
  freelancerWaistUpImageKey?: string | null,
  freelancerFullLengthImageKey?: string | null,
  freelancerProfileImageKey?: string | null,
  freelancerBodyProfile?: FreelancerBodyProfileInput | null,
};

export type UserCreatePortfolioInput = {
  portfolio: string,
};

export type UserDeletePortfolioInput = {
  id: string,
};

export type UserUpdateEmailInput = {
  userID: string,
  newEmail: string,
  oldEmail: string,
};

export type UserUpdatePasswordInput = {
  userID: string,
  newPassword: string,
  oldPassword: string,
};

export type UserCreateReservationInput = {
  offerID: string,
  userID: string,
  startDateTime: string,
};

export type UserActionInviteInput = {
  id: number,
  status: InviteType,
  startDateTime?: string | null,
};

export enum InviteType {
  available = "available",
  accepted = "accepted",
  declined = "declined",
  expired = "expired",
}


export type UserMarkInviteInput = {
  invites: Array< number | null >,
};

export type UserCreateReservationContentInput = {
  reservationID?: string | null,
  content?: Array< string | null > | null,
};

export type UserUpdateReservationInput = {
  id: string,
  checkInDateTime?: string | null,
  requirementsSubmitted?: boolean | null,
  checkin?: boolean | null,
  status?: UserUpdateStatus | null,
  postLink?: string | null,
};

export enum UserUpdateStatus {
  reserved = "reserved",
  checkedin = "checkedin",
  cancelled = "cancelled",
}


export type UserUpdateReservationContentInput = {
  id: string,
  hidden: boolean,
};

export type UserCreateAccessRequestInput = {
  offerID: number,
};

export type UserCancelAccessRequestInput = {
  offerID: number,
};

export type VenueActionAccessRequest = {
  offerID: number,
  userID: number,
  action: VenueAccessREnum,
};

export enum VenueAccessREnum {
  accepted = "accepted",
  declined = "declined",
}


export type UserCreateComplaintInput = {
  items: Array< ComplaintItemInput >,
};

export type ComplaintItemInput = {
  name: string,
  value: string,
  title: string,
};

export type VenueCreateComplaintInput = {
  items: Array< ComplaintItemInput >,
};

export type VenueCreatePenaltyInput = {
  userID: string,
  reservationID: string,
  type: Array< OfferRequirements | null >,
  venueID: string,
};

export type UserUpdateNotificationInput = {
  ids?: Array< string | null > | null,
  status: UserNotificationStatus,
};

export enum UserNotificationStatus {
  pending = "pending",
  sent = "sent",
  read = "read",
  created = "created",
}


export type VenueLeadInput = {
  userID: string,
  name: string,
  address: string,
  website?: string | null,
  instagram?: string | null,
  contactName: string,
  contactNumber: string,
  contactEmail?: string | null,
};

export type VenueLeadUpdateInput = {
  id: number,
  userID?: string | null,
  name?: string | null,
  address?: string | null,
  website?: string | null,
  instagram?: string | null,
  contactName?: string | null,
  contactNumber?: string | null,
};

export type VenueCreateVenueInput = {
  name: string,
  type: OfferCategory,
  phone?: string | null,
  email: string,
  password: string,
  contactFirstname?: string | null,
  contactLastname?: string | null,
  contactPosition?: string | null,
  location: LocationInput,
  address1: string,
  address2?: string | null,
  city: string,
  postalCode?: string | null,
  stateProvince: string,
  country: string,
  countryCode?: string | null,
  dependentLocality?: string | null,
  website?: string | null,
  instagramHandle?: string | null,
  description?: string | null,
  platform?: string | null,
  venueImageKey?: string | null,
  tncVersion?: string | null,
  masterVenueID?: number | null,
  formattedAddress?: string | null,
  place_id?: string | null,
  referralInstagramHandle?: string | null,
};

export type VenueCreatePortfolioInput = {
  portfolio: string,
};

export type VenueDeletePortfolioInput = {
  id: string,
};

export type VenueUserRateInput = {
  userID: string,
  reservationID: number,
  feedback?: string | null,
  rating: number,
};

export type VenueUpdateVenueInput = {
  id: string,
  name?: string | null,
  type?: OfferCategory | null,
  phone?: string | null,
  venueImageKey?: string | null,
  contactFirstname?: string | null,
  contactLastname?: string | null,
  contactPosition?: string | null,
  location?: LocationInput | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  postalCode?: string | null,
  stateProvince?: string | null,
  country?: string | null,
  countryCode?: string | null,
  dependentLocality?: string | null,
  website?: string | null,
  instagramHandle?: string | null,
  profession?: Profession | null,
  description?: string | null,
  closedDates?: Array< string | null > | null,
  status?: VenueStatus | null,
  platform?: string | null,
  enabledEmailNotifications?: boolean | null,
  tncVersion?: string | null,
  newContentAvailable?: boolean | null,
  masterVenueID?: number | null,
  formattedAddress?: string | null,
  lifestyleMembersAccess?: OfferAccessType | null,
  selectMembersAccess?: OfferAccessType | null,
  place_id?: string | null,
  termsAccepted?: boolean | null,
};

export type VenueUpdateEmailInput = {
  venueID: string,
  newEmail: string,
  oldEmail: string,
};

export type VenueUpdatePasswordInput = {
  venueID: string,
  newPassword: string,
  oldPassword: string,
};

export type VenueBlockUserInput = {
  venueID: number,
  userID: number,
};

export type VenueSaveUserInput = {
  venueID: number,
  userID: number,
};

export type VenueCreateOfferInput = {
  title: string,
  venueID: string,
  offerImageKey?: string | null,
  description?: string | null,
  category: OfferCategory,
  subCategory?: FoodCategories | null,
  availableTo: Array< Profession | null >,
  gender: Array< Gender | null >,
  offerValue: number,
  startDate: string,
  endDate?: string | null,
  startTime: string,
  endTime: string,
  spots: number,
  waitTime?: number | null,
  availableDays?: Array< DaysOfWeek | null > | null,
  dressCode?: string | null,
  includeSelfInPost?: boolean | null,
  includeServiceOrProductInPost?: boolean | null,
  instagramTag?: string | null,
  postOnInstagramFeed?: boolean | null,
  postOnInstagramStories?: boolean | null,
  postOnInstagramReels?: boolean | null,
  postOnInstagramStories3x?: boolean | null,
  tipType?: TipType | null,
  suggestedTip?: string | null,
  status: OfferStatus,
  additionalreq?: string | null,
  minFollowers?: number | null,
  confirmWithEmail?: boolean | null,
  confirmWithCall?: boolean | null,
  premium?: boolean | null,
  private?: boolean | null,
  lifestyleMembersAccess?: OfferAccessType | null,
  selectMembersAccess?: OfferAccessType | null,
  location?: LocationInput | null,
  address?: string | null,
};

export type VenueCreateInviteInput = {
  userID: Array< number | null >,
  offerID: number,
  venueID: number,
  availableDates: Array< string | null >,
  startTime: string,
  endTime: string,
};

export type VenueCreateInviteInputV2 = {
  userIDsWithOfferPrice: Array< UserIDsWithOfferPrice | null >,
  offerID: number,
  venueID: number,
  availableDates: Array< string | null >,
  startTime: string,
  endTime: string,
  requirements: OfferRequirements,
};

export type UserIDsWithOfferPrice = {
  userID: number,
  offerPrice: number,
};

export type VenueDeleteInviteInput = {
  id?: number | null,
};

export type VenueUpdateOfferInput = {
  id: string,
  title?: string | null,
  venueID?: string | null,
  description?: string | null,
  offerImageKey?: string | null,
  category?: OfferCategory | null,
  subCategory?: FoodCategories | null,
  availableTo?: Array< Profession | null > | null,
  gender?: Array< Gender | null > | null,
  offerValue?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  spots?: number | null,
  waitTime?: number | null,
  availableDays?: Array< DaysOfWeek | null > | null,
  dressCode?: string | null,
  includeSelfInPost?: boolean | null,
  includeServiceOrProductInPost?: boolean | null,
  instagramTag?: string | null,
  postOnInstagramFeed?: boolean | null,
  postOnInstagramStories?: boolean | null,
  postOnInstagramReels?: boolean | null,
  postOnInstagramStories3x?: boolean | null,
  tipType?: TipType | null,
  suggestedTip?: string | null,
  status?: OfferStatus | null,
  additionalreq?: string | null,
  minFollowers?: number | null,
  confirmWithEmail?: boolean | null,
  confirmWithCall?: boolean | null,
  premium?: boolean | null,
  lifestyleMembersAccess?: OfferAccessType | null,
  selectMembersAccess?: OfferAccessType | null,
  location?: LocationInput | null,
  address?: string | null,
  requestVersion?: string | null,
};

export type VenueUpdateReservationContentInput = {
  id: string,
  seen: boolean,
};

export type VenueDownloadReservationContentInput = {
  id?: string | null,
};

export type AdminDownloadReservationContentInput = {
  id?: string | null,
  downloaded?: boolean | null,
};

export type VenueMarkReservationContentInput = {
  userID: string,
};

export type VenueMarkReservationContentInputV2 = {
  venueID: string,
};

export type VenueUpdateSubscriptionInput = {
  subscriptionId?: string | null,
  newPriceId?: string | null,
};

export type VenueUpdateReservationInput = {
  id: string,
  status?: UserUpdateStatus | null,
  effectiveStartDateTime?: string | null,
  effectiveEndDateTime?: string | null,
};

export type VenueUpdateNotificationInput = {
  ids?: Array< string | null > | null,
  status: VenueNotificationStatus,
};

export enum VenueNotificationStatus {
  pending = "pending",
  sent = "sent",
  read = "read",
  created = "created",
}


export type CreateFeedbackInput = {
  message: string,
};

export type AppEventInput = {
  eventType: AppEventType,
  deviceId: string,
  appVersion?: string | null,
  locale?: string | null,
  deviceMake?: string | null,
  deviceModel?: string | null,
  deviceModelVersion?: string | null,
  platform?: string | null,
  platformVersion?: string | null,
  timezone?: string | null,
  city?: string | null,
  countryCode?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  postalCode?: string | null,
  region?: string | null,
  appPackageName?: string | null,
  appTitle?: string | null,
  appVersionCode?: string | null,
};

export enum AppEventType {
  _session_start = "_session_start",
  _session_stop = "_session_stop",
  _userauth_sign_in = "_userauth_sign_in",
  _userauth_sign_up = "_userauth_sign_up",
  _userauth_auth_fail = "_userauth_auth_fail",
  _session_pause = "_session_pause",
  _session_resume = "_session_resume",
}


export type AdminConfigInput = {
  data: string,
  type: string,
};

export type NewAdminChatMessage = {
  userChatId: string,
  message: string,
  conversationId?: string | null,
  userType?: Entity | null,
  messageType?: string | null,
};

export enum Entity {
  user = "user",
  venue = "venue",
}


export type ChatMessageItem = {
  __typename: "ChatMessageItem",
  fromUserId: string,
  toUserId: string,
  conversationId: string,
  userType: string,
  messageTime: string,
  message?: string | null,
  messageType?: string | null,
  received?: boolean | null,
  seen?: boolean | null,
  rejected?: boolean | null,
  url?: string | null,
  title?: string | null,
  thumbnail?: string | null,
  complaintID?: string | null,
};

export type NewUserAdminChatMessage = {
  message: string,
  conversationId?: string | null,
  messageType?: string | null,
  url?: string | null,
  thumbnail?: string | null,
  title?: string | null,
};

export type NewVenueAdminChatMessage = {
  userChatId: string,
  message: string,
  conversationId?: string | null,
  messageType?: string | null,
  url?: string | null,
  thumbnail?: string | null,
  title?: string | null,
};

export type ChatMessageUpdateInput = {
  conversationId: string,
  messageTime: string,
  seen?: boolean | null,
  received?: boolean | null,
  rejected?: boolean | null,
};

export type ChatMessageRejectInput = {
  conversationId: string,
  messageTime: string,
  rejected: boolean,
};

export type NewAdminCloseMessage = {
  userChatId: string,
  conversationId?: string | null,
  userType?: Entity | null,
};

export type AdminListMarketsFilter = {
  status?: MarketStatus | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  search?: string | null,
  offset?: number | null,
  limit?: number | null,
  isAgency?: boolean | null,
  userApplicationFilters?: AdminListMarketsUserApplicationFilter | null,
};

export type AdminListMarketsUserApplicationFilter = {
  status?: string | null,
  search?: string | null,
  scoutingEnabled?: boolean | null,
  scoutingPlanEnabled?: boolean | null,
  followers?: UserFollowers | null,
  freelancer?: boolean | null,
  taggedFilter?: string | null,
};

export enum UserFollowers {
  all = "all",
  lt1k = "lt1k",
  from1kTo10k = "from1kTo10k",
  from10kTo50k = "from10kTo50k",
  from50kTo100k = "from50kTo100k",
  from100kUp = "from100kUp",
}


export type AdminListMarketsItems = {
  __typename: "AdminListMarketsItems",
  items?:  Array<Market | null > | null,
};

export type Market = {
  __typename: "Market",
  id: string,
  name: string,
  venues?:  Array<Venue | null > | null,
  totalActiveVenues?: number | null,
  totalVenues?: number | null,
  totalPremiumVenues?: number | null,
  totalActiveModels?: number | null,
  totalUsers?: number | null,
  totalPremiumUsers?: number | null,
  percentActiveModels?: number | null,
  totalActiveOffers?: number | null,
  totalActiveVenueWithOffers?: number | null,
  totalPremiumOffers?: number | null,
  totalSpots?: number | null,
  totalReservations?: number | null,
  percentBookedLastFewDays?: number | null,
  percentBookedAllTime?: number | null,
  valueActiveOfferSpots?: number | null,
  totalReach?: number | null,
  stateProvince?: string | null,
  countryCode?: string | null,
  country?: string | null,
  currency?: string | null,
  status: MarketStatus,
  location: Location,
  timezone?: Timezone | null,
  unitSystem?: UnitSystem | null,
  createdAt: string,
  tipEnabled?: boolean | null,
  totalVenuesLeads?: number | null,
  totalApplicationUsers?: number | null,
};

export type Venue = {
  __typename: "Venue",
  id: string,
  offers?:  Array<Offer | null > | null,
  reservations?:  Array<Reservation | null > | null,
  editedBy?: Staff | null,
  approvedBy?: Staff | null,
  marketName?: string | null,
  marketID: string,
  marketCurrency?: string | null,
  market?: Market | null,
  type?: OfferCategory | null,
  phone?: string | null,
  email: string,
  name: string,
  picture?: Image | null,
  contactFirstname?: string | null,
  contactLastname?: string | null,
  contactPosition?: string | null,
  location?: Location | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  postalCode?: string | null,
  stateProvince?: string | null,
  country?: string | null,
  countryCode?: string | null,
  dependentLocality?: string | null,
  timeZoneId?: string | null,
  website?: string | null,
  instagramHandle?: string | null,
  referredBy?: string | null,
  description?: string | null,
  sideLetter?: string | null,
  approvalDate?: string | null,
  device?: string | null,
  totalActiveOffers?: number | null,
  totalOffers?: number | null,
  totalFavourites?: number | null,
  totalFavouritesByActiveUsers?: number | null,
  totalSpots?: number | null,
  totalReservations?: number | null,
  totalCheckins?: number | null,
  totalContentSaved?: number | null,
  totalImpressions?: number | null,
  percentBooked?: number | null,
  totalReach?: number | null,
  totalNoshow?: number | null,
  distance?: number | null,
  lastSeen?: string | null,
  lastBrowser?: string | null,
  lastPlatform?: string | null,
  lastSynced?: string | null,
  createdAt: string,
  updatedAt: string,
  status: VenueStatus,
  offerReservationStatus?: OfferReservationStatus | null,
  stats?: string | null,
  enabledPushNotifications?: boolean | null,
  enabledLocationAccess?: boolean | null,
  offerUpdatedAt?: string | null,
  reservationUpdatedAt?: string | null,
  closedDates?: Array< string | null > | null,
  checkInRadius?: number | null,
  platform?: string | null,
  notes?: string | null,
  enabledEmailNotifications?: boolean | null,
  currency?: string | null,
  tags?: Array< string | null > | null,
  appVersion?: string | null,
  tncVersion?: string | null,
  newContentAvailable?: boolean | null,
  availableDownloads?: number | null,
  unreadNotificationCount?: number | null,
  masterVenueID?: number | null,
  views?: number | null,
  formattedAddress?: string | null,
  tipEnabled?: boolean | null,
  premium?: boolean | null,
  lifestyleMembersAccess?: OfferAccessType | null,
  selectMembersAccess?: OfferAccessType | null,
  accessRequestsCount?: number | null,
  hasPermissionRequiredOffers?: boolean | null,
  hasSetLifestyleAccessType?: boolean | null,
  hasSetSelectAccessType?: boolean | null,
  plan?: string | null,
  termsAccepted?: boolean | null,
  referralInstagramHandle?: string | null,
  referredInstagramUserId?: string | null,
  totalAgencyUserInvites?: number | null,
  totalAgencyUserSignedInvites?: number | null,
};

export type Offer = {
  __typename: "Offer",
  id: string,
  title?: string | null,
  venueID: string,
  venue?: Venue | null,
  venueName?: string | null,
  marketName?: string | null,
  marketCurrency?: string | null,
  marketID?: string | null,
  editedBy?: Staff | null,
  description?: string | null,
  category: OfferCategory,
  subCategory?: FoodCategories | null,
  availableTo: Array< Profession | null >,
  gender: Array< Gender | null >,
  reservations?:  Array<Reservation | null > | null,
  offerValue?: number | null,
  offerValueUSD?: number | null,
  startDate: string,
  endDate?: string | null,
  startTime: string,
  endTime: string,
  durationInHours?: number | null,
  spots: number,
  waitTime?: number | null,
  availableDays?: Array< DaysOfWeek | null > | null,
  dressCode?: string | null,
  includeSelfInPost?: boolean | null,
  includeServiceOrProductInPost?: boolean | null,
  instagramTag?: string | null,
  postOnInstagramFeed?: boolean | null,
  postOnInstagramStories?: boolean | null,
  postOnInstagramReels?: boolean | null,
  postOnInstagramStories3x?: boolean | null,
  yelpReview?: boolean | null,
  suggestedTip?: string | null,
  tipType?: TipType | null,
  reasonRejected?: string | null,
  totalReservations?: number | null,
  totalCheckins?: number | null,
  percentBooked?: number | null,
  totalUniqueVisitors?: number | null,
  location?: Location | null,
  timeZoneId?: string | null,
  lastSynced?: string | null,
  createdAt: string,
  picture?: Image | null,
  stats?: offerStats | null,
  offerData?: string | null,
  status: OfferStatus,
  startDateTime?: string | null,
  additionalreq?: string | null,
  minFollowers?: number | null,
  confirmWithEmail?: boolean | null,
  confirmWithCall?: boolean | null,
  effectiveStartDateTime?: string | null,
  effectiveEndDateTime?: string | null,
  originalOfferID?: number | null,
  depositCost?: number | null,
  premium?: boolean | null,
  private?: boolean | null,
  views?: number | null,
  lifestyleMembersAccess?: OfferAccessType | null,
  selectMembersAccess?: OfferAccessType | null,
  totalSavedContent?: number | null,
  address?: string | null,
};

export type Staff = {
  __typename: "Staff",
  id: string,
  email: string,
  firstname: string,
  lastname: string,
  group: StaffGroup,
  picture?: string | null,
  createdAt: string,
};

export enum StaffGroup {
  admin = "admin",
}


export type Reservation = {
  __typename: "Reservation",
  id: string,
  editedBy?: Staff | null,
  marketName?: string | null,
  marketID?: string | null,
  offerID: string,
  offerCategory?: OfferCategory | null,
  offer?: Offer | null,
  offerTitle?: string | null,
  offerValue?: number | null,
  offerStartTime?: string | null,
  offerEndTime?: string | null,
  offerEndDateTime?: string | null,
  timeZoneId?: string | null,
  userID: string,
  name?: string | null,
  user?: User | null,
  venueID: string,
  venue?: Venue | null,
  venueName?: string | null,
  venueLocation?: Location | null,
  venueAddress1?: string | null,
  venueAddress2?: string | null,
  venueCity?: string | null,
  venueCountry?: string | null,
  note?: string | null,
  location?: Location | null,
  status: ReservationStatus,
  reservationDate: string,
  actionDate?: string | null,
  checkInDateTime?: string | null,
  createdAt: string,
  updatedAt: string,
  venuePicture?: Image | null,
  offerPicture?: Image | null,
  userPicture?: Image | null,
  canSubmitRequirements?: boolean | null,
  requirementsSubmitted?: boolean | null,
  instagramTag?: string | null,
  noShowStatus?: NoShowStatus | null,
  currency?: string | null,
  payoutAmount?: number | null,
  validation?: string | null,
  validationRequested?: string | null,
  penaltyType?: string | null,
  effectiveStartDateTime?: string | null,
  effectiveEndDateTime?: string | null,
  offerPostType?: string | null,
  postLink?: string | null,
  inviteID?: number | null,
  privateOffer?: boolean | null,
  requirementsSubmittedDateTime?: string | null,
  rated?: boolean | null,
  ratedByVenue?: boolean | null,
  rating?: number | null,
  offerValueSnapshot?: number | null,
  dressCodeSnapshot?: string | null,
  suggestedTipSnapshot?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  email: string,
  suspensions?:  Array<Suspension | null > | null,
  penalties?:  Array<Penalty | null > | null,
  reservations?:  Array<Reservation | null > | null,
  phone?: string | null,
  name?: string | null,
  country?: string | null,
  firstname: string,
  lastname: string,
  birthdate?: string | null,
  instagramHandle?: string | null,
  instagramFollowers?: number | null,
  instagramUpdatedAt?: string | null,
  failedInstaCheck?: boolean | null,
  profession: Profession,
  picture?: Image | null,
  gender: Gender,
  compositeCard?: FrontBack | null,
  agency?: string | null,
  motherAgency?: string | null,
  timezone?: string | null,
  currency?: string | null,
  homeCountry?: string | null,
  homeStateProvince?: string | null,
  homeCity?: string | null,
  city?: string | null,
  marketName?: string | null,
  marketID?: string | null,
  market?: Market | null,
  currentCity?: string | null,
  location?: Location | null,
  credits?: number | null,
  approvalDate?: string | null,
  lastSeen?: string | null,
  device?: string | null,
  totalCheckins?: number | null,
  totalCheckinLastMonth?: number | null,
  totalOfferValueRedeemed?: number | null,
  totalReservations?: number | null,
  totalWarnings?: number | null,
  totalSuspensions?: number | null,
  totalNoShow?: number | null,
  totalActiveNoShow?: number | null,
  totalCancellation?: number | null,
  totalNoPost?: number | null,
  totalPenalties?: number | null,
  totalActivePenalties?: number | null,
  totalPortfolioContents?: number | null,
  totalContentUploaded?: number | null,
  offerCategoriesRedeemed?: string | null,
  timeOnApp?: number | null,
  editedBy?: Staff | null,
  reviewedBy?: Staff | null,
  acceptedCodeOfConduct?: boolean | null,
  enabledPushNotifications?: boolean | null,
  enabledLocationAccess?: boolean | null,
  lastSynced?: string | null,
  createdAt: string,
  updatedAt?: string | null,
  status: UserStatus,
  reactivationDate?: string | null,
  instagramDuplicates?: string | null,
  firstnameDuplicates?: string | null,
  lastnameDuplicates?: string | null,
  birthdateDuplicates?: string | null,
  instagramVerificationRequested?: string | null,
  platform?: string | null,
  stripeId?: string | null,
  totalRequirementsSubmitted?: number | null,
  tags?: Array< string | null > | null,
  appVersion?: string | null,
  noshowCredits?: number | null,
  tncVersion?: string | null,
  hasPendingNoshowCredits?: boolean | null,
  agencyProfileUrl?: string | null,
  freelancerBodyProfile?: FreelancerBodyProfile | null,
  freelancerPictures?: FreelancerImages | null,
  freelancer?: boolean | null,
  reservationRewardClaimed?: boolean | null,
  instagramVerified?: boolean | null,
  points?: number | null,
  premium?: boolean | null,
  savedByVenue?: boolean | null,
  inviteCredits?: number | null,
  newInvitesAvailable?: boolean | null,
  invitesAvailable?: boolean | null,
  invitesReceived?: boolean | null,
  webProfilePrivate?: boolean | null,
  rating?: number | null,
  totalRatings?: number | null,
  tiktokHandle?: string | null,
  xHandle?: string | null,
  youtubeHandle?: string | null,
  highRated?: boolean | null,
  rank?: number | null,
  scoutingEnabled?: boolean | null,
  profileVideo?: string | null,
  scoutingPlanEnabled?: boolean | null,
  inviteStatus?: string | null,
  agencyInviteStatus?: string | null,
  hasDeletedInstagram?: boolean | null,
  hasDeletedEmail?: boolean | null,
  lifestyle?: boolean | null,
  signedByAgency?: SignedByAgency | null,
  profileVideoWithThumbnail?: Image | null,
  potentialMatches?: Array< string | null > | null,
};

export type Suspension = {
  __typename: "Suspension",
  id: string,
  suspendedBy?: Staff | null,
  editedBy?: Staff | null,
  userID: string,
  user?: User | null,
  startDate: string,
  endDate: string,
  reason: SuspensionReason,
  detail?: string | null,
  paymentAmount?: number | null,
  status: SuspensionStatus,
  lastSynced?: string | null,
  createdAt: string,
};

export type Penalty = {
  __typename: "Penalty",
  id: string,
  userID: string,
  user?: User | null,
  editedBy?: Staff | null,
  reservationID: string,
  reservation?: Reservation | null,
  type?: Array< OfferRequirements | null > | null,
  status: PenaltyStatus,
  createdAt: string,
  currency?: string | null,
  paymentAmount?: number | null,
  timeZoneId: string,
};

export type Image = {
  __typename: "Image",
  thumbnail?: string | null,
  small?: string | null,
  medium?: string | null,
  large?: string | null,
  video?: string | null,
};

export type FrontBack = {
  __typename: "FrontBack",
  front: Image,
  back?: Image | null,
};

export type Location = {
  __typename: "Location",
  lat: number,
  lon: number,
};

export type FreelancerBodyProfile = {
  __typename: "FreelancerBodyProfile",
  height?: number | null,
  waist?: number | null,
  bust?: number | null,
  hips?: number | null,
};

export type FreelancerImages = {
  __typename: "FreelancerImages",
  profile?: Image | null,
  closeUp?: Image | null,
  waistUp?: Image | null,
  fullLength?: Image | null,
};

export type SignedByAgency = {
  __typename: "SignedByAgency",
  id?: number | null,
  name?: string | null,
};

export type offerStats = {
  __typename: "offerStats",
  spotCount?: number | null,
  reservationCount?: number | null,
  checkInCount?: number | null,
};

export enum OfferReservationStatus {
  available = "available",
  not_available = "not_available",
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
  sunday = "sunday",
  expired = "expired",
  sold_out = "sold_out",
  reserved = "reserved",
  tomorrow = "tomorrow",
}


export type Timezone = {
  __typename: "Timezone",
  dstOffset: number,
  rawOffset: number,
  timeZoneId: string,
  timeZoneName: string,
};

export type AdminGetMarketStatisticsFilter = {
  marketID?: number | null,
  isAgency?: boolean | null,
  status?: string | null,
};

export type AdminGetMarketStatisticsResponse = {
  __typename: "AdminGetMarketStatisticsResponse",
  totalActiveVenues?: number | null,
  totalBlockedVenues?: number | null,
  totalPendingVenues?: number | null,
  totalRejectedVenues?: number | null,
  totalInactiveVenues?: number | null,
  totalDeletedVenues?: number | null,
  totalActiveVenuesWithNoOffers?: number | null,
};

export type AdminGetMarketStatisticModelsResponse = {
  __typename: "AdminGetMarketStatisticModelsResponse",
  totalActiveModels?: number | null,
  totalPendingModels?: number | null,
  totalBlockedModels?: number | null,
  totalRejectedModels?: number | null,
  totalSuspendedModels?: number | null,
  totalNoInstagramModels?: number | null,
  totalDeletedModels?: number | null,
  totalScoutingEnabled?: number | null,
  totalScoutingPlanEnabled?: number | null,
  totalNoScoutingEnabled?: number | null,
};

export type AdminListVenuesFilter = {
  status?: VenueStatus | null,
  sortBy?: string | null,
  venueIds?: Array< number | null > | null,
  sortDirection?: string | null,
  marketID?: string | null,
  search?: string | null,
  offset?: number | null,
  limit?: number | null,
  tags?: Array< string | null > | null,
  withOffers?: boolean | null,
  appVersion?: string | null,
  plan?: VenuePlan | null,
  isAgency?: boolean | null,
};

export enum VenuePlan {
  starter = "starter",
  plus = "plus",
}


export type AdminListVenuesListItems = {
  __typename: "AdminListVenuesListItems",
  items?:  Array<Venue | null > | null,
};

export type VenueCreditLedgerFilter = {
  venueID: number,
  limit?: number | null,
  offset?: number | null,
};

export type VenueCreditLedgerItems = {
  __typename: "VenueCreditLedgerItems",
  items?:  Array<VenueCreditLedgerItem | null > | null,
};

export type VenueCreditLedgerItem = {
  __typename: "VenueCreditLedgerItem",
  credits: number,
  createdAt?: string | null,
  updatedAt?: string | null,
  title: string,
  subtitle?: string | null,
  picture?: Image | null,
  user?: User | null,
};

export type AdminListOfferHistoryFilter = {
  id?: string | null,
};

export type AdminListOffersListItems = {
  __typename: "AdminListOffersListItems",
  items?:  Array<Offer | null > | null,
};

export type AdminListOffersFilter = {
  status?: OfferStatus | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  marketID?: string | null,
  search?: string | null,
  offset?: number | null,
  limit?: number | null,
  venueID?: string | null,
  category?: OfferCategory | null,
  private?: boolean | null,
  premium?: boolean | null,
  lifestyleMembersAccess?: string | null,
  selectMembersAccess?: string | null,
  postOnInstagramFeed?: boolean | null,
  postOnInstagramStories?: boolean | null,
  postOnInstagramStories3x?: boolean | null,
  postOnInstagramReels?: boolean | null,
};

export type AdminGetTotalOfferByCategoryFilter = {
  marketID?: string | null,
};

export type VenueGetOfferStatisticsRequest = {
  venueID: number,
};

export type VenueGetOfferStatisticsResponse = {
  __typename: "VenueGetOfferStatisticsResponse",
  totalActiveOffers?: number | null,
  totalCancelledOffers?: number | null,
  totalPendingOffers?: number | null,
  totalRejectedOffers?: number | null,
  totalEndedOffers?: number | null,
  totalInactiveOffers?: number | null,
  totalDeletedOffers?: number | null,
};

export type AllSupportCityManagerUserResponse = {
  __typename: "AllSupportCityManagerUserResponse",
  data?:  Array<SupportCityManagerUser | null > | null,
};

export type SupportCityManagerUser = {
  __typename: "SupportCityManagerUser",
  username?: string | null,
  email?: string | null,
  marketIds?: Array< string | null > | null,
  cognitoGroups?: Array< string | null > | null,
};

export type CohortsResponse = {
  __typename: "CohortsResponse",
  lapsed0UsersLt1k?: number | null,
  lapsed0Users1kTo10k?: number | null,
  lapsed0Users10kTo50k?: number | null,
  lapsed0Users50kTo100k?: number | null,
  lapsed0UsersGt100k?: number | null,
  lapsedUsersLt1k?: number | null,
  lapsedUsers1kTo10k?: number | null,
  lapsedUsers10kTo50k?: number | null,
  lapsedUsers50kTo100k?: number | null,
  lapsedUsersGt100k?: number | null,
  casualUsersLt1k?: number | null,
  casualUsers1kTo10k?: number | null,
  casualUsers10kTo50k?: number | null,
  casualUsers50kTo100k?: number | null,
  casualUsersGt100k?: number | null,
  powerUsersLt1k?: number | null,
  powerUsers1kTo10k?: number | null,
  powerUsers10kTo50k?: number | null,
  powerUsers50kTo100k?: number | null,
  powerUsersGt100k?: number | null,
  usersRetentionPerMonth?: Array< number | null > | null,
};

export type AdminListUsersFilter = {
  status?: UserStatus | null,
  followers?: UserFollowers | null,
  userIds?: Array< number | null > | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  marketID?: string | null,
  search?: string | null,
  offset?: number | null,
  limit?: number | null,
  instagramFilter?: string | null,
  taggedFilter?: string | null,
  tags?: Array< string | null > | null,
  blockedByVenues?: Array< number | null > | null,
  appVersion?: string | null,
  freelancer?: boolean | null,
  premium?: boolean | null,
  age?: AgeFilterInput | null,
  lifestyle?: boolean | null,
  credits?: AgeFilterInput | null,
  noShow?: AgeFilterInput | null,
  noOfReservation?: AgeFilterInput | null,
  checkins?: AgeFilterInput | null,
  rating?: UserRatingInput | null,
  lastSeen?: UserLastSeenInput | null,
  portfolio?: PortfolioStatus | null,
  hasPenality?: boolean | null,
  scoutingEnabled?: boolean | null,
  scoutingPlanEnabled?: boolean | null,
  duplicatesIncluded?: boolean | null,
};

export type AgeFilterInput = {
  start?: number | null,
  end?: number | null,
};

export enum UserRatingInput {
  all = "all",
  excellent = "excellent",
  good = "good",
  ok = "ok",
  bad = "bad",
  terrible = "terrible",
}


export enum UserLastSeenInput {
  all = "all",
  lastday = "lastday",
  last3days = "last3days",
  lastweek = "lastweek",
  lastmonth = "lastmonth",
}


export enum PortfolioStatus {
  all = "all",
  yes = "yes",
  no = "no",
}


export type AdminListUsersListItems = {
  __typename: "AdminListUsersListItems",
  items?:  Array<User | null > | null,
};

export type AdminListPortfolioContentFilter = {
  userID?: number | null,
  limit?: number | null,
  offset?: number | null,
};

export type PortfolioItemListItems = {
  __typename: "PortfolioItemListItems",
  items?:  Array<UserPortfolioItem | null > | null,
};

export type UserPortfolioItem = {
  __typename: "UserPortfolioItem",
  id?: string | null,
  userID?: number | null,
  portfolio?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  asset?: Image | null,
};

export type AdminListVenuePortfolioContentFilter = {
  venueID?: number | null,
};

export type VenuePortfolioItemListItems = {
  __typename: "VenuePortfolioItemListItems",
  items?:  Array<VenuePortfolioItem | null > | null,
};

export type VenuePortfolioItem = {
  __typename: "VenuePortfolioItem",
  id?: string | null,
  venueID?: number | null,
  portfolio?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  asset?: Image | null,
};

export type UserListVenuePortfolioContentFilter = {
  venueID?: number | null,
};

export type VenueListUserPortfolioContentFilter = {
  userID: number,
  limit?: number | null,
  offset?: number | null,
};

export type VenueListUserReservationContentFilter = {
  userID: number,
  limit: number,
  offset: number,
};

export type UserReservationContentListItems = {
  __typename: "UserReservationContentListItems",
  items?:  Array<UserReservationContent | null > | null,
};

export type UserReservationContent = {
  __typename: "UserReservationContent",
  id?: number | null,
  seen?: boolean | null,
  downloaded?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userID?: number | null,
  images?: Image | null,
};

export type UserListUserReservationContentFilter = {
  limit: number,
  offset: number,
};

export type VenueListUsersFilter = {
  minFollowers: number,
  modelType?: ModelType | null,
  instagram?: boolean | null,
  market: boolean,
  venueID: number,
  saved?: boolean | null,
  limit?: number | null,
  offset?: number | null,
  searchText?: string | null,
  inviteCost?: number | null,
  salt?: number | null,
  venueFavourite?: boolean | null,
  lifestyle?: boolean | null,
};

export enum ModelType {
  model = "model",
  freelancer = "freelancer",
}


export type VenueUserListItems = {
  __typename: "VenueUserListItems",
  items?:  Array<User | null > | null,
};

export type VenueListUserFvouritesFilter = {
  venueID: number,
  limit?: number | null,
  offset?: number | null,
};

export type AgencyListUsersFilter = {
  minFollowers?: number | null,
  maxFollowers?: number | null,
  market?: string | null,
  venueID: number,
  saved?: boolean | null,
  limit: number,
  offset: number,
  searchText?: string | null,
  salt?: number | null,
  minAge?: number | null,
  maxAge?: number | null,
  minHeight?: number | null,
  maxHeight?: number | null,
  minBust?: number | null,
  maxBust?: number | null,
  minWaist?: number | null,
  maxWaist?: number | null,
  minHip?: number | null,
  maxHip?: number | null,
  country?: string | null,
  agencyInviteStatus?: string | null,
  sortBy?: string | null,
  sortDirection?: string | null,
};

export type AgencyListCountriesFilter = {
  offset?: number | null,
  limit?: number | null,
};

export type AgencyCountryListItems = {
  __typename: "AgencyCountryListItems",
  items?:  Array<Country | null > | null,
};

export type Country = {
  __typename: "Country",
  name: string,
  totalUsers: number,
};

export type AdminListUserHistoryFilter = {
  id?: string | null,
  limit?: number | null,
  offset?: number | null,
};

export type AdminListPenaltiesFilter = {
  status?: PenaltyStatus | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  marketID?: string | null,
  search?: string | null,
  offset?: number | null,
  limit?: number | null,
  type?: PenaltyTypes | null,
  userID?: string | null,
  venueID?: string | null,
  offerID?: string | null,
  reservationID?: string | null,
};

export enum PenaltyTypes {
  noshow = "noshow",
  dresscode = "dresscode",
  include_self_in_post = "include_self_in_post",
  tag = "tag",
  post_on_instagram_feed = "post_on_instagram_feed",
  yelp_review = "yelp_review",
  tip_staff = "tip_staff",
}


export type AdminListPenaltiesListItems = {
  __typename: "AdminListPenaltiesListItems",
  items?:  Array<Penalty | null > | null,
};

export type AdminListReservationsFilter = {
  status?: ReservationStatus | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  marketID?: string | null,
  search?: string | null,
  offset?: number | null,
  limit?: number | null,
  userID?: string | null,
  venueID?: string | null,
  offerID?: string | null,
  privateOffer?: boolean | null,
};

export type AdminListReservationsListItems = {
  __typename: "AdminListReservationsListItems",
  items?:  Array<Reservation | null > | null,
};

export type AdminListReservationContentsFilter = {
  offset?: number | null,
  limit?: number | null,
  userID?: string | null,
  venueID?: string | null,
  contentType?: MediaType | null,
  searchText?: string | null,
  minFollowers?: number | null,
  maxFollowers?: number | null,
  savedByVenue?: boolean | null,
  tags?: Array< string | null > | null,
};

export enum MediaType {
  image = "image",
  video = "video",
}


export type AdminListReservationContentResponse = {
  __typename: "AdminListReservationContentResponse",
  items?:  Array<AdminReservationContent | null > | null,
};

export type AdminReservationContent = {
  __typename: "AdminReservationContent",
  id?: number | null,
  reservationID?: number | null,
  seen?: boolean | null,
  downloaded?: boolean | null,
  byAdmin?: boolean | null,
  byVenue?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userfirstname?: string | null,
  userlastname?: string | null,
  userInstagramHandle?: string | null,
  userInstagramFollowers?: number | null,
  userID?: number | null,
  venueID?: number | null,
  venueName?: string | null,
  offerTitle?: string | null,
  images?: Image | null,
  content?: string | null,
};

export type AdminListReservationContentCountResponse = {
  __typename: "AdminListReservationContentCountResponse",
  count?: number | null,
};

export type AdminListSupportReservationsFilter = {
  beginDateTime?: string | null,
  endDateTime?: string | null,
  offset?: number | null,
  limit?: number | null,
  type?: ReservsationsSupportListType | null,
  reviewedBy?: string | null,
  sortDirection?: string | null,
};

export enum ReservsationsSupportListType {
  pending = "pending",
  unverified = "unverified",
  verified = "verified",
  validated = "validated",
  validationFailed = "validationFailed",
}


export type SupportListReservationsListItems = {
  __typename: "SupportListReservationsListItems",
  items?:  Array<SupportReservation | null > | null,
};

export type SupportReservation = {
  __typename: "SupportReservation",
  id: string,
  marketName?: string | null,
  marketID?: string | null,
  offerID: string,
  offerTitle?: string | null,
  userID: string,
  name?: string | null,
  venueID: string,
  venueName?: string | null,
  status: ReservationStatus,
  checkInDateTime?: string | null,
  venuePicture?: Image | null,
  offerPicture?: Image | null,
  userPicture?: Image | null,
  instagramTag?: string | null,
  userInstagramHandle?: string | null,
  validation?: string | null,
  validationRequested?: string | null,
  postOnInstagramFeed?: string | null,
  postOnInstagramStories?: boolean | null,
  postOnInstagramReels?: boolean | null,
  postOnInstagramStories3x?: boolean | null,
  dressCode?: string | null,
  includeSelfInPost?: boolean | null,
  includeServiceOrProductInPost?: boolean | null,
  suggestedTip?: string | null,
  warnings?: Array< ReservationWarningType | null > | null,
  reviewStaff?: string | null,
  postLink?: string | null,
};

export type AdminCountSupportReservationsFilter = {
  beginDateTime?: string | null,
  endDateTime?: string | null,
  type?: ReservsationsSupportListType | null,
  reviewedBy?: string | null,
};

export type SupportCountReservationsListItems = {
  __typename: "SupportCountReservationsListItems",
  items?:  Array<SupportReservationsCountResult | null > | null,
};

export type SupportReservationsCountResult = {
  __typename: "SupportReservationsCountResult",
  reviewStaff?: string | null,
  count?: number | null,
};

export type AdminListInvitesFilter = {
  status?: InviteStatus | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  marketID?: string | null,
  search?: string | null,
  offset?: number | null,
  limit?: number | null,
  userID?: string | null,
  venueID?: string | null,
  offerID?: string | null,
};

export enum InviteStatus {
  available = "available",
  accepted = "accepted",
  declined = "declined",
  expired = "expired",
}


export type AdminListInvitesListItems = {
  __typename: "AdminListInvitesListItems",
  items?:  Array<AdminInvite | null > | null,
};

export type AdminInvite = {
  __typename: "AdminInvite",
  id?: number | null,
  userID?: number | null,
  offerID?: number | null,
  expiry?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  status?: InviteStatus | null,
  availableDates?: Array< string | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  seen?: boolean | null,
  marketID?: number | null,
  marketName?: string | null,
  timeZoneId?: string | null,
  offerTitle?: string | null,
  offerUpdatedAt?: string | null,
  userName?: string | null,
  userUpdatedAt?: string | null,
  venueID?: number | null,
  venueName?: string | null,
  location?: string | null,
  venueUpdatedAt?: string | null,
  instagramTag?: string | null,
  venuePicture?: Image | null,
  offerPicture?: Image | null,
  userPicture?: Image | null,
  user?: User | null,
  effectiveStartDateTime?: string | null,
  reservationDate?: string | null,
};

export type AdminListSuspensionsFilter = {
  status?: SuspensionStatus | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  marketID?: string | null,
  search?: string | null,
  offset?: number | null,
  limit?: number | null,
  reason?: SuspensionReason | null,
  userID?: string | null,
};

export type AdminListSuspensionsListItems = {
  __typename: "AdminListSuspensionsListItems",
  items?:  Array<Suspension | null > | null,
};

export type AdminListSuspensionsTotalsResponse = {
  __typename: "AdminListSuspensionsTotalsResponse",
  totalActive?: number | null,
  totalArchived?: number | null,
};

export type AdminGetSuspensionStatisticsFilter = {
  status?: string | null,
};

export type AdminGetSuspensionStatisticsResponse = {
  __typename: "AdminGetSuspensionStatisticsResponse",
  totalPenaltyCount?: number | null,
  totalNoshowCount?: number | null,
  totalAdminCount?: number | null,
  totalNoInstagramCount?: number | null,
};

export type DashboardData = {
  __typename: "DashboardData",
  totalActiveUsers?: number | null,
  totalActiveUsersLastMonth?: number | null,
  totalReach?: number | null,
  newUserApplications?: number | null,
  newApprovedUsers?: number | null,
  totalPendingUsers?: number | null,
  totalRejectedUsers?: number | null,
  totalActiveVenues?: number | null,
  totalActiveOffers?: number | null,
  totalValueOfActiveOffersSpots?: number | null,
};

export type AdvancedDashboardData = {
  __typename: "AdvancedDashboardData",
  totals?: AdvancedDashboardDataTotals | null,
  lines?:  Array<AdvancedDashboardDataLine | null > | null,
  totalActiveOffers?: number | null,
  totalActiveVenues?: number | null,
  totalPremiumVenues?: number | null,
  totalStarterVenues?: number | null,
  totalPlusVenues?: number | null,
  totalPlusFreeVenues?: number | null,
  totalStarterFreeVenues?: number | null,
  totalActiveUsers?: number | null,
  totalPremiumUsers?: number | null,
  totalRejectedUsers?: number | null,
  totalPendingUsers?: number | null,
  totalModelCreditsInMonth?: number | null,
  totalVenueCreditsInMonth?: number | null,
  totalUsersBpSelect?: number | null,
  totalUsersBpLifestyle?: number | null,
  totalScoutingActiveUsers?: number | null,
  totalScoutingRejectedUsers?: number | null,
  totalValueOfActiveOffersSpots?: number | null,
  totalModelCreditsInFromToDate?: number | null,
  totalVenueCreditsInFromToDate?: number | null,
  totalPremiumVenuesInFromToDate?: number | null,
  totalPremiumUsersInFromToDate?: number | null,
  totalPremiumExpiredUsersInFromToDate?: number | null,
  modelApplicationsInFromToDate?: number | null,
  venueApplicationsInFromToDate?: number | null,
  reservationsInFromToDate?: number | null,
  registeredFreelancerModelsInFromToDate?: number | null,
  registeredModelsInFromToDate?: number | null,
  totalPremiumVenuesWithoutSubscription?: number | null,
};

export type AdvancedDashboardDataTotals = {
  __typename: "AdvancedDashboardDataTotals",
  activeModelsLastMonth?: number | null,
  activeOffers?: number | null,
  activeVenues?: number | null,
  activeVenuesWithOffer?: number | null,
  reach?: number | null,
  redeemedOfferValue?: number | null,
};

export type AdvancedDashboardDataLine = {
  __typename: "AdvancedDashboardDataLine",
  date?: string | null,
  creditPurchaseByModel?: number | null,
  creditPurchaseByVenue?: number | null,
  modelApplicationsPending?: number | null,
  modelApplicationsActive?: number | null,
  modelApplicationsRejected?: number | null,
  venuesApproved?: number | null,
  venuesBlocked?: number | null,
  offersPerCategory?: AdvancedDashboardDataLineOfferPerCategory | null,
  offersValuePerCategory?: AdvancedDashboardDataLineOfferPerCategory | null,
  offersValue?: number | null,
  reservationsReserved?: number | null,
  reservationsCheckedIn?: number | null,
  reservationsCancelled?: number | null,
  reservationsNoShow?: number | null,
  premiumModel?: number | null,
  premiumVenue?: number | null,
  premiumExpiredModel?: number | null,
};

export type AdvancedDashboardDataLineOfferPerCategory = {
  __typename: "AdvancedDashboardDataLineOfferPerCategory",
  drink?: number | null,
  beauty?: number | null,
  food?: number | null,
  event?: number | null,
  experience?: number | null,
  fitness?: number | null,
  boutique?: number | null,
  unknown?: number | null,
};

export type AdminListCampaignFilter = {
  status?: AdminCampaignStatus | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  offset?: number | null,
  limit?: number | null,
};

export type AdminCampaignListItems = {
  __typename: "AdminCampaignListItems",
  items:  Array<AdminCampaign | null >,
};

export type AdminCampaign = {
  __typename: "AdminCampaign",
  id: string,
  title?: string | null,
  type?: NotificationType | null,
  content?: string | null,
  status?: AdminCampaignStatus | null,
  selectedUserIDList?: Array< number | null > | null,
  selectedVenueIDList?: Array< number | null > | null,
  selectedMarketIDList?: Array< number | null > | null,
  selectAllMarkets?: boolean | null,
  selectedEntities?: Array< AdminCreateCampaignEntity | null > | null,
  date?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  venueID?: number | null,
  offerID?: number | null,
  screen?: string | null,
};

export type AdminListFeedbackFilter = {
  entity?: Entity | null,
  entityId?: string | null,
  limit?: number | null,
  offset?: number | null,
  sortDirection?: string | null,
  status?: FeedbackStatus | null,
};

export enum FeedbackStatus {
  created = "created",
  read = "read",
  archived = "archived",
}


export type Feedback = {
  __typename: "Feedback",
  id: string,
  message: string,
  user?: User | null,
  venue?: Venue | null,
  createdAt: string,
  updatedAt: string,
  status: FeedbackStatus,
};

export type AdminListUserComplaintFilter = {
  limit?: number | null,
  offset?: number | null,
  types?: Array< string | null > | null,
  status?: ComplaintStatus | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  subtypes?: Array< string | null > | null,
  userID?: string | null,
  venueID?: string | null,
  statuses?: Array< ComplaintStatus | null > | null,
  premium?: boolean | null,
  marketIDs?: Array< string | null > | null,
};

export type UserComplaint = {
  __typename: "UserComplaint",
  id: string,
  userID: string,
  user?: User | null,
  type: string,
  subtype?: string | null,
  instagramHandle?: string | null,
  penaltyID?: string | null,
  noshowID?: string | null,
  rawData?: string | null,
  status?: ComplaintStatus | null,
  createdAt: string,
  updatedAt: string,
};

export type VenueComplaint = {
  __typename: "VenueComplaint",
  id: string,
  userID: string,
  venueID: string,
  user?: User | null,
  type: string,
  subtype?: string | null,
  rawData?: string | null,
  status?: ComplaintStatus | null,
  createdAt: string,
  updatedAt: string,
};

export type AdminListUserComplaintTypesCountFilter = {
  status?: string | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  statuses?: Array< string | null > | null,
  premium?: boolean | null,
};

export type UserComplaintType = {
  __typename: "UserComplaintType",
  type?: string | null,
  count?: string | null,
};

export type AdminListUserComplaintUserIDsFilter = {
  type?: string | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  offset?: number | null,
  limit?: number | null,
  statuses?: Array< string | null > | null,
  premium?: boolean | null,
  search?: string | null,
  marketIDs?: Array< string | null > | null,
};

export type UserComplaintUserIDsResponse = {
  __typename: "UserComplaintUserIDsResponse",
  complaintID?: number | null,
  userID?: string | null,
  complaintType?: string | null,
};

export type VenueComplaintUserIDsResponse = {
  __typename: "VenueComplaintUserIDsResponse",
  complaintID?: number | null,
  venueID?: string | null,
  complaintType?: string | null,
};

export type AgencyListInvitesFilter = {
  venueID: number,
  status?: string | null,
  limit?: number | null,
  offset?: number | null,
  userID?: number | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  statuses?: Array< string > | null,
};

export type AgencyListInvitesListItems = {
  __typename: "AgencyListInvitesListItems",
  items?:  Array<AgencyInvite | null > | null,
};

export type AgencyInvite = {
  __typename: "AgencyInvite",
  id: number,
  userID: number,
  venueID: number,
  status: string,
  createdAt: string,
  updatedAt: string,
  user?: User | null,
  marketName?: string | null,
  seen?: boolean | null,
  venue?: Venue | null,
};

export type GetAnalyticsRequest = {
  userID: string,
};

export type AnalyticsResponse = {
  __typename: "AnalyticsResponse",
  followers?: number | null,
  engagemenRate?: number | null,
  credibilityScore?: number | null,
  credibilityClass?: string | null,
  averageLikes?: number | null,
  averageComments?: number | null,
  averageReelViews?: number | null,
  genderDistribution?: AnalyticsGenderDistribution | null,
  countries?:  Array<AnalyticsCountryDistribution | null > | null,
  priceEstimate?: PriceEstimateAnalytics | null,
};

export type AnalyticsGenderDistribution = {
  __typename: "AnalyticsGenderDistribution",
  female?: number | null,
  male?: number | null,
};

export type AnalyticsCountryDistribution = {
  __typename: "AnalyticsCountryDistribution",
  name?: string | null,
  code?: string | null,
  followers?: number | null,
};

export type PriceEstimateAnalytics = {
  __typename: "PriceEstimateAnalytics",
  min?: number | null,
  max?: number | null,
};

export type VenuePaymentIntentInput = {
  availableDownloads: number,
};

export type PaymentIntent = {
  __typename: "PaymentIntent",
  customerId: string,
  merchantDisplayName: string,
  customerEphemeralKeySecret: string,
  paymentIntentClientSecret: string,
  applePay?: ApplePayIntent | null,
  googlePay?: GooglePayIntent | null,
};

export type ApplePayIntent = {
  __typename: "ApplePayIntent",
  merchantCountryCode?: string | null,
};

export type GooglePayIntent = {
  __typename: "GooglePayIntent",
  merchantCountryCode?: string | null,
  testEnv?: boolean | null,
};

export type VenueAccountBalanceRequest = {
  venueID: string,
};

export type AccountBalanceResponse = {
  __typename: "AccountBalanceResponse",
  balance: number,
  accountId: string,
  status: string,
};

export type VenueVerificatioURLResponse = {
  __typename: "VenueVerificatioURLResponse",
  onBoardingURL: string,
};

export type StripePricingListItems = {
  __typename: "StripePricingListItems",
  items?:  Array<StripePricing | null > | null,
};

export type StripePricing = {
  __typename: "StripePricing",
  id: string,
  object: string,
  active: boolean,
  billing_scheme?: string | null,
  created?: number | null,
  currency: string,
  custom_unit_amount?: string | null,
  nickname?: string | null,
  product?: string | null,
  recurring?: StripePriceRecurring | null,
  unit_amount?: number | null,
  metadata?: string | null,
};

export type StripePriceRecurring = {
  __typename: "StripePriceRecurring",
  interval?: string | null,
  interval_count?: number | null,
};

export type VenueGetCollabEstimateFilter = {
  userIDs: Array< number | null >,
};

export type VenueGetCollabEstimateResponse = {
  __typename: "VenueGetCollabEstimateResponse",
  totalPremiumCredits?: number | null,
  totalNonPremiumCredits?: number | null,
};

export type VenueListUserPriceEstimatedFilter = {
  userIDs: Array< number | null >,
};

export type VenueListUserEstimatedPriceResponse = {
  __typename: "VenueListUserEstimatedPriceResponse",
  items?:  Array<UserEstimatedPrice | null > | null,
};

export type UserEstimatedPrice = {
  __typename: "UserEstimatedPrice",
  userID?: number | null,
  estimatedPrice?: number | null,
};

export type VenueListOffersFilter = {
  status?: OfferStatus | null,
  statuses?: Array< OfferStatus | null > | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  marketID?: string | null,
  search?: string | null,
  offset?: number | null,
  limit?: number | null,
  type?: string | null,
  venueID: string,
  category?: OfferCategory | null,
  includesReservations?: boolean | null,
};

export type VenueListOffersListItems = {
  __typename: "VenueListOffersListItems",
  items?:  Array<Offer | null > | null,
};

export type VenueListAccessRequetsFilter = {
  venueID: number,
  userID?: number | null,
  status?: UserAccessRequestResultValue | null,
  userCategory?: string | null,
  offset?: number | null,
  limit?: number | null,
  searchText?: string | null,
};

export enum UserAccessRequestResultValue {
  accepted = "accepted",
  declined = "declined",
  requested = "requested",
}


export type VenueAccessRequestListItems = {
  __typename: "VenueAccessRequestListItems",
  items?:  Array<VennueAccessRequestItem | null > | null,
};

export type VennueAccessRequestItem = {
  __typename: "VennueAccessRequestItem",
  offerID?: number | null,
  userID?: number | null,
  status?: UserAccessRequestResultValue | null,
  user?: User | null,
  offer?: Offer | null,
};

export type VenueListInviteOffersFilter = {
  venueID: string,
};

export type VenueListInvitesFilter = {
  venueID: number,
  includeExpired?: boolean | null,
  limit: number,
  offset: number,
};

export type VenueListInvitesListItems = {
  __typename: "VenueListInvitesListItems",
  items?:  Array<Invite | null > | null,
};

export type Invite = {
  __typename: "Invite",
  id?: number | null,
  userID?: number | null,
  offerID?: number | null,
  expiry?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  status?: InviteType | null,
  user?: User | null,
  offer?: Offer | null,
  reservation?: Reservation | null,
  availableDates?: Array< string | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  seen?: boolean | null,
};

export type VenueListRatedUsersFilter = {
  venueID: number,
  minAvgRating?: number | null,
  limit: number,
  offset: number,
};

export type VenueListRatedUserListItems = {
  __typename: "VenueListRatedUserListItems",
  items?:  Array<VenueRatedUser | null > | null,
};

export type VenueRatedUser = {
  __typename: "VenueRatedUser",
  id: string,
  firstname: string,
  instagramHandle: string,
  instagramFollowers?: number | null,
  picture?: Image | null,
  updatedAt?: string | null,
  lastname: string,
  totalRatings: number,
  averageRatings: number,
  tags?: Array< string | null > | null,
};

export type AgencyGetInviteCountResponse = {
  __typename: "AgencyGetInviteCountResponse",
  count?: number | null,
};

export type VenueListOfferImagesFilter = {
  venueID: string,
};

export type VenueListOfferImagesListItems = {
  __typename: "VenueListOfferImagesListItems",
  items?:  Array<Image | null > | null,
};

export type VenueInsigts = {
  __typename: "VenueInsigts",
  totalReach7Days?: number | null,
  totalReach7DaysChange?: number | null,
  totalReach30Days?: number | null,
  totalReach30DaysChange?: number | null,
  totalReachEver?: number | null,
  totalCheckin7Days?: number | null,
  totalCheckin7DaysChange?: number | null,
  totalCheckin30Days?: number | null,
  totalCheckin30DaysChange?: number | null,
  totalCheckinEver?: number | null,
  totalReservations7Days?: number | null,
  totalReservations7DaysChange?: number | null,
  totalReservations30Days?: number | null,
  totalReservations30DaysChange?: number | null,
  totalReservationsEver?: number | null,
  totalNewVisits7Days?: number | null,
  totalNewVisits7DaysChange?: number | null,
  totalNewVisits30Days?: number | null,
  totalNewVisits30DaysChange?: number | null,
  totalNewVisitsEver?: number | null,
};

export type VenueGetUserFilter = {
  venueID: number,
  userID: number,
};

export type VenueListReservationsFilter = {
  status: Array< ReservationStatus | null >,
  sortBy?: string | null,
  sortDirection?: string | null,
  offset?: number | null,
  limit?: number | null,
  venueID: string,
  offerID?: string | null,
  offerIDs?: Array< number | null > | null,
  type?: OccurenceType | null,
  searchText?: string | null,
};

export enum OccurenceType {
  past = "past",
  future = "future",
}


export type VenueListReservationsListItems = {
  __typename: "VenueListReservationsListItems",
  items?:  Array<Reservation | null > | null,
};

export type VenueListReservationsContentFilter = {
  offset?: number | null,
  limit?: number | null,
  venueID?: number | null,
  downloaded?: boolean | null,
};

export type VenueListReservationContentResponse = {
  __typename: "VenueListReservationContentResponse",
  items?:  Array<VenueReservationContentItem | null > | null,
};

export type VenueReservationContentItem = {
  __typename: "VenueReservationContentItem",
  userID?: string | null,
  user?: ContentUserDetail | null,
  contents?:  Array<ReservationContent | null > | null,
  offers?:  Array<Offer | null > | null,
};

export type ContentUserDetail = {
  __typename: "ContentUserDetail",
  id?: number | null,
  firstname?: string | null,
  lastname?: string | null,
  updatedAt?: string | null,
  picture?: Image | null,
  instagramHandle?: string | null,
  instagramFollowers?: number | null,
  tags?: Array< string | null > | null,
  savedByVenue?: boolean | null,
};

export type ReservationContent = {
  __typename: "ReservationContent",
  id?: string | null,
  userID?: string | null,
  offerID?: string | null,
  content?: string | null,
  seen?: boolean | null,
  downloaded?: boolean | null,
  images?: Image | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type VenueListReservationContentResponseV2 = {
  __typename: "VenueListReservationContentResponseV2",
  items?:  Array<VenueReservationContentItemV2 | null > | null,
};

export type VenueReservationContentItemV2 = {
  __typename: "VenueReservationContentItemV2",
  id?: number | null,
  user?: ContentUserDetail | null,
  content?: string | null,
  seen?: boolean | null,
  downloaded?: boolean | null,
  byVenue?: boolean | null,
  images?: Image | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  downloadCredits?: number | null,
};

export type VenueListUsersListItems = {
  __typename: "VenueListUsersListItems",
  items?:  Array<BlockedUser | null > | null,
};

export type BlockedUser = {
  __typename: "BlockedUser",
  id: string,
  email: string,
  suspensions?:  Array<Suspension | null > | null,
  penalties?:  Array<Penalty | null > | null,
  reservations?:  Array<Reservation | null > | null,
  phone?: string | null,
  name?: string | null,
  country?: string | null,
  firstname: string,
  lastname: string,
  birthdate?: string | null,
  instagramHandle?: string | null,
  instagramFollowers?: number | null,
  instagramUpdatedAt?: string | null,
  failedInstaCheck?: boolean | null,
  profession: Profession,
  picture?: Image | null,
  gender: Gender,
  compositeCard?: FrontBack | null,
  agency?: string | null,
  motherAgency?: string | null,
  timezone?: string | null,
  currency?: string | null,
  homeCountry?: string | null,
  homeStateProvince?: string | null,
  homeCity?: string | null,
  city?: string | null,
  marketName?: string | null,
  marketID?: string | null,
  market?: Market | null,
  currentCity?: string | null,
  location?: Location | null,
  credits?: number | null,
  approvalDate?: string | null,
  lastSeen?: string | null,
  device?: string | null,
  totalCheckins?: number | null,
  totalCheckinLastMonth?: number | null,
  totalOfferValueRedeemed?: number | null,
  totalReservations?: number | null,
  totalWarnings?: number | null,
  totalSuspensions?: number | null,
  totalNoShow?: number | null,
  totalActiveNoShow?: number | null,
  totalCancellation?: number | null,
  totalNoPost?: number | null,
  totalPenalties?: number | null,
  totalActivePenalties?: number | null,
  totalPortfolioContents?: number | null,
  totalContentUploaded?: number | null,
  offerCategoriesRedeemed?: string | null,
  timeOnApp?: number | null,
  editedBy?: Staff | null,
  reviewedBy?: Staff | null,
  acceptedCodeOfConduct?: boolean | null,
  enabledPushNotifications?: boolean | null,
  enabledLocationAccess?: boolean | null,
  lastSynced?: string | null,
  createdAt: string,
  updatedAt?: string | null,
  status: UserStatus,
  reactivationDate?: string | null,
  instagramDuplicates?: string | null,
  firstnameDuplicates?: string | null,
  lastnameDuplicates?: string | null,
  birthdateDuplicates?: string | null,
  instagramVerificationRequested?: string | null,
  platform?: string | null,
  stripeId?: string | null,
  totalRequirementsSubmitted?: number | null,
  tags?: Array< string | null > | null,
  appVersion?: string | null,
  noshowCredits?: number | null,
  tncVersion?: string | null,
  hasPendingNoshowCredits?: boolean | null,
  agencyProfileUrl?: string | null,
  freelancerBodyProfile?: FreelancerBodyProfile | null,
  freelancerPictures?: FreelancerImages | null,
  freelancer?: boolean | null,
  reservationRewardClaimed?: boolean | null,
  instagramVerified?: boolean | null,
  points?: number | null,
  premium?: boolean | null,
  savedByVenue?: boolean | null,
  inviteCredits?: number | null,
  newInvitesAvailable?: boolean | null,
  invitesAvailable?: boolean | null,
  invitesReceived?: boolean | null,
  webProfilePrivate?: boolean | null,
  rating?: number | null,
  totalRatings?: number | null,
  blockedDate?: string | null,
  tiktokHandle?: string | null,
  xHandle?: string | null,
  youtubeHandle?: string | null,
};

export type VenueListVenueBlockedUsersRequest = {
  venueID?: number | null,
  offset?: number | null,
  limit?: number | null,
};

export type VenueListVenuesRequest = {
  venueID?: number | null,
};

export type VenueListVenuesListItems = {
  __typename: "VenueListVenuesListItems",
  items?:  Array<Venue | null > | null,
};

export type VenueListNotificationsFilter = {
  venueID: string,
  sortDirection?: SortDirection | null,
  type?: NotificationType | null,
  status?: Array< UserNotificationStatus | null > | null,
  offset?: number | null,
  limit?: number | null,
};

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}


export type VenueListNotificationItems = {
  __typename: "VenueListNotificationItems",
  items?:  Array<VenueNotification | null > | null,
};

export type VenueNotification = {
  __typename: "VenueNotification",
  id: string,
  venueID: string,
  userID?: string | null,
  type: NotificationType,
  thumbnail?: string | null,
  title?: string | null,
  content?: string | null,
  status?: VenueNotificationStatus | null,
  metadata?: string | null,
  redirectCTA?: string | null,
  createdAt: string,
};

export type UserListMarketsFilter = {
  status?: MarketStatus | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  search?: string | null,
  offset?: number | null,
  limit?: number | null,
  working?: boolean | null,
  visited?: boolean | null,
};

export type UserListMarketsItems = {
  __typename: "UserListMarketsItems",
  items?:  Array<UserMarketItem | null > | null,
};

export type UserMarketItem = {
  __typename: "UserMarketItem",
  id: string,
  name: string,
  currency?: string | null,
  location: Location,
  timezone?: Timezone | null,
  unitSystem?: UnitSystem | null,
  offersRedeemed?: number | null,
  imageUrl?: string | null,
  tipEnabled?: boolean | null,
};

export type UserListVenuesFilter = {
  location?: LocationInput | null,
  from?: number | null,
  size?: number | null,
  marketID?: string | null,
  userID: string,
  categories?: Array< OfferCategory | null > | null,
  availability?: Array< AvailabilityTypes | null > | null,
  searchText?: string | null,
  radius?: number | null,
  favouritesOnly?: boolean | null,
  minFollowers?: number | null,
  maxFollowers?: number | null,
  depositCost?: number | null,
  postType?: PostTypes | null,
  venuesWithNewOffers?: boolean | null,
  accessType?: OfferAccessFilterEnum | null,
};

export enum AvailabilityTypes {
  NOW = "NOW",
  TODAY = "TODAY",
  TOMORROW = "TOMORROW",
  SOON = "SOON",
}


export enum PostTypes {
  FEED = "FEED",
  REEL = "REEL",
  STORY = "STORY",
  STORY3X = "STORY3X",
  NO_REQUIREMENTS = "NO_REQUIREMENTS",
}


export enum OfferAccessFilterEnum {
  acceptAll = "acceptAll",
  permissionRequired = "permissionRequired",
}


export type UserListVenuesListItems = {
  __typename: "UserListVenuesListItems",
  items?:  Array<UserVenueItem | null > | null,
};

export type UserVenueItem = {
  __typename: "UserVenueItem",
  id: string,
  offers?:  Array<Offer | null > | null,
  marketName?: string | null,
  marketID: string,
  market?: Market | null,
  type?: OfferCategory | null,
  phone?: string | null,
  name?: string | null,
  picture?: Image | null,
  contactFirstname?: string | null,
  contactLastname?: string | null,
  contactPosition?: string | null,
  location?: Location | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  postalCode?: string | null,
  stateProvince?: string | null,
  country?: string | null,
  countryCode?: string | null,
  dependentLocality?: string | null,
  timeZoneId?: string | null,
  website?: string | null,
  instagramHandle?: string | null,
  description?: string | null,
  distance?: number | null,
  status?: VenueStatus | null,
  offerReservationStatus?: UserOfferStatus | null,
  closedDates?: Array< string | null > | null,
  approvalDate?: string | null,
  earliestOfferDate?: string | null,
  totalCheckins?: number | null,
  totalCheckinLastMonth?: number | null,
  totalOfferValueRedeemed?: number | null,
  userFavourite?: boolean | null,
  formattedAddress?: string | null,
  totalSavedContent?: number | null,
  offerCount?: number | null,
  topInMarket?: boolean | null,
  totalPositiveRatings?: number | null,
  totalRatings?: number | null,
  userMuted?: boolean | null,
};

export enum UserOfferStatus {
  available = "available",
  not_available = "not_available",
  reserved = "reserved",
  reserved_by_user = "reserved_by_user",
  checkedin = "checkedin",
  coming_soon = "coming_soon",
  fully_booked = "fully_booked",
  conflict = "conflict",
  ended = "ended",
}


export type UserListFeaturedOffersFilter = {
  marketID?: number | null,
  salt?: number | null,
  offset?: number | null,
  limit?: number | null,
  minFollowers?: number | null,
  maxFollowers?: number | null,
  depositCost?: number | null,
  postType?: string | null,
  accessType?: string | null,
  categories?: Array< OfferCategory | null > | null,
};

export type UserListFeaturedOfferListItems = {
  __typename: "UserListFeaturedOfferListItems",
  items?:  Array<FeaturedOffer | null > | null,
};

export type FeaturedOffer = {
  __typename: "FeaturedOffer",
  offerID?: number | null,
  offerTitle?: string | null,
  isNew?: boolean | null,
  availableSpots?: number | null,
  venueID?: number | null,
  venueName?: string | null,
  venuePicture?: Image | null,
  offerPicture?: Image | null,
};

export type UserListRatingsFilter = {
  venueID: string,
  limit?: number | null,
  offset?: number | null,
};

export type UserListVenueRatingListItems = {
  __typename: "UserListVenueRatingListItems",
  items?:  Array<UserVenueRatingItem | null > | null,
};

export type UserVenueRatingItem = {
  __typename: "UserVenueRatingItem",
  userUpdatedAt?: string | null,
  picture?: Image | null,
  userID?: string | null,
  venueID?: string | null,
  reservationID?: string | null,
  feedback?: string | null,
  positive?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userFirstname?: string | null,
  userLastname?: string | null,
  agencyProfileUrl?: string | null,
  effectiveStartDateTime?: string | null,
  offerTitle?: string | null,
  offerID?: number | null,
  offerPicture?: Image | null,
};

export type VenueListPortfolioContentFilter = {
  venueID: string,
  limit?: number | null,
  offset?: number | null,
};

export type UserListOffersFilter = {
  status: OfferStatus,
  sortBy?: string | null,
  sortDirection?: string | null,
  marketID?: string | null,
  search?: string | null,
  offset?: number | null,
  limit?: number | null,
  venueID?: string | null,
  category?: OfferCategory | null,
};

export type UserListOffersListItems = {
  __typename: "UserListOffersListItems",
  items?:  Array<UserOfferItem | null > | null,
};

export type UserOfferItem = {
  __typename: "UserOfferItem",
  id: string,
  title?: string | null,
  venueID: string,
  venue?: Venue | null,
  venueName?: string | null,
  marketName?: string | null,
  marketID?: string | null,
  description?: string | null,
  category?: OfferCategory | null,
  subCategory?: string | null,
  availableTo?: Array< Profession | null > | null,
  gender?: Array< Gender | null > | null,
  startDate?: string | null,
  spots: number,
  waitTime?: number | null,
  availableDays?: Array< DaysOfWeek | null > | null,
  dressCode?: string | null,
  includeSelfInPost?: boolean | null,
  includeServiceOrProductInPost?: boolean | null,
  instagramTag?: string | null,
  postOnInstagramFeed?: boolean | null,
  postOnInstagramStories?: boolean | null,
  postOnInstagramReels?: boolean | null,
  postOnInstagramStories3x?: boolean | null,
  suggestedTip?: string | null,
  tipType?: TipType | null,
  location?: Location | null,
  picture?: Image | null,
  status: UserOfferStatus,
  startDateTime?: string | null,
  endDateTime?: string | null,
  timeZoneId?: string | null,
  additionalreq?: string | null,
  minFollowers?: number | null,
  checkInDateTime?: string | null,
  requirementsSubmitted?: boolean | null,
  reservationId?: string | null,
  confirmWithEmail?: boolean | null,
  confirmWithCall?: boolean | null,
  currency?: string | null,
  validation?: string | null,
  penaltyType?: string | null,
  penaltyId?: string | null,
  reservationCreatedAt?: string | null,
  warnings?: Array< ReservationWarningType | null > | null,
  effectiveStartDateTime?: string | null,
  effectiveEndDateTime?: string | null,
  depositCost?: number | null,
  premium?: boolean | null,
  isNew?: boolean | null,
  availableSpots?: number | null,
  userAccessRequestStatus?: UserOfferAccessValue | null,
  totalSavedContent?: number | null,
};

export enum UserOfferAccessValue {
  requested = "requested",
  permissionRequired = "permissionRequired",
}


export type UserListInvitesFilter = {
  includeExpired?: boolean | null,
};

export type UserListInvitesListItems = {
  __typename: "UserListInvitesListItems",
  items?:  Array<Invite | null > | null,
};

export type UserGetInviteFilter = {
  inviteID: string,
};

export type UserListOfferByVenueFilter = {
  status: OfferStatus,
  venueID: string,
  limit?: number | null,
  offset?: number | null,
};

export type UserListOfferByVenueListItems = {
  __typename: "UserListOfferByVenueListItems",
  items?:  Array<UserOfferItem | null > | null,
};

export type UserOfferInput = {
  offerID: string,
  userID: string,
  startDateTime?: string | null,
  currentActive?: boolean | null,
};

export type UserGetOfferV2Response = {
  __typename: "UserGetOfferV2Response",
  success?: boolean | null,
  message?: string | null,
  offer?: UserOfferItem | null,
};

export type ListPortfolioContentFilter = {
  limit?: number | null,
  offset?: number | null,
};

export type PaymentSheet = {
  __typename: "PaymentSheet",
  customerId: string,
  merchantDisplayName: string,
  customerEphemeralKeySecret: string,
  setupIntentClientSecret: string,
};

export type PaymentIntentInput = {
  suspensionId?: string | null,
  reservationId?: string | null,
  penaltyId?: string | null,
  noshowCredits?: number | null,
};

export type UserAccountBalanceRequest = {
  userID: string,
};

export type UserAccountBalanceResponse = {
  __typename: "UserAccountBalanceResponse",
  balance: number,
  accountId: string,
  status: string,
};

export type UserVerificatioURLResponse = {
  __typename: "UserVerificatioURLResponse",
  onBoardingURL: string,
};

export type UserSubscriptionsFilter = {
  status?: string | null,
  productGroup?: string | null,
};

export type UserListSubscriptionsListItems = {
  __typename: "UserListSubscriptionsListItems",
  items?:  Array<UserSubscriptionItem | null > | null,
};

export type UserSubscriptionItem = {
  __typename: "UserSubscriptionItem",
  id: number,
  subscriptionId: string,
  userID: number,
  sku: string,
  productGroup: string,
  price?: number | null,
  convertedPrice?: number | null,
  currency?: string | null,
  convertedCurrency?: string | null,
  platform?: string | null,
  expirationDate?: string | null,
  status: string,
  isSubscriptionRenewable?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UserListReservationsFilter = {
  status: Array< ReservationStatus | null >,
  limit?: number | null,
  offset?: number | null,
  validated?: boolean | null,
};

export type UserListReservationsListItems = {
  __typename: "UserListReservationsListItems",
  items?:  Array<UserReservationItem | null > | null,
};

export type UserReservationItem = {
  __typename: "UserReservationItem",
  id: string,
  marketName?: string | null,
  marketID?: string | null,
  offerID: string,
  offerCategory?: OfferCategory | null,
  offer?: Offer | null,
  offerTitle?: string | null,
  offerStartTime?: string | null,
  offerEndTime?: string | null,
  offerEndDateTime?: string | null,
  offerDescription?: string | null,
  timeZoneId?: string | null,
  userID: string,
  name?: string | null,
  user?: User | null,
  venueID: string,
  venue?: Venue | null,
  venueName?: string | null,
  venueLocation?: Location | null,
  venueAddress1?: string | null,
  venueAddress2?: string | null,
  venueCity?: string | null,
  venueCountry?: string | null,
  note?: string | null,
  location?: Location | null,
  reservationDate: string,
  checkInDateTime?: string | null,
  createdAt: string,
  venuePicture?: Image | null,
  offerPicture?: Image | null,
  userPicture?: Image | null,
  requirementsSubmitted?: boolean | null,
  status: ReservationStatus,
  currency?: string | null,
  payoutAmount?: number | null,
  validation?: string | null,
  penaltyType?: string | null,
  warnings?: Array< ReservationWarningType | null > | null,
  effectiveStartDateTime?: string | null,
  effectiveEndDateTime?: string | null,
  penaltyId?: string | null,
  contentUploaded?: boolean | null,
  depositCost?: number | null,
  offerPostType?: string | null,
  requirementsSubmittedDateTime?: string | null,
  premium?: boolean | null,
  inviteID?: number | null,
  privateOffer?: boolean | null,
  offerDressCode?: string | null,
  offerSuggestedTip?: string | null,
  offerCurrency?: string | null,
  offerPostInclude?: string | null,
  offerIncludeSelfInPost?: boolean | null,
  offerIncludeServiceOrProductInPost?: boolean | null,
  offerConfirmWithCall?: boolean | null,
  venueInstagramHandle?: string | null,
  rated?: boolean | null,
  ratedByVenue?: boolean | null,
  totalSavedContent?: number | null,
  offerValueUSD?: number | null,
};

export type UserListRedeemedCategoriesListItems = {
  __typename: "UserListRedeemedCategoriesListItems",
  items?:  Array<UserRedeemedCategoriesItem | null > | null,
};

export type UserRedeemedCategoriesItem = {
  __typename: "UserRedeemedCategoriesItem",
  category?: string | null,
  totalRedeemed?: number | null,
  totalPoints?: number | null,
  offers?: Array< number | null > | null,
  offerThumbnails?: Array< string | null > | null,
};

export type UserListReservationsByCategoryFilter = {
  category: string,
};

export type UserListReservationsByCategoryResponse = {
  __typename: "UserListReservationsByCategoryResponse",
  items?:  Array<UserListReservationsByCategoryItem | null > | null,
};

export type UserListReservationsByCategoryItem = {
  __typename: "UserListReservationsByCategoryItem",
  offerTitle?: string | null,
  offerID?: number | null,
  venueName?: string | null,
  points?: number | null,
  startDateTime?: string | null,
  offerThumbnail?: string | null,
  timeZoneId?: string | null,
};

export type UserListReservationsContentFilter = {
  venueID?: string | null,
  offerID?: number | null,
  offset?: number | null,
  limit?: number | null,
};

export type UserListReservationContentResponse = {
  __typename: "UserListReservationContentResponse",
  items?:  Array<UserReservationContentItem | null > | null,
};

export type UserReservationContentItem = {
  __typename: "UserReservationContentItem",
  id?: string | null,
  seen?: boolean | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  userID?: string | null,
  userFirstname?: string | null,
  userLastname?: string | null,
  userUpdatedAt?: string | null,
  offerID?: string | null,
  offerTitle?: string | null,
  offerUpdatedAt?: string | null,
  offerPicture?: Image | null,
  userPicture?: Image | null,
  contentPicture?: Image | null,
};

export type UserTotals = {
  __typename: "UserTotals",
  offerRedeemed: number,
  noShow: number,
  penalty: number,
  noshowCredits: number,
  points: number,
};

export type CreditLedgerFilter = {
  userID: number,
  limit?: number | null,
  offset?: number | null,
};

export type CreditLedgerItems = {
  __typename: "CreditLedgerItems",
  items?:  Array<CreditLedgerItem | null > | null,
};

export type CreditLedgerItem = {
  __typename: "CreditLedgerItem",
  userID: number,
  reservationId?: number | null,
  credits: number,
  createdAt?: string | null,
  updatedAt?: string | null,
  title: string,
  subtitle?: string | null,
  pending?: boolean | null,
  balance?: number | null,
  picture?: Image | null,
};

export type UserListPenaltiesFilter = {
  status: PenaltyStatus,
  userID: string,
};

export type UserListPenaltiesListItems = {
  __typename: "UserListPenaltiesListItems",
  items:  Array<Penalty | null >,
};

export type UserListSuspensionsFilter = {
  status: SuspensionStatus,
  sortBy?: string | null,
  sortDirection?: string | null,
  marketID?: string | null,
  offset?: number | null,
  limit?: number | null,
  reason?: SuspensionReason | null,
  userID: string,
};

export type UserListSuspensionsListItems = {
  __typename: "UserListSuspensionsListItems",
  items?:  Array<Suspension | null > | null,
};

export type UserListNotificationsFilter = {
  userID: string,
  sortDirection?: SortDirection | null,
  type?: NotificationType | null,
  status?: Array< UserNotificationStatus | null > | null,
  offset?: number | null,
  limit?: number | null,
};

export type UserNotificationListItems = {
  __typename: "UserNotificationListItems",
  items:  Array<UserNotification | null >,
};

export type UserNotification = {
  __typename: "UserNotification",
  id: string,
  userID: string,
  venueID?: string | null,
  type: NotificationType,
  thumbnail?: string | null,
  title?: string | null,
  content?: string | null,
  status?: UserNotificationStatus | null,
  metadata?: string | null,
  redirectCTA?: string | null,
  createdAt: string,
};

export enum GooglePlaceType {
  cities = "cities",
  establishment = "establishment",
}


export type ListLogItemInput = {
  type?: LogType | null,
  startDateTimeMilliSeconds?: number | null,
  endDateTimeMilliSeconds?: number | null,
  match?: string | null,
  resources?: Array< string | null > | null,
};

export enum LogType {
  ERROR = "ERROR",
  INFO = "INFO",
  WARN = "WARN",
}


export type ListLogItemResult = {
  __typename: "ListLogItemResult",
  nextToken?: string | null,
  items:  Array<LogItem >,
};

export type LogItem = {
  __typename: "LogItem",
  id: string,
  type: LogType,
  resource: string,
  message: string,
  timestamp: number,
};

export type AdminListVenueLeadsFilter = {
  status?: string | null,
  sortBy?: string | null,
  sortDirection?: string | null,
  search?: string | null,
  marketID?: string | null,
};

export type AdminListVenueLeadsResponse = {
  __typename: "AdminListVenueLeadsResponse",
  items?:  Array<VenueLeads | null > | null,
};

export type VenueLeads = {
  __typename: "VenueLeads",
  id?: number | null,
  status?: string | null,
  userID?: string | null,
  name?: string | null,
  address?: VenueLeadsAddress | null,
  website?: string | null,
  instagram?: string | null,
  contactName?: string | null,
  contactNumber?: string | null,
  contactEmail?: string | null,
  marketName?: string | null,
  marketID?: string | null,
  recommendedByFirstname?: string | null,
  recommendedByLastname?: string | null,
  recommendedByInstagramHandle?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type VenueLeadsAddress = {
  __typename: "VenueLeadsAddress",
  location?: Location | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  postalCode?: string | null,
  stateProvince?: string | null,
  country?: string | null,
  countryCode?: string | null,
  dependentLocality?: string | null,
  formattedAddress?: string | null,
};

export type CurrencyListResponse = {
  __typename: "CurrencyListResponse",
  items?:  Array<CurrencyItem | null > | null,
};

export type CurrencyItem = {
  __typename: "CurrencyItem",
  code: string,
  rate: number,
  updatedAt: number,
};

export type VenueSubscriptionsFilter = {
  venueID: number,
  status?: string | null,
  productGroup?: string | null,
};

export type VenueListSubscriptionsListItems = {
  __typename: "VenueListSubscriptionsListItems",
  items?:  Array<VenueSubscriptionItem | null > | null,
};

export type VenueSubscriptionItem = {
  __typename: "VenueSubscriptionItem",
  id: number,
  subscriptionId: string,
  venueID: number,
  sku: string,
  productGroup: string,
  price?: number | null,
  convertedPrice?: number | null,
  currency?: string | null,
  convertedCurrency?: string | null,
  platform?: string | null,
  expirationDate?: string | null,
  status: string,
  isSubscriptionRenewable?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type AgencyInviteGetRequest = {
  venueID?: number | null,
};

export type AdminListConversationsFilter = {
  startKey?: string | null,
  limit: number,
  userType: string,
  sortbyRecent?: boolean | null,
};

export type ConversationList = {
  __typename: "ConversationList",
  nextPageKey?: string | null,
  items?:  Array<ConversationItem | null > | null,
};

export type ConversationItem = {
  __typename: "ConversationItem",
  fromUserId: string,
  toUserId: string,
  conversationId: string,
  userType: string,
  messageTime: string,
  fromUserMessageCount?: number | null,
  toUserMessageCount?: number | null,
  images?: Image | null,
  firstname?: string | null,
  lastname?: string | null,
  instagramHandle?: string | null,
  instagramFollowers?: string | null,
  premium?: string | null,
  plan?: string | null,
};

export type AdminGetConversationFilter = {
  userType?: string | null,
  toUserId?: string | null,
};

export type VenueGetConversationFilter = {
  venueID?: string | null,
};

export type AdminListChatMessagesFilter = {
  startKey?: string | null,
  limit: number,
  conversationId: string,
};

export type ChatMessagesList = {
  __typename: "ChatMessagesList",
  nextPageKey?: string | null,
  items?:  Array<ChatMessageItem | null > | null,
};

export type VenueListChatMessagesFilter = {
  startKey?: string | null,
  limit: number,
  conversationId: string,
};

export type UserListChatMessagesFilter = {
  startKey?: string | null,
  limit: number,
  conversationId: string,
};

export type AgencyCreateUserInviteMutationVariables = {
  input?: AgencyCreateRequest | null,
};

export type AgencyCreateUserInviteMutation = {
  agencyCreateUserInvite: string,
};

export type UserActionAgencyInviteMutationVariables = {
  input?: UserActionAgencyRequest | null,
};

export type UserActionAgencyInviteMutation = {
  userActionAgencyInvite: string,
};

export type AgencyMarkInviteSeenMutationVariables = {
  input?: AgencyMarkInviteSeenRequest | null,
};

export type AgencyMarkInviteSeenMutation = {
  agencyMarkInviteSeen: string,
};

export type AgencyInviteAgreementSignMutationVariables = {
  input?: AgencyInviteAgreementSignRequest | null,
};

export type AgencyInviteAgreementSignMutation = {
  agencyInviteAgreementSign: string,
};

export type ServiceResetPasswordMutationVariables = {
  input?: ResetPasswordInput | null,
};

export type ServiceResetPasswordMutation = {
  serviceResetPassword: string,
};

export type AdminApproveVenueLeadsMutationVariables = {
  id: string,
};

export type AdminApproveVenueLeadsMutation = {
  adminApproveVenueLeads: string,
};

export type AdminRejectVenueLeadsMutationVariables = {
  id: string,
};

export type AdminRejectVenueLeadsMutation = {
  adminRejectVenueLeads: string,
};

export type AdminCreateMarketMutationVariables = {
  input: AdminCreateMarketInput,
};

export type AdminCreateMarketMutation = {
  adminCreateMarket: string,
};

export type AdminUpdateMarketMutationVariables = {
  input: AdminUpdateMarketInput,
};

export type AdminUpdateMarketMutation = {
  adminUpdateMarket: string,
};

export type AdminCreateVenueMutationVariables = {
  input: AdminCreateVenueInput,
};

export type AdminCreateVenueMutation = {
  adminCreateVenue: string,
};

export type AdminUpdateVenueMutationVariables = {
  input: AdminUpdateVenueInput,
};

export type AdminUpdateVenueMutation = {
  adminUpdateVenue: string,
};

export type AdminUpdateVenuesMutationVariables = {
  input: AdminUpdateVenuesInput,
};

export type AdminUpdateVenuesMutation = {
  adminUpdateVenues: string,
};

export type VenueAdminUpdateVenueMutationVariables = {
  input: AdminUpdateVenueInput,
};

export type VenueAdminUpdateVenueMutation = {
  venueAdminUpdateVenue: string,
};

export type VenueConnectVenueMutationVariables = {
  input: VenueConnectVenueInput,
};

export type VenueConnectVenueMutation = {
  venueConnectVenue:  {
    __typename: "VenueConnectVenueResponse",
    connectedVenueID?: string | null,
    message?: string | null,
  },
};

export type AdminDeleteVenueMutationVariables = {
  id: string,
};

export type AdminDeleteVenueMutation = {
  adminDeleteVenue: string,
};

export type AdminUpdateStaffApiMutationVariables = {
  input: EditStaffValuesInput,
};

export type AdminUpdateStaffApiMutation = {
  adminUpdateStaffApi: string,
};

export type AdminCreateStaffApiMutationVariables = {
  input: EditStaffValuesInput,
};

export type AdminCreateStaffApiMutation = {
  adminCreateStaffApi: string,
};

export type AdminCreateOfferMutationVariables = {
  input: AdminCreateOfferInput,
};

export type AdminCreateOfferMutation = {
  adminCreateOffer: string,
};

export type AdminCreateOfferV2MutationVariables = {
  input: AdminCreateOfferInput,
};

export type AdminCreateOfferV2Mutation = {
  adminCreateOfferV2: string,
};

export type VenueAdminCreateOfferMutationVariables = {
  input: AdminCreateOfferInput,
};

export type VenueAdminCreateOfferMutation = {
  venueAdminCreateOffer: string,
};

export type AdminUpdateOfferMutationVariables = {
  input: AdminUpdateOfferInput,
};

export type AdminUpdateOfferMutation = {
  adminUpdateOffer: string,
};

export type VenueAdminUpdateOfferMutationVariables = {
  input: AdminUpdateOfferInput,
};

export type VenueAdminUpdateOfferMutation = {
  venueAdminUpdateOffer: string,
};

export type AdminDeleteOfferMutationVariables = {
  id: string,
};

export type AdminDeleteOfferMutation = {
  adminDeleteOffer: string,
};

export type AdminCreateUserMutationVariables = {
  input: AdminCreateUserInput,
};

export type AdminCreateUserMutation = {
  adminCreateUser: string,
};

export type AdminUpdateUserMutationVariables = {
  input: AdminUpdateUserInput,
};

export type AdminUpdateUserMutation = {
  adminUpdateUser: string,
};

export type AdminUpdateUsersMutationVariables = {
  input: AdminUpdateUsersInput,
};

export type AdminUpdateUsersMutation = {
  adminUpdateUsers: string,
};

export type AdminUpdateUserInstagramMutationVariables = {
  input: AdminUpdateUserInstagramInput,
};

export type AdminUpdateUserInstagramMutation = {
  adminUpdateUserInstagram: string,
};

export type AdminInstagramScrapeMutationVariables = {
  input: AdminInstagramScrapeInput,
};

export type AdminInstagramScrapeMutation = {
  adminInstagramScrape: string,
};

export type AdminEnableUserMutationVariables = {
  userID: string,
};

export type AdminEnableUserMutation = {
  adminEnableUser: string,
};

export type AdminUnblockUserMutationVariables = {
  userID: string,
};

export type AdminUnblockUserMutation = {
  adminUnblockUser: string,
};

export type AdminUpdatePasswordMutationVariables = {
  input: AdminUpdatePasswordInput,
};

export type AdminUpdatePasswordMutation = {
  adminUpdatePassword: string,
};

export type AdminDeleteUserMutationVariables = {
  id: string,
};

export type AdminDeleteUserMutation = {
  adminDeleteUser: string,
};

export type AdminMergeUsersMutationVariables = {
  input: AdminMergeUsersInput,
};

export type AdminMergeUsersMutation = {
  adminMergeUsers: string,
};

export type AdminUpdateReservationMutationVariables = {
  input: AdminUpdateReservationInput,
};

export type AdminUpdateReservationMutation = {
  adminUpdateReservation: string,
};

export type SupportReviewReservationMutationVariables = {
  input: SupportReviewReservationInput,
};

export type SupportReviewReservationMutation = {
  supportReviewReservation: string,
};

export type VenueReviewReservationMutationVariables = {
  input: SupportReviewReservationInput,
};

export type VenueReviewReservationMutation = {
  venueReviewReservation: string,
};

export type AdminDeleteReservationMutationVariables = {
  id: string,
};

export type AdminDeleteReservationMutation = {
  adminDeleteReservation: string,
};

export type AdminDeleteReservationContentMutationVariables = {
  id: string,
};

export type AdminDeleteReservationContentMutation = {
  adminDeleteReservationContent: string,
};

export type AdminCreatePenaltyMutationVariables = {
  input: AdminCreatePenaltyInput,
};

export type AdminCreatePenaltyMutation = {
  adminCreatePenalty: string,
};

export type AdminUpdatePenaltyMutationVariables = {
  input: AdminUpdatePenaltyInput,
};

export type AdminUpdatePenaltyMutation = {
  adminUpdatePenalty: string,
};

export type AdminDeletePenaltyMutationVariables = {
  id: string,
};

export type AdminDeletePenaltyMutation = {
  adminDeletePenalty: string,
};

export type AdminCreateSuspensionMutationVariables = {
  input: AdminCreateSuspensionInput,
};

export type AdminCreateSuspensionMutation = {
  adminCreateSuspension: string,
};

export type AdminUpdateSuspensionMutationVariables = {
  input: AdminUpdateSuspensionInput,
};

export type AdminUpdateSuspensionMutation = {
  adminUpdateSuspension: string,
};

export type AdminDeleteSuspensionMutationVariables = {
  id: string,
};

export type AdminDeleteSuspensionMutation = {
  adminDeleteSuspension: string,
};

export type AdminCreateCampaignMutationVariables = {
  input: AdminCreateCampaignInput,
};

export type AdminCreateCampaignMutation = {
  adminCreateCampaign: string,
};

export type AdminUpdateCampaignMutationVariables = {
  input: AdminUpdateCampaignInput,
};

export type AdminUpdateCampaignMutation = {
  adminUpdateCampaign: string,
};

export type AdminDeleteCampaignMutationVariables = {
  id: string,
};

export type AdminDeleteCampaignMutation = {
  adminDeleteCampaign: string,
};

export type AdminMarkUserComplaintMutationVariables = {
  input: AdminMarkUserComplaintInput,
};

export type AdminMarkUserComplaintMutation = {
  adminMarkUserComplaint: string,
};

export type AdminMarkVenueComplaintMutationVariables = {
  input: AdminMarkUserComplaintInput,
};

export type AdminMarkVenueComplaintMutation = {
  adminMarkVenueComplaint: string,
};

export type UserChangeMutedVenueMutationVariables = {
  input: UserUpdateMutedVenueInput,
};

export type UserChangeMutedVenueMutation = {
  userChangeMutedVenue: string,
};

export type UserChangeVenueFavouriteMutationVariables = {
  input: UserUpdateVenueFavouriteInput,
};

export type UserChangeVenueFavouriteMutation = {
  userChangeVenueFavourite: string,
};

export type UserRateVenueMutationVariables = {
  input: UserVenueRateInput,
};

export type UserRateVenueMutation = {
  userRateVenue: string,
};

export type UserCreateUserMutationVariables = {
  input: UserCreateUserInput,
};

export type UserCreateUserMutation = {
  userCreateUser: string,
};

export type UserUpdateUserMutationVariables = {
  input: UserUpdateUserInput,
};

export type UserUpdateUserMutation = {
  userUpdateUser: string,
};

export type UserCreatePortfolioContentMutationVariables = {
  input: UserCreatePortfolioInput,
};

export type UserCreatePortfolioContentMutation = {
  userCreatePortfolioContent: string,
};

export type UserDeletePortfolioContentMutationVariables = {
  input: UserDeletePortfolioInput,
};

export type UserDeletePortfolioContentMutation = {
  userDeletePortfolioContent: string,
};

export type UserUpdateEmailMutationVariables = {
  input: UserUpdateEmailInput,
};

export type UserUpdateEmailMutation = {
  userUpdateEmail: string,
};

export type UserUpdatePasswordMutationVariables = {
  input: UserUpdatePasswordInput,
};

export type UserUpdatePasswordMutation = {
  userUpdatePassword: string,
};

export type UserCreateReservationMutationVariables = {
  input: UserCreateReservationInput,
};

export type UserCreateReservationMutation = {
  userCreateReservation: string,
};

export type UserActionInviteMutationVariables = {
  input: UserActionInviteInput,
};

export type UserActionInviteMutation = {
  userActionInvite: string,
};

export type UserMarkInviteSeenMutationVariables = {
  input: UserMarkInviteInput,
};

export type UserMarkInviteSeenMutation = {
  userMarkInviteSeen: string,
};

export type UserCreateReservationContentMutationVariables = {
  input: UserCreateReservationContentInput,
};

export type UserCreateReservationContentMutation = {
  userCreateReservationContent: string,
};

export type UserUpdateReservationMutationVariables = {
  input: UserUpdateReservationInput,
};

export type UserUpdateReservationMutation = {
  userUpdateReservation: string,
};

export type UserUpdateReservationContentMutationVariables = {
  input: UserUpdateReservationContentInput,
};

export type UserUpdateReservationContentMutation = {
  userUpdateReservationContent: string,
};

export type UserCreateAccessRequestMutationVariables = {
  input: UserCreateAccessRequestInput,
};

export type UserCreateAccessRequestMutation = {
  userCreateAccessRequest: string,
};

export type UserCreateAccessRequestV2MutationVariables = {
  input: UserCreateAccessRequestInput,
};

export type UserCreateAccessRequestV2Mutation = {
  userCreateAccessRequestV2: string,
};

export type UserCancelAccessRequestMutationVariables = {
  input: UserCancelAccessRequestInput,
};

export type UserCancelAccessRequestMutation = {
  userCancelAccessRequest: string,
};

export type VenueActionAccessRequestMutationVariables = {
  input: VenueActionAccessRequest,
};

export type VenueActionAccessRequestMutation = {
  venueActionAccessRequest: string,
};

export type UserCreateComplaintMutationVariables = {
  input: UserCreateComplaintInput,
};

export type UserCreateComplaintMutation = {
  userCreateComplaint: string,
};

export type VenueCreateComplaintMutationVariables = {
  input: VenueCreateComplaintInput,
};

export type VenueCreateComplaintMutation = {
  venueCreateComplaint: string,
};

export type VenueCreatePenaltyMutationVariables = {
  input: VenueCreatePenaltyInput,
};

export type VenueCreatePenaltyMutation = {
  venueCreatePenalty: string,
};

export type UserUpdateNotificationsMutationVariables = {
  input: UserUpdateNotificationInput,
};

export type UserUpdateNotificationsMutation = {
  userUpdateNotifications: string,
};

export type UserMarkNotificationsReceivedMutationVariables = {
  userID: string,
};

export type UserMarkNotificationsReceivedMutation = {
  userMarkNotificationsReceived: string,
};

export type UserCreateVenueRecommendationMutationVariables = {
  input: VenueLeadInput,
};

export type UserCreateVenueRecommendationMutation = {
  userCreateVenueRecommendation: string,
};

export type AdminUpdateVenueRecommendationMutationVariables = {
  input: VenueLeadUpdateInput,
};

export type AdminUpdateVenueRecommendationMutation = {
  adminUpdateVenueRecommendation: string,
};

export type VenueCreateVenueMutationVariables = {
  input: VenueCreateVenueInput,
};

export type VenueCreateVenueMutation = {
  venueCreateVenue: string,
};

export type VenueCreatePortfolioContentMutationVariables = {
  input: VenueCreatePortfolioInput,
};

export type VenueCreatePortfolioContentMutation = {
  venueCreatePortfolioContent: string,
};

export type VenueDeletePortfolioContentMutationVariables = {
  input: VenueDeletePortfolioInput,
};

export type VenueDeletePortfolioContentMutation = {
  venueDeletePortfolioContent: string,
};

export type VenueRateUserMutationVariables = {
  input: VenueUserRateInput,
};

export type VenueRateUserMutation = {
  venueRateUser: string,
};

export type VenueUpdateVenueMutationVariables = {
  input: VenueUpdateVenueInput,
};

export type VenueUpdateVenueMutation = {
  venueUpdateVenue: string,
};

export type VenueUpdateEmailMutationVariables = {
  input: VenueUpdateEmailInput,
};

export type VenueUpdateEmailMutation = {
  venueUpdateEmail: string,
};

export type VenueUpdatePasswordMutationVariables = {
  input: VenueUpdatePasswordInput,
};

export type VenueUpdatePasswordMutation = {
  venueUpdatePassword: string,
};

export type AdminVenueBlockUserMutationVariables = {
  input: VenueBlockUserInput,
};

export type AdminVenueBlockUserMutation = {
  adminVenueBlockUser: string,
};

export type AdminVenueUnblockUserMutationVariables = {
  input: VenueBlockUserInput,
};

export type AdminVenueUnblockUserMutation = {
  adminVenueUnblockUser: string,
};

export type VenueBlockUserMutationVariables = {
  input: VenueBlockUserInput,
};

export type VenueBlockUserMutation = {
  venueBlockUser: string,
};

export type VenueUnblockUserMutationVariables = {
  input: VenueBlockUserInput,
};

export type VenueUnblockUserMutation = {
  venueUnblockUser: string,
};

export type VenueSaveUserMutationVariables = {
  input: VenueSaveUserInput,
};

export type VenueSaveUserMutation = {
  venueSaveUser: string,
};

export type VenueUnsaveUserMutationVariables = {
  input: VenueSaveUserInput,
};

export type VenueUnsaveUserMutation = {
  venueUnsaveUser: string,
};

export type VenueCreateOfferMutationVariables = {
  input: VenueCreateOfferInput,
};

export type VenueCreateOfferMutation = {
  venueCreateOffer: string,
};

export type VenueCreateInviteMutationVariables = {
  input: VenueCreateInviteInput,
};

export type VenueCreateInviteMutation = {
  venueCreateInvite: string,
};

export type VenueCreateInviteV2MutationVariables = {
  input: VenueCreateInviteInputV2,
};

export type VenueCreateInviteV2Mutation = {
  venueCreateInviteV2: string,
};

export type VenueDeleteInviteMutationVariables = {
  input: VenueDeleteInviteInput,
};

export type VenueDeleteInviteMutation = {
  venueDeleteInvite: string,
};

export type VenueUpdateOfferMutationVariables = {
  input: VenueUpdateOfferInput,
};

export type VenueUpdateOfferMutation = {
  venueUpdateOffer: string,
};

export type VenueUpdateReservationContentMutationVariables = {
  input: VenueUpdateReservationContentInput,
};

export type VenueUpdateReservationContentMutation = {
  venueUpdateReservationContent: string,
};

export type VenueDownloadReservationContentMutationVariables = {
  input: VenueDownloadReservationContentInput,
};

export type VenueDownloadReservationContentMutation = {
  venueDownloadReservationContent: string,
};

export type AdminDownloadReservationContentMutationVariables = {
  input: AdminDownloadReservationContentInput,
};

export type AdminDownloadReservationContentMutation = {
  adminDownloadReservationContent: string,
};

export type VenueMarkReservationContentMutationVariables = {
  input: VenueMarkReservationContentInput,
};

export type VenueMarkReservationContentMutation = {
  venueMarkReservationContent: string,
};

export type VenueMarkReservationContentV2MutationVariables = {
  input: VenueMarkReservationContentInputV2,
};

export type VenueMarkReservationContentV2Mutation = {
  venueMarkReservationContentV2: string,
};

export type VenueUpdateSubscriptionMutationVariables = {
  input: VenueUpdateSubscriptionInput,
};

export type VenueUpdateSubscriptionMutation = {
  venueUpdateSubscription: string,
};

export type VenueUpdateReservationMutationVariables = {
  input: VenueUpdateReservationInput,
};

export type VenueUpdateReservationMutation = {
  venueUpdateReservation: string,
};

export type VenueUpdateNotificationsMutationVariables = {
  input: VenueUpdateNotificationInput,
};

export type VenueUpdateNotificationsMutation = {
  venueUpdateNotifications: string,
};

export type VenueMarkNotificationsReceivedMutationVariables = {
  venueID: string,
};

export type VenueMarkNotificationsReceivedMutation = {
  venueMarkNotificationsReceived: string,
};

export type CreateFeedbackMutationVariables = {
  input: CreateFeedbackInput,
};

export type CreateFeedbackMutation = {
  createFeedback: string,
};

export type ServiceAppPutEventMutationVariables = {
  input: AppEventInput,
};

export type ServiceAppPutEventMutation = {
  serviceAppPutEvent: string,
};

export type UpdateAdminConfigMutationVariables = {
  input: AdminConfigInput,
};

export type UpdateAdminConfigMutation = {
  updateAdminConfig: string,
};

export type AdminSendChatMessageMutationVariables = {
  input?: NewAdminChatMessage | null,
};

export type AdminSendChatMessageMutation = {
  adminSendChatMessage:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  },
};

export type UserSendAdminChatMessageMutationVariables = {
  input?: NewUserAdminChatMessage | null,
};

export type UserSendAdminChatMessageMutation = {
  userSendAdminChatMessage:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  },
};

export type VenueSendAdminChatMessageMutationVariables = {
  input?: NewVenueAdminChatMessage | null,
};

export type VenueSendAdminChatMessageMutation = {
  venueSendAdminChatMessage:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  },
};

export type UpdateMessageStatusMutationVariables = {
  input?: ChatMessageUpdateInput | null,
};

export type UpdateMessageStatusMutation = {
  updateMessageStatus?:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  } | null,
};

export type UpdateMessagesStatusMutationVariables = {
  input?: Array< ChatMessageUpdateInput | null > | null,
};

export type UpdateMessagesStatusMutation = {
  updateMessagesStatus?:  Array< {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  } | null > | null,
};

export type AdminRejectMessageMutationVariables = {
  input?: ChatMessageRejectInput | null,
};

export type AdminRejectMessageMutation = {
  adminRejectMessage?:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  } | null,
};

export type AdminCloseChatMutationVariables = {
  input?: NewAdminCloseMessage | null,
};

export type AdminCloseChatMutation = {
  adminCloseChat:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  },
};

export type AdminListMarketsQueryVariables = {
  filter: AdminListMarketsFilter,
};

export type AdminListMarketsQuery = {
  adminListMarkets:  {
    __typename: "AdminListMarketsItems",
    items?:  Array< {
      __typename: "Market",
      id: string,
      name: string,
      totalActiveVenues?: number | null,
      totalVenues?: number | null,
      totalPremiumVenues?: number | null,
      totalActiveModels?: number | null,
      totalUsers?: number | null,
      totalPremiumUsers?: number | null,
      percentActiveModels?: number | null,
      totalActiveOffers?: number | null,
      totalActiveVenueWithOffers?: number | null,
      totalPremiumOffers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      percentBookedLastFewDays?: number | null,
      percentBookedAllTime?: number | null,
      valueActiveOfferSpots?: number | null,
      totalReach?: number | null,
      stateProvince?: string | null,
      countryCode?: string | null,
      country?: string | null,
      currency?: string | null,
      status: MarketStatus,
      unitSystem?: UnitSystem | null,
      createdAt: string,
      tipEnabled?: boolean | null,
      totalVenuesLeads?: number | null,
      totalApplicationUsers?: number | null,
    } | null > | null,
  },
};

export type AdminGetMarketQueryVariables = {
  id: string,
};

export type AdminGetMarketQuery = {
  adminGetMarket:  {
    __typename: "Market",
    id: string,
    name: string,
    venues?:  Array< {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null > | null,
    totalActiveVenues?: number | null,
    totalVenues?: number | null,
    totalPremiumVenues?: number | null,
    totalActiveModels?: number | null,
    totalUsers?: number | null,
    totalPremiumUsers?: number | null,
    percentActiveModels?: number | null,
    totalActiveOffers?: number | null,
    totalActiveVenueWithOffers?: number | null,
    totalPremiumOffers?: number | null,
    totalSpots?: number | null,
    totalReservations?: number | null,
    percentBookedLastFewDays?: number | null,
    percentBookedAllTime?: number | null,
    valueActiveOfferSpots?: number | null,
    totalReach?: number | null,
    stateProvince?: string | null,
    countryCode?: string | null,
    country?: string | null,
    currency?: string | null,
    status: MarketStatus,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    timezone?:  {
      __typename: "Timezone",
      dstOffset: number,
      rawOffset: number,
      timeZoneId: string,
      timeZoneName: string,
    } | null,
    unitSystem?: UnitSystem | null,
    createdAt: string,
    tipEnabled?: boolean | null,
    totalVenuesLeads?: number | null,
    totalApplicationUsers?: number | null,
  },
};

export type AdminGetMarketStatisticsQueryVariables = {
  filter: AdminGetMarketStatisticsFilter,
};

export type AdminGetMarketStatisticsQuery = {
  adminGetMarketStatistics:  {
    __typename: "AdminGetMarketStatisticsResponse",
    totalActiveVenues?: number | null,
    totalBlockedVenues?: number | null,
    totalPendingVenues?: number | null,
    totalRejectedVenues?: number | null,
    totalInactiveVenues?: number | null,
    totalDeletedVenues?: number | null,
    totalActiveVenuesWithNoOffers?: number | null,
  },
};

export type AdminGetMarketStatisticModelsQueryVariables = {
  filter: AdminGetMarketStatisticsFilter,
};

export type AdminGetMarketStatisticModelsQuery = {
  adminGetMarketStatisticModels:  {
    __typename: "AdminGetMarketStatisticModelsResponse",
    totalActiveModels?: number | null,
    totalPendingModels?: number | null,
    totalBlockedModels?: number | null,
    totalRejectedModels?: number | null,
    totalSuspendedModels?: number | null,
    totalNoInstagramModels?: number | null,
    totalDeletedModels?: number | null,
    totalScoutingEnabled?: number | null,
    totalScoutingPlanEnabled?: number | null,
    totalNoScoutingEnabled?: number | null,
  },
};

export type AdminListVenuesQueryVariables = {
  filter: AdminListVenuesFilter,
};

export type AdminListVenuesQuery = {
  adminListVenues:  {
    __typename: "AdminListVenuesListItems",
    items?:  Array< {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null > | null,
  },
};

export type AdminListVenuesCountQueryVariables = {
  filter: AdminListVenuesFilter,
};

export type AdminListVenuesCountQuery = {
  adminListVenuesCount: string,
};

export type AdminGetVenueQueryVariables = {
  id: string,
};

export type AdminGetVenueQuery = {
  adminGetVenue:  {
    __typename: "Venue",
    id: string,
    offers?:  Array< {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null > | null,
    reservations?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    approvedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    marketName?: string | null,
    marketID: string,
    marketCurrency?: string | null,
    market?:  {
      __typename: "Market",
      id: string,
      name: string,
      totalActiveVenues?: number | null,
      totalVenues?: number | null,
      totalPremiumVenues?: number | null,
      totalActiveModels?: number | null,
      totalUsers?: number | null,
      totalPremiumUsers?: number | null,
      percentActiveModels?: number | null,
      totalActiveOffers?: number | null,
      totalActiveVenueWithOffers?: number | null,
      totalPremiumOffers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      percentBookedLastFewDays?: number | null,
      percentBookedAllTime?: number | null,
      valueActiveOfferSpots?: number | null,
      totalReach?: number | null,
      stateProvince?: string | null,
      countryCode?: string | null,
      country?: string | null,
      currency?: string | null,
      status: MarketStatus,
      unitSystem?: UnitSystem | null,
      createdAt: string,
      tipEnabled?: boolean | null,
      totalVenuesLeads?: number | null,
      totalApplicationUsers?: number | null,
    } | null,
    type?: OfferCategory | null,
    phone?: string | null,
    email: string,
    name: string,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    contactFirstname?: string | null,
    contactLastname?: string | null,
    contactPosition?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    postalCode?: string | null,
    stateProvince?: string | null,
    country?: string | null,
    countryCode?: string | null,
    dependentLocality?: string | null,
    timeZoneId?: string | null,
    website?: string | null,
    instagramHandle?: string | null,
    referredBy?: string | null,
    description?: string | null,
    sideLetter?: string | null,
    approvalDate?: string | null,
    device?: string | null,
    totalActiveOffers?: number | null,
    totalOffers?: number | null,
    totalFavourites?: number | null,
    totalFavouritesByActiveUsers?: number | null,
    totalSpots?: number | null,
    totalReservations?: number | null,
    totalCheckins?: number | null,
    totalContentSaved?: number | null,
    totalImpressions?: number | null,
    percentBooked?: number | null,
    totalReach?: number | null,
    totalNoshow?: number | null,
    distance?: number | null,
    lastSeen?: string | null,
    lastBrowser?: string | null,
    lastPlatform?: string | null,
    lastSynced?: string | null,
    createdAt: string,
    updatedAt: string,
    status: VenueStatus,
    offerReservationStatus?: OfferReservationStatus | null,
    stats?: string | null,
    enabledPushNotifications?: boolean | null,
    enabledLocationAccess?: boolean | null,
    offerUpdatedAt?: string | null,
    reservationUpdatedAt?: string | null,
    closedDates?: Array< string | null > | null,
    checkInRadius?: number | null,
    platform?: string | null,
    notes?: string | null,
    enabledEmailNotifications?: boolean | null,
    currency?: string | null,
    tags?: Array< string | null > | null,
    appVersion?: string | null,
    tncVersion?: string | null,
    newContentAvailable?: boolean | null,
    availableDownloads?: number | null,
    unreadNotificationCount?: number | null,
    masterVenueID?: number | null,
    views?: number | null,
    formattedAddress?: string | null,
    tipEnabled?: boolean | null,
    premium?: boolean | null,
    lifestyleMembersAccess?: OfferAccessType | null,
    selectMembersAccess?: OfferAccessType | null,
    accessRequestsCount?: number | null,
    hasPermissionRequiredOffers?: boolean | null,
    hasSetLifestyleAccessType?: boolean | null,
    hasSetSelectAccessType?: boolean | null,
    plan?: string | null,
    termsAccepted?: boolean | null,
    referralInstagramHandle?: string | null,
    referredInstagramUserId?: string | null,
    totalAgencyUserInvites?: number | null,
    totalAgencyUserSignedInvites?: number | null,
  },
};

export type VenueAdminGetVenueQueryVariables = {
};

export type VenueAdminGetVenueQuery = {
  venueAdminGetVenue:  {
    __typename: "Venue",
    id: string,
    offers?:  Array< {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null > | null,
    reservations?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    approvedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    marketName?: string | null,
    marketID: string,
    marketCurrency?: string | null,
    market?:  {
      __typename: "Market",
      id: string,
      name: string,
      totalActiveVenues?: number | null,
      totalVenues?: number | null,
      totalPremiumVenues?: number | null,
      totalActiveModels?: number | null,
      totalUsers?: number | null,
      totalPremiumUsers?: number | null,
      percentActiveModels?: number | null,
      totalActiveOffers?: number | null,
      totalActiveVenueWithOffers?: number | null,
      totalPremiumOffers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      percentBookedLastFewDays?: number | null,
      percentBookedAllTime?: number | null,
      valueActiveOfferSpots?: number | null,
      totalReach?: number | null,
      stateProvince?: string | null,
      countryCode?: string | null,
      country?: string | null,
      currency?: string | null,
      status: MarketStatus,
      unitSystem?: UnitSystem | null,
      createdAt: string,
      tipEnabled?: boolean | null,
      totalVenuesLeads?: number | null,
      totalApplicationUsers?: number | null,
    } | null,
    type?: OfferCategory | null,
    phone?: string | null,
    email: string,
    name: string,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    contactFirstname?: string | null,
    contactLastname?: string | null,
    contactPosition?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    postalCode?: string | null,
    stateProvince?: string | null,
    country?: string | null,
    countryCode?: string | null,
    dependentLocality?: string | null,
    timeZoneId?: string | null,
    website?: string | null,
    instagramHandle?: string | null,
    referredBy?: string | null,
    description?: string | null,
    sideLetter?: string | null,
    approvalDate?: string | null,
    device?: string | null,
    totalActiveOffers?: number | null,
    totalOffers?: number | null,
    totalFavourites?: number | null,
    totalFavouritesByActiveUsers?: number | null,
    totalSpots?: number | null,
    totalReservations?: number | null,
    totalCheckins?: number | null,
    totalContentSaved?: number | null,
    totalImpressions?: number | null,
    percentBooked?: number | null,
    totalReach?: number | null,
    totalNoshow?: number | null,
    distance?: number | null,
    lastSeen?: string | null,
    lastBrowser?: string | null,
    lastPlatform?: string | null,
    lastSynced?: string | null,
    createdAt: string,
    updatedAt: string,
    status: VenueStatus,
    offerReservationStatus?: OfferReservationStatus | null,
    stats?: string | null,
    enabledPushNotifications?: boolean | null,
    enabledLocationAccess?: boolean | null,
    offerUpdatedAt?: string | null,
    reservationUpdatedAt?: string | null,
    closedDates?: Array< string | null > | null,
    checkInRadius?: number | null,
    platform?: string | null,
    notes?: string | null,
    enabledEmailNotifications?: boolean | null,
    currency?: string | null,
    tags?: Array< string | null > | null,
    appVersion?: string | null,
    tncVersion?: string | null,
    newContentAvailable?: boolean | null,
    availableDownloads?: number | null,
    unreadNotificationCount?: number | null,
    masterVenueID?: number | null,
    views?: number | null,
    formattedAddress?: string | null,
    tipEnabled?: boolean | null,
    premium?: boolean | null,
    lifestyleMembersAccess?: OfferAccessType | null,
    selectMembersAccess?: OfferAccessType | null,
    accessRequestsCount?: number | null,
    hasPermissionRequiredOffers?: boolean | null,
    hasSetLifestyleAccessType?: boolean | null,
    hasSetSelectAccessType?: boolean | null,
    plan?: string | null,
    termsAccepted?: boolean | null,
    referralInstagramHandle?: string | null,
    referredInstagramUserId?: string | null,
    totalAgencyUserInvites?: number | null,
    totalAgencyUserSignedInvites?: number | null,
  },
};

export type AdminGetVenueCreditLedgerQueryVariables = {
  filter: VenueCreditLedgerFilter,
};

export type AdminGetVenueCreditLedgerQuery = {
  adminGetVenueCreditLedger:  {
    __typename: "VenueCreditLedgerItems",
    items?:  Array< {
      __typename: "VenueCreditLedgerItem",
      credits: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      subtitle?: string | null,
    } | null > | null,
  },
};

export type VenueAdminGetOfferQueryVariables = {
  id: string,
};

export type VenueAdminGetOfferQuery = {
  venueAdminGetOffer:  {
    __typename: "Offer",
    id: string,
    title?: string | null,
    venueID: string,
    venue?:  {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null,
    venueName?: string | null,
    marketName?: string | null,
    marketCurrency?: string | null,
    marketID?: string | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    description?: string | null,
    category: OfferCategory,
    subCategory?: FoodCategories | null,
    availableTo: Array< Profession | null >,
    gender: Array< Gender | null >,
    reservations?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
    offerValue?: number | null,
    offerValueUSD?: number | null,
    startDate: string,
    endDate?: string | null,
    startTime: string,
    endTime: string,
    durationInHours?: number | null,
    spots: number,
    waitTime?: number | null,
    availableDays?: Array< DaysOfWeek | null > | null,
    dressCode?: string | null,
    includeSelfInPost?: boolean | null,
    includeServiceOrProductInPost?: boolean | null,
    instagramTag?: string | null,
    postOnInstagramFeed?: boolean | null,
    postOnInstagramStories?: boolean | null,
    postOnInstagramReels?: boolean | null,
    postOnInstagramStories3x?: boolean | null,
    yelpReview?: boolean | null,
    suggestedTip?: string | null,
    tipType?: TipType | null,
    reasonRejected?: string | null,
    totalReservations?: number | null,
    totalCheckins?: number | null,
    percentBooked?: number | null,
    totalUniqueVisitors?: number | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    timeZoneId?: string | null,
    lastSynced?: string | null,
    createdAt: string,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    stats?:  {
      __typename: "offerStats",
      spotCount?: number | null,
      reservationCount?: number | null,
      checkInCount?: number | null,
    } | null,
    offerData?: string | null,
    status: OfferStatus,
    startDateTime?: string | null,
    additionalreq?: string | null,
    minFollowers?: number | null,
    confirmWithEmail?: boolean | null,
    confirmWithCall?: boolean | null,
    effectiveStartDateTime?: string | null,
    effectiveEndDateTime?: string | null,
    originalOfferID?: number | null,
    depositCost?: number | null,
    premium?: boolean | null,
    private?: boolean | null,
    views?: number | null,
    lifestyleMembersAccess?: OfferAccessType | null,
    selectMembersAccess?: OfferAccessType | null,
    totalSavedContent?: number | null,
    address?: string | null,
  },
};

export type AdminListOfferHistoryQueryVariables = {
  filter: AdminListOfferHistoryFilter,
};

export type AdminListOfferHistoryQuery = {
  adminListOfferHistory:  {
    __typename: "AdminListOffersListItems",
    items?:  Array< {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null > | null,
  },
};

export type AdminListOffersQueryVariables = {
  filter: AdminListOffersFilter,
};

export type AdminListOffersQuery = {
  adminListOffers:  {
    __typename: "AdminListOffersListItems",
    items?:  Array< {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null > | null,
  },
};

export type AdminListOffersCountQueryVariables = {
  filter: AdminListOffersFilter,
};

export type AdminListOffersCountQuery = {
  adminListOffersCount: string,
};

export type VenueAdminListOffersQueryVariables = {
  filter: AdminListOffersFilter,
};

export type VenueAdminListOffersQuery = {
  venueAdminListOffers:  {
    __typename: "AdminListOffersListItems",
    items?:  Array< {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null > | null,
  },
};

export type AdminGetOfferQueryVariables = {
  id: string,
};

export type AdminGetOfferQuery = {
  adminGetOffer:  {
    __typename: "Offer",
    id: string,
    title?: string | null,
    venueID: string,
    venue?:  {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null,
    venueName?: string | null,
    marketName?: string | null,
    marketCurrency?: string | null,
    marketID?: string | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    description?: string | null,
    category: OfferCategory,
    subCategory?: FoodCategories | null,
    availableTo: Array< Profession | null >,
    gender: Array< Gender | null >,
    reservations?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
    offerValue?: number | null,
    offerValueUSD?: number | null,
    startDate: string,
    endDate?: string | null,
    startTime: string,
    endTime: string,
    durationInHours?: number | null,
    spots: number,
    waitTime?: number | null,
    availableDays?: Array< DaysOfWeek | null > | null,
    dressCode?: string | null,
    includeSelfInPost?: boolean | null,
    includeServiceOrProductInPost?: boolean | null,
    instagramTag?: string | null,
    postOnInstagramFeed?: boolean | null,
    postOnInstagramStories?: boolean | null,
    postOnInstagramReels?: boolean | null,
    postOnInstagramStories3x?: boolean | null,
    yelpReview?: boolean | null,
    suggestedTip?: string | null,
    tipType?: TipType | null,
    reasonRejected?: string | null,
    totalReservations?: number | null,
    totalCheckins?: number | null,
    percentBooked?: number | null,
    totalUniqueVisitors?: number | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    timeZoneId?: string | null,
    lastSynced?: string | null,
    createdAt: string,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    stats?:  {
      __typename: "offerStats",
      spotCount?: number | null,
      reservationCount?: number | null,
      checkInCount?: number | null,
    } | null,
    offerData?: string | null,
    status: OfferStatus,
    startDateTime?: string | null,
    additionalreq?: string | null,
    minFollowers?: number | null,
    confirmWithEmail?: boolean | null,
    confirmWithCall?: boolean | null,
    effectiveStartDateTime?: string | null,
    effectiveEndDateTime?: string | null,
    originalOfferID?: number | null,
    depositCost?: number | null,
    premium?: boolean | null,
    private?: boolean | null,
    views?: number | null,
    lifestyleMembersAccess?: OfferAccessType | null,
    selectMembersAccess?: OfferAccessType | null,
    totalSavedContent?: number | null,
    address?: string | null,
  },
};

export type AdminGetTotalOfferByCategoryQueryVariables = {
  filter?: AdminGetTotalOfferByCategoryFilter | null,
};

export type AdminGetTotalOfferByCategoryQuery = {
  adminGetTotalOfferByCategory: string,
};

export type VenueGetOfferStatisticsQueryVariables = {
  filter: VenueGetOfferStatisticsRequest,
};

export type VenueGetOfferStatisticsQuery = {
  venueGetOfferStatistics:  {
    __typename: "VenueGetOfferStatisticsResponse",
    totalActiveOffers?: number | null,
    totalCancelledOffers?: number | null,
    totalPendingOffers?: number | null,
    totalRejectedOffers?: number | null,
    totalEndedOffers?: number | null,
    totalInactiveOffers?: number | null,
    totalDeletedOffers?: number | null,
  },
};

export type ListAllSupportCityManagerUsersQueryVariables = {
};

export type ListAllSupportCityManagerUsersQuery = {
  listAllSupportCityManagerUsers:  {
    __typename: "AllSupportCityManagerUserResponse",
    data?:  Array< {
      __typename: "SupportCityManagerUser",
      username?: string | null,
      email?: string | null,
      marketIds?: Array< string | null > | null,
      cognitoGroups?: Array< string | null > | null,
    } | null > | null,
  },
};

export type ListAllCohortsQueryVariables = {
};

export type ListAllCohortsQuery = {
  listAllCohorts:  {
    __typename: "CohortsResponse",
    lapsed0UsersLt1k?: number | null,
    lapsed0Users1kTo10k?: number | null,
    lapsed0Users10kTo50k?: number | null,
    lapsed0Users50kTo100k?: number | null,
    lapsed0UsersGt100k?: number | null,
    lapsedUsersLt1k?: number | null,
    lapsedUsers1kTo10k?: number | null,
    lapsedUsers10kTo50k?: number | null,
    lapsedUsers50kTo100k?: number | null,
    lapsedUsersGt100k?: number | null,
    casualUsersLt1k?: number | null,
    casualUsers1kTo10k?: number | null,
    casualUsers10kTo50k?: number | null,
    casualUsers50kTo100k?: number | null,
    casualUsersGt100k?: number | null,
    powerUsersLt1k?: number | null,
    powerUsers1kTo10k?: number | null,
    powerUsers10kTo50k?: number | null,
    powerUsers50kTo100k?: number | null,
    powerUsersGt100k?: number | null,
    usersRetentionPerMonth?: Array< number | null > | null,
  },
};

export type AdminListUsersQueryVariables = {
  filter: AdminListUsersFilter,
};

export type AdminListUsersQuery = {
  adminListUsers:  {
    __typename: "AdminListUsersListItems",
    items?:  Array< {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null > | null,
  },
};

export type AdminListPortfolioContentQueryVariables = {
  filter: AdminListPortfolioContentFilter,
};

export type AdminListPortfolioContentQuery = {
  adminListPortfolioContent:  {
    __typename: "PortfolioItemListItems",
    items?:  Array< {
      __typename: "UserPortfolioItem",
      id?: string | null,
      userID?: number | null,
      portfolio?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
  },
};

export type AdminListVenuePortfolioContentQueryVariables = {
  filter: AdminListVenuePortfolioContentFilter,
};

export type AdminListVenuePortfolioContentQuery = {
  adminListVenuePortfolioContent:  {
    __typename: "VenuePortfolioItemListItems",
    items?:  Array< {
      __typename: "VenuePortfolioItem",
      id?: string | null,
      venueID?: number | null,
      portfolio?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
  },
};

export type UserListVenuePortfolioContentQueryVariables = {
  filter: UserListVenuePortfolioContentFilter,
};

export type UserListVenuePortfolioContentQuery = {
  userListVenuePortfolioContent:  {
    __typename: "VenuePortfolioItemListItems",
    items?:  Array< {
      __typename: "VenuePortfolioItem",
      id?: string | null,
      venueID?: number | null,
      portfolio?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
  },
};

export type VenueListUserPortfolioContentQueryVariables = {
  filter: VenueListUserPortfolioContentFilter,
};

export type VenueListUserPortfolioContentQuery = {
  venueListUserPortfolioContent:  {
    __typename: "PortfolioItemListItems",
    items?:  Array< {
      __typename: "UserPortfolioItem",
      id?: string | null,
      userID?: number | null,
      portfolio?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
  },
};

export type VenueListUserReservationContentQueryVariables = {
  filter: VenueListUserReservationContentFilter,
};

export type VenueListUserReservationContentQuery = {
  venueListUserReservationContent:  {
    __typename: "UserReservationContentListItems",
    items?:  Array< {
      __typename: "UserReservationContent",
      id?: number | null,
      seen?: boolean | null,
      downloaded?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      userID?: number | null,
    } | null > | null,
  },
};

export type UserListUserReservationContentQueryVariables = {
  filter: UserListUserReservationContentFilter,
};

export type UserListUserReservationContentQuery = {
  userListUserReservationContent:  {
    __typename: "UserReservationContentListItems",
    items?:  Array< {
      __typename: "UserReservationContent",
      id?: number | null,
      seen?: boolean | null,
      downloaded?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      userID?: number | null,
    } | null > | null,
  },
};

export type VenueListUsersQueryVariables = {
  filter: VenueListUsersFilter,
};

export type VenueListUsersQuery = {
  venueListUsers:  {
    __typename: "VenueUserListItems",
    items?:  Array< {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null > | null,
  },
};

export type VenueListUserFvouritesQueryVariables = {
  filter: VenueListUserFvouritesFilter,
};

export type VenueListUserFvouritesQuery = {
  venueListUserFvourites:  {
    __typename: "VenueUserListItems",
    items?:  Array< {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null > | null,
  },
};

export type AgencyListUsersQueryVariables = {
  filter: AgencyListUsersFilter,
};

export type AgencyListUsersQuery = {
  agencyListUsers:  {
    __typename: "VenueUserListItems",
    items?:  Array< {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null > | null,
  },
};

export type AgencyListCountriesQueryVariables = {
  filter: AgencyListCountriesFilter,
};

export type AgencyListCountriesQuery = {
  agencyListCountries:  {
    __typename: "AgencyCountryListItems",
    items?:  Array< {
      __typename: "Country",
      name: string,
      totalUsers: number,
    } | null > | null,
  },
};

export type AgencyListMarketsQueryVariables = {
  filter: AgencyListCountriesFilter,
};

export type AgencyListMarketsQuery = {
  agencyListMarkets:  {
    __typename: "AgencyCountryListItems",
    items?:  Array< {
      __typename: "Country",
      name: string,
      totalUsers: number,
    } | null > | null,
  },
};

export type AdminListUserHistoryQueryVariables = {
  filter: AdminListUserHistoryFilter,
};

export type AdminListUserHistoryQuery = {
  adminListUserHistory:  {
    __typename: "AdminListUsersListItems",
    items?:  Array< {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null > | null,
  },
};

export type AdminListUsersCountQueryVariables = {
  filter: AdminListUsersFilter,
};

export type AdminListUsersCountQuery = {
  adminListUsersCount: string,
};

export type AdminGetUserQueryVariables = {
  id: string,
};

export type AdminGetUserQuery = {
  adminGetUser:  {
    __typename: "User",
    id: string,
    email: string,
    suspensions?:  Array< {
      __typename: "Suspension",
      id: string,
      userID: string,
      startDate: string,
      endDate: string,
      reason: SuspensionReason,
      detail?: string | null,
      paymentAmount?: number | null,
      status: SuspensionStatus,
      lastSynced?: string | null,
      createdAt: string,
    } | null > | null,
    penalties?:  Array< {
      __typename: "Penalty",
      id: string,
      userID: string,
      reservationID: string,
      type?: Array< OfferRequirements | null > | null,
      status: PenaltyStatus,
      createdAt: string,
      currency?: string | null,
      paymentAmount?: number | null,
      timeZoneId: string,
    } | null > | null,
    reservations?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
    phone?: string | null,
    name?: string | null,
    country?: string | null,
    firstname: string,
    lastname: string,
    birthdate?: string | null,
    instagramHandle?: string | null,
    instagramFollowers?: number | null,
    instagramUpdatedAt?: string | null,
    failedInstaCheck?: boolean | null,
    profession: Profession,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    gender: Gender,
    compositeCard?:  {
      __typename: "FrontBack",
    } | null,
    agency?: string | null,
    motherAgency?: string | null,
    timezone?: string | null,
    currency?: string | null,
    homeCountry?: string | null,
    homeStateProvince?: string | null,
    homeCity?: string | null,
    city?: string | null,
    marketName?: string | null,
    marketID?: string | null,
    market?:  {
      __typename: "Market",
      id: string,
      name: string,
      totalActiveVenues?: number | null,
      totalVenues?: number | null,
      totalPremiumVenues?: number | null,
      totalActiveModels?: number | null,
      totalUsers?: number | null,
      totalPremiumUsers?: number | null,
      percentActiveModels?: number | null,
      totalActiveOffers?: number | null,
      totalActiveVenueWithOffers?: number | null,
      totalPremiumOffers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      percentBookedLastFewDays?: number | null,
      percentBookedAllTime?: number | null,
      valueActiveOfferSpots?: number | null,
      totalReach?: number | null,
      stateProvince?: string | null,
      countryCode?: string | null,
      country?: string | null,
      currency?: string | null,
      status: MarketStatus,
      unitSystem?: UnitSystem | null,
      createdAt: string,
      tipEnabled?: boolean | null,
      totalVenuesLeads?: number | null,
      totalApplicationUsers?: number | null,
    } | null,
    currentCity?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    credits?: number | null,
    approvalDate?: string | null,
    lastSeen?: string | null,
    device?: string | null,
    totalCheckins?: number | null,
    totalCheckinLastMonth?: number | null,
    totalOfferValueRedeemed?: number | null,
    totalReservations?: number | null,
    totalWarnings?: number | null,
    totalSuspensions?: number | null,
    totalNoShow?: number | null,
    totalActiveNoShow?: number | null,
    totalCancellation?: number | null,
    totalNoPost?: number | null,
    totalPenalties?: number | null,
    totalActivePenalties?: number | null,
    totalPortfolioContents?: number | null,
    totalContentUploaded?: number | null,
    offerCategoriesRedeemed?: string | null,
    timeOnApp?: number | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    reviewedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    acceptedCodeOfConduct?: boolean | null,
    enabledPushNotifications?: boolean | null,
    enabledLocationAccess?: boolean | null,
    lastSynced?: string | null,
    createdAt: string,
    updatedAt?: string | null,
    status: UserStatus,
    reactivationDate?: string | null,
    instagramDuplicates?: string | null,
    firstnameDuplicates?: string | null,
    lastnameDuplicates?: string | null,
    birthdateDuplicates?: string | null,
    instagramVerificationRequested?: string | null,
    platform?: string | null,
    stripeId?: string | null,
    totalRequirementsSubmitted?: number | null,
    tags?: Array< string | null > | null,
    appVersion?: string | null,
    noshowCredits?: number | null,
    tncVersion?: string | null,
    hasPendingNoshowCredits?: boolean | null,
    agencyProfileUrl?: string | null,
    freelancerBodyProfile?:  {
      __typename: "FreelancerBodyProfile",
      height?: number | null,
      waist?: number | null,
      bust?: number | null,
      hips?: number | null,
    } | null,
    freelancerPictures?:  {
      __typename: "FreelancerImages",
    } | null,
    freelancer?: boolean | null,
    reservationRewardClaimed?: boolean | null,
    instagramVerified?: boolean | null,
    points?: number | null,
    premium?: boolean | null,
    savedByVenue?: boolean | null,
    inviteCredits?: number | null,
    newInvitesAvailable?: boolean | null,
    invitesAvailable?: boolean | null,
    invitesReceived?: boolean | null,
    webProfilePrivate?: boolean | null,
    rating?: number | null,
    totalRatings?: number | null,
    tiktokHandle?: string | null,
    xHandle?: string | null,
    youtubeHandle?: string | null,
    highRated?: boolean | null,
    rank?: number | null,
    scoutingEnabled?: boolean | null,
    profileVideo?: string | null,
    scoutingPlanEnabled?: boolean | null,
    inviteStatus?: string | null,
    agencyInviteStatus?: string | null,
    hasDeletedInstagram?: boolean | null,
    hasDeletedEmail?: boolean | null,
    lifestyle?: boolean | null,
    signedByAgency?:  {
      __typename: "SignedByAgency",
      id?: number | null,
      name?: string | null,
    } | null,
    profileVideoWithThumbnail?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    potentialMatches?: Array< string | null > | null,
  },
};

export type AdminListPenaltiesQueryVariables = {
  filter: AdminListPenaltiesFilter,
};

export type AdminListPenaltiesQuery = {
  adminListPenalties:  {
    __typename: "AdminListPenaltiesListItems",
    items?:  Array< {
      __typename: "Penalty",
      id: string,
      userID: string,
      reservationID: string,
      type?: Array< OfferRequirements | null > | null,
      status: PenaltyStatus,
      createdAt: string,
      currency?: string | null,
      paymentAmount?: number | null,
      timeZoneId: string,
    } | null > | null,
  },
};

export type AdminGetPenaltyQueryVariables = {
  id: string,
};

export type AdminGetPenaltyQuery = {
  adminGetPenalty:  {
    __typename: "Penalty",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    reservationID: string,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null,
    type?: Array< OfferRequirements | null > | null,
    status: PenaltyStatus,
    createdAt: string,
    currency?: string | null,
    paymentAmount?: number | null,
    timeZoneId: string,
  },
};

export type AdminListReservationsQueryVariables = {
  filter: AdminListReservationsFilter,
};

export type AdminListReservationsQuery = {
  adminListReservations:  {
    __typename: "AdminListReservationsListItems",
    items?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
  },
};

export type AdminListReservationContentQueryVariables = {
  filter: AdminListReservationContentsFilter,
};

export type AdminListReservationContentQuery = {
  adminListReservationContent:  {
    __typename: "AdminListReservationContentResponse",
    items?:  Array< {
      __typename: "AdminReservationContent",
      id?: number | null,
      reservationID?: number | null,
      seen?: boolean | null,
      downloaded?: boolean | null,
      byAdmin?: boolean | null,
      byVenue?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      userfirstname?: string | null,
      userlastname?: string | null,
      userInstagramHandle?: string | null,
      userInstagramFollowers?: number | null,
      userID?: number | null,
      venueID?: number | null,
      venueName?: string | null,
      offerTitle?: string | null,
      content?: string | null,
    } | null > | null,
  },
};

export type AdminListReservationContentCountQueryVariables = {
  filter: AdminListReservationContentsFilter,
};

export type AdminListReservationContentCountQuery = {
  adminListReservationContentCount:  {
    __typename: "AdminListReservationContentCountResponse",
    count?: number | null,
  },
};

export type SupportListReservationsForReviewQueryVariables = {
  filter?: AdminListSupportReservationsFilter | null,
};

export type SupportListReservationsForReviewQuery = {
  supportListReservationsForReview:  {
    __typename: "SupportListReservationsListItems",
    items?:  Array< {
      __typename: "SupportReservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerTitle?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      status: ReservationStatus,
      checkInDateTime?: string | null,
      instagramTag?: string | null,
      userInstagramHandle?: string | null,
      validation?: string | null,
      validationRequested?: string | null,
      postOnInstagramFeed?: string | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      suggestedTip?: string | null,
      warnings?: Array< ReservationWarningType | null > | null,
      reviewStaff?: string | null,
      postLink?: string | null,
    } | null > | null,
  },
};

export type SupportCountReservationsForReviewQueryVariables = {
  filter?: AdminCountSupportReservationsFilter | null,
};

export type SupportCountReservationsForReviewQuery = {
  supportCountReservationsForReview:  {
    __typename: "SupportCountReservationsListItems",
    items?:  Array< {
      __typename: "SupportReservationsCountResult",
      reviewStaff?: string | null,
      count?: number | null,
    } | null > | null,
  },
};

export type VenueAdminListReservationsQueryVariables = {
  filter: AdminListReservationsFilter,
};

export type VenueAdminListReservationsQuery = {
  venueAdminListReservations:  {
    __typename: "AdminListReservationsListItems",
    items?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
  },
};

export type AdminGetReservationQueryVariables = {
  id: string,
};

export type AdminGetReservationQuery = {
  adminGetReservation:  {
    __typename: "Reservation",
    id: string,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    marketName?: string | null,
    marketID?: string | null,
    offerID: string,
    offerCategory?: OfferCategory | null,
    offer?:  {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null,
    offerTitle?: string | null,
    offerValue?: number | null,
    offerStartTime?: string | null,
    offerEndTime?: string | null,
    offerEndDateTime?: string | null,
    timeZoneId?: string | null,
    userID: string,
    name?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    venueID: string,
    venue?:  {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null,
    venueName?: string | null,
    venueLocation?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    venueAddress1?: string | null,
    venueAddress2?: string | null,
    venueCity?: string | null,
    venueCountry?: string | null,
    note?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    status: ReservationStatus,
    reservationDate: string,
    actionDate?: string | null,
    checkInDateTime?: string | null,
    createdAt: string,
    updatedAt: string,
    venuePicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    offerPicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    userPicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    canSubmitRequirements?: boolean | null,
    requirementsSubmitted?: boolean | null,
    instagramTag?: string | null,
    noShowStatus?: NoShowStatus | null,
    currency?: string | null,
    payoutAmount?: number | null,
    validation?: string | null,
    validationRequested?: string | null,
    penaltyType?: string | null,
    effectiveStartDateTime?: string | null,
    effectiveEndDateTime?: string | null,
    offerPostType?: string | null,
    postLink?: string | null,
    inviteID?: number | null,
    privateOffer?: boolean | null,
    requirementsSubmittedDateTime?: string | null,
    rated?: boolean | null,
    ratedByVenue?: boolean | null,
    rating?: number | null,
    offerValueSnapshot?: number | null,
    dressCodeSnapshot?: string | null,
    suggestedTipSnapshot?: string | null,
  },
};

export type VenueAdminGetReservationQueryVariables = {
  id: string,
};

export type VenueAdminGetReservationQuery = {
  venueAdminGetReservation:  {
    __typename: "Reservation",
    id: string,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    marketName?: string | null,
    marketID?: string | null,
    offerID: string,
    offerCategory?: OfferCategory | null,
    offer?:  {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null,
    offerTitle?: string | null,
    offerValue?: number | null,
    offerStartTime?: string | null,
    offerEndTime?: string | null,
    offerEndDateTime?: string | null,
    timeZoneId?: string | null,
    userID: string,
    name?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    venueID: string,
    venue?:  {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null,
    venueName?: string | null,
    venueLocation?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    venueAddress1?: string | null,
    venueAddress2?: string | null,
    venueCity?: string | null,
    venueCountry?: string | null,
    note?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    status: ReservationStatus,
    reservationDate: string,
    actionDate?: string | null,
    checkInDateTime?: string | null,
    createdAt: string,
    updatedAt: string,
    venuePicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    offerPicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    userPicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    canSubmitRequirements?: boolean | null,
    requirementsSubmitted?: boolean | null,
    instagramTag?: string | null,
    noShowStatus?: NoShowStatus | null,
    currency?: string | null,
    payoutAmount?: number | null,
    validation?: string | null,
    validationRequested?: string | null,
    penaltyType?: string | null,
    effectiveStartDateTime?: string | null,
    effectiveEndDateTime?: string | null,
    offerPostType?: string | null,
    postLink?: string | null,
    inviteID?: number | null,
    privateOffer?: boolean | null,
    requirementsSubmittedDateTime?: string | null,
    rated?: boolean | null,
    ratedByVenue?: boolean | null,
    rating?: number | null,
    offerValueSnapshot?: number | null,
    dressCodeSnapshot?: string | null,
    suggestedTipSnapshot?: string | null,
  },
};

export type AdminListInvitesCountQueryVariables = {
  filter: AdminListInvitesFilter,
};

export type AdminListInvitesCountQuery = {
  adminListInvitesCount: string,
};

export type AdminListInvitesQueryVariables = {
  filter: AdminListInvitesFilter,
};

export type AdminListInvitesQuery = {
  adminListInvites:  {
    __typename: "AdminListInvitesListItems",
    items?:  Array< {
      __typename: "AdminInvite",
      id?: number | null,
      userID?: number | null,
      offerID?: number | null,
      expiry?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      status?: InviteStatus | null,
      availableDates?: Array< string | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      seen?: boolean | null,
      marketID?: number | null,
      marketName?: string | null,
      timeZoneId?: string | null,
      offerTitle?: string | null,
      offerUpdatedAt?: string | null,
      userName?: string | null,
      userUpdatedAt?: string | null,
      venueID?: number | null,
      venueName?: string | null,
      location?: string | null,
      venueUpdatedAt?: string | null,
      instagramTag?: string | null,
      effectiveStartDateTime?: string | null,
      reservationDate?: string | null,
    } | null > | null,
  },
};

export type AdminGetInviteQueryVariables = {
  id: string,
};

export type AdminGetInviteQuery = {
  adminGetInvite:  {
    __typename: "AdminInvite",
    id?: number | null,
    userID?: number | null,
    offerID?: number | null,
    expiry?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    status?: InviteStatus | null,
    availableDates?: Array< string | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    seen?: boolean | null,
    marketID?: number | null,
    marketName?: string | null,
    timeZoneId?: string | null,
    offerTitle?: string | null,
    offerUpdatedAt?: string | null,
    userName?: string | null,
    userUpdatedAt?: string | null,
    venueID?: number | null,
    venueName?: string | null,
    location?: string | null,
    venueUpdatedAt?: string | null,
    instagramTag?: string | null,
    venuePicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    offerPicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    userPicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    effectiveStartDateTime?: string | null,
    reservationDate?: string | null,
  },
};

export type AdminListSuspensionsQueryVariables = {
  filter: AdminListSuspensionsFilter,
};

export type AdminListSuspensionsQuery = {
  adminListSuspensions:  {
    __typename: "AdminListSuspensionsListItems",
    items?:  Array< {
      __typename: "Suspension",
      id: string,
      userID: string,
      startDate: string,
      endDate: string,
      reason: SuspensionReason,
      detail?: string | null,
      paymentAmount?: number | null,
      status: SuspensionStatus,
      lastSynced?: string | null,
      createdAt: string,
    } | null > | null,
  },
};

export type AdminListSuspensionsTotalsQueryVariables = {
};

export type AdminListSuspensionsTotalsQuery = {
  adminListSuspensionsTotals:  {
    __typename: "AdminListSuspensionsTotalsResponse",
    totalActive?: number | null,
    totalArchived?: number | null,
  },
};

export type AdminGetSuspensionStatisticsQueryVariables = {
  filter: AdminGetSuspensionStatisticsFilter,
};

export type AdminGetSuspensionStatisticsQuery = {
  adminGetSuspensionStatistics:  {
    __typename: "AdminGetSuspensionStatisticsResponse",
    totalPenaltyCount?: number | null,
    totalNoshowCount?: number | null,
    totalAdminCount?: number | null,
    totalNoInstagramCount?: number | null,
  },
};

export type AdminGetSuspensionQueryVariables = {
  id: string,
};

export type AdminGetSuspensionQuery = {
  adminGetSuspension:  {
    __typename: "Suspension",
    id: string,
    suspendedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    startDate: string,
    endDate: string,
    reason: SuspensionReason,
    detail?: string | null,
    paymentAmount?: number | null,
    status: SuspensionStatus,
    lastSynced?: string | null,
    createdAt: string,
  },
};

export type AdminTotalsGetTodayQueryVariables = {
};

export type AdminTotalsGetTodayQuery = {
  adminTotalsGetToday: string,
};

export type AdminTotalsGetMonthlyQueryVariables = {
};

export type AdminTotalsGetMonthlyQuery = {
  adminTotalsGetMonthly: string,
};

export type AdminGetDashboardDataQueryVariables = {
};

export type AdminGetDashboardDataQuery = {
  adminGetDashboardData:  {
    __typename: "DashboardData",
    totalActiveUsers?: number | null,
    totalActiveUsersLastMonth?: number | null,
    totalReach?: number | null,
    newUserApplications?: number | null,
    newApprovedUsers?: number | null,
    totalPendingUsers?: number | null,
    totalRejectedUsers?: number | null,
    totalActiveVenues?: number | null,
    totalActiveOffers?: number | null,
    totalValueOfActiveOffersSpots?: number | null,
  },
};

export type AdminGetAdvancedDashboardDataQueryVariables = {
  fromDate: string,
  toDate: string,
  by: string,
};

export type AdminGetAdvancedDashboardDataQuery = {
  adminGetAdvancedDashboardData:  {
    __typename: "AdvancedDashboardData",
    totals?:  {
      __typename: "AdvancedDashboardDataTotals",
      activeModelsLastMonth?: number | null,
      activeOffers?: number | null,
      activeVenues?: number | null,
      activeVenuesWithOffer?: number | null,
      reach?: number | null,
      redeemedOfferValue?: number | null,
    } | null,
    lines?:  Array< {
      __typename: "AdvancedDashboardDataLine",
      date?: string | null,
      creditPurchaseByModel?: number | null,
      creditPurchaseByVenue?: number | null,
      modelApplicationsPending?: number | null,
      modelApplicationsActive?: number | null,
      modelApplicationsRejected?: number | null,
      venuesApproved?: number | null,
      venuesBlocked?: number | null,
      offersValue?: number | null,
      reservationsReserved?: number | null,
      reservationsCheckedIn?: number | null,
      reservationsCancelled?: number | null,
      reservationsNoShow?: number | null,
      premiumModel?: number | null,
      premiumVenue?: number | null,
      premiumExpiredModel?: number | null,
    } | null > | null,
    totalActiveOffers?: number | null,
    totalActiveVenues?: number | null,
    totalPremiumVenues?: number | null,
    totalStarterVenues?: number | null,
    totalPlusVenues?: number | null,
    totalPlusFreeVenues?: number | null,
    totalStarterFreeVenues?: number | null,
    totalActiveUsers?: number | null,
    totalPremiumUsers?: number | null,
    totalRejectedUsers?: number | null,
    totalPendingUsers?: number | null,
    totalModelCreditsInMonth?: number | null,
    totalVenueCreditsInMonth?: number | null,
    totalUsersBpSelect?: number | null,
    totalUsersBpLifestyle?: number | null,
    totalScoutingActiveUsers?: number | null,
    totalScoutingRejectedUsers?: number | null,
    totalValueOfActiveOffersSpots?: number | null,
    totalModelCreditsInFromToDate?: number | null,
    totalVenueCreditsInFromToDate?: number | null,
    totalPremiumVenuesInFromToDate?: number | null,
    totalPremiumUsersInFromToDate?: number | null,
    totalPremiumExpiredUsersInFromToDate?: number | null,
    modelApplicationsInFromToDate?: number | null,
    venueApplicationsInFromToDate?: number | null,
    reservationsInFromToDate?: number | null,
    registeredFreelancerModelsInFromToDate?: number | null,
    registeredModelsInFromToDate?: number | null,
    totalPremiumVenuesWithoutSubscription?: number | null,
  },
};

export type AdminListCampaignsQueryVariables = {
  filter: AdminListCampaignFilter,
};

export type AdminListCampaignsQuery = {
  adminListCampaigns:  {
    __typename: "AdminCampaignListItems",
    items:  Array< {
      __typename: "AdminCampaign",
      id: string,
      title?: string | null,
      type?: NotificationType | null,
      content?: string | null,
      status?: AdminCampaignStatus | null,
      selectedUserIDList?: Array< number | null > | null,
      selectedVenueIDList?: Array< number | null > | null,
      selectedMarketIDList?: Array< number | null > | null,
      selectAllMarkets?: boolean | null,
      selectedEntities?: Array< AdminCreateCampaignEntity | null > | null,
      date?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      venueID?: number | null,
      offerID?: number | null,
      screen?: string | null,
    } | null >,
  },
};

export type AdminGetCampaignQueryVariables = {
  id: string,
};

export type AdminGetCampaignQuery = {
  adminGetCampaign:  {
    __typename: "AdminCampaign",
    id: string,
    title?: string | null,
    type?: NotificationType | null,
    content?: string | null,
    status?: AdminCampaignStatus | null,
    selectedUserIDList?: Array< number | null > | null,
    selectedVenueIDList?: Array< number | null > | null,
    selectedMarketIDList?: Array< number | null > | null,
    selectAllMarkets?: boolean | null,
    selectedEntities?: Array< AdminCreateCampaignEntity | null > | null,
    date?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    venueID?: number | null,
    offerID?: number | null,
    screen?: string | null,
  },
};

export type AdminListFeedbackQueryVariables = {
  filter?: AdminListFeedbackFilter | null,
};

export type AdminListFeedbackQuery = {
  adminListFeedback:  Array< {
    __typename: "Feedback",
    id: string,
    message: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    status: FeedbackStatus,
  } | null >,
};

export type AdminListUserComplaintQueryVariables = {
  filter?: AdminListUserComplaintFilter | null,
};

export type AdminListUserComplaintQuery = {
  adminListUserComplaint:  Array< {
    __typename: "UserComplaint",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    type: string,
    subtype?: string | null,
    instagramHandle?: string | null,
    penaltyID?: string | null,
    noshowID?: string | null,
    rawData?: string | null,
    status?: ComplaintStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null >,
};

export type AdminListVenueComplaintQueryVariables = {
  filter?: AdminListUserComplaintFilter | null,
};

export type AdminListVenueComplaintQuery = {
  adminListVenueComplaint:  Array< {
    __typename: "VenueComplaint",
    id: string,
    userID: string,
    venueID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    type: string,
    subtype?: string | null,
    rawData?: string | null,
    status?: ComplaintStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null >,
};

export type AdminListUserComplaintCountQueryVariables = {
  filter?: AdminListUserComplaintFilter | null,
};

export type AdminListUserComplaintCountQuery = {
  adminListUserComplaintCount: string,
};

export type AdminListVenueComplaintCountQueryVariables = {
  filter?: AdminListUserComplaintFilter | null,
};

export type AdminListVenueComplaintCountQuery = {
  adminListVenueComplaintCount: string,
};

export type AdminGetUnreadUserComplaintCountQueryVariables = {
  filter?: AdminListUserComplaintFilter | null,
};

export type AdminGetUnreadUserComplaintCountQuery = {
  adminGetUnreadUserComplaintCount: number,
};

export type AdminListUserComplaintTypesCountQueryVariables = {
  filter?: AdminListUserComplaintTypesCountFilter | null,
};

export type AdminListUserComplaintTypesCountQuery = {
  adminListUserComplaintTypesCount:  Array< {
    __typename: "UserComplaintType",
    type?: string | null,
    count?: string | null,
  } | null >,
};

export type AdminListUserComplaintUserIDsQueryVariables = {
  filter?: AdminListUserComplaintUserIDsFilter | null,
};

export type AdminListUserComplaintUserIDsQuery = {
  adminListUserComplaintUserIDs:  Array< {
    __typename: "UserComplaintUserIDsResponse",
    complaintID?: number | null,
    userID?: string | null,
    complaintType?: string | null,
  } | null >,
};

export type AdminListVenueComplaintTypesCountQueryVariables = {
  filter?: AdminListUserComplaintTypesCountFilter | null,
};

export type AdminListVenueComplaintTypesCountQuery = {
  adminListVenueComplaintTypesCount:  Array< {
    __typename: "UserComplaintType",
    type?: string | null,
    count?: string | null,
  } | null >,
};

export type AdminListVenueComplaintVenueIDsQueryVariables = {
  filter?: AdminListUserComplaintUserIDsFilter | null,
};

export type AdminListVenueComplaintVenueIDsQuery = {
  adminListVenueComplaintVenueIDs:  Array< {
    __typename: "VenueComplaintUserIDsResponse",
    complaintID?: number | null,
    venueID?: string | null,
    complaintType?: string | null,
  } | null >,
};

export type VenueListVenueComplaintCountQueryVariables = {
  filter?: AdminListUserComplaintFilter | null,
};

export type VenueListVenueComplaintCountQuery = {
  venueListVenueComplaintCount: string,
};

export type AdminListAgencyInvitesQueryVariables = {
  filter: AgencyListInvitesFilter,
};

export type AdminListAgencyInvitesQuery = {
  adminListAgencyInvites:  {
    __typename: "AgencyListInvitesListItems",
    items?:  Array< {
      __typename: "AgencyInvite",
      id: number,
      userID: number,
      venueID: number,
      status: string,
      createdAt: string,
      updatedAt: string,
      marketName?: string | null,
      seen?: boolean | null,
    } | null > | null,
  },
};

export type AdminGetAnalyticsQueryVariables = {
  filter: GetAnalyticsRequest,
};

export type AdminGetAnalyticsQuery = {
  adminGetAnalytics?:  {
    __typename: "AnalyticsResponse",
    followers?: number | null,
    engagemenRate?: number | null,
    credibilityScore?: number | null,
    credibilityClass?: string | null,
    averageLikes?: number | null,
    averageComments?: number | null,
    averageReelViews?: number | null,
    genderDistribution?:  {
      __typename: "AnalyticsGenderDistribution",
      female?: number | null,
      male?: number | null,
    } | null,
    countries?:  Array< {
      __typename: "AnalyticsCountryDistribution",
      name?: string | null,
      code?: string | null,
      followers?: number | null,
    } | null > | null,
    priceEstimate?:  {
      __typename: "PriceEstimateAnalytics",
      min?: number | null,
      max?: number | null,
    } | null,
  } | null,
};

export type VenueGetMarketQueryVariables = {
  id: string,
};

export type VenueGetMarketQuery = {
  venueGetMarket:  {
    __typename: "Market",
    id: string,
    name: string,
    venues?:  Array< {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null > | null,
    totalActiveVenues?: number | null,
    totalVenues?: number | null,
    totalPremiumVenues?: number | null,
    totalActiveModels?: number | null,
    totalUsers?: number | null,
    totalPremiumUsers?: number | null,
    percentActiveModels?: number | null,
    totalActiveOffers?: number | null,
    totalActiveVenueWithOffers?: number | null,
    totalPremiumOffers?: number | null,
    totalSpots?: number | null,
    totalReservations?: number | null,
    percentBookedLastFewDays?: number | null,
    percentBookedAllTime?: number | null,
    valueActiveOfferSpots?: number | null,
    totalReach?: number | null,
    stateProvince?: string | null,
    countryCode?: string | null,
    country?: string | null,
    currency?: string | null,
    status: MarketStatus,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    timezone?:  {
      __typename: "Timezone",
      dstOffset: number,
      rawOffset: number,
      timeZoneId: string,
      timeZoneName: string,
    } | null,
    unitSystem?: UnitSystem | null,
    createdAt: string,
    tipEnabled?: boolean | null,
    totalVenuesLeads?: number | null,
    totalApplicationUsers?: number | null,
  },
};

export type VenueGetAnalyticsQueryVariables = {
  filter?: GetAnalyticsRequest | null,
};

export type VenueGetAnalyticsQuery = {
  venueGetAnalytics?:  {
    __typename: "AnalyticsResponse",
    followers?: number | null,
    engagemenRate?: number | null,
    credibilityScore?: number | null,
    credibilityClass?: string | null,
    averageLikes?: number | null,
    averageComments?: number | null,
    averageReelViews?: number | null,
    genderDistribution?:  {
      __typename: "AnalyticsGenderDistribution",
      female?: number | null,
      male?: number | null,
    } | null,
    countries?:  Array< {
      __typename: "AnalyticsCountryDistribution",
      name?: string | null,
      code?: string | null,
      followers?: number | null,
    } | null > | null,
    priceEstimate?:  {
      __typename: "PriceEstimateAnalytics",
      min?: number | null,
      max?: number | null,
    } | null,
  } | null,
};

export type VenueGetVenueQueryVariables = {
  id: string,
};

export type VenueGetVenueQuery = {
  venueGetVenue:  {
    __typename: "Venue",
    id: string,
    offers?:  Array< {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null > | null,
    reservations?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    approvedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    marketName?: string | null,
    marketID: string,
    marketCurrency?: string | null,
    market?:  {
      __typename: "Market",
      id: string,
      name: string,
      totalActiveVenues?: number | null,
      totalVenues?: number | null,
      totalPremiumVenues?: number | null,
      totalActiveModels?: number | null,
      totalUsers?: number | null,
      totalPremiumUsers?: number | null,
      percentActiveModels?: number | null,
      totalActiveOffers?: number | null,
      totalActiveVenueWithOffers?: number | null,
      totalPremiumOffers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      percentBookedLastFewDays?: number | null,
      percentBookedAllTime?: number | null,
      valueActiveOfferSpots?: number | null,
      totalReach?: number | null,
      stateProvince?: string | null,
      countryCode?: string | null,
      country?: string | null,
      currency?: string | null,
      status: MarketStatus,
      unitSystem?: UnitSystem | null,
      createdAt: string,
      tipEnabled?: boolean | null,
      totalVenuesLeads?: number | null,
      totalApplicationUsers?: number | null,
    } | null,
    type?: OfferCategory | null,
    phone?: string | null,
    email: string,
    name: string,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    contactFirstname?: string | null,
    contactLastname?: string | null,
    contactPosition?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    postalCode?: string | null,
    stateProvince?: string | null,
    country?: string | null,
    countryCode?: string | null,
    dependentLocality?: string | null,
    timeZoneId?: string | null,
    website?: string | null,
    instagramHandle?: string | null,
    referredBy?: string | null,
    description?: string | null,
    sideLetter?: string | null,
    approvalDate?: string | null,
    device?: string | null,
    totalActiveOffers?: number | null,
    totalOffers?: number | null,
    totalFavourites?: number | null,
    totalFavouritesByActiveUsers?: number | null,
    totalSpots?: number | null,
    totalReservations?: number | null,
    totalCheckins?: number | null,
    totalContentSaved?: number | null,
    totalImpressions?: number | null,
    percentBooked?: number | null,
    totalReach?: number | null,
    totalNoshow?: number | null,
    distance?: number | null,
    lastSeen?: string | null,
    lastBrowser?: string | null,
    lastPlatform?: string | null,
    lastSynced?: string | null,
    createdAt: string,
    updatedAt: string,
    status: VenueStatus,
    offerReservationStatus?: OfferReservationStatus | null,
    stats?: string | null,
    enabledPushNotifications?: boolean | null,
    enabledLocationAccess?: boolean | null,
    offerUpdatedAt?: string | null,
    reservationUpdatedAt?: string | null,
    closedDates?: Array< string | null > | null,
    checkInRadius?: number | null,
    platform?: string | null,
    notes?: string | null,
    enabledEmailNotifications?: boolean | null,
    currency?: string | null,
    tags?: Array< string | null > | null,
    appVersion?: string | null,
    tncVersion?: string | null,
    newContentAvailable?: boolean | null,
    availableDownloads?: number | null,
    unreadNotificationCount?: number | null,
    masterVenueID?: number | null,
    views?: number | null,
    formattedAddress?: string | null,
    tipEnabled?: boolean | null,
    premium?: boolean | null,
    lifestyleMembersAccess?: OfferAccessType | null,
    selectMembersAccess?: OfferAccessType | null,
    accessRequestsCount?: number | null,
    hasPermissionRequiredOffers?: boolean | null,
    hasSetLifestyleAccessType?: boolean | null,
    hasSetSelectAccessType?: boolean | null,
    plan?: string | null,
    termsAccepted?: boolean | null,
    referralInstagramHandle?: string | null,
    referredInstagramUserId?: string | null,
    totalAgencyUserInvites?: number | null,
    totalAgencyUserSignedInvites?: number | null,
  },
};

export type VenueGetPreSignedUrlQueryVariables = {
  ext?: string | null,
};

export type VenueGetPreSignedUrlQuery = {
  venueGetPreSignedUrl: string,
};

export type VenueGetPaymentIntentQueryVariables = {
  input: VenuePaymentIntentInput,
};

export type VenueGetPaymentIntentQuery = {
  venueGetPaymentIntent:  {
    __typename: "PaymentIntent",
    customerId: string,
    merchantDisplayName: string,
    customerEphemeralKeySecret: string,
    paymentIntentClientSecret: string,
    applePay?:  {
      __typename: "ApplePayIntent",
      merchantCountryCode?: string | null,
    } | null,
    googlePay?:  {
      __typename: "GooglePayIntent",
      merchantCountryCode?: string | null,
      testEnv?: boolean | null,
    } | null,
  },
};

export type VenueGetAccountBalanceQueryVariables = {
  filter?: VenueAccountBalanceRequest | null,
};

export type VenueGetAccountBalanceQuery = {
  venueGetAccountBalance:  {
    __typename: "AccountBalanceResponse",
    balance: number,
    accountId: string,
    status: string,
  },
};

export type VenueGetVerificationUrlQueryVariables = {
  filter?: VenueAccountBalanceRequest | null,
};

export type VenueGetVerificationUrlQuery = {
  venueGetVerificationUrl:  {
    __typename: "VenueVerificatioURLResponse",
    onBoardingURL: string,
  },
};

export type VenueGetSubscriptionSessionQueryVariables = {
  priceId?: string | null,
};

export type VenueGetSubscriptionSessionQuery = {
  venueGetSubscriptionSession: string,
};

export type VenueCancelSubscriptionQueryVariables = {
  subscriptionId?: string | null,
};

export type VenueCancelSubscriptionQuery = {
  venueCancelSubscription: string,
};

export type VenueListPricingQueryVariables = {
};

export type VenueListPricingQuery = {
  venueListPricing:  {
    __typename: "StripePricingListItems",
    items?:  Array< {
      __typename: "StripePricing",
      id: string,
      object: string,
      active: boolean,
      billing_scheme?: string | null,
      created?: number | null,
      currency: string,
      custom_unit_amount?: string | null,
      nickname?: string | null,
      product?: string | null,
      unit_amount?: number | null,
      metadata?: string | null,
    } | null > | null,
  },
};

export type VenueGetCollabEstimateQueryVariables = {
  filter: VenueGetCollabEstimateFilter,
};

export type VenueGetCollabEstimateQuery = {
  venueGetCollabEstimate:  {
    __typename: "VenueGetCollabEstimateResponse",
    totalPremiumCredits?: number | null,
    totalNonPremiumCredits?: number | null,
  },
};

export type VenueListUserEstimatedPriceQueryVariables = {
  filter?: VenueListUserPriceEstimatedFilter | null,
};

export type VenueListUserEstimatedPriceQuery = {
  venueListUserEstimatedPrice:  {
    __typename: "VenueListUserEstimatedPriceResponse",
    items?:  Array< {
      __typename: "UserEstimatedPrice",
      userID?: number | null,
      estimatedPrice?: number | null,
    } | null > | null,
  },
};

export type VenueListOffersQueryVariables = {
  filter: VenueListOffersFilter,
};

export type VenueListOffersQuery = {
  venueListOffers:  {
    __typename: "VenueListOffersListItems",
    items?:  Array< {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null > | null,
  },
};

export type VenueListAccessRequestsQueryVariables = {
  filter: VenueListAccessRequetsFilter,
};

export type VenueListAccessRequestsQuery = {
  venueListAccessRequests:  {
    __typename: "VenueAccessRequestListItems",
    items?:  Array< {
      __typename: "VennueAccessRequestItem",
      offerID?: number | null,
      userID?: number | null,
      status?: UserAccessRequestResultValue | null,
    } | null > | null,
  },
};

export type VenueListInviteOffersQueryVariables = {
  filter: VenueListInviteOffersFilter,
};

export type VenueListInviteOffersQuery = {
  venueListInviteOffers:  {
    __typename: "VenueListOffersListItems",
    items?:  Array< {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null > | null,
  },
};

export type VenueListInvitesQueryVariables = {
  filter: VenueListInvitesFilter,
};

export type VenueListInvitesQuery = {
  venueListInvites:  {
    __typename: "VenueListInvitesListItems",
    items?:  Array< {
      __typename: "Invite",
      id?: number | null,
      userID?: number | null,
      offerID?: number | null,
      expiry?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      status?: InviteType | null,
      availableDates?: Array< string | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      seen?: boolean | null,
    } | null > | null,
  },
};

export type VenueListRatedUsersQueryVariables = {
  filter: VenueListRatedUsersFilter,
};

export type VenueListRatedUsersQuery = {
  venueListRatedUsers:  {
    __typename: "VenueListRatedUserListItems",
    items?:  Array< {
      __typename: "VenueRatedUser",
      id: string,
      firstname: string,
      instagramHandle: string,
      instagramFollowers?: number | null,
      updatedAt?: string | null,
      lastname: string,
      totalRatings: number,
      averageRatings: number,
      tags?: Array< string | null > | null,
    } | null > | null,
  },
};

export type AgencyListInvitesQueryVariables = {
  filter: AgencyListInvitesFilter,
};

export type AgencyListInvitesQuery = {
  agencyListInvites:  {
    __typename: "AgencyListInvitesListItems",
    items?:  Array< {
      __typename: "AgencyInvite",
      id: number,
      userID: number,
      venueID: number,
      status: string,
      createdAt: string,
      updatedAt: string,
      marketName?: string | null,
      seen?: boolean | null,
    } | null > | null,
  },
};

export type AgencyGetInvitesCountQueryVariables = {
  filter: AgencyListInvitesFilter,
};

export type AgencyGetInvitesCountQuery = {
  agencyGetInvitesCount:  {
    __typename: "AgencyGetInviteCountResponse",
    count?: number | null,
  },
};

export type VenueListOfferImagesQueryVariables = {
  filter: VenueListOfferImagesFilter,
};

export type VenueListOfferImagesQuery = {
  venueListOfferImages:  {
    __typename: "VenueListOfferImagesListItems",
    items?:  Array< {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null > | null,
  },
};

export type VenueGetOfferQueryVariables = {
  id: string,
};

export type VenueGetOfferQuery = {
  venueGetOffer:  {
    __typename: "Offer",
    id: string,
    title?: string | null,
    venueID: string,
    venue?:  {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null,
    venueName?: string | null,
    marketName?: string | null,
    marketCurrency?: string | null,
    marketID?: string | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    description?: string | null,
    category: OfferCategory,
    subCategory?: FoodCategories | null,
    availableTo: Array< Profession | null >,
    gender: Array< Gender | null >,
    reservations?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
    offerValue?: number | null,
    offerValueUSD?: number | null,
    startDate: string,
    endDate?: string | null,
    startTime: string,
    endTime: string,
    durationInHours?: number | null,
    spots: number,
    waitTime?: number | null,
    availableDays?: Array< DaysOfWeek | null > | null,
    dressCode?: string | null,
    includeSelfInPost?: boolean | null,
    includeServiceOrProductInPost?: boolean | null,
    instagramTag?: string | null,
    postOnInstagramFeed?: boolean | null,
    postOnInstagramStories?: boolean | null,
    postOnInstagramReels?: boolean | null,
    postOnInstagramStories3x?: boolean | null,
    yelpReview?: boolean | null,
    suggestedTip?: string | null,
    tipType?: TipType | null,
    reasonRejected?: string | null,
    totalReservations?: number | null,
    totalCheckins?: number | null,
    percentBooked?: number | null,
    totalUniqueVisitors?: number | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    timeZoneId?: string | null,
    lastSynced?: string | null,
    createdAt: string,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    stats?:  {
      __typename: "offerStats",
      spotCount?: number | null,
      reservationCount?: number | null,
      checkInCount?: number | null,
    } | null,
    offerData?: string | null,
    status: OfferStatus,
    startDateTime?: string | null,
    additionalreq?: string | null,
    minFollowers?: number | null,
    confirmWithEmail?: boolean | null,
    confirmWithCall?: boolean | null,
    effectiveStartDateTime?: string | null,
    effectiveEndDateTime?: string | null,
    originalOfferID?: number | null,
    depositCost?: number | null,
    premium?: boolean | null,
    private?: boolean | null,
    views?: number | null,
    lifestyleMembersAccess?: OfferAccessType | null,
    selectMembersAccess?: OfferAccessType | null,
    totalSavedContent?: number | null,
    address?: string | null,
  },
};

export type VenueGetInsightsQueryVariables = {
  id?: string | null,
};

export type VenueGetInsightsQuery = {
  venueGetInsights:  {
    __typename: "VenueInsigts",
    totalReach7Days?: number | null,
    totalReach7DaysChange?: number | null,
    totalReach30Days?: number | null,
    totalReach30DaysChange?: number | null,
    totalReachEver?: number | null,
    totalCheckin7Days?: number | null,
    totalCheckin7DaysChange?: number | null,
    totalCheckin30Days?: number | null,
    totalCheckin30DaysChange?: number | null,
    totalCheckinEver?: number | null,
    totalReservations7Days?: number | null,
    totalReservations7DaysChange?: number | null,
    totalReservations30Days?: number | null,
    totalReservations30DaysChange?: number | null,
    totalReservationsEver?: number | null,
    totalNewVisits7Days?: number | null,
    totalNewVisits7DaysChange?: number | null,
    totalNewVisits30Days?: number | null,
    totalNewVisits30DaysChange?: number | null,
    totalNewVisitsEver?: number | null,
  },
};

export type VenueGetCreditLedgerQueryVariables = {
  filter: VenueCreditLedgerFilter,
};

export type VenueGetCreditLedgerQuery = {
  venueGetCreditLedger:  {
    __typename: "VenueCreditLedgerItems",
    items?:  Array< {
      __typename: "VenueCreditLedgerItem",
      credits: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      subtitle?: string | null,
    } | null > | null,
  },
};

export type VenueGetUserQueryVariables = {
  filter: VenueGetUserFilter,
};

export type VenueGetUserQuery = {
  venueGetUser:  {
    __typename: "User",
    id: string,
    email: string,
    suspensions?:  Array< {
      __typename: "Suspension",
      id: string,
      userID: string,
      startDate: string,
      endDate: string,
      reason: SuspensionReason,
      detail?: string | null,
      paymentAmount?: number | null,
      status: SuspensionStatus,
      lastSynced?: string | null,
      createdAt: string,
    } | null > | null,
    penalties?:  Array< {
      __typename: "Penalty",
      id: string,
      userID: string,
      reservationID: string,
      type?: Array< OfferRequirements | null > | null,
      status: PenaltyStatus,
      createdAt: string,
      currency?: string | null,
      paymentAmount?: number | null,
      timeZoneId: string,
    } | null > | null,
    reservations?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
    phone?: string | null,
    name?: string | null,
    country?: string | null,
    firstname: string,
    lastname: string,
    birthdate?: string | null,
    instagramHandle?: string | null,
    instagramFollowers?: number | null,
    instagramUpdatedAt?: string | null,
    failedInstaCheck?: boolean | null,
    profession: Profession,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    gender: Gender,
    compositeCard?:  {
      __typename: "FrontBack",
    } | null,
    agency?: string | null,
    motherAgency?: string | null,
    timezone?: string | null,
    currency?: string | null,
    homeCountry?: string | null,
    homeStateProvince?: string | null,
    homeCity?: string | null,
    city?: string | null,
    marketName?: string | null,
    marketID?: string | null,
    market?:  {
      __typename: "Market",
      id: string,
      name: string,
      totalActiveVenues?: number | null,
      totalVenues?: number | null,
      totalPremiumVenues?: number | null,
      totalActiveModels?: number | null,
      totalUsers?: number | null,
      totalPremiumUsers?: number | null,
      percentActiveModels?: number | null,
      totalActiveOffers?: number | null,
      totalActiveVenueWithOffers?: number | null,
      totalPremiumOffers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      percentBookedLastFewDays?: number | null,
      percentBookedAllTime?: number | null,
      valueActiveOfferSpots?: number | null,
      totalReach?: number | null,
      stateProvince?: string | null,
      countryCode?: string | null,
      country?: string | null,
      currency?: string | null,
      status: MarketStatus,
      unitSystem?: UnitSystem | null,
      createdAt: string,
      tipEnabled?: boolean | null,
      totalVenuesLeads?: number | null,
      totalApplicationUsers?: number | null,
    } | null,
    currentCity?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    credits?: number | null,
    approvalDate?: string | null,
    lastSeen?: string | null,
    device?: string | null,
    totalCheckins?: number | null,
    totalCheckinLastMonth?: number | null,
    totalOfferValueRedeemed?: number | null,
    totalReservations?: number | null,
    totalWarnings?: number | null,
    totalSuspensions?: number | null,
    totalNoShow?: number | null,
    totalActiveNoShow?: number | null,
    totalCancellation?: number | null,
    totalNoPost?: number | null,
    totalPenalties?: number | null,
    totalActivePenalties?: number | null,
    totalPortfolioContents?: number | null,
    totalContentUploaded?: number | null,
    offerCategoriesRedeemed?: string | null,
    timeOnApp?: number | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    reviewedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    acceptedCodeOfConduct?: boolean | null,
    enabledPushNotifications?: boolean | null,
    enabledLocationAccess?: boolean | null,
    lastSynced?: string | null,
    createdAt: string,
    updatedAt?: string | null,
    status: UserStatus,
    reactivationDate?: string | null,
    instagramDuplicates?: string | null,
    firstnameDuplicates?: string | null,
    lastnameDuplicates?: string | null,
    birthdateDuplicates?: string | null,
    instagramVerificationRequested?: string | null,
    platform?: string | null,
    stripeId?: string | null,
    totalRequirementsSubmitted?: number | null,
    tags?: Array< string | null > | null,
    appVersion?: string | null,
    noshowCredits?: number | null,
    tncVersion?: string | null,
    hasPendingNoshowCredits?: boolean | null,
    agencyProfileUrl?: string | null,
    freelancerBodyProfile?:  {
      __typename: "FreelancerBodyProfile",
      height?: number | null,
      waist?: number | null,
      bust?: number | null,
      hips?: number | null,
    } | null,
    freelancerPictures?:  {
      __typename: "FreelancerImages",
    } | null,
    freelancer?: boolean | null,
    reservationRewardClaimed?: boolean | null,
    instagramVerified?: boolean | null,
    points?: number | null,
    premium?: boolean | null,
    savedByVenue?: boolean | null,
    inviteCredits?: number | null,
    newInvitesAvailable?: boolean | null,
    invitesAvailable?: boolean | null,
    invitesReceived?: boolean | null,
    webProfilePrivate?: boolean | null,
    rating?: number | null,
    totalRatings?: number | null,
    tiktokHandle?: string | null,
    xHandle?: string | null,
    youtubeHandle?: string | null,
    highRated?: boolean | null,
    rank?: number | null,
    scoutingEnabled?: boolean | null,
    profileVideo?: string | null,
    scoutingPlanEnabled?: boolean | null,
    inviteStatus?: string | null,
    agencyInviteStatus?: string | null,
    hasDeletedInstagram?: boolean | null,
    hasDeletedEmail?: boolean | null,
    lifestyle?: boolean | null,
    signedByAgency?:  {
      __typename: "SignedByAgency",
      id?: number | null,
      name?: string | null,
    } | null,
    profileVideoWithThumbnail?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    potentialMatches?: Array< string | null > | null,
  },
};

export type VenueListReservationsQueryVariables = {
  filter: VenueListReservationsFilter,
};

export type VenueListReservationsQuery = {
  venueListReservations:  {
    __typename: "VenueListReservationsListItems",
    items?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
  },
};

export type VenueListReservationContentQueryVariables = {
  filter: VenueListReservationsContentFilter,
};

export type VenueListReservationContentQuery = {
  venueListReservationContent:  {
    __typename: "VenueListReservationContentResponse",
    items?:  Array< {
      __typename: "VenueReservationContentItem",
      userID?: string | null,
    } | null > | null,
  },
};

export type VenueListReservationContentV2QueryVariables = {
  filter: VenueListReservationsContentFilter,
};

export type VenueListReservationContentV2Query = {
  venueListReservationContentV2:  {
    __typename: "VenueListReservationContentResponseV2",
    items?:  Array< {
      __typename: "VenueReservationContentItemV2",
      id?: number | null,
      content?: string | null,
      seen?: boolean | null,
      downloaded?: boolean | null,
      byVenue?: boolean | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      downloadCredits?: number | null,
    } | null > | null,
  },
};

export type VenueGetReservationQueryVariables = {
  id: string,
};

export type VenueGetReservationQuery = {
  venueGetReservation:  {
    __typename: "Reservation",
    id: string,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    marketName?: string | null,
    marketID?: string | null,
    offerID: string,
    offerCategory?: OfferCategory | null,
    offer?:  {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null,
    offerTitle?: string | null,
    offerValue?: number | null,
    offerStartTime?: string | null,
    offerEndTime?: string | null,
    offerEndDateTime?: string | null,
    timeZoneId?: string | null,
    userID: string,
    name?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    venueID: string,
    venue?:  {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null,
    venueName?: string | null,
    venueLocation?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    venueAddress1?: string | null,
    venueAddress2?: string | null,
    venueCity?: string | null,
    venueCountry?: string | null,
    note?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    status: ReservationStatus,
    reservationDate: string,
    actionDate?: string | null,
    checkInDateTime?: string | null,
    createdAt: string,
    updatedAt: string,
    venuePicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    offerPicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    userPicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    canSubmitRequirements?: boolean | null,
    requirementsSubmitted?: boolean | null,
    instagramTag?: string | null,
    noShowStatus?: NoShowStatus | null,
    currency?: string | null,
    payoutAmount?: number | null,
    validation?: string | null,
    validationRequested?: string | null,
    penaltyType?: string | null,
    effectiveStartDateTime?: string | null,
    effectiveEndDateTime?: string | null,
    offerPostType?: string | null,
    postLink?: string | null,
    inviteID?: number | null,
    privateOffer?: boolean | null,
    requirementsSubmittedDateTime?: string | null,
    rated?: boolean | null,
    ratedByVenue?: boolean | null,
    rating?: number | null,
    offerValueSnapshot?: number | null,
    dressCodeSnapshot?: string | null,
    suggestedTipSnapshot?: string | null,
  },
};

export type AdminListVenueBlockedUsersQueryVariables = {
  id: number,
};

export type AdminListVenueBlockedUsersQuery = {
  adminListVenueBlockedUsers:  {
    __typename: "AdminListUsersListItems",
    items?:  Array< {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null > | null,
  },
};

export type VenueListVenueBlockedUsersQueryVariables = {
  id: number,
};

export type VenueListVenueBlockedUsersQuery = {
  venueListVenueBlockedUsers:  {
    __typename: "VenueListUsersListItems",
    items?:  Array< {
      __typename: "BlockedUser",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      blockedDate?: string | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
    } | null > | null,
  },
};

export type VenueListVenueBlockedUsers2QueryVariables = {
  filter: VenueListVenueBlockedUsersRequest,
};

export type VenueListVenueBlockedUsers2Query = {
  venueListVenueBlockedUsers2:  {
    __typename: "VenueListUsersListItems",
    items?:  Array< {
      __typename: "BlockedUser",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      blockedDate?: string | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
    } | null > | null,
  },
};

export type VenueListVenuesQueryVariables = {
  input: VenueListVenuesRequest,
};

export type VenueListVenuesQuery = {
  venueListVenues:  {
    __typename: "VenueListVenuesListItems",
    items?:  Array< {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null > | null,
  },
};

export type VenueListNotificationsQueryVariables = {
  filter: VenueListNotificationsFilter,
};

export type VenueListNotificationsQuery = {
  venueListNotifications:  {
    __typename: "VenueListNotificationItems",
    items?:  Array< {
      __typename: "VenueNotification",
      id: string,
      venueID: string,
      userID?: string | null,
      type: NotificationType,
      thumbnail?: string | null,
      title?: string | null,
      content?: string | null,
      status?: VenueNotificationStatus | null,
      metadata?: string | null,
      redirectCTA?: string | null,
      createdAt: string,
    } | null > | null,
  },
};

export type VenueGetUnreadNotificationCountQueryVariables = {
  venueID: string,
};

export type VenueGetUnreadNotificationCountQuery = {
  venueGetUnreadNotificationCount: number,
};

export type UserListMarketsQueryVariables = {
  filter: UserListMarketsFilter,
};

export type UserListMarketsQuery = {
  userListMarkets:  {
    __typename: "UserListMarketsItems",
    items?:  Array< {
      __typename: "UserMarketItem",
      id: string,
      name: string,
      currency?: string | null,
      unitSystem?: UnitSystem | null,
      offersRedeemed?: number | null,
      imageUrl?: string | null,
      tipEnabled?: boolean | null,
    } | null > | null,
  },
};

export type UserGetMarketQueryVariables = {
  id: string,
};

export type UserGetMarketQuery = {
  userGetMarket:  {
    __typename: "UserMarketItem",
    id: string,
    name: string,
    currency?: string | null,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    timezone?:  {
      __typename: "Timezone",
      dstOffset: number,
      rawOffset: number,
      timeZoneId: string,
      timeZoneName: string,
    } | null,
    unitSystem?: UnitSystem | null,
    offersRedeemed?: number | null,
    imageUrl?: string | null,
    tipEnabled?: boolean | null,
  },
};

export type UserGetMarketByLocationQueryVariables = {
  location: LocationInput,
};

export type UserGetMarketByLocationQuery = {
  userGetMarketByLocation:  {
    __typename: "UserMarketItem",
    id: string,
    name: string,
    currency?: string | null,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    timezone?:  {
      __typename: "Timezone",
      dstOffset: number,
      rawOffset: number,
      timeZoneId: string,
      timeZoneName: string,
    } | null,
    unitSystem?: UnitSystem | null,
    offersRedeemed?: number | null,
    imageUrl?: string | null,
    tipEnabled?: boolean | null,
  },
};

export type UserListVenuesQueryVariables = {
  filter: UserListVenuesFilter,
};

export type UserListVenuesQuery = {
  userListVenues:  {
    __typename: "UserListVenuesListItems",
    items?:  Array< {
      __typename: "UserVenueItem",
      id: string,
      marketName?: string | null,
      marketID: string,
      type?: OfferCategory | null,
      phone?: string | null,
      name?: string | null,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      description?: string | null,
      distance?: number | null,
      status?: VenueStatus | null,
      offerReservationStatus?: UserOfferStatus | null,
      closedDates?: Array< string | null > | null,
      approvalDate?: string | null,
      earliestOfferDate?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      userFavourite?: boolean | null,
      formattedAddress?: string | null,
      totalSavedContent?: number | null,
      offerCount?: number | null,
      topInMarket?: boolean | null,
      totalPositiveRatings?: number | null,
      totalRatings?: number | null,
      userMuted?: boolean | null,
    } | null > | null,
  },
};

export type UserListFeaturedOffersQueryVariables = {
  filter: UserListFeaturedOffersFilter,
};

export type UserListFeaturedOffersQuery = {
  userListFeaturedOffers:  {
    __typename: "UserListFeaturedOfferListItems",
    items?:  Array< {
      __typename: "FeaturedOffer",
      offerID?: number | null,
      offerTitle?: string | null,
      isNew?: boolean | null,
      availableSpots?: number | null,
      venueID?: number | null,
      venueName?: string | null,
    } | null > | null,
  },
};

export type UserListRatingsQueryVariables = {
  filter: UserListRatingsFilter,
};

export type UserListRatingsQuery = {
  userListRatings:  {
    __typename: "UserListVenueRatingListItems",
    items?:  Array< {
      __typename: "UserVenueRatingItem",
      userUpdatedAt?: string | null,
      userID?: string | null,
      venueID?: string | null,
      reservationID?: string | null,
      feedback?: string | null,
      positive?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      userFirstname?: string | null,
      userLastname?: string | null,
      agencyProfileUrl?: string | null,
      effectiveStartDateTime?: string | null,
      offerTitle?: string | null,
      offerID?: number | null,
    } | null > | null,
  },
};

export type UserGetVenueQueryVariables = {
  id: string,
};

export type UserGetVenueQuery = {
  userGetVenue:  {
    __typename: "UserVenueItem",
    id: string,
    offers?:  Array< {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null > | null,
    marketName?: string | null,
    marketID: string,
    market?:  {
      __typename: "Market",
      id: string,
      name: string,
      totalActiveVenues?: number | null,
      totalVenues?: number | null,
      totalPremiumVenues?: number | null,
      totalActiveModels?: number | null,
      totalUsers?: number | null,
      totalPremiumUsers?: number | null,
      percentActiveModels?: number | null,
      totalActiveOffers?: number | null,
      totalActiveVenueWithOffers?: number | null,
      totalPremiumOffers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      percentBookedLastFewDays?: number | null,
      percentBookedAllTime?: number | null,
      valueActiveOfferSpots?: number | null,
      totalReach?: number | null,
      stateProvince?: string | null,
      countryCode?: string | null,
      country?: string | null,
      currency?: string | null,
      status: MarketStatus,
      unitSystem?: UnitSystem | null,
      createdAt: string,
      tipEnabled?: boolean | null,
      totalVenuesLeads?: number | null,
      totalApplicationUsers?: number | null,
    } | null,
    type?: OfferCategory | null,
    phone?: string | null,
    name?: string | null,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    contactFirstname?: string | null,
    contactLastname?: string | null,
    contactPosition?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    postalCode?: string | null,
    stateProvince?: string | null,
    country?: string | null,
    countryCode?: string | null,
    dependentLocality?: string | null,
    timeZoneId?: string | null,
    website?: string | null,
    instagramHandle?: string | null,
    description?: string | null,
    distance?: number | null,
    status?: VenueStatus | null,
    offerReservationStatus?: UserOfferStatus | null,
    closedDates?: Array< string | null > | null,
    approvalDate?: string | null,
    earliestOfferDate?: string | null,
    totalCheckins?: number | null,
    totalCheckinLastMonth?: number | null,
    totalOfferValueRedeemed?: number | null,
    userFavourite?: boolean | null,
    formattedAddress?: string | null,
    totalSavedContent?: number | null,
    offerCount?: number | null,
    topInMarket?: boolean | null,
    totalPositiveRatings?: number | null,
    totalRatings?: number | null,
    userMuted?: boolean | null,
  },
};

export type UserGetAgencyQueryVariables = {
  id: string,
};

export type UserGetAgencyQuery = {
  userGetAgency:  {
    __typename: "Venue",
    id: string,
    offers?:  Array< {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null > | null,
    reservations?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    approvedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    marketName?: string | null,
    marketID: string,
    marketCurrency?: string | null,
    market?:  {
      __typename: "Market",
      id: string,
      name: string,
      totalActiveVenues?: number | null,
      totalVenues?: number | null,
      totalPremiumVenues?: number | null,
      totalActiveModels?: number | null,
      totalUsers?: number | null,
      totalPremiumUsers?: number | null,
      percentActiveModels?: number | null,
      totalActiveOffers?: number | null,
      totalActiveVenueWithOffers?: number | null,
      totalPremiumOffers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      percentBookedLastFewDays?: number | null,
      percentBookedAllTime?: number | null,
      valueActiveOfferSpots?: number | null,
      totalReach?: number | null,
      stateProvince?: string | null,
      countryCode?: string | null,
      country?: string | null,
      currency?: string | null,
      status: MarketStatus,
      unitSystem?: UnitSystem | null,
      createdAt: string,
      tipEnabled?: boolean | null,
      totalVenuesLeads?: number | null,
      totalApplicationUsers?: number | null,
    } | null,
    type?: OfferCategory | null,
    phone?: string | null,
    email: string,
    name: string,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    contactFirstname?: string | null,
    contactLastname?: string | null,
    contactPosition?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    postalCode?: string | null,
    stateProvince?: string | null,
    country?: string | null,
    countryCode?: string | null,
    dependentLocality?: string | null,
    timeZoneId?: string | null,
    website?: string | null,
    instagramHandle?: string | null,
    referredBy?: string | null,
    description?: string | null,
    sideLetter?: string | null,
    approvalDate?: string | null,
    device?: string | null,
    totalActiveOffers?: number | null,
    totalOffers?: number | null,
    totalFavourites?: number | null,
    totalFavouritesByActiveUsers?: number | null,
    totalSpots?: number | null,
    totalReservations?: number | null,
    totalCheckins?: number | null,
    totalContentSaved?: number | null,
    totalImpressions?: number | null,
    percentBooked?: number | null,
    totalReach?: number | null,
    totalNoshow?: number | null,
    distance?: number | null,
    lastSeen?: string | null,
    lastBrowser?: string | null,
    lastPlatform?: string | null,
    lastSynced?: string | null,
    createdAt: string,
    updatedAt: string,
    status: VenueStatus,
    offerReservationStatus?: OfferReservationStatus | null,
    stats?: string | null,
    enabledPushNotifications?: boolean | null,
    enabledLocationAccess?: boolean | null,
    offerUpdatedAt?: string | null,
    reservationUpdatedAt?: string | null,
    closedDates?: Array< string | null > | null,
    checkInRadius?: number | null,
    platform?: string | null,
    notes?: string | null,
    enabledEmailNotifications?: boolean | null,
    currency?: string | null,
    tags?: Array< string | null > | null,
    appVersion?: string | null,
    tncVersion?: string | null,
    newContentAvailable?: boolean | null,
    availableDownloads?: number | null,
    unreadNotificationCount?: number | null,
    masterVenueID?: number | null,
    views?: number | null,
    formattedAddress?: string | null,
    tipEnabled?: boolean | null,
    premium?: boolean | null,
    lifestyleMembersAccess?: OfferAccessType | null,
    selectMembersAccess?: OfferAccessType | null,
    accessRequestsCount?: number | null,
    hasPermissionRequiredOffers?: boolean | null,
    hasSetLifestyleAccessType?: boolean | null,
    hasSetSelectAccessType?: boolean | null,
    plan?: string | null,
    termsAccepted?: boolean | null,
    referralInstagramHandle?: string | null,
    referredInstagramUserId?: string | null,
    totalAgencyUserInvites?: number | null,
    totalAgencyUserSignedInvites?: number | null,
  },
};

export type VenueListPortfolioContentQueryVariables = {
  id: string,
};

export type VenueListPortfolioContentQuery = {
  venueListPortfolioContent:  {
    __typename: "VenuePortfolioItemListItems",
    items?:  Array< {
      __typename: "VenuePortfolioItem",
      id?: string | null,
      venueID?: number | null,
      portfolio?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
  },
};

export type VenueListPortfolioContentV2QueryVariables = {
  filter: VenueListPortfolioContentFilter,
};

export type VenueListPortfolioContentV2Query = {
  venueListPortfolioContentV2:  {
    __typename: "VenuePortfolioItemListItems",
    items?:  Array< {
      __typename: "VenuePortfolioItem",
      id?: string | null,
      venueID?: number | null,
      portfolio?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
  },
};

export type UserListOffersQueryVariables = {
  filter: UserListOffersFilter,
};

export type UserListOffersQuery = {
  userListOffers:  {
    __typename: "UserListOffersListItems",
    items?:  Array< {
      __typename: "UserOfferItem",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      description?: string | null,
      category?: OfferCategory | null,
      subCategory?: string | null,
      availableTo?: Array< Profession | null > | null,
      gender?: Array< Gender | null > | null,
      startDate?: string | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      status: UserOfferStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      timeZoneId?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      checkInDateTime?: string | null,
      requirementsSubmitted?: boolean | null,
      reservationId?: string | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      currency?: string | null,
      validation?: string | null,
      penaltyType?: string | null,
      penaltyId?: string | null,
      reservationCreatedAt?: string | null,
      warnings?: Array< ReservationWarningType | null > | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      depositCost?: number | null,
      premium?: boolean | null,
      isNew?: boolean | null,
      availableSpots?: number | null,
      userAccessRequestStatus?: UserOfferAccessValue | null,
      totalSavedContent?: number | null,
    } | null > | null,
  },
};

export type UserListInvitesQueryVariables = {
  filter: UserListInvitesFilter,
};

export type UserListInvitesQuery = {
  userListInvites:  {
    __typename: "UserListInvitesListItems",
    items?:  Array< {
      __typename: "Invite",
      id?: number | null,
      userID?: number | null,
      offerID?: number | null,
      expiry?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      status?: InviteType | null,
      availableDates?: Array< string | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      seen?: boolean | null,
    } | null > | null,
  },
};

export type UserGetInviteQueryVariables = {
  filter: UserGetInviteFilter,
};

export type UserGetInviteQuery = {
  userGetInvite:  {
    __typename: "Invite",
    id?: number | null,
    userID?: number | null,
    offerID?: number | null,
    expiry?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    status?: InviteType | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    offer?:  {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null,
    availableDates?: Array< string | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    seen?: boolean | null,
  },
};

export type UserListOfferByVenueQueryVariables = {
  filter: UserListOfferByVenueFilter,
};

export type UserListOfferByVenueQuery = {
  userListOfferByVenue:  {
    __typename: "UserListOfferByVenueListItems",
    items?:  Array< {
      __typename: "UserOfferItem",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      description?: string | null,
      category?: OfferCategory | null,
      subCategory?: string | null,
      availableTo?: Array< Profession | null > | null,
      gender?: Array< Gender | null > | null,
      startDate?: string | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      status: UserOfferStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      timeZoneId?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      checkInDateTime?: string | null,
      requirementsSubmitted?: boolean | null,
      reservationId?: string | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      currency?: string | null,
      validation?: string | null,
      penaltyType?: string | null,
      penaltyId?: string | null,
      reservationCreatedAt?: string | null,
      warnings?: Array< ReservationWarningType | null > | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      depositCost?: number | null,
      premium?: boolean | null,
      isNew?: boolean | null,
      availableSpots?: number | null,
      userAccessRequestStatus?: UserOfferAccessValue | null,
      totalSavedContent?: number | null,
    } | null > | null,
  },
};

export type UserGetOfferQueryVariables = {
  filter: UserOfferInput,
};

export type UserGetOfferQuery = {
  userGetOffer:  {
    __typename: "UserOfferItem",
    id: string,
    title?: string | null,
    venueID: string,
    venue?:  {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null,
    venueName?: string | null,
    marketName?: string | null,
    marketID?: string | null,
    description?: string | null,
    category?: OfferCategory | null,
    subCategory?: string | null,
    availableTo?: Array< Profession | null > | null,
    gender?: Array< Gender | null > | null,
    startDate?: string | null,
    spots: number,
    waitTime?: number | null,
    availableDays?: Array< DaysOfWeek | null > | null,
    dressCode?: string | null,
    includeSelfInPost?: boolean | null,
    includeServiceOrProductInPost?: boolean | null,
    instagramTag?: string | null,
    postOnInstagramFeed?: boolean | null,
    postOnInstagramStories?: boolean | null,
    postOnInstagramReels?: boolean | null,
    postOnInstagramStories3x?: boolean | null,
    suggestedTip?: string | null,
    tipType?: TipType | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    status: UserOfferStatus,
    startDateTime?: string | null,
    endDateTime?: string | null,
    timeZoneId?: string | null,
    additionalreq?: string | null,
    minFollowers?: number | null,
    checkInDateTime?: string | null,
    requirementsSubmitted?: boolean | null,
    reservationId?: string | null,
    confirmWithEmail?: boolean | null,
    confirmWithCall?: boolean | null,
    currency?: string | null,
    validation?: string | null,
    penaltyType?: string | null,
    penaltyId?: string | null,
    reservationCreatedAt?: string | null,
    warnings?: Array< ReservationWarningType | null > | null,
    effectiveStartDateTime?: string | null,
    effectiveEndDateTime?: string | null,
    depositCost?: number | null,
    premium?: boolean | null,
    isNew?: boolean | null,
    availableSpots?: number | null,
    userAccessRequestStatus?: UserOfferAccessValue | null,
    totalSavedContent?: number | null,
  },
};

export type UserGetOfferV2QueryVariables = {
  filter: UserOfferInput,
};

export type UserGetOfferV2Query = {
  userGetOfferV2:  {
    __typename: "UserGetOfferV2Response",
    success?: boolean | null,
    message?: string | null,
    offer?:  {
      __typename: "UserOfferItem",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      description?: string | null,
      category?: OfferCategory | null,
      subCategory?: string | null,
      availableTo?: Array< Profession | null > | null,
      gender?: Array< Gender | null > | null,
      startDate?: string | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      status: UserOfferStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      timeZoneId?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      checkInDateTime?: string | null,
      requirementsSubmitted?: boolean | null,
      reservationId?: string | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      currency?: string | null,
      validation?: string | null,
      penaltyType?: string | null,
      penaltyId?: string | null,
      reservationCreatedAt?: string | null,
      warnings?: Array< ReservationWarningType | null > | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      depositCost?: number | null,
      premium?: boolean | null,
      isNew?: boolean | null,
      availableSpots?: number | null,
      userAccessRequestStatus?: UserOfferAccessValue | null,
      totalSavedContent?: number | null,
    } | null,
  },
};

export type UserGetUserQueryVariables = {
  id: string,
};

export type UserGetUserQuery = {
  userGetUser:  {
    __typename: "User",
    id: string,
    email: string,
    suspensions?:  Array< {
      __typename: "Suspension",
      id: string,
      userID: string,
      startDate: string,
      endDate: string,
      reason: SuspensionReason,
      detail?: string | null,
      paymentAmount?: number | null,
      status: SuspensionStatus,
      lastSynced?: string | null,
      createdAt: string,
    } | null > | null,
    penalties?:  Array< {
      __typename: "Penalty",
      id: string,
      userID: string,
      reservationID: string,
      type?: Array< OfferRequirements | null > | null,
      status: PenaltyStatus,
      createdAt: string,
      currency?: string | null,
      paymentAmount?: number | null,
      timeZoneId: string,
    } | null > | null,
    reservations?:  Array< {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null > | null,
    phone?: string | null,
    name?: string | null,
    country?: string | null,
    firstname: string,
    lastname: string,
    birthdate?: string | null,
    instagramHandle?: string | null,
    instagramFollowers?: number | null,
    instagramUpdatedAt?: string | null,
    failedInstaCheck?: boolean | null,
    profession: Profession,
    picture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    gender: Gender,
    compositeCard?:  {
      __typename: "FrontBack",
    } | null,
    agency?: string | null,
    motherAgency?: string | null,
    timezone?: string | null,
    currency?: string | null,
    homeCountry?: string | null,
    homeStateProvince?: string | null,
    homeCity?: string | null,
    city?: string | null,
    marketName?: string | null,
    marketID?: string | null,
    market?:  {
      __typename: "Market",
      id: string,
      name: string,
      totalActiveVenues?: number | null,
      totalVenues?: number | null,
      totalPremiumVenues?: number | null,
      totalActiveModels?: number | null,
      totalUsers?: number | null,
      totalPremiumUsers?: number | null,
      percentActiveModels?: number | null,
      totalActiveOffers?: number | null,
      totalActiveVenueWithOffers?: number | null,
      totalPremiumOffers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      percentBookedLastFewDays?: number | null,
      percentBookedAllTime?: number | null,
      valueActiveOfferSpots?: number | null,
      totalReach?: number | null,
      stateProvince?: string | null,
      countryCode?: string | null,
      country?: string | null,
      currency?: string | null,
      status: MarketStatus,
      unitSystem?: UnitSystem | null,
      createdAt: string,
      tipEnabled?: boolean | null,
      totalVenuesLeads?: number | null,
      totalApplicationUsers?: number | null,
    } | null,
    currentCity?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    credits?: number | null,
    approvalDate?: string | null,
    lastSeen?: string | null,
    device?: string | null,
    totalCheckins?: number | null,
    totalCheckinLastMonth?: number | null,
    totalOfferValueRedeemed?: number | null,
    totalReservations?: number | null,
    totalWarnings?: number | null,
    totalSuspensions?: number | null,
    totalNoShow?: number | null,
    totalActiveNoShow?: number | null,
    totalCancellation?: number | null,
    totalNoPost?: number | null,
    totalPenalties?: number | null,
    totalActivePenalties?: number | null,
    totalPortfolioContents?: number | null,
    totalContentUploaded?: number | null,
    offerCategoriesRedeemed?: string | null,
    timeOnApp?: number | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    reviewedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    acceptedCodeOfConduct?: boolean | null,
    enabledPushNotifications?: boolean | null,
    enabledLocationAccess?: boolean | null,
    lastSynced?: string | null,
    createdAt: string,
    updatedAt?: string | null,
    status: UserStatus,
    reactivationDate?: string | null,
    instagramDuplicates?: string | null,
    firstnameDuplicates?: string | null,
    lastnameDuplicates?: string | null,
    birthdateDuplicates?: string | null,
    instagramVerificationRequested?: string | null,
    platform?: string | null,
    stripeId?: string | null,
    totalRequirementsSubmitted?: number | null,
    tags?: Array< string | null > | null,
    appVersion?: string | null,
    noshowCredits?: number | null,
    tncVersion?: string | null,
    hasPendingNoshowCredits?: boolean | null,
    agencyProfileUrl?: string | null,
    freelancerBodyProfile?:  {
      __typename: "FreelancerBodyProfile",
      height?: number | null,
      waist?: number | null,
      bust?: number | null,
      hips?: number | null,
    } | null,
    freelancerPictures?:  {
      __typename: "FreelancerImages",
    } | null,
    freelancer?: boolean | null,
    reservationRewardClaimed?: boolean | null,
    instagramVerified?: boolean | null,
    points?: number | null,
    premium?: boolean | null,
    savedByVenue?: boolean | null,
    inviteCredits?: number | null,
    newInvitesAvailable?: boolean | null,
    invitesAvailable?: boolean | null,
    invitesReceived?: boolean | null,
    webProfilePrivate?: boolean | null,
    rating?: number | null,
    totalRatings?: number | null,
    tiktokHandle?: string | null,
    xHandle?: string | null,
    youtubeHandle?: string | null,
    highRated?: boolean | null,
    rank?: number | null,
    scoutingEnabled?: boolean | null,
    profileVideo?: string | null,
    scoutingPlanEnabled?: boolean | null,
    inviteStatus?: string | null,
    agencyInviteStatus?: string | null,
    hasDeletedInstagram?: boolean | null,
    hasDeletedEmail?: boolean | null,
    lifestyle?: boolean | null,
    signedByAgency?:  {
      __typename: "SignedByAgency",
      id?: number | null,
      name?: string | null,
    } | null,
    profileVideoWithThumbnail?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    potentialMatches?: Array< string | null > | null,
  },
};

export type UserGetAnalyticsQueryVariables = {
};

export type UserGetAnalyticsQuery = {
  userGetAnalytics?:  {
    __typename: "AnalyticsResponse",
    followers?: number | null,
    engagemenRate?: number | null,
    credibilityScore?: number | null,
    credibilityClass?: string | null,
    averageLikes?: number | null,
    averageComments?: number | null,
    averageReelViews?: number | null,
    genderDistribution?:  {
      __typename: "AnalyticsGenderDistribution",
      female?: number | null,
      male?: number | null,
    } | null,
    countries?:  Array< {
      __typename: "AnalyticsCountryDistribution",
      name?: string | null,
      code?: string | null,
      followers?: number | null,
    } | null > | null,
    priceEstimate?:  {
      __typename: "PriceEstimateAnalytics",
      min?: number | null,
      max?: number | null,
    } | null,
  } | null,
};

export type UserListPortfolioContentQueryVariables = {
};

export type UserListPortfolioContentQuery = {
  userListPortfolioContent:  {
    __typename: "PortfolioItemListItems",
    items?:  Array< {
      __typename: "UserPortfolioItem",
      id?: string | null,
      userID?: number | null,
      portfolio?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
  },
};

export type UserListPortfolioContentV2QueryVariables = {
  filter: ListPortfolioContentFilter,
};

export type UserListPortfolioContentV2Query = {
  userListPortfolioContentV2:  {
    __typename: "PortfolioItemListItems",
    items?:  Array< {
      __typename: "UserPortfolioItem",
      id?: string | null,
      userID?: number | null,
      portfolio?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
  },
};

export type UserGetSetupIntentQueryVariables = {
};

export type UserGetSetupIntentQuery = {
  userGetSetupIntent:  {
    __typename: "PaymentSheet",
    customerId: string,
    merchantDisplayName: string,
    customerEphemeralKeySecret: string,
    setupIntentClientSecret: string,
  },
};

export type UserGetPaymentIntentQueryVariables = {
  input: PaymentIntentInput,
};

export type UserGetPaymentIntentQuery = {
  userGetPaymentIntent:  {
    __typename: "PaymentIntent",
    customerId: string,
    merchantDisplayName: string,
    customerEphemeralKeySecret: string,
    paymentIntentClientSecret: string,
    applePay?:  {
      __typename: "ApplePayIntent",
      merchantCountryCode?: string | null,
    } | null,
    googlePay?:  {
      __typename: "GooglePayIntent",
      merchantCountryCode?: string | null,
      testEnv?: boolean | null,
    } | null,
  },
};

export type UserGetAccountBalanceQueryVariables = {
  input: UserAccountBalanceRequest,
};

export type UserGetAccountBalanceQuery = {
  userGetAccountBalance:  {
    __typename: "UserAccountBalanceResponse",
    balance: number,
    accountId: string,
    status: string,
  },
};

export type UserGetVerificationUrlQueryVariables = {
  input: UserAccountBalanceRequest,
};

export type UserGetVerificationUrlQuery = {
  userGetVerificationUrl:  {
    __typename: "UserVerificatioURLResponse",
    onBoardingURL: string,
  },
};

export type UserListSubscriptionsQueryVariables = {
  filter: UserSubscriptionsFilter,
};

export type UserListSubscriptionsQuery = {
  userListSubscriptions:  {
    __typename: "UserListSubscriptionsListItems",
    items?:  Array< {
      __typename: "UserSubscriptionItem",
      id: number,
      subscriptionId: string,
      userID: number,
      sku: string,
      productGroup: string,
      price?: number | null,
      convertedPrice?: number | null,
      currency?: string | null,
      convertedCurrency?: string | null,
      platform?: string | null,
      expirationDate?: string | null,
      status: string,
      isSubscriptionRenewable?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
  },
};

export type UserListReservationsQueryVariables = {
  filter: UserListReservationsFilter,
};

export type UserListReservationsQuery = {
  userListReservations:  {
    __typename: "UserListReservationsListItems",
    items?:  Array< {
      __typename: "UserReservationItem",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      offerDescription?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      reservationDate: string,
      checkInDateTime?: string | null,
      createdAt: string,
      requirementsSubmitted?: boolean | null,
      status: ReservationStatus,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      penaltyType?: string | null,
      warnings?: Array< ReservationWarningType | null > | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      penaltyId?: string | null,
      contentUploaded?: boolean | null,
      depositCost?: number | null,
      offerPostType?: string | null,
      requirementsSubmittedDateTime?: string | null,
      premium?: boolean | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      offerDressCode?: string | null,
      offerSuggestedTip?: string | null,
      offerCurrency?: string | null,
      offerPostInclude?: string | null,
      offerIncludeSelfInPost?: boolean | null,
      offerIncludeServiceOrProductInPost?: boolean | null,
      offerConfirmWithCall?: boolean | null,
      venueInstagramHandle?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      totalSavedContent?: number | null,
      offerValueUSD?: number | null,
    } | null > | null,
  },
};

export type UserListRedeemedCategoriesQueryVariables = {
};

export type UserListRedeemedCategoriesQuery = {
  userListRedeemedCategories:  {
    __typename: "UserListRedeemedCategoriesListItems",
    items?:  Array< {
      __typename: "UserRedeemedCategoriesItem",
      category?: string | null,
      totalRedeemed?: number | null,
      totalPoints?: number | null,
      offers?: Array< number | null > | null,
      offerThumbnails?: Array< string | null > | null,
    } | null > | null,
  },
};

export type UserListRedeemedOffersByCategoryQueryVariables = {
  filter: UserListReservationsByCategoryFilter,
};

export type UserListRedeemedOffersByCategoryQuery = {
  userListRedeemedOffersByCategory:  {
    __typename: "UserListReservationsByCategoryResponse",
    items?:  Array< {
      __typename: "UserListReservationsByCategoryItem",
      offerTitle?: string | null,
      offerID?: number | null,
      venueName?: string | null,
      points?: number | null,
      startDateTime?: string | null,
      offerThumbnail?: string | null,
      timeZoneId?: string | null,
    } | null > | null,
  },
};

export type UserListReservationContentQueryVariables = {
  filter: UserListReservationsContentFilter,
};

export type UserListReservationContentQuery = {
  userListReservationContent:  {
    __typename: "UserListReservationContentResponse",
    items?:  Array< {
      __typename: "UserReservationContentItem",
      id?: string | null,
      seen?: boolean | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      userID?: string | null,
      userFirstname?: string | null,
      userLastname?: string | null,
      userUpdatedAt?: string | null,
      offerID?: string | null,
      offerTitle?: string | null,
      offerUpdatedAt?: string | null,
    } | null > | null,
  },
};

export type UserGetReservationQueryVariables = {
  id: string,
};

export type UserGetReservationQuery = {
  userGetReservation:  {
    __typename: "UserReservationItem",
    id: string,
    marketName?: string | null,
    marketID?: string | null,
    offerID: string,
    offerCategory?: OfferCategory | null,
    offer?:  {
      __typename: "Offer",
      id: string,
      title?: string | null,
      venueID: string,
      venueName?: string | null,
      marketName?: string | null,
      marketCurrency?: string | null,
      marketID?: string | null,
      description?: string | null,
      category: OfferCategory,
      subCategory?: FoodCategories | null,
      availableTo: Array< Profession | null >,
      gender: Array< Gender | null >,
      offerValue?: number | null,
      offerValueUSD?: number | null,
      startDate: string,
      endDate?: string | null,
      startTime: string,
      endTime: string,
      durationInHours?: number | null,
      spots: number,
      waitTime?: number | null,
      availableDays?: Array< DaysOfWeek | null > | null,
      dressCode?: string | null,
      includeSelfInPost?: boolean | null,
      includeServiceOrProductInPost?: boolean | null,
      instagramTag?: string | null,
      postOnInstagramFeed?: boolean | null,
      postOnInstagramStories?: boolean | null,
      postOnInstagramReels?: boolean | null,
      postOnInstagramStories3x?: boolean | null,
      yelpReview?: boolean | null,
      suggestedTip?: string | null,
      tipType?: TipType | null,
      reasonRejected?: string | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      percentBooked?: number | null,
      totalUniqueVisitors?: number | null,
      timeZoneId?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      offerData?: string | null,
      status: OfferStatus,
      startDateTime?: string | null,
      additionalreq?: string | null,
      minFollowers?: number | null,
      confirmWithEmail?: boolean | null,
      confirmWithCall?: boolean | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      originalOfferID?: number | null,
      depositCost?: number | null,
      premium?: boolean | null,
      private?: boolean | null,
      views?: number | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      totalSavedContent?: number | null,
      address?: string | null,
    } | null,
    offerTitle?: string | null,
    offerStartTime?: string | null,
    offerEndTime?: string | null,
    offerEndDateTime?: string | null,
    offerDescription?: string | null,
    timeZoneId?: string | null,
    userID: string,
    name?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    venueID: string,
    venue?:  {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null,
    venueName?: string | null,
    venueLocation?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    venueAddress1?: string | null,
    venueAddress2?: string | null,
    venueCity?: string | null,
    venueCountry?: string | null,
    note?: string | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    reservationDate: string,
    checkInDateTime?: string | null,
    createdAt: string,
    venuePicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    offerPicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    userPicture?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    requirementsSubmitted?: boolean | null,
    status: ReservationStatus,
    currency?: string | null,
    payoutAmount?: number | null,
    validation?: string | null,
    penaltyType?: string | null,
    warnings?: Array< ReservationWarningType | null > | null,
    effectiveStartDateTime?: string | null,
    effectiveEndDateTime?: string | null,
    penaltyId?: string | null,
    contentUploaded?: boolean | null,
    depositCost?: number | null,
    offerPostType?: string | null,
    requirementsSubmittedDateTime?: string | null,
    premium?: boolean | null,
    inviteID?: number | null,
    privateOffer?: boolean | null,
    offerDressCode?: string | null,
    offerSuggestedTip?: string | null,
    offerCurrency?: string | null,
    offerPostInclude?: string | null,
    offerIncludeSelfInPost?: boolean | null,
    offerIncludeServiceOrProductInPost?: boolean | null,
    offerConfirmWithCall?: boolean | null,
    venueInstagramHandle?: string | null,
    rated?: boolean | null,
    ratedByVenue?: boolean | null,
    totalSavedContent?: number | null,
    offerValueUSD?: number | null,
  },
};

export type UserGetOfferCategoriesRedeemedByUserQueryVariables = {
  userID: string,
};

export type UserGetOfferCategoriesRedeemedByUserQuery = {
  userGetOfferCategoriesRedeemedByUser: string,
};

export type UserGetTotalsQueryVariables = {
  userID: string,
};

export type UserGetTotalsQuery = {
  userGetTotals:  {
    __typename: "UserTotals",
    offerRedeemed: number,
    noShow: number,
    penalty: number,
    noshowCredits: number,
    points: number,
  },
};

export type UserGetCreditLedgerQueryVariables = {
  filter: CreditLedgerFilter,
};

export type UserGetCreditLedgerQuery = {
  userGetCreditLedger:  {
    __typename: "CreditLedgerItems",
    items?:  Array< {
      __typename: "CreditLedgerItem",
      userID: number,
      reservationId?: number | null,
      credits: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      subtitle?: string | null,
      pending?: boolean | null,
      balance?: number | null,
    } | null > | null,
  },
};

export type AdminGetUserCreditLedgerQueryVariables = {
  filter: CreditLedgerFilter,
};

export type AdminGetUserCreditLedgerQuery = {
  adminGetUserCreditLedger:  {
    __typename: "CreditLedgerItems",
    items?:  Array< {
      __typename: "CreditLedgerItem",
      userID: number,
      reservationId?: number | null,
      credits: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      title: string,
      subtitle?: string | null,
      pending?: boolean | null,
      balance?: number | null,
    } | null > | null,
  },
};

export type UserListPenaltiesQueryVariables = {
  filter: UserListPenaltiesFilter,
};

export type UserListPenaltiesQuery = {
  userListPenalties:  {
    __typename: "UserListPenaltiesListItems",
    items:  Array< {
      __typename: "Penalty",
      id: string,
      userID: string,
      reservationID: string,
      type?: Array< OfferRequirements | null > | null,
      status: PenaltyStatus,
      createdAt: string,
      currency?: string | null,
      paymentAmount?: number | null,
      timeZoneId: string,
    } | null >,
  },
};

export type UserGetPenaltyQueryVariables = {
  id: string,
};

export type UserGetPenaltyQuery = {
  userGetPenalty:  {
    __typename: "Penalty",
    id: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    reservationID: string,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      marketName?: string | null,
      marketID?: string | null,
      offerID: string,
      offerCategory?: OfferCategory | null,
      offerTitle?: string | null,
      offerValue?: number | null,
      offerStartTime?: string | null,
      offerEndTime?: string | null,
      offerEndDateTime?: string | null,
      timeZoneId?: string | null,
      userID: string,
      name?: string | null,
      venueID: string,
      venueName?: string | null,
      venueAddress1?: string | null,
      venueAddress2?: string | null,
      venueCity?: string | null,
      venueCountry?: string | null,
      note?: string | null,
      status: ReservationStatus,
      reservationDate: string,
      actionDate?: string | null,
      checkInDateTime?: string | null,
      createdAt: string,
      updatedAt: string,
      canSubmitRequirements?: boolean | null,
      requirementsSubmitted?: boolean | null,
      instagramTag?: string | null,
      noShowStatus?: NoShowStatus | null,
      currency?: string | null,
      payoutAmount?: number | null,
      validation?: string | null,
      validationRequested?: string | null,
      penaltyType?: string | null,
      effectiveStartDateTime?: string | null,
      effectiveEndDateTime?: string | null,
      offerPostType?: string | null,
      postLink?: string | null,
      inviteID?: number | null,
      privateOffer?: boolean | null,
      requirementsSubmittedDateTime?: string | null,
      rated?: boolean | null,
      ratedByVenue?: boolean | null,
      rating?: number | null,
      offerValueSnapshot?: number | null,
      dressCodeSnapshot?: string | null,
      suggestedTipSnapshot?: string | null,
    } | null,
    type?: Array< OfferRequirements | null > | null,
    status: PenaltyStatus,
    createdAt: string,
    currency?: string | null,
    paymentAmount?: number | null,
    timeZoneId: string,
  },
};

export type UserListSuspensionsQueryVariables = {
  filter: UserListSuspensionsFilter,
};

export type UserListSuspensionsQuery = {
  userListSuspensions:  {
    __typename: "UserListSuspensionsListItems",
    items?:  Array< {
      __typename: "Suspension",
      id: string,
      userID: string,
      startDate: string,
      endDate: string,
      reason: SuspensionReason,
      detail?: string | null,
      paymentAmount?: number | null,
      status: SuspensionStatus,
      lastSynced?: string | null,
      createdAt: string,
    } | null > | null,
  },
};

export type UserGetSuspensionQueryVariables = {
  id: string,
};

export type UserGetSuspensionQuery = {
  userGetSuspension:  {
    __typename: "Suspension",
    id: string,
    suspendedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    editedBy?:  {
      __typename: "Staff",
      id: string,
      email: string,
      firstname: string,
      lastname: string,
      group: StaffGroup,
      picture?: string | null,
      createdAt: string,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    startDate: string,
    endDate: string,
    reason: SuspensionReason,
    detail?: string | null,
    paymentAmount?: number | null,
    status: SuspensionStatus,
    lastSynced?: string | null,
    createdAt: string,
  },
};

export type UserListNotificationsQueryVariables = {
  filter: UserListNotificationsFilter,
};

export type UserListNotificationsQuery = {
  userListNotifications:  {
    __typename: "UserNotificationListItems",
    items:  Array< {
      __typename: "UserNotification",
      id: string,
      userID: string,
      venueID?: string | null,
      type: NotificationType,
      thumbnail?: string | null,
      title?: string | null,
      content?: string | null,
      status?: UserNotificationStatus | null,
      metadata?: string | null,
      redirectCTA?: string | null,
      createdAt: string,
    } | null >,
  },
};

export type UserGetUnreadNotificationCountQueryVariables = {
  userID: string,
};

export type UserGetUnreadNotificationCountQuery = {
  userGetUnreadNotificationCount: number,
};

export type ServiceGetInstagramQueryVariables = {
  username?: string | null,
};

export type ServiceGetInstagramQuery = {
  serviceGetInstagram: string,
};

export type ServiceEmailExistQueryVariables = {
  email: string,
};

export type ServiceEmailExistQuery = {
  serviceEmailExist: boolean,
};

export type ServiceSendForgotPasswordQueryVariables = {
  email: string,
  fromVenueWeb?: boolean | null,
};

export type ServiceSendForgotPasswordQuery = {
  serviceSendForgotPassword: boolean,
};

export type ServiceInstagramExistQueryVariables = {
  instagram: string,
};

export type ServiceInstagramExistQuery = {
  serviceInstagramExist: boolean,
};

export type ServiceGooglePlacesAutocompleteQueryVariables = {
  place: string,
  placeType?: GooglePlaceType | null,
};

export type ServiceGooglePlacesAutocompleteQuery = {
  serviceGooglePlacesAutocomplete: string,
};

export type ServiceGoogleGetPlaceQueryVariables = {
  placeID: string,
};

export type ServiceGoogleGetPlaceQuery = {
  serviceGoogleGetPlace: string,
};

export type ServiceGetPreSignedUrlQueryVariables = {
  ext?: string | null,
};

export type ServiceGetPreSignedUrlQuery = {
  serviceGetPreSignedUrl: string,
};

export type ServiceGetCurrentMarketByCoordinatesQueryVariables = {
  location: LocationInput,
};

export type ServiceGetCurrentMarketByCoordinatesQuery = {
  serviceGetCurrentMarketByCoordinates:  {
    __typename: "Market",
    id: string,
    name: string,
    venues?:  Array< {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null > | null,
    totalActiveVenues?: number | null,
    totalVenues?: number | null,
    totalPremiumVenues?: number | null,
    totalActiveModels?: number | null,
    totalUsers?: number | null,
    totalPremiumUsers?: number | null,
    percentActiveModels?: number | null,
    totalActiveOffers?: number | null,
    totalActiveVenueWithOffers?: number | null,
    totalPremiumOffers?: number | null,
    totalSpots?: number | null,
    totalReservations?: number | null,
    percentBookedLastFewDays?: number | null,
    percentBookedAllTime?: number | null,
    valueActiveOfferSpots?: number | null,
    totalReach?: number | null,
    stateProvince?: string | null,
    countryCode?: string | null,
    country?: string | null,
    currency?: string | null,
    status: MarketStatus,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    timezone?:  {
      __typename: "Timezone",
      dstOffset: number,
      rawOffset: number,
      timeZoneId: string,
      timeZoneName: string,
    } | null,
    unitSystem?: UnitSystem | null,
    createdAt: string,
    tipEnabled?: boolean | null,
    totalVenuesLeads?: number | null,
    totalApplicationUsers?: number | null,
  },
};

export type ListLogItemsQueryVariables = {
  input?: ListLogItemInput | null,
};

export type ListLogItemsQuery = {
  listLogItems:  {
    __typename: "ListLogItemResult",
    nextToken?: string | null,
    items:  Array< {
      __typename: "LogItem",
      id: string,
      type: LogType,
      resource: string,
      message: string,
      timestamp: number,
    } >,
  },
};

export type ListLogResourcesQueryVariables = {
};

export type ListLogResourcesQuery = {
  listLogResources: Array< string >,
};

export type AdminListVenueLeadsQueryVariables = {
  filter: AdminListVenueLeadsFilter,
};

export type AdminListVenueLeadsQuery = {
  adminListVenueLeads?:  {
    __typename: "AdminListVenueLeadsResponse",
    items?:  Array< {
      __typename: "VenueLeads",
      id?: number | null,
      status?: string | null,
      userID?: string | null,
      name?: string | null,
      website?: string | null,
      instagram?: string | null,
      contactName?: string | null,
      contactNumber?: string | null,
      contactEmail?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      recommendedByFirstname?: string | null,
      recommendedByLastname?: string | null,
      recommendedByInstagramHandle?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
  } | null,
};

export type AdminListCurrenciesQueryVariables = {
};

export type AdminListCurrenciesQuery = {
  adminListCurrencies?:  {
    __typename: "CurrencyListResponse",
    items?:  Array< {
      __typename: "CurrencyItem",
      code: string,
      rate: number,
      updatedAt: number,
    } | null > | null,
  } | null,
};

export type VenueListCurrenciesQueryVariables = {
};

export type VenueListCurrenciesQuery = {
  venueListCurrencies?:  {
    __typename: "CurrencyListResponse",
    items?:  Array< {
      __typename: "CurrencyItem",
      code: string,
      rate: number,
      updatedAt: number,
    } | null > | null,
  } | null,
};

export type VenueListSubscriptionsQueryVariables = {
  filter: VenueSubscriptionsFilter,
};

export type VenueListSubscriptionsQuery = {
  venueListSubscriptions:  {
    __typename: "VenueListSubscriptionsListItems",
    items?:  Array< {
      __typename: "VenueSubscriptionItem",
      id: number,
      subscriptionId: string,
      venueID: number,
      sku: string,
      productGroup: string,
      price?: number | null,
      convertedPrice?: number | null,
      currency?: string | null,
      convertedCurrency?: string | null,
      platform?: string | null,
      expirationDate?: string | null,
      status: string,
      isSubscriptionRenewable?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
  },
};

export type UserGetAgencyInviteQueryVariables = {
  filter?: AgencyInviteGetRequest | null,
};

export type UserGetAgencyInviteQuery = {
  userGetAgencyInvite:  {
    __typename: "AgencyInvite",
    id: number,
    userID: number,
    venueID: number,
    status: string,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      phone?: string | null,
      name?: string | null,
      country?: string | null,
      firstname: string,
      lastname: string,
      birthdate?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: number | null,
      instagramUpdatedAt?: string | null,
      failedInstaCheck?: boolean | null,
      profession: Profession,
      gender: Gender,
      agency?: string | null,
      motherAgency?: string | null,
      timezone?: string | null,
      currency?: string | null,
      homeCountry?: string | null,
      homeStateProvince?: string | null,
      homeCity?: string | null,
      city?: string | null,
      marketName?: string | null,
      marketID?: string | null,
      currentCity?: string | null,
      credits?: number | null,
      approvalDate?: string | null,
      lastSeen?: string | null,
      device?: string | null,
      totalCheckins?: number | null,
      totalCheckinLastMonth?: number | null,
      totalOfferValueRedeemed?: number | null,
      totalReservations?: number | null,
      totalWarnings?: number | null,
      totalSuspensions?: number | null,
      totalNoShow?: number | null,
      totalActiveNoShow?: number | null,
      totalCancellation?: number | null,
      totalNoPost?: number | null,
      totalPenalties?: number | null,
      totalActivePenalties?: number | null,
      totalPortfolioContents?: number | null,
      totalContentUploaded?: number | null,
      offerCategoriesRedeemed?: string | null,
      timeOnApp?: number | null,
      acceptedCodeOfConduct?: boolean | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      status: UserStatus,
      reactivationDate?: string | null,
      instagramDuplicates?: string | null,
      firstnameDuplicates?: string | null,
      lastnameDuplicates?: string | null,
      birthdateDuplicates?: string | null,
      instagramVerificationRequested?: string | null,
      platform?: string | null,
      stripeId?: string | null,
      totalRequirementsSubmitted?: number | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      noshowCredits?: number | null,
      tncVersion?: string | null,
      hasPendingNoshowCredits?: boolean | null,
      agencyProfileUrl?: string | null,
      freelancer?: boolean | null,
      reservationRewardClaimed?: boolean | null,
      instagramVerified?: boolean | null,
      points?: number | null,
      premium?: boolean | null,
      savedByVenue?: boolean | null,
      inviteCredits?: number | null,
      newInvitesAvailable?: boolean | null,
      invitesAvailable?: boolean | null,
      invitesReceived?: boolean | null,
      webProfilePrivate?: boolean | null,
      rating?: number | null,
      totalRatings?: number | null,
      tiktokHandle?: string | null,
      xHandle?: string | null,
      youtubeHandle?: string | null,
      highRated?: boolean | null,
      rank?: number | null,
      scoutingEnabled?: boolean | null,
      profileVideo?: string | null,
      scoutingPlanEnabled?: boolean | null,
      inviteStatus?: string | null,
      agencyInviteStatus?: string | null,
      hasDeletedInstagram?: boolean | null,
      hasDeletedEmail?: boolean | null,
      lifestyle?: boolean | null,
      potentialMatches?: Array< string | null > | null,
    } | null,
    marketName?: string | null,
    seen?: boolean | null,
    venue?:  {
      __typename: "Venue",
      id: string,
      marketName?: string | null,
      marketID: string,
      marketCurrency?: string | null,
      type?: OfferCategory | null,
      phone?: string | null,
      email: string,
      name: string,
      contactFirstname?: string | null,
      contactLastname?: string | null,
      contactPosition?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      postalCode?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      countryCode?: string | null,
      dependentLocality?: string | null,
      timeZoneId?: string | null,
      website?: string | null,
      instagramHandle?: string | null,
      referredBy?: string | null,
      description?: string | null,
      sideLetter?: string | null,
      approvalDate?: string | null,
      device?: string | null,
      totalActiveOffers?: number | null,
      totalOffers?: number | null,
      totalFavourites?: number | null,
      totalFavouritesByActiveUsers?: number | null,
      totalSpots?: number | null,
      totalReservations?: number | null,
      totalCheckins?: number | null,
      totalContentSaved?: number | null,
      totalImpressions?: number | null,
      percentBooked?: number | null,
      totalReach?: number | null,
      totalNoshow?: number | null,
      distance?: number | null,
      lastSeen?: string | null,
      lastBrowser?: string | null,
      lastPlatform?: string | null,
      lastSynced?: string | null,
      createdAt: string,
      updatedAt: string,
      status: VenueStatus,
      offerReservationStatus?: OfferReservationStatus | null,
      stats?: string | null,
      enabledPushNotifications?: boolean | null,
      enabledLocationAccess?: boolean | null,
      offerUpdatedAt?: string | null,
      reservationUpdatedAt?: string | null,
      closedDates?: Array< string | null > | null,
      checkInRadius?: number | null,
      platform?: string | null,
      notes?: string | null,
      enabledEmailNotifications?: boolean | null,
      currency?: string | null,
      tags?: Array< string | null > | null,
      appVersion?: string | null,
      tncVersion?: string | null,
      newContentAvailable?: boolean | null,
      availableDownloads?: number | null,
      unreadNotificationCount?: number | null,
      masterVenueID?: number | null,
      views?: number | null,
      formattedAddress?: string | null,
      tipEnabled?: boolean | null,
      premium?: boolean | null,
      lifestyleMembersAccess?: OfferAccessType | null,
      selectMembersAccess?: OfferAccessType | null,
      accessRequestsCount?: number | null,
      hasPermissionRequiredOffers?: boolean | null,
      hasSetLifestyleAccessType?: boolean | null,
      hasSetSelectAccessType?: boolean | null,
      plan?: string | null,
      termsAccepted?: boolean | null,
      referralInstagramHandle?: string | null,
      referredInstagramUserId?: string | null,
      totalAgencyUserInvites?: number | null,
      totalAgencyUserSignedInvites?: number | null,
    } | null,
  },
};

export type AdminListConversationsQueryVariables = {
  filter: AdminListConversationsFilter,
};

export type AdminListConversationsQuery = {
  adminListConversations:  {
    __typename: "ConversationList",
    nextPageKey?: string | null,
    items?:  Array< {
      __typename: "ConversationItem",
      fromUserId: string,
      toUserId: string,
      conversationId: string,
      userType: string,
      messageTime: string,
      fromUserMessageCount?: number | null,
      toUserMessageCount?: number | null,
      firstname?: string | null,
      lastname?: string | null,
      instagramHandle?: string | null,
      instagramFollowers?: string | null,
      premium?: string | null,
      plan?: string | null,
    } | null > | null,
  },
};

export type AdminGetConversationQueryVariables = {
  filter: AdminGetConversationFilter,
};

export type AdminGetConversationQuery = {
  adminGetConversation?:  {
    __typename: "ConversationItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    fromUserMessageCount?: number | null,
    toUserMessageCount?: number | null,
    images?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    firstname?: string | null,
    lastname?: string | null,
    instagramHandle?: string | null,
    instagramFollowers?: string | null,
    premium?: string | null,
    plan?: string | null,
  } | null,
};

export type VenueGetConversationQueryVariables = {
  filter: VenueGetConversationFilter,
};

export type VenueGetConversationQuery = {
  venueGetConversation?:  {
    __typename: "ConversationItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    fromUserMessageCount?: number | null,
    toUserMessageCount?: number | null,
    images?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    firstname?: string | null,
    lastname?: string | null,
    instagramHandle?: string | null,
    instagramFollowers?: string | null,
    premium?: string | null,
    plan?: string | null,
  } | null,
};

export type UserGetConversationQueryVariables = {
};

export type UserGetConversationQuery = {
  userGetConversation?:  {
    __typename: "ConversationItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    fromUserMessageCount?: number | null,
    toUserMessageCount?: number | null,
    images?:  {
      __typename: "Image",
      thumbnail?: string | null,
      small?: string | null,
      medium?: string | null,
      large?: string | null,
      video?: string | null,
    } | null,
    firstname?: string | null,
    lastname?: string | null,
    instagramHandle?: string | null,
    instagramFollowers?: string | null,
    premium?: string | null,
    plan?: string | null,
  } | null,
};

export type AdminListChatMessagesQueryVariables = {
  filter: AdminListChatMessagesFilter,
};

export type AdminListChatMessagesQuery = {
  adminListChatMessages:  {
    __typename: "ChatMessagesList",
    nextPageKey?: string | null,
    items?:  Array< {
      __typename: "ChatMessageItem",
      fromUserId: string,
      toUserId: string,
      conversationId: string,
      userType: string,
      messageTime: string,
      message?: string | null,
      messageType?: string | null,
      received?: boolean | null,
      seen?: boolean | null,
      rejected?: boolean | null,
      url?: string | null,
      title?: string | null,
      thumbnail?: string | null,
      complaintID?: string | null,
    } | null > | null,
  },
};

export type VenueListChatMessagesQueryVariables = {
  filter: VenueListChatMessagesFilter,
};

export type VenueListChatMessagesQuery = {
  venueListChatMessages:  {
    __typename: "ChatMessagesList",
    nextPageKey?: string | null,
    items?:  Array< {
      __typename: "ChatMessageItem",
      fromUserId: string,
      toUserId: string,
      conversationId: string,
      userType: string,
      messageTime: string,
      message?: string | null,
      messageType?: string | null,
      received?: boolean | null,
      seen?: boolean | null,
      rejected?: boolean | null,
      url?: string | null,
      title?: string | null,
      thumbnail?: string | null,
      complaintID?: string | null,
    } | null > | null,
  },
};

export type UserListChatMessagesQueryVariables = {
  filter: UserListChatMessagesFilter,
};

export type UserListChatMessagesQuery = {
  userListChatMessages:  {
    __typename: "ChatMessagesList",
    nextPageKey?: string | null,
    items?:  Array< {
      __typename: "ChatMessageItem",
      fromUserId: string,
      toUserId: string,
      conversationId: string,
      userType: string,
      messageTime: string,
      message?: string | null,
      messageType?: string | null,
      received?: boolean | null,
      seen?: boolean | null,
      rejected?: boolean | null,
      url?: string | null,
      title?: string | null,
      thumbnail?: string | null,
      complaintID?: string | null,
    } | null > | null,
  },
};

export type OnAdminReceiveChatMessageFromVenueOrUserSubscriptionVariables = {
};

export type OnAdminReceiveChatMessageFromVenueOrUserSubscription = {
  onAdminReceiveChatMessageFromVenueOrUser?:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  } | null,
};

export type OnVenueReceiveChatMessageFromAdminSubscriptionVariables = {
  userType: string,
  toUserId: string,
};

export type OnVenueReceiveChatMessageFromAdminSubscription = {
  onVenueReceiveChatMessageFromAdmin?:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  } | null,
};

export type OnUserReceiveChatMessageFromAdminSubscriptionVariables = {
  userType: string,
  toUserId: string,
};

export type OnUserReceiveChatMessageFromAdminSubscription = {
  onUserReceiveChatMessageFromAdmin?:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  } | null,
};

export type OnAdminReceiveChatMessageFromAdminSubscriptionVariables = {
  conversationId: string,
};

export type OnAdminReceiveChatMessageFromAdminSubscription = {
  onAdminReceiveChatMessageFromAdmin?:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  } | null,
};

export type OnMessageUpdatedSubscriptionVariables = {
  conversationId: string,
};

export type OnMessageUpdatedSubscription = {
  onMessageUpdated?:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  } | null,
};

export type OnMessageRejectedSubscriptionVariables = {
  conversationId: string,
  messageTime: string,
};

export type OnMessageRejectedSubscription = {
  onMessageRejected?:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  } | null,
};

export type OnChatClosedSubscriptionVariables = {
  userType: string,
  toUserId: string,
};

export type OnChatClosedSubscription = {
  onChatClosed?:  {
    __typename: "ChatMessageItem",
    fromUserId: string,
    toUserId: string,
    conversationId: string,
    userType: string,
    messageTime: string,
    message?: string | null,
    messageType?: string | null,
    received?: boolean | null,
    seen?: boolean | null,
    rejected?: boolean | null,
    url?: string | null,
    title?: string | null,
    thumbnail?: string | null,
    complaintID?: string | null,
  } | null,
};
