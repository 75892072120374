import { Reservation } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueGetReservationQuery = /* GraphQL */ `
  query VenueGetReservation($id: String!) {
    venueGetReservation(id: $id) {
      id
      editedBy {
        id
        email
        firstname
        lastname
        group
        picture
        createdAt
      }
      marketName
      marketID
      offerID
      offerCategory
      offer {
        id
        title
        venueID
        venueName
        marketName
        marketCurrency
        marketID
        description
        category
        subCategory
        availableTo
        gender
        offerValue
        offerValueUSD
        startDate
        endDate
        startTime
        endTime
        durationInHours
        spots
        waitTime
        availableDays
        dressCode
        includeSelfInPost
        includeServiceOrProductInPost
        instagramTag
        postOnInstagramFeed
        postOnInstagramStories
        postOnInstagramReels
        postOnInstagramStories3x
        yelpReview
        suggestedTip
        tipType
        reasonRejected
        totalReservations
        totalCheckins
        percentBooked
        totalUniqueVisitors
        timeZoneId
        lastSynced
        createdAt
        offerData
        status
        startDateTime
        additionalreq
        minFollowers
        confirmWithEmail
        confirmWithCall
        effectiveStartDateTime
        effectiveEndDateTime
        originalOfferID
        depositCost
        premium
        private
      }
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      user {
        id
        email
        phone
        name
        country
        firstname
        lastname
        birthdate
        instagramHandle
        instagramFollowers
        instagramUpdatedAt
        failedInstaCheck
        profession
        gender
        agency
        motherAgency
        timezone
        currency
        homeCountry
        homeStateProvince
        homeCity
        city
        marketName
        marketID
        currentCity
        credits
        approvalDate
        lastSeen
        device
        totalCheckins
        totalCheckinLastMonth
        totalOfferValueRedeemed
        totalReservations
        totalWarnings
        totalSuspensions
        totalNoShow
        totalActiveNoShow
        totalCancellation
        totalNoPost
        totalPenalties
        totalActivePenalties
        totalPortfolioContents
        totalContentUploaded
        offerCategoriesRedeemed
        timeOnApp
        acceptedCodeOfConduct
        enabledPushNotifications
        enabledLocationAccess
        lastSynced
        createdAt
        updatedAt
        status
        reactivationDate
        instagramDuplicates
        firstnameDuplicates
        lastnameDuplicates
        birthdateDuplicates
        instagramVerificationRequested
        platform
        stripeId
        totalRequirementsSubmitted
        tags
        appVersion
        noshowCredits
        tncVersion
        hasPendingNoshowCredits
        agencyProfileUrl
        freelancer
        reservationRewardClaimed
        instagramVerified
        points
        premium
        savedByVenue
        inviteCredits
        newInvitesAvailable
        invitesAvailable
        invitesReceived
        rating
        totalRatings
      }
      venueID
      venue {
        id
        marketName
        marketID
        marketCurrency
        type
        phone
        email
        name
        contactFirstname
        contactLastname
        contactPosition
        address1
        address2
        city
        postalCode
        stateProvince
        country
        countryCode
        dependentLocality
        timeZoneId
        website
        instagramHandle
        referredBy
        description
        sideLetter
        approvalDate
        device
        totalActiveOffers
        totalOffers
        totalFavourites
        totalSpots
        totalReservations
        totalCheckins
        totalContentSaved
        totalImpressions
        percentBooked
        totalReach
        totalNoshow
        distance
        lastSeen
        lastBrowser
        lastPlatform
        lastSynced
        createdAt
        updatedAt
        status
        offerReservationStatus
        stats
        enabledPushNotifications
        enabledLocationAccess
        offerUpdatedAt
        reservationUpdatedAt
        closedDates
        checkInRadius
        platform
        notes
        enabledEmailNotifications
        currency
        tags
        appVersion
        tncVersion
        newContentAvailable
        availableDownloads
        unreadNotificationCount
        masterVenueID
        views
        formattedAddress
        tipEnabled
        premium
      }
      venueName
      venueLocation {
        lat
        lon
      }
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      location {
        lat
        lon
      }
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      venuePicture {
        thumbnail
        small
        medium
        large
        video
      }
      offerPicture {
        thumbnail
        small
        medium
        large
        video
      }
      userPicture {
        thumbnail
        small
        medium
        large
        video
      }
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
    }
  }
`;

export const venueGetReservationApi = async (id: string): Promise<Reservation> => {
  const result = await executeGql<Reservation>(venueGetReservationQuery, { id });
  return processGqlResult<Reservation>('venueGetReservation', result);
};
