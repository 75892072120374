import * as React from 'react';
import { CalendarProps, Calendar as ReactBigCalendar, dayjsLocalizer } from 'react-big-calendar';

import { styled } from '@mui/material';

import dayjs from 'dayjs';

import { useAppSelector } from '../../../redux/hooks';
import Toolbar from '../Toolbar/Toolbar';
import '../styles/calendar.css';
import MonthEvent from './MonthEvent';
import { mapToCalendarEvent } from './mapper';

const djLocalizer = dayjsLocalizer(dayjs);

const Calendar = styled(ReactBigCalendar)(() => ({
  '.rbc-event': {
    backgroundColor: 'unset',
  },
}));

const OfferPreviewCalendar: React.FC = () => {
  const data = useAppSelector((state) => state.offer.value || []);

  const [date, setDate] = React.useState(new Date());

  const { components } = React.useMemo<Partial<CalendarProps>>(
    () => ({
      components: {
        toolbar: Toolbar,
        month: { event: MonthEvent },
      },
      showAllEvents: true,
    }),
    [],
  );

  const dayPropGetter = React.useCallback(
    () => ({
      style: {
        backgroundColor: 'white',
      },
    }),
    [],
  );

  const { events } = React.useMemo<Partial<CalendarProps>>(
    () => ({
      events: mapToCalendarEvent(data, date),
    }),
    [date, data],
  );

  return (
    <Calendar
      style={{ height: '100%' }}
      dayPropGetter={dayPropGetter}
      events={events}
      localizer={djLocalizer}
      views={['month']}
      popup
      onNavigate={(dt) => setDate(dt)}
      components={components}
    />
  );
};

export default OfferPreviewCalendar;
