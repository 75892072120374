import * as React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';

import TimePicker from '../../../TimePicker/TimePicker';

type TimeRangePickerProps = {
  startTime?: Dayjs;
  endTime?: Dayjs;
  onChange: (props: { startTime: Dayjs; endTime: Dayjs }) => void;
  shouldDisaledThePreviousTime?: boolean;
};

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({
  startTime,
  endTime,
  onChange,
  shouldDisaledThePreviousTime = false,
}) => {
  const [valueFrom, setValueFrom] = React.useState(dayjs(startTime));
  const [valueTo, setValueTo] = React.useState(dayjs(endTime));

  return (
    <Stack direction='row' spacing={4}>
      <Box>
        <Typography fontWeight='medium' marginBottom={1}>
          Start time
        </Typography>
        <TimePicker
          name='startTime'
          defaultValue={valueFrom}
          onSelectChange={(newValue) => {
            if (newValue) {
              setValueFrom(newValue);
              onChange({ startTime: newValue, endTime: valueTo });
            }
          }}
          shouldDisaledThePreviousTime={shouldDisaledThePreviousTime}
        />
      </Box>
      <Box>
        <Typography fontWeight='medium' marginBottom={1}>
          End time
        </Typography>
        <TimePicker
          name='endTime'
          defaultValue={valueTo}
          onSelectChange={(newValue) => {
            if (newValue) {
              setValueTo(newValue);
              onChange({ startTime: valueFrom, endTime: newValue });
            }
          }}
          shouldDisaledThePreviousTime={shouldDisaledThePreviousTime}
        />
      </Box>
    </Stack>
  );
};

export default TimeRangePicker;
