import { useContext } from 'react';

import { BackdropContext } from './Backdrop';

export const useBackdrop = () => {
  const context = useContext(BackdropContext);
  if (context === undefined) {
    throw new Error('useBackdrop must be used within a BackdropProvider');
  }

  return context;
};

export default useBackdrop;
