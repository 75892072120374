import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import { Link } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';

import LazyAvatar from '../../../components/Avatar/LazyAvatar/LazyAvatar';
import TrustedIcon from '../../../components/Icons/TrustedIcon/TrustedIcon';
import { OnChange } from './types';

const labelProps = { inputProps: { 'aria-label': 'Checkbox demo' } };

type ProfileCheckBoxProps = {
  avatarSrc: string;
  placeholderSrc: string;
  instagramVerified: boolean;
  lifeStyle: boolean;
} & CheckboxProps;

const FloatHeader = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
}));

const CheckBox = styled(MuiCheckbox)(({ checked, theme }) => ({
  color: '#FFFFFF',
  padding: 0,
  '.MuiSvgIcon-root': {
    fontSize: calculateSize(Boolean(checked), 2),
    [theme.breakpoints.only('md')]: {
      fontSize: calculateSize(Boolean(checked), 1.5),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: calculateSize(Boolean(checked), 1),
    },
  },
}));

const CheckBoxIcon: React.FC = () => (
  <Box
    display='flex'
    sx={{ color: '#fff', backgroundColor: '#BA8AFF', margin: 0.5, borderRadius: 1 }}
  >
    <CheckIcon />
  </Box>
);

const calculateSize = (checked: boolean, size: number) =>
  checked ? `${size}rem` : `${size * 1.25}rem`;

const ProfileCheckBox: React.FC<ProfileCheckBoxProps> = ({
  avatarSrc,
  placeholderSrc,
  instagramVerified,
  lifeStyle,
  onChange,
  checked,
  ...restProps
}) => {
  const handleOnChange = React.useCallback<OnChange>(
    (e, c) => {
      onChange && onChange(e, c);
    },
    [onChange],
  );

  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <Box component={Link} to={`/users/${restProps.value}`}>
        <AspectRatio>
          <LazyAvatar
            variant='rounded'
            src={avatarSrc}
            placeholderSrc={placeholderSrc}
            sx={{
              border: checked ? '4px solid #BA8AFF' : undefined,
            }}
          />
        </AspectRatio>
      </Box>

      <FloatHeader>
        {instagramVerified ? (
          <Box
            sx={{
              borderRadius: 50,
              display: 'flex',
              p: 0.8,
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            <TrustedIcon sx={{ fontSize: 24 }} />
          </Box>
        ) : null}

        <Box
          sx={{
            borderRadius: 50,
            display: 'flex',
            px: 2,
            py: 0.8,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: '#fff',
            fontSize: '13px',
            border: `1px solid ${lifeStyle ? '#1CAF7F' : '#B780FE'}`,
          }}
        >
          <span>{lifeStyle ? 'Lifestyle' : 'Beautypass Select'}</span>
        </Box>
        <Box component='label'>
          <CheckBox
            {...labelProps}
            {...restProps}
            checked={checked}
            onChange={handleOnChange}
            icon={<CheckBoxOutlineBlankOutlinedIcon />}
            checkedIcon={<CheckBoxIcon />}
          />
        </Box>
      </FloatHeader>
    </Box>
  );
};

export default ProfileCheckBox;
