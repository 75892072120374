import dayjs from 'dayjs';
import * as yup from 'yup';

import { Reservation } from '../../../../graphql/API';
import { getUTCDateTimeFromDateAndTimeInTimezone } from '../../../../pages/ReservationDetail/components/useDate';
import { getTimeInTimezoneInDayjs } from '../../../../utils/date';

// export const mapToEditReservationDateAndTimeForm = (data: Offer): FormValue => {};
export const editReservationDateAndTimeSchema = yup.object({
  date: yup.date().required().nullable(),
  effectiveStartDateTime: yup.date().required().nullable(),
  effectiveEndDateTime: yup.date().required().nullable(),
});

export type EditReservationDateAndTimeFormValue = yup.InferType<
  typeof editReservationDateAndTimeSchema
>;

const dayjsToDate = (date: dayjs.Dayjs, timezoneId?: string) =>
  new Date(date.toDate().toLocaleString('en-US', { timeZone: timezoneId || '' }));

export const mapToRservationDateAndTimeForm = (
  data: Reservation,
): EditReservationDateAndTimeFormValue => {
  const reserveDate = dayjs.utc(data?.effectiveStartDateTime);
  const effectiveStartTime = dayjs.utc(data?.effectiveStartDateTime).format('HH:mm');
  const effectiveEndTime = dayjs.utc(data?.effectiveEndDateTime).format('HH:mm');

  const timezoneId = data?.timeZoneId;

  const effectiveStartDateTime = getTimeInTimezoneInDayjs(effectiveStartTime, timezoneId);
  const effectiveEndDateTime = getTimeInTimezoneInDayjs(effectiveEndTime, timezoneId);

  return {
    date: reserveDate ? dayjsToDate(reserveDate, timezoneId || '') : null,
    effectiveStartDateTime: effectiveStartDateTime
      ? dayjsToDate(effectiveStartDateTime, timezoneId || '')
      : null,
    effectiveEndDateTime: effectiveEndDateTime
      ? dayjsToDate(effectiveEndDateTime, timezoneId || '')
      : null,
  };
};

export const mapReservationFormValue = (
  values: EditReservationDateAndTimeFormValue,
  timezoneId: string | null | undefined,
) => {
  const selectedDate = dayjs(values?.date).format('YYYY-MM-DD');
  const effectiveStartTime = dayjs(values?.effectiveStartDateTime).format('HH:mm');
  const effectiveEndTime = dayjs(values?.effectiveEndDateTime).format('HH:mm');

  const effectiveStartDateTime = getUTCDateTimeFromDateAndTimeInTimezone(
    selectedDate,
    effectiveStartTime,
    timezoneId || '',
  );

  const effectiveEndDateTime = getUTCDateTimeFromDateAndTimeInTimezone(
    selectedDate,
    effectiveEndTime,
    timezoneId || '',
  );

  return {
    effectiveStartDateTime: effectiveStartDateTime?.format(),
    effectiveEndDateTime: effectiveEndDateTime?.format(),
  };
};
