import * as React from 'react';

import BaseDialog, { BaseDialogProps } from '../Base';
import CollabsFilters, { CollabsFiltersProps } from './CollabsFilters';
import { PartialVenueListUsersFilter } from './types';

type CollabsFiltersDialogProps = {
  filters?: PartialVenueListUsersFilter;
  onFilterChange: CollabsFiltersProps['onChange'];
} & BaseDialogProps;

const CollabsFiltersDialog: React.FC<CollabsFiltersDialogProps> = ({
  filters,
  onFilterChange,
  ...restProps
}) => {
  return (
    <BaseDialog {...restProps}>
      <CollabsFilters onChange={onFilterChange} filters={filters} />
    </BaseDialog>
  );
};

export default CollabsFiltersDialog;
