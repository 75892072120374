import * as React from 'react';

import { LinearProgress } from '@mui/material';

import { ShowFnOutput, useModal } from 'mui-modal-provider';

import { venueGetCollabEstimateApi } from '../../../api/invites/venueGetCollabEstimateApi';
import { User } from '../../../graphql/API';
import { markHighlight } from '../../../redux/features/invite/inviteSlice';
import { useAppDispatch } from '../../../redux/hooks';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import ListSkeleton from '../../List/Base/ListSkeleton';
import BaseDialog, { BaseDialogProps } from '../Base';
import InviteDialog, { InviteDialogProps } from '../CreateInvite/InviteDialog';
import { ListFavourites } from './ListFavourites';
import useListVenueFavouritesUsersQuery from './hook';

const ListFavouritesUsersDialog: React.FC<BaseDialogProps> = (props) => {
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } =
    useListVenueFavouritesUsersQuery();
  const inviteModalRef = React.useRef<ShowFnOutput<InviteDialogProps>>();
  const [isEstimateLoading, setIsEstimateLoading] = React.useState(false);
  const { showModal } = useModal();
  const dispatch = useAppDispatch();

  const handleCreateInviteDialog = React.useCallback(
    async (user: User) => {
      if (!user) {
        return;
      }
      setIsEstimateLoading(true);
      const estimateResponse = await venueGetCollabEstimateApi({ userIDs: [Number(user.id)] });
      setIsEstimateLoading(false);
      inviteModalRef.current = showModal(InviteDialog, {
        title: 'Invite',
        users: [user],
        modalRef: inviteModalRef,
        premiumCredits: estimateResponse?.totalPremiumCredits || 0,
        nonPremiumCredits: estimateResponse?.totalNonPremiumCredits || 0,
        onSuccess: () => {
          dispatch(markHighlight(true));
        },
      });
    },
    [dispatch, showModal],
  );
  return (
    <BaseDialog width='624px' {...props}>
      <InfiniteScroll
        isLoading={isLoading}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      >
        {isLoading ? (
          <ListSkeleton avatarVariant='circular' />
        ) : (
          <ListFavourites users={data} onInviteHandle={handleCreateInviteDialog} />
        )}
      </InfiniteScroll>
      {isEstimateLoading && <LinearProgress />}
    </BaseDialog>
  );
};

export default ListFavouritesUsersDialog;
