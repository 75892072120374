import * as React from 'react';

import { Box, Typography } from '@mui/material';

import dayjs from 'dayjs';

import Button from '../../../Button/Button';
import { useCreateInvite } from '../context/CreateInvite.context';
import DateCalendar from './DateCalendar';
import TimeRangePicker from './TimeRangePicker';

const Step2: React.FC = () => {
  const { state, dispatch } = useCreateInvite();

  const currentDate = dayjs();

  const isCurrentDateFound = state.dates
    ? state.dates.some((date) => currentDate.isSame(date, 'day'))
    : false;

  return (
    <Box mt={4}>
      <Box display='flex' flexDirection='column' alignItems='center' marginY={2}>
        <Typography fontWeight='medium'>Select dates for your invitation,</Typography>
        <Typography fontWeight='medium'>members will be able to reserve only one date</Typography>
      </Box>

      <DateCalendar
        defaultValue={state.dates}
        onChange={(value) => dispatch({ type: 'update', payload: { dates: value ?? undefined } })}
      />

      <Box display='flex' justifyContent='space-between' alignItems='flex-end' marginTop={2}>
        <TimeRangePicker
          startTime={state.startTime}
          endTime={state.endTime}
          onChange={(payload) => dispatch({ type: 'update', payload })}
          shouldDisaledThePreviousTime={isCurrentDateFound}
        />

        <Button
          id='btn-offer-step2'
          sx={{ px: 5 }}
          variant='contained'
          disabled={!state.dates?.length}
          onClick={() => dispatch({ type: 'next' })}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default Step2;
