import * as React from 'react';

import { Box, Divider, Stack, Switch, SwitchProps, Typography, debounce } from '@mui/material';

import useSubscriptionAccess from '../../../hooks/useSubscriptionAccess';
import { ChipOptionsProps } from '../../ChipOptions/ChipOptions';
import ChipOptionContainer from './ChipOptionContainer';
import {
  followerOptions,
  makeDefaultValue,
  makeFiltersOutput,
  memberCategoryOptions,
  modelOptions,
  verifiedOptions,
} from './options';
import { PartialVenueListUsersFilter } from './types';

export type CollabsFiltersProps = {
  filters?: PartialVenueListUsersFilter;
  onChange?: (filters: PartialVenueListUsersFilter | undefined) => void;
};

type SwitchOnChange = Exclude<SwitchProps['onChange'], undefined>;
type ChipOnChange = ChipOptionsProps['onChange'];

const CollabsFilters: React.FC<CollabsFiltersProps> = ({ filters: defaultFilters, onChange }) => {
  const subscriptionAccess = useSubscriptionAccess();

  const [filters, setFilters] = React.useState<PartialVenueListUsersFilter | undefined>(
    defaultFilters,
  );

  const debounceOnChange = React.useMemo(
    () => debounce((f: PartialVenueListUsersFilter) => onChange && onChange(f), 500),
    [onChange],
  );

  const { fOptions, vOptions } = React.useMemo(
    () => ({
      fOptions: followerOptions(subscriptionAccess),
      vOptions: verifiedOptions(subscriptionAccess),
    }),
    [subscriptionAccess],
  );

  const handleOnChange = React.useCallback<ChipOnChange>(
    (e) => {
      const outputFilters = makeFiltersOutput(filters)[e.name](e.value);
      setFilters(outputFilters);
      debounceOnChange(outputFilters);
    },
    [filters, debounceOnChange],
  );

  const handleSwitchOnChange = React.useCallback<SwitchOnChange>(
    (_e, checked) => {
      const outputFilters = makeFiltersOutput(filters)['market'](checked);
      setFilters(outputFilters);
      debounceOnChange(outputFilters);
    },
    [filters, debounceOnChange],
  );

  return (
    <Box>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography fontWeight='bold' fontSize={'15px'}>Show me members in all market</Typography>
        <Switch
          onChange={handleSwitchOnChange}
          checked={!filters?.market}
          value=''
          inputProps={{ 'aria-label': 'market switch' }}
        />
      </Box>

      <Divider sx={{ my: 4 }} />

      <Stack spacing={4}>
        <ChipOptionContainer
          defaultValue={makeDefaultValue['follower'](filters)}
          name='follower'
          label='Followers'
          options={fOptions}
          onChange={handleOnChange}
        />

        <ChipOptionContainer
          defaultValue={makeDefaultValue['lifestyle'](filters)}
          name='lifestyle'
          label='Member category'
          options={memberCategoryOptions}
          onChange={handleOnChange}
        />

        <ChipOptionContainer
          defaultValue={makeDefaultValue['model'](filters)}
          name='model'
          label='Models'
          options={modelOptions}
          onChange={handleOnChange}
        />

        <ChipOptionContainer
          verifiedIcon
          defaultValue={makeDefaultValue['instagramVerified'](filters)}
          name='instagramVerified'
          label='Verified on Instagram'
          options={vOptions}
          onChange={handleOnChange}
        />
      </Stack>
    </Box>
  );
};

export default CollabsFilters;
