import * as React from 'react';

import { useAppSelector } from '../../../redux/hooks';
import { extractSubscriptionPlan } from '../common/helper';
import { plans } from '../types';
import { SubscriptionContext } from './Subscription.context';
import { initialState, reducer } from './Subscription.reducer';

const defaultPlan = 'basic_venue_1';

const normaliseSubscriptionName = (s: string) => s.split('_').slice(0, 3).join('_');

const SubscriptionPrivider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isLoading, subscription } = useAppSelector((state) => state.subscription.value);

  const [state, dispatch] = React.useReducer(reducer, initialState());

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    const normalisedSubscription = subscription?.sku
      ? normaliseSubscriptionName(subscription?.sku)
      : undefined;

    const [plan, , billingCycle] = extractSubscriptionPlan(normalisedSubscription ?? defaultPlan);

    const isWebPlatform = !subscription || subscription?.platform === 'web';

    dispatch({
      type: 'update-state',
      payload: {
        plan,
        billingCycle,
        currentSubscription: normalisedSubscription,
        isWebPlatform,
      },
    });
  }, [isLoading, subscription]);

  React.useEffect(() => {
    if (!state.currentSubscription) {
      return;
    }

    const [currentPlan, , currentBillingCycle] = extractSubscriptionPlan(state.currentSubscription);

    const bc =
      state.billingCycle === currentBillingCycle
        ? 0
        : Number(state.billingCycle) > Number(currentBillingCycle)
          ? 1
          : 2;

    const p = plans.indexOf(currentPlan);

    dispatch({
      type: 'update-state',
      payload: {
        logicPlan: `${bc}:${p}`,
      },
    });
  }, [state.billingCycle, state.currentSubscription]);

  return (
    <SubscriptionContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

53740517;
export default SubscriptionPrivider;
