import * as React from 'react';

import { Box } from '@mui/material';

import { useModal } from 'mui-modal-provider';

import AccessRequestDescDialog from '../../components/Dialogs/AccessRequestDesc/AccessRequestDescDialog';
import ListAccessRequestDialog from '../../components/Dialogs/ListAccessRequest/ListAccessRequestDialog';
import PageTitle from '../../components/PageTitle/PageTitle';
import AccessRequestTable from '../../components/Tables/AccessRequest/AccessRequest';

const AccessRequest: React.FC = () => {
  const { showModal } = useModal();

  const handleClickDesc = React.useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault();

      showModal(AccessRequestDescDialog, { width: '600px', height: 'unset' });
    },
    [showModal],
  );

  const handleClickList = React.useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault();

      showModal(ListAccessRequestDialog, {
        titleComponent: <Box textAlign='center'>Access list</Box>,
        width: '900px',
      });
    },
    [showModal],
  );

  return (
    <Box sx={{ backgroundColor: 'white', flex: 1, p: 4 }}>
      <PageTitle
        title='Access Request'
        sideComponent={
          <Box
            component='a'
            href='#'
            onClick={handleClickDesc}
            sx={{ fontSize: '0.8rem', color: 'black' }}
          >
            What is access request?
          </Box>
        }
      />

      <Box mt={2}>
        <AccessRequestTable height='calc(100vh - 245px)' handleClickList={handleClickList} />
      </Box>
    </Box>
  );
};

export default AccessRequest;
