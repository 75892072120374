import { configureStore } from '@reduxjs/toolkit';

import inviteReducer from './features/invite/inviteSlice';
import offerReducer from './features/offer/offerSlice';
import reservationReducer from './features/reservation/reservationSlice';
import subscriptionReducer from './features/subscription/subscriptionSlice';
import venueReducer from './features/venue/venueSlice';

export const store = configureStore({
  reducer: {
    offer: offerReducer,
    venue: venueReducer,
    reservation: reservationReducer,
    invite: inviteReducer,
    subscription: subscriptionReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
