import * as React from 'react';

import { Box, Chip, Typography } from '@mui/material';

import Button from '../../../components/Button/Button';
import Paper from './Paper';
import { mapCreditOption } from './options';
import { CreditOption } from './types';

type CreditOptionBoxProps = {
  option: CreditOption;
  onClick: React.ChangeEventHandler<HTMLButtonElement>;
};

const CreditOptionBox: React.FC<CreditOptionBoxProps> = ({ option, onClick }) => {
  const mapped = React.useMemo(() => {
    if (option.creditType) {
      return mapCreditOption[option.creditType];
    }

    return undefined;
  }, [option.creditType]);

  return (
    <Paper
      sx={{
        flexDirection: 'column',
        boxShadow: '0px 0px 30px #0000000D',
        borderRadius: '12px',
        border: '1px solid #F1F1F1',
        padding: '24px',
      }}
    >
      <Box py={4} position='relative' width='100%'>
        {option.promotion && (
          <Chip
            label={option.promotion}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              background: mapped?.background,
              height: 26,
              color: 'white',
              borderRadius: 1,
            }}
          />
        )}
      </Box>

      <Typography variant='h4' fontWeight='bold'>
        {option.credit}
      </Typography>
      <Typography fontWeight='medium'>Credits</Typography>
      <Typography
        component='div'
        color={mapped?.color}
        fontStyle='italic'
        fontSize='18px'
        fontWeight='500'
        sx={{
          pt: 2,
          pb: 10,
        }}
      >
        {mapped?.label ?? <div>&nbsp;</div>}
      </Typography>
      <Button
        id={`btn-credit-${option.credit}`}
        color='primary'
        fullWidth
        variant='contained'
        value={option.credit}
        onClick={onClick}
        sx={{
          paddingY: '12px',
        }}
      >
        Buy for ${option.price}
      </Button>
    </Paper>
  );
};

export default CreditOptionBox;
