import * as React from 'react';

import { User } from '../../../graphql/API';
import BaseDialog, { BaseDialogProps } from '../Base';
import CreateInvite from './CreateInvite';
import CreateInviteProvider, { CreateInviteProviderProps } from './context/CreateInvite.provider';

export type InviteDialogProps = {
  users: User[];
  premiumCredits?: number;
  nonPremiumCredits?: number;
  onSuccess: CreateInviteProviderProps['onSuccess'];
  modalRef: CreateInviteProviderProps['modalRef'];
} & BaseDialogProps;

const InviteDialog: React.FC<InviteDialogProps> = ({
  users,
  premiumCredits,
  nonPremiumCredits,
  modalRef,
  onSuccess,
  ...restProps
}) => {
  return (
    <BaseDialog height='654px' {...restProps}>
      <CreateInviteProvider
        users={users}
        premiumCredits={premiumCredits}
        nonPremiumCredits={nonPremiumCredits}
        onSuccess={onSuccess}
        modalRef={modalRef}
      >
        <CreateInvite />
      </CreateInviteProvider>
    </BaseDialog>
  );
};

export default InviteDialog;
