import React from 'react';

import { Alert, Box, Typography } from '@mui/material';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useMutation } from '@tanstack/react-query';

import Button from '../../../components/Button/Button';
import useBackdrop from '../../../contexts/Backgrop/useBackdrop';

type CheckoutProps = {
  credits?: number;
};

const Checkout: React.FC<CheckoutProps> = ({ credits }) => {
  const { toggleBackdrop } = useBackdrop();
  const [isReady, setIsready] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const stripe = useStripe();
  const elements = useElements();

  const paymentMutation = useMutation({
    mutationFn: async () => {
      if (!stripe || !elements) {
        return;
      }

      const { origin, pathname } = window.location;
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${origin}${pathname}?credits=${credits}`,
        },
      });

      if (result.error) {
        throw result.error;
      }
    },
    onMutate: () => {
      toggleBackdrop(true);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => {
      toggleBackdrop(false);
    },
  });

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();

    paymentMutation.mutate();
  };

  return (
    <Box component='form' width='100%' paddingX={8} paddingTop={4} onSubmit={handleSubmit}>
      {error && (
        <Alert variant='filled' severity='error'>
          <Typography>{error}</Typography>
        </Alert>
      )}

      <Box paddingY={4}>
        <PaymentElement
          options={{ layout: { type: 'accordion' } }}
          onReady={() => {
            setIsready(true);
          }}
        />
      </Box>

      <Button size='large' variant='contained' type='submit' disabled={!isReady}>
        Submit
      </Button>
    </Box>
  );
};

export default Checkout;
