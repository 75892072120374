import * as React from 'react';

import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';

import dayjs, { Dayjs } from 'dayjs';
import { useField } from 'formik';

type MuiDatePickerPropsWithType = MuiDatePickerProps<Dayjs>;

type DatePickerProps = { name: string } & MuiDatePickerPropsWithType;

type OnChange = Exclude<MuiDatePickerPropsWithType['onChange'], undefined>;

export type SlotProps = MuiDatePickerPropsWithType['slotProps'];

const DatePicker: React.FC<DatePickerProps> = ({
  name,
  label,
  minDate,
  slotProps,
  ...restProps
}) => {
  const [field, meta, helper] = useField(name || '');

  const handleChange = React.useCallback<OnChange>(
    (v) => {
      const vv = v ? v.toDate() : null;
      helper.setValue(vv);
    },
    [helper],
  );

  React.useEffect(() => {
    if (!field.value || !minDate) {
      return;
    }

    if (field.value && dayjs(field.value).isBefore(minDate)) {
      helper.setValue(minDate);
    }
  }, [name, minDate, field, helper]);

  return (
    <MuiDatePicker
      label={label}
      value={field.value ? dayjs(field.value) : null}
      onChange={handleChange}
      slotProps={{
        ...slotProps,
        textField: {
          ...slotProps?.textField,
          error: meta.touched && Boolean(meta.error),
          helperText: meta.touched ? meta.error : '',
        },
      }}
      minDate={minDate}
      {...restProps}
    />
  );
};

export default DatePicker;
