import { createBrowserRouter } from 'react-router-dom';

import { BrandRoute } from '../components/Layout/BrandRoute';
import Layout from '../components/Layout/Layout';
import AccessRequest from '../pages/AccessRequest/AccessRequest';
import Collabs from '../pages/Collabs/Collabs';
import CreateOffer from '../pages/CreateUpdateOffer/CreateOffer';
import EditOffer from '../pages/CreateUpdateOffer/EditOffer';
import Credit from '../pages/Credit/Credit';
import Dashboard from '../pages/Dashboard/Dashboard';
import EmailSend from '../pages/EmailSend/EmailSend';
import Error from '../pages/Error/Error';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Login from '../pages/Login/Login';
import Offer from '../pages/Offer/Offer';
import OfferDetail from '../pages/OfferDetail/OfferDetail';
import Profile from '../pages/Profile/Profile';
import Reservation from '../pages/Reservation/Reservation';
import ReservationContent from '../pages/ReservationContent/ReservationContent';
import ReservationDetail from '../pages/ReservationDetail/ReservationDetail';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import SubscriptionPlans from '../pages/SubscriptionPlans/SubscriptionPlans';
import UserProfile from '../pages/UserProfile/UserProfile';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        path: '',
        element: (
          <BrandRoute>
            <Dashboard />
          </BrandRoute>
        ),
      },
      {
        path: 'collabs',
        element: (
          <BrandRoute>
            <Collabs />
          </BrandRoute>
        ),
      },
      {
        path: 'reservations',
        element: (
          <BrandRoute>
            <Reservation />
          </BrandRoute>
        ),
      },
      {
        path: 'reservations/:reservationId',
        element: (
          <BrandRoute>
            <ReservationDetail />
          </BrandRoute>
        ),
      },
      {
        path: 'offers',
        element: (
          <BrandRoute>
            <Offer />
          </BrandRoute>
        ),
      },
      {
        path: 'offers/create',
        element: (
          <BrandRoute>
            <CreateOffer />
          </BrandRoute>
        ),
      },
      {
        path: 'offers/:offerId/edit',
        element: (
          <BrandRoute>
            <EditOffer />
          </BrandRoute>
        ),
      },
      {
        path: 'offers/:offerId',
        element: (
          <BrandRoute>
            <OfferDetail />
          </BrandRoute>
        ),
      },
      {
        path: 'credits',
        element: <Credit />,
      },
      {
        path: 'plans',
        element: <SubscriptionPlans />,
      },
      {
        path: 'content',
        element: (
          <BrandRoute>
            <ReservationContent />
          </BrandRoute>
        ),
      },
      {
        path: 'profile',
        element: (
          <BrandRoute>
            <Profile />
          </BrandRoute>
        ),
      },
      {
        path: 'users/:userId',
        element: (
          <BrandRoute>
            <UserProfile />
          </BrandRoute>
        ),
      },
      {
        path: 'access-requests',
        element: (
          <BrandRoute>
            <AccessRequest />
          </BrandRoute>
        ),
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/email-send',
    element: <EmailSend />,
  },
  {
    path: '/reset-password/:id',
    element: <ResetPassword />,
  },
]);

export default router;
