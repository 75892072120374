import React from 'react';

import { Box } from '@mui/material';

import { OfferCategory } from '../../../../graphql/API';
import { useAppSelector } from '../../../../redux/hooks';
import icons from '../../../../themes/icons';
import { OfferType } from '../schema';
import { OfferSelectionButton } from './OfferSelectionButton';

type OfferTypeEvent = {
  title: string;
  description: string;
  specialText: string;
  value: string;
  category: string;
  icon: JSX.Element;
};

const OFFER_TYPES: OfferTypeEvent[] = [
  {
    title: 'Recurring offer',
    description: 'For all Beautypass users',
    specialText: '',
    value: 'public',
    category: '',
    icon: <icons.RecruitOfferIcon />,
  },
  {
    title: 'Collab',
    description: 'You choose which Beautypass users to invite',
    specialText: '',
    value: 'private',
    category: OfferCategory.unknown,
    icon: <icons.CollabOfferIcon />,
  },
  {
    title: 'Special event',
    description: 'One-time offer for all Beautypass users',
    specialText: 'One time offer',
    value: 'public',
    category: OfferCategory.event,
    icon: <icons.SpecialOfferIcon />,
  },
];

type Props = {
  resetForm: (type: OfferType, category: OfferCategory) => void;
  offerTypeIndex?: number;
};

export const OfferTypes: React.FC<Props> = ({ resetForm, offerTypeIndex }) => {
  const venueState = useAppSelector((state) => state.venue.value);
  const [offerTypes, setOfferTypes] = React.useState<OfferTypeEvent[]>([]);
  const [activeIndex, setActiveIndex] = React.useState(0);

  React.useEffect(() => {
    if (offerTypeIndex) {
      setActiveIndex(offerTypeIndex);
    }
  }, [offerTypeIndex]);

  React.useEffect(() => {
    if (
      venueState?.type === OfferCategory.event ||
      venueState?.type === OfferCategory.boutique ||
      venueState?.type === OfferCategory.experience
    ) {
      setOfferTypes(OFFER_TYPES);
    } else {
      setOfferTypes(OFFER_TYPES.slice(0, 2));
    }
  }, [venueState]);

  const handleChange = (index: number, offerType: OfferTypeEvent) => {
    setActiveIndex(index);
    let category: OfferCategory = OfferCategory.beauty;
    if (offerType.category) category = offerType.category as OfferCategory;
    else category = OfferCategory.beauty;
    resetForm(offerType.value as OfferType, category);
  };

  return (
    <Box display={`flex`} flexDirection={`column`} gap={`15px`}>
      {offerTypes.map((offer, index) => (
        <OfferSelectionButton
          title={offer.title}
          description={offer.description}
          specialText={offer.specialText}
          icon={offer.icon}
          active={index === activeIndex}
          handleChange={() => {
            handleChange(index, offer);
          }}
        />
      ))}
    </Box>
  );
};
