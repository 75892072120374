import { useMemo } from 'react';

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { venueListUsersApi } from '../../api/users/venueListUsersApi';
import { User, VenueListUsersFilter } from '../../graphql/API';
import { QueryResult } from './types';

const PAGE_LIMIT = 50;

const useListUserQuery = (
  venueId: string | undefined,
  filters?: Partial<VenueListUsersFilter>,
): QueryResult<User[]> => {
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery<User[]>({
    initialPageParam: 0,
    queryKey: ['list-user', venueId, filters],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, venueID, queryFilters] = queryKey as [string, string, Partial<VenueListUsersFilter>];
      const offset = <number>pageParam * PAGE_LIMIT;

      return venueListUsersApi({
        ...queryFilters,
        market: queryFilters?.market ?? false,
        minFollowers: queryFilters?.minFollowers || 0,
        limit: PAGE_LIMIT,
        offset,
        venueID: Number(venueID),
      });
    },
    getNextPageParam: (lastGroup, groups) =>
      lastGroup.length === PAGE_LIMIT ? groups.length : undefined,
    placeholderData: keepPreviousData,
    enabled: !!(venueId && filters?.salt),
    refetchOnWindowFocus: false,
  });

  const flatData = useMemo(() => data?.pages.flatMap((x) => x) ?? [], [data]);

  return { data: flatData, isFetching, isLoading, fetchNextPage, hasNextPage };
};

export default useListUserQuery;
