import { createContext, useContext } from 'react';

import { UseMutationResult } from '@tanstack/react-query';

import { VenueCreateInviteInput } from '../../../../graphql/API';
import { Action, State } from './types';

export enum Step {
  OfferSelection,
  DateSelection,
  Summary,
  Success,
}

type CreateInviteContextProps = {
  state: State;
  dispatch: React.Dispatch<Action>;

  mutation: UseMutationResult<unknown, unknown, VenueCreateInviteInput>;
  submitForm: () => void;
};

export const CreateInviteContext = createContext<CreateInviteContextProps>(
  {} as CreateInviteContextProps,
);

export const useCreateInvite = () => useContext(CreateInviteContext);
