import * as React from 'react';

import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import List from '../../List/Base/List';
import ListSkeleton from '../Base/ListSkeleton';
import { mapToInviteList } from '../Base/mapper';
import useInviteQuery from './hooks';

const ListInvite: React.FC = () => {
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useInviteQuery();

  return (
    <InfiniteScroll
      isLoading={isLoading}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
    >
      {isLoading ? (
        <ListSkeleton avatarVariant='circular' />
      ) : (
        <List items={mapToInviteList(data)} />
      )}
    </InfiniteScroll>
  );
};

export default ListInvite;
