import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ChipOptions, { ChipOptionsProps } from '../../ChipOptions/ChipOptions';

type ChipOptionContainerProps = {
  label: string;
  verifiedIcon?: boolean;
} & ChipOptionsProps;

const ChipOptionContainer: React.FC<ChipOptionContainerProps> = ({
  label,
  verifiedIcon = false,
  ...restProps
}) => {
  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} sx={{ mb: 1 }} gap={'5px'}>
        <Typography component='div' variant='caption' fontWeight='bold'>
          {label}
        </Typography>
        {verifiedIcon && <img src='/png/verified@2x.png' alt='verified-icon' height={16} width={16} />}
      </Box>
      <ChipOptions {...restProps} />
    </Box>
  );
};

export default ChipOptionContainer;
