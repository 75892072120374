import * as React from 'react';
import { Link } from 'react-router-dom';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import {
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  debounce,
} from '@mui/material';

import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import { ShowFnOutput, useModal } from 'mui-modal-provider';

import { venueGetCollabEstimateApi } from '../../api/invites/venueGetCollabEstimateApi';
import Button from '../../components/Button/Button';
import SavedButton from '../../components/Button/SavedButton';
import { InviteDialog } from '../../components/Dialogs';
import CollabsFiltersDialog from '../../components/Dialogs/CollabsFilters/CollabsFiltersDialog';
import { InviteDialogProps } from '../../components/Dialogs/CreateInvite/InviteDialog';
import ListInviteDialog from '../../components/Dialogs/ListInvite/ListInviteDialog';
import TitleWithIcon from '../../components/Dialogs/TitleWithIcon';
import SvgIcon from '../../components/Icons';
import PageTitle from '../../components/PageTitle/PageTitle';
import { User, VenueGetCollabEstimateFilter, VenueListUsersFilter } from '../../graphql/API';
import useSubscriptionAccess from '../../hooks/useSubscriptionAccess';
import { markHighlight } from '../../redux/features/invite/inviteSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { extractSubscriptionPlan } from '../SubscriptionPlans/common/helper';
import InviteIcon from './InviteIcon';
import InviteSentButton from './components/InviteSentButton';
import { CollabReducer } from './reducer';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';

type HeaderProps = {
  reducer: CollabReducer;
  inScroll: boolean;
};

type CountProps = {
  count: number;
};
const Count: React.FC<CountProps> = ({ count }) => (
  <Box component='span' sx={{ color: count > 0 ? '#BA8AFF' : undefined }}>
    {count}
  </Box>
);

const calcCost = (user: User | undefined, credit1Only: boolean | undefined) => {
  if (credit1Only) {
    return user?.premium ? 0 : 1;
  }

  return user?.inviteCredits ?? 0;
};

const Header: React.FC<HeaderProps> = ({ reducer, inScroll }) => {
  const subscriptionAccess = useSubscriptionAccess();
  const { subscription } = useAppSelector((state) => state.subscription.value);
  const [collabState, dispatch] = reducer;
  const {
    filters,
    selected: { users: selectedUsers },
  } = collabState;

  const inviteModalRef = React.useRef<ShowFnOutput<InviteDialogProps>>();

  const { showModal } = useModal();

  const highlight = useAppSelector((state) => state.invite.highlight);
  const appDispatch = useAppDispatch();
  const [searchText, setSearchText] = useLocalStorageState('collab-search-text', '');

  const { data: venueCollabEstimateData, isFetching: veneuCollabEstimateIsFetching } = useQuery({
    queryKey: ['venueGetCollabEstimate', { userIDs: selectedUsers.map((user) => Number(user.id)) }],
    queryFn: async ({ queryKey }) => {
      const [, filters] = queryKey as [string, VenueGetCollabEstimateFilter];

      return venueGetCollabEstimateApi({ userIDs: filters.userIDs });
    },
    refetchOnWindowFocus: false,
    enabled: selectedUsers.length > 0,
  });

  const searchDebounce = React.useMemo(
    () =>
      debounce(
        (searchText: string) => dispatch({ type: 'SET_FILTERS', payload: { searchText } }),
        500,
      ),
    [dispatch],
  );

  React.useEffect(() => {
    searchDebounce(searchText);
  }, [searchText, searchDebounce]);

  const subscriptionPlan = React.useMemo(() => {
    const [plan] = extractSubscriptionPlan(subscription?.sku ?? '');

    return plan;
  }, [subscription]);

  const selected = React.useMemo(
    () => ({
      count: selectedUsers.length,
      cost:
        subscriptionPlan === 'plus'
          ? venueCollabEstimateData?.totalPremiumCredits || 0
          : venueCollabEstimateData?.totalNonPremiumCredits || 0,
    }),
    [selectedUsers, subscriptionPlan, venueCollabEstimateData],
  );

  const handleCreateInviteDialog = React.useCallback(() => {
    inviteModalRef.current = showModal(InviteDialog, {
      title: 'Invite',
      users: selectedUsers,
      modalRef: inviteModalRef,
      premiumCredits: venueCollabEstimateData?.totalPremiumCredits || 0,
      nonPremiumCredits: venueCollabEstimateData?.totalNonPremiumCredits || 0,
      onSuccess: () => {
        appDispatch(markHighlight(true));
        dispatch({ type: 'RESET_SELECTED_USERS' });
      },
    });
  }, [selectedUsers, showModal, dispatch, appDispatch, venueCollabEstimateData]);

  const handleFilterDialog = React.useCallback(
    () =>
      showModal(CollabsFiltersDialog, {
        titleComponent: <TitleWithIcon title='Filters' icon={<TuneOutlinedIcon />} />,
        onFilterChange: (f) => dispatch({ type: 'SET_FILTERS', payload: f }),
        filters,
      }),
    [filters, dispatch, showModal],
  );

  const handleListInviteDialog = React.useCallback(() => {
    showModal(ListInviteDialog, {
      titleComponent: <TitleWithIcon title='Invites sent' icon={<SvgIcon name='InviteSent' />} />,
    });

    appDispatch(markHighlight(false));
  }, [showModal, appDispatch]);

  const handleTextOnChange = React.useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => setSearchText(e.target.value),
    [setSearchText],
  );

  const handleClick = React.useCallback(() => {
    dispatch({ type: 'SET_FILTERS', payload: { saved: filters?.saved ? undefined : true } });
  }, [filters, dispatch]);

  const isAnyFilterActive = React.useCallback(
    (filters: Partial<VenueListUsersFilter> | undefined) => {
      return (
        filters &&
        ((filters['minFollowers'] && filters['minFollowers'] > 0) ||
          filters['lifestyle'] !== undefined ||
          filters['modelType'] !== undefined ||
          filters['instagram'] !== undefined)
      );
    },
    [],
  );

  const handleClearSearchText = React.useCallback(() => {
    setSearchText('');
  }, [setSearchText]);

  return (
    <Box
      sx={{
        position: 'sticky',
        backgroundColor: 'white',
        p: 4,
        ...(inScroll ? { boxShadow: '0px 1px 20px #00000026' } : undefined),
      }}
    >
      <PageTitle
        title='Collabs'
        rightComponent={
          <Stack direction='row' spacing={2}>
            <SavedButton onClick={handleClick} active={Boolean(filters?.saved)}>
              Saved
            </SavedButton>
            <Button
              id='btn-filter'
              startIcon={
                <TuneOutlinedIcon htmlColor={isAnyFilterActive(filters) ? '#765BC6' : ''} />
              }
              onClick={handleFilterDialog}
            >
              <span style={{ color: isAnyFilterActive(filters) ? '#765BC6' : '' }}>Filters</span>
            </Button>
            <InviteSentButton
              id='btn-list-invite'
              highlight={highlight}
              onClick={handleListInviteDialog}
            />
            <Stack direction='row' spacing={1}>
              <OutlinedInput
                id='outlined-adornment-amount'
                placeholder='Search a member...'
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchText && (
                    <InputAdornment position='end'>
                      <ClearIcon
                        cursor='pointer'
                        onClick={handleClearSearchText}
                      />
                    </InputAdornment>
                  )
                }
                defaultValue={filters?.searchText}
                value={searchText}
                onChange={handleTextOnChange}
                size='small'
              />
              <Button
                id='btn-create-invite'
                variant='contained'
                disabled={!selectedUsers.length}
                sx={{ width: 140 }}
                onClick={handleCreateInviteDialog}
                startIcon={<InviteIcon active={Boolean(selected.count)} />}
              >
                Invite (<Count count={selected.count} />)
              </Button>
            </Stack>
          </Stack>
        }
      />

      <Typography component={Stack} spacing={3} direction='row' mt={1} alignItems={'center'}>
        <Box component='span' fontWeight='medium'>
          {selected.count} Selected member
        </Box>
        <Button
          sx={{
            p: 0,
            lineHeight: 'unset',
            minWidth: 'unset',
            textDecoration: 'underline',
            ':hover': { backgroundColor: 'unset', textDecoration: 'underline' },
          }}
          onClick={() => {
            dispatch({ type: 'RESET_SELECTED_USERS' });
            dispatch({ type: 'RESET_SELECTED_FILTERS' });
          }}
        >
          Reset
        </Button>

        <Divider orientation='vertical' flexItem />
        <Box component='span'>
          Total cost:{' '}
          {veneuCollabEstimateIsFetching ? (
            <CircularProgress size={14} sx={{ color: '#a9a9a9' }} />
          ) : (
            <b>{selected.cost} credits</b>
          )}
        </Box>
        {subscriptionPlan === 'plus' ? null : (
          <>
            {venueCollabEstimateData?.totalPremiumCredits &&
              venueCollabEstimateData?.totalNonPremiumCredits &&
              venueCollabEstimateData.totalPremiumCredits <
                venueCollabEstimateData.totalNonPremiumCredits && (
                <Box
                  sx={{
                    paddingY: '6px',
                    paddingX: '10px',
                    border: '1px solid #E28DDE',
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                  }}
                >
                  <Typography
                    fontSize='15px'
                    color='#a9a9a9'
                    sx={{ textDecoration: 'line-through' }}
                  >
                    {selected.cost} credits
                  </Typography>
                  <Typography fontSize='15px'>
                    {veneuCollabEstimateIsFetching ? (
                      <CircularProgress size={14} sx={{ color: '#E28DDE' }} />
                    ) : (
                      venueCollabEstimateData?.totalPremiumCredits
                    )}
                    &nbsp;credits with
                  </Typography>
                  <Box display={'flex'} alignItems={'center'} gap={'4px'}>
                    <SvgIcon name='Plus' sx={{ fill: 'url(#linear-gradient)' }} fontSize='small' />
                    <Typography
                      component={Link}
                      fontSize='15px'
                      color='#E28DDE'
                      fontWeight={'600'}
                      sx={{ textDecoration: 'underline' }}
                      to={'/plans'}
                    >
                      Plus
                    </Typography>
                  </Box>
                </Box>
              )}
          </>
        )}
      </Typography>
    </Box>
  );
};

export default Header;

const CrossBtn = styled.span`
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;