import * as React from 'react';

import { Checkbox, CheckboxProps, InputAdornment } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

import { useField } from 'formik';

import { CurrencySign } from '../../../components/CurrencySign/CurrencySign';
import FormikNumberField from '../../../components/Formiks/NumberField/NumberField';
import FormikTextField from '../../../components/Formiks/TextField/TextField';

type OnChange = Exclude<CheckboxProps['onChange'], undefined>;

type Props = {
  currencySign?: string | null;
} & TextFieldProps;

const TextFieldWithCheckbox: React.FC<Props> = ({ currencySign, name, ...restProps }) => {
  const [field, , helper] = useField(name ?? '');
  const [checked, setChecked] = React.useState<boolean>(Boolean(field.value));

  React.useEffect(() => {
    if (!field.value) setChecked(false);
    else setChecked(true);
  }, [field]);

  const handleChange = React.useCallback<OnChange>(
    (_, checked) => {
      setChecked(checked);

      if (!checked) {
        helper.setValue('');
      }
    },
    [helper, setChecked],
  );

  return (
    <FormikTextField
      name={name}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Checkbox checked={checked} onChange={handleChange} />
            {currencySign && <CurrencySign currency={currencySign} />}
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};

export default TextFieldWithCheckbox;
