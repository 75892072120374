import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const LinkProfile: React.FC<{ url: string; label: string; target?: string; fontSize?: string, fontWeight?: string, hoverTextDecoration?:string }> = ({
    url,
    label,
    target,
    fontSize = '19px',
    fontWeight,
    hoverTextDecoration
  }) => (
    <Box
      component={Link}
      to={url}
      fontSize={fontSize}
      fontWeight={fontWeight}
      sx={{
        textDecoration: 'none',
        ':hover': {
          textDecoration: hoverTextDecoration,
        },
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#111',
      }}
      target={target}
    >
      {label}
    </Box>
  );

export default LinkProfile