import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogContent, IconButton } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

export type BaseDialogProps = {
  titleComponent?: React.ReactNode;
  sideComponent?: React.ReactNode;
  hideHeader?: boolean;
  height?: string;
  width?: string;
  padding?: string;
} & DialogProps;

export type BaseDialogWithChildrenProps<T = unknown> = {
  childrenProps: T;
} & BaseDialogProps;

const BaseDialog: React.FC<React.PropsWithChildren<BaseDialogProps>> = ({
  title,
  titleComponent,
  sideComponent,
  onClose,
  open,
  children,
  hideHeader,
  width,
  height,
  sx,
  padding,
  ...restProps
}) => {
  React.useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Escape':
          onClose && onClose({}, 'escapeKeyDown');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', onKeyPress);

    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [onClose]);

  const handleClose = React.useCallback(() => onClose && onClose({}, 'backdropClick'), [onClose]);

  const renderChild = React.useCallback(() => {
    return (
      <>
        {!hideHeader && (
          <DialogTitle>
            {titleComponent || title}
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.primary.dark,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
      </>
    );
  }, [hideHeader, children, titleComponent, title, handleClose]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth='md'
      sx={{
        '.MuiDialog-paper': {
          height: height ?? '600px',
          width,
          maxWidth: width ? '1200px' : '900px',
          padding,
        },
        ...sx,
      }}
      {...restProps}
    >
      {sideComponent ? (
        <Box display='flex' flexDirection='row'>
          <Box>{sideComponent}</Box>
          <Box>{renderChild()}</Box>
        </Box>
      ) : (
        renderChild()
      )}
    </Dialog>
  );
};

export default BaseDialog;
