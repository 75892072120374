import * as React from 'react';

import {
  Box,
  ListItemAvatar,
  ListItemText,
  List as MuiList,
  ListItem as MuiListItem,
  styled,
} from '@mui/material';

const StyledMuiList = styled(MuiList)(({ theme }) => ({
  '& > .MuiListItem-root:not(:last-of-type)': {
    marginBottom: theme.spacing(2),
  },
}));

const ListItem = styled(MuiListItem)({
  alignItems: 'flex-start',
  '.MuiTypography-root': {
    fontSize: '15px',
  },
  '.MuiListItemText-primary': {
    fontWeight: 'bold',
  },
});

type ListItem = {
  icon: string;
  primaryText: string;
  secondaryText: string;
};

const items: ListItem[] = [
  {
    icon: '/png/credits.png',
    primaryText: 'What are credits?',
    secondaryText:
      'Credits enable you to save content uploaded by Beautypass members and send invitations to members in our new Collabs feature',
  },
  {
    icon: '/png/content.png',
    primaryText: 'How to use your credits?',
    secondaryText:
      'Purchase credits from the Credits page and use them to download content or send invitations. Each content saved or invitation will deduct from your total available credits.',
  },
  {
    icon: '/png/back.png',
    primaryText: "What happens if an invitation isn't redeemed?",
    secondaryText:
      'If a Beautypass member does not respond or declines your invitation, your credits will be refunded.',
  },
  {
    icon: '/png/wallet.png',
    primaryText: 'How can I get credits?',
    secondaryText:
      'Purchase credits anytime from the Credits page. Get great content and promote your brand with ease!',
  },
];

const CreditTips: React.FC = () => (
  <StyledMuiList disablePadding>
    {items.map((x, i) => (
      <ListItem key={i}>
        <ListItemAvatar>
          <Box component='img' src={x.icon} sx={{ width: '24px', marginTop: 1.5 }} />
        </ListItemAvatar>
        <ListItemText primary={x.primaryText} secondary={<>{x.secondaryText}</>} />
      </ListItem>
    ))}
  </StyledMuiList>
);

export default CreditTips;
