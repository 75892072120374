import * as React from 'react';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';

import TabPanel from './TabPanel';

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

export type Panel = {
  label: string;
  children: React.ReactNode;
};

const Tabs = styled(MuiTabs)({
  '.MuiButtonBase-root': {
    paddingLeft: 0,
    alignItems: 'flex-start',
  },
});

type TabPanelContainerProps = {
  panels: Panel[];
};

const TabPanelContainer: React.FC<TabPanelContainerProps> = ({ panels }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          {panels.map((x, i) => (
            <Tab
              key={`tab-${i}`}
              label={x.label}
              {...a11yProps(i)}
              sx={{ marginRight: '32px', minWidth: '64px', padding: 0 }}
            />
          ))}
        </Tabs>
      </Box>

      {panels.map((x, i) => (
        <TabPanel key={`tab-panel-${i}`} value={value} index={i}>
          {x.children}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabPanelContainer;
