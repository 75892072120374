import * as React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { Box, ModalProps, Paper, Stack } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';

import { VenueReservationContentItemV2 } from '../../../../graphql/API';
import { useAppSelector } from '../../../../redux/hooks';
import { ConfirmAction } from '../../Confirm/context/types';
import ActionDownload from './ActionDownload';
import ActionSave from './ActionSave';
import MediaContent from './MediaContent';
import UserHeader from './UserHeader';
import useAction from './useAction';

const IconButton = styled(MuiIconButton)({
  backgroundColor: 'white',
  ':hover': {
    backgroundColor: 'rgb(255,255,255,0.84)',
  },
});

export type ContentProps = {
  itemId: number;
  items: VenueReservationContentItemV2[];
  onClose?: ModalProps['onClose'];
  confirm: ConfirmAction;
};

const Content: React.FC<ContentProps> = ({ onClose, ...restProps }) => {
  const {
    item,
    handleDownloadContent,
    handleInviteUser,
    handleSaveContent,
    handleSaveUser,
    handleNavigation,
    getLoading,
  } = useAction(restProps);

  React.useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Escape':
          onClose && onClose({}, 'escapeKeyDown');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', onKeyPress);

    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [onClose]);

  const venueState = useAppSelector((state) => state.venue.value);

  const isSaveLoading = React.useMemo<boolean | undefined>(
    () => getLoading('saveContent', String(item?.id)),
    [getLoading, item?.id],
  );

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <IconButton color='primary' onClick={() => handleNavigation('prev')}>
        <ArrowBackIcon />
      </IconButton>
      <Paper sx={{ position: 'relative', width: '984px', height: '684px' }}>
        <IconButton
          color='primary'
          sx={{ position: 'absolute', right: 10, top: 10 }}
          onClick={() => onClose && onClose({}, 'backdropClick')}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              width: '100%',
            }}
          >
            <Box sx={{ width: '50%', position: 'relative' }}>
              <MediaContent
                src={item?.images?.medium ?? ''}
                videoSrc={item?.images?.video ?? undefined}
              />
            </Box>
            <Box
              sx={{
                width: '50%',
                px: 4,
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <UserHeader
                item={item}
                handleInviteUser={handleInviteUser}
                handleSaveUser={handleSaveUser}
                getLoading={getLoading}
              />
              {isSaveLoading === false || item?.downloaded ? (
                <ActionDownload
                  successMessage={isSaveLoading === false}
                  onDownload={handleDownloadContent}
                />
              ) : (
                <ActionSave
                  loading={isSaveLoading}
                  credit={item?.downloadCredits ?? 0}
                  disabled={!venueState?.availableDownloads}
                  onSave={handleSaveContent}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      <IconButton color='primary' onClick={() => handleNavigation('next')}>
        <ArrowForwardIcon />
      </IconButton>
    </Stack>
  );
};

export default Content;
