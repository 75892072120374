import * as React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import { Box, Stack, Typography } from '@mui/material';

import LoadingButton from '../../../Button/LoadingButton';

export type ActionSaveProps = {
  disabled?: boolean;
  loading?: boolean;
  credit: number;
  onSave: () => void;
};

const ActionSave: React.FC<ActionSaveProps> = ({ loading, credit, disabled, onSave }) => {
  return (
    <Box
      sx={{
        px: 3,
        py: 2,
        backgroundColor: '#F7F7F7',
        border: '1px solid #EBE8E8',
        borderRadius: 1,
      }}
    >
      <Typography fontWeight='bold'>Save content</Typography>
      <Typography variant='body2' sx={{ color: '#5F5F60' }}>
        To download and use the content, you must save it first
      </Typography>

      <Stack
        direction='row'
        alignItems='center'
        sx={{
          mt: 4,
          '> *': {
            flex: '1',
          },
        }}
      >
        <LoadingButton
          loading={loading}
          disabled={disabled}
          startIcon={<LockIcon />}
          variant='contained'
          onClick={onSave}
        >
          Save
        </LoadingButton>
        <Typography textAlign='center'>
          Cost{' '}
          <Box component='span' sx={{ color: '#D04040' }}>
            -{credit}
          </Box>{' '}
          Credits
        </Typography>
      </Stack>
    </Box>
  );
};

export default ActionSave;
