import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import { Link } from 'react-router-dom';

import GroupsIcon from '@mui/icons-material/Groups';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PersonIcon from '@mui/icons-material/Person';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import { Box, Card, Divider, Grid, Stack, Typography } from '@mui/material';

import { useModal } from 'mui-modal-provider';

import LazyAvatar from '../../components/Avatar/LazyAvatar/LazyAvatar';
import Button from '../../components/Button/Button';
import ListFavouritesUsersDialog from '../../components/Dialogs/ListFavouritesUsers/ListFavouritesUsersDialog';
import { TitleComponent } from '../../components/Dialogs/ListFavouritesUsers/TitleComponent';
import { CollabsIcon, NewOfferIcon, ReservationIcon } from '../../components/Icons';
import { SubscriptionPlan, mapPlanLabel } from '../../components/Layout/Header/Header';
import ListOffer from '../../components/List/ListOffer/ListOffer';
import ListRatedUser from '../../components/List/ListRatedUser/ListRatedUser';
import { Venue } from '../../graphql/API';
import { useAppSelector } from '../../redux/hooks';
import { extractSubscriptionPlan } from '../SubscriptionPlans/common/helper';
import CardRadius from './components/CardRadius';
import DashboardCard from './components/DashboardCard';
import SkeletonLoading from './components/SkeletonLoading';

type Card = { label: string; icon: React.FC; key: keyof Venue };

const cards: Card[] = [
  {
    label: 'Views',
    icon: LocalOfferIcon,
    key: 'views',
  },
  {
    label: `Favorites`,
    icon: PersonIcon,
    key: 'totalFavouritesByActiveUsers',
  },
  {
    label: 'Social reach',
    icon: GroupsIcon,
    key: 'totalReach',
  },
  {
    label: 'Total reservations',
    icon: ReservationIcon,
    key: 'totalReservations',
  },
  {
    label: 'Total check-ins',
    icon: WbTwilightIcon,
    key: 'totalCheckins',
  },
];

const APP_BAR_HEIGHT = 64;
const OFFSET = 220;

const useListHeight = (elementRef: React.RefObject<HTMLDivElement>) => {
  const [height, setHeight] = React.useState(0);

  const calculateListHeight = React.useCallback(
    (windowsInnerHeight: number, topContainerHeight: number | null) => {
      const h = topContainerHeight
        ? (windowsInnerHeight - (topContainerHeight + APP_BAR_HEIGHT + OFFSET)) / 2
        : 0;

      return h > 0 ? h : 0;
    },
    [],
  );

  React.useLayoutEffect(() => {
    function updateHeight() {
      setHeight(calculateListHeight(window.innerHeight, elementRef.current?.clientHeight ?? null));
    }

    window.addEventListener('resize', updateHeight);
    updateHeight();
    return () => window.removeEventListener('resize', updateHeight);
  }, [elementRef, calculateListHeight]);

  return height;
};

const Dashboard: React.FC = () => {
  const venueState = useAppSelector((state) => state.venue.value);
  const { subscription } = useAppSelector((state) => state.subscription.value);

  const elementRef = React.useRef<HTMLDivElement>(null);

  const listHeight = useListHeight(elementRef);

  const { showModal } = useModal();

  const handleListFavouritesDialog = React.useCallback(() => {
    showModal(ListFavouritesUsersDialog, {
      titleComponent: (
        <TitleComponent count={venueState ? Number(venueState['totalFavourites']) : 0} />
      ),
    });
  }, [showModal, venueState]);

  const subscriptionPlan = React.useMemo(() => {
    const [plan] = extractSubscriptionPlan(subscription?.sku ?? '');

    return plan;
  }, [subscription]);

  const sp = React.useMemo(() => mapPlanLabel[subscriptionPlan], [subscriptionPlan]);

  return venueState ? (
    <Box height='100%' display='flex' flexDirection='column' sx={{ backgroundColor: '#ffffff' }}>
      <Box
        sx={{ background: '#ffffff', p: 4, boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.04)' }}
        ref={elementRef}
      >
        <Box display='flex' width='100%' justifyContent='space-between'>
          <Box display='flex' flex={1} sx={{ marginLeft: '17px' }}>
            <Box component={AspectRatio} sx={{ width: 120 }}>
              <LazyAvatar
                variant='rounded'
                placeholderSrc={String(venueState?.picture?.thumbnail)}
                src={String(venueState?.picture?.medium)}
              />
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              flex={1}
              ml={4}
            >
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <Typography variant='h5' fontWeight='bold'>
                  {venueState?.name}
                </Typography>
                {sp ? (
                  <SubscriptionPlan label={sp.label} planColor={sp.color} fill={sp.fill} />
                ) : (
                  <Typography component='div' sx={{ mr: 2 }}>
                    <Box component='span' sx={{ fontWeight: 500, mr: 0.5 }}>
                      FREE
                    </Box>
                    Plan
                  </Typography>
                )}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                {cards.map((x, i) => (
                  <DashboardCard
                    key={i}
                    label={x.label}
                    count={Number(venueState[x.key])}
                    Icon={x.icon}
                    subscriptionPlan={subscriptionPlan}
                    onFavouritesHandle={handleListFavouritesDialog}
                  />
                ))}
              </Box>
            </Box>
          </Box>

          <Divider
            orientation='vertical'
            flexItem
            sx={{ mx: 4, borderColor: '#eaeaea' }}
            style={{ borderColor: '#eaeaea' }}
          />

          <Box display='flex' flexDirection='column' justifyContent='space-between' sx={{ mr: 2 }}>
            <Typography variant='h5' fontWeight='bold'>
              Quick Actions
            </Typography>

            <Stack spacing={2} direction='row'>
              <Button
                component={Link}
                to='/offers/create'
                id='btn-create-offer'
                startIcon={<NewOfferIcon />}
                variant='outlined'
                style={{ border: '0.6px solid #eaeaea' }}
              >
                Add a new offer
              </Button>

              <Button
                component={Link}
                to='/collabs'
                id='btn-invite'
                startIcon={<CollabsIcon />}
                variant='outlined'
                style={{ border: '0.6px solid #eaeaea' }}
              >
                Invite to Collab
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>

      <Grid sx={{ py: 2, px: 6, height: '100%' }} container spacing={2}>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <CardRadius
              showBackground
              noPadding
              sectionTitle='Your Top Rated Models'
              listHeight={listHeight}
              gradientType='rated'
            >
              <ListRatedUser listHeight={listHeight} />
            </CardRadius>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <CardRadius
              showBackground
              noPadding
              sectionTitle='Your Top Performing Offers'
              listHeight={listHeight}
              gradientType='performance'
            >
              <ListOffer listHeight={listHeight} />
            </CardRadius>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <SkeletonLoading />
  );
};

export default Dashboard;
