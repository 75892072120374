import * as React from 'react';

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { venueListInvitesApi } from '../../../api/invites/venueListInvitesApi';
import { Invite, VenueListInvitesFilter } from '../../../graphql/API';
import { QueryResult } from '../../../hooks/reactQuery/types';
import { update } from '../../../redux/features/invite/inviteSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

const PAGE_LIMIT = 50;

const useInviteQuery = (): QueryResult<Invite[]> => {
  const venueState = useAppSelector((state) => state.venue.value);
  const dispatch = useAppDispatch();

  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery<Invite[]>({
    initialPageParam: 0,
    queryKey: ['list-invites', venueState?.id],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, venueID] = queryKey as [string, string, Partial<VenueListInvitesFilter>];
      const offset = <number>pageParam * PAGE_LIMIT;

      return venueListInvitesApi({
        limit: PAGE_LIMIT,
        offset,
        includeExpired: true,
        venueID: Number(venueID),
      });
    },
    getNextPageParam: (lastGroup, groups) =>
      lastGroup.length === PAGE_LIMIT ? groups.length : undefined,
    placeholderData: keepPreviousData,
    enabled: !!venueState?.id,
    refetchOnWindowFocus: false,
  });

  const flatData = React.useMemo(() => data?.pages.flatMap((x) => x) ?? [], [data]);

  React.useEffect(() => {
    dispatch(update(flatData));
  }, [dispatch, flatData]);

  return { data: flatData, isFetching, isLoading, fetchNextPage, hasNextPage };
};

export default useInviteQuery;
