import { Box } from '@mui/material';

import dayjs from 'dayjs';

import {
  BlockedUser,
  UserAccessRequestResultValue,
  VennueAccessRequestItem,
  VenueAccessREnum,
} from '../../../graphql/API';
import { getUserCategory } from '../../../utils/user';
import UserAvatarCategory from '../../Avatar/UserAvatarCategory/UserAvatarCategory';
import LoadingButton from '../../Button/LoadingButton';
import StatusLabel from '../../StatusLabel/StatusLabel';
import { ListItem } from '../Base/types';
import { MapProps } from './types';

const isAccessRequest = (
  item: VennueAccessRequestItem | BlockedUser,
): item is VennueAccessRequestItem =>
  Boolean((item as VennueAccessRequestItem).offerID && (item as VennueAccessRequestItem).userID);

const mapToItem =
  (props: MapProps) =>
  (data: VennueAccessRequestItem | BlockedUser): ListItem => {
    if (isAccessRequest(data)) {
      return {
        avatar: (
          <UserAvatarCategory
            src={data.user?.picture?.thumbnail || ''}
            userCategory={getUserCategory(data.user?.tags ?? [])}
          />
        ),
        primaryText: data.user?.firstname ?? '',
        to: `/users/${data.userID}`,
        secondaryText: (
          <StatusLabel
            type='userCategory'
            status={getUserCategory(data.user?.tags ?? [])}
            emptyBackground
          />
        ),
        secondarySlot: (
          <Box>
            {data.status === UserAccessRequestResultValue.accepted
              ? 'Has access to'
              : "Doesn't have access to"}{' '}
            <Box component='span' sx={{ fontWeight: 'bold' }}>
              {data.offer?.title}
            </Box>
          </Box>
        ),
        actionSlot: (
          <LoadingButton
            id={`btn-access-edit${data.offerID}`}
            variant='contained'
            size='small'
            sx={{
              padding: '4px 10px',
              minWidth: 'unset',
            }}
            loading={props.loadingState[`${data.userID}:${data.offerID}`]}
            onClick={() =>
              data.userID &&
              data.offerID &&
              props.onAction({
                action:
                  data.status === UserAccessRequestResultValue.accepted
                    ? VenueAccessREnum.declined
                    : VenueAccessREnum.accepted,
                userID: data.userID,
                offerID: data.offerID,
              })
            }
          >
            {data.status === UserAccessRequestResultValue.accepted ? 'Remove' : 'Undo'}
          </LoadingButton>
        ),
      };
    }

    return {
      avatar: data.picture?.thumbnail || '',
      primaryText: data.firstname ?? '',
      to: `/users/${data.id}`,
      secondaryText: (
        <StatusLabel
          type='userCategory'
          status={getUserCategory(data?.tags ?? [])}
          emptyBackground
        />
      ),
      secondarySlot: <Box>Blocked on {dayjs.utc(data.blockedDate).format('LL')}</Box>,
      actionSlot: (
        <LoadingButton
          id={`btn-blocked-action${data.id}`}
          variant='contained'
          size='small'
          loading={props.loadingState[`${data.id}:${props.venueId}`]}
          sx={{
            padding: '4px 10px',
            minWidth: 'unset',
          }}
          onClick={() =>
            data.id &&
            props.onAction({
              userID: Number(data.id),
              venueID: props.venueId,
            })
          }
        >
          Unblock
        </LoadingButton>
      ),
    };
  };

export const mapToListItem = (data: VennueAccessRequestItem[] | BlockedUser[], props: MapProps) =>
  data.map(mapToItem(props));
