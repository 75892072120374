const POST_VIEW_NAMES: Record<string, string> = {
  pending: 'pending',
  'in review': 'in review',
  validated: 'approved',
  validationFailed: 'rejected',
  validationUpdated: 'approved',
};

export const calculateReservationPostStatus = (
  checkinTime?: string | null,
  postOnInstagramReels?: boolean | null,
  postOnInstagramStories?: boolean | null,
  defaultStatus?: string | null,
): string => {
  let status = defaultStatus;
  if (checkinTime) {
    const date = new Date(checkinTime);
    const hoursSinceCheckin = (Date.now() - date.getTime()) / (1000 * 60 * 60);

    if (postOnInstagramStories) {
      if (hoursSinceCheckin < 20) {
        status = 'pending';
      } else if (hoursSinceCheckin >= 20 && hoursSinceCheckin <= 24) {
        status = 'in review';
      }
    } else if (postOnInstagramReels) {
      if (hoursSinceCheckin < 92) {
        status = 'pending';
      } else if (hoursSinceCheckin >= 92 && hoursSinceCheckin < 96) {
        status = 'in review';
      }
    }
  }

  return status ? POST_VIEW_NAMES[status] : '';
};
