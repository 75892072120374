import * as React from 'react';

import { Grid, GridProps } from '@mui/material';
import Box from '@mui/material/Box';

import { useModal } from 'mui-modal-provider';

import ChipOptions, { ChipOptionsProps } from '../../../components/ChipOptions/ChipOptions';
import { useConfirm } from '../../../components/Dialogs/Confirm/context/ConfirmDialog.context';
import ManageContentDialog from '../../../components/Dialogs/ManageContent/ManageContentDialog';
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import {
  VenueListReservationsContentFilter,
  VenueReservationContentItemV2,
} from '../../../graphql/API';
import useListReservationContentQuery from '../../../hooks/reactQuery/useListReservationContentQuery';
import Content from './Content';
import ContentSkeleton from './ContentSkeleton';
import EmptyReceived from './EmptyReceived';
import EmptySaved from './EmptySaved';

type OnClick = (item: VenueReservationContentItemV2) => void;
const commonGrid = (key: string): GridProps => ({ item: true, key, xs: 3 });

const ListContent: React.FC = () => {
  const { showModal } = useModal();
  const { confirm } = useConfirm();

  const [filters, setFilters] = React.useState<
    Partial<VenueListReservationsContentFilter> | undefined
  >({ downloaded: false });

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useListReservationContentQuery(filters);

  const handleChipChanged = React.useCallback<ChipOptionsProps['onChange']>(
    ({ value: v }) => {
      const downloaded = Boolean(v);
      setFilters((x) => ({ ...x, downloaded }));
    },
    [setFilters],
  );

  const handleClick = React.useCallback<OnClick>(
    (item) => {
      showModal(ManageContentDialog, {
        items: data,
        itemId: item.id ?? 0,
        confirm,
      });
    },
    [showModal, confirm, data],
  );

  const renderContent = React.useCallback(() => {
    if (isLoading) {
      return [...Array(6)].map((x, i) => (
        <Grid {...commonGrid(`grid-skeleton-${i}`)}>
          <ContentSkeleton />
        </Grid>
      ));
    }

    if (data.length === 0) {
      return (
        <Grid {...commonGrid(`grid-empty`)} xs={12}>
          {filters?.downloaded ? <EmptySaved /> : <EmptyReceived />}
        </Grid>
      );
    }

    return data.map((x) => (
      <Grid {...commonGrid(`grid-content-${x.id}`)}>
        <Content
          onClick={() => handleClick(x)}
          src={x.images?.small ?? ''}
          video={x.images?.video ?? undefined}
          userAvatar={x.user?.picture?.thumbnail ?? ''}
          name={`${x.user?.firstname} ${x.user?.lastname}`}
        />
      </Grid>
    ));
  }, [isLoading, data, handleClick, filters?.downloaded]);

  return (
    <Box>
      <ChipOptions
        name='contentDownloaded'
        options={[
          { label: 'Received', value: '' },
          { label: 'Saved', value: 'saved' },
        ]}
        onChange={handleChipChanged}
      />

      <Box sx={{ pt: 4 }}>
        <InfiniteScroll
          isLoading={isLoading}
          isFetching={isFetching}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        >
          <Grid container spacing={1}>
            {renderContent()}
          </Grid>
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

export default ListContent;
