import React from 'react';

import { Box, Typography } from '@mui/material';

import { ShowFnOutput } from 'mui-modal-provider';

import { Reservation, UserUpdateStatus } from '../../../../graphql/API';
import { useUpdateReservationMutation } from '../../../../pages/ReservationDetail/components/useMutation';
import LoadingButton from '../../../Button/LoadingButton';
import { BaseDialogProps } from '../../Base';

export type CancelReservationProps = {
  reservationDetail?: Reservation;
  modalRef: React.MutableRefObject<ShowFnOutput<BaseDialogProps> | undefined>;
  refetchReservationDetails?: () => void;
};

export const CancelReservation: React.FC<CancelReservationProps> = ({
  reservationDetail,
  modalRef,
  refetchReservationDetails,
}) => {
  const cancelReservationMutation = useUpdateReservationMutation({
    onSuccess: () => {
      modalRef.current?.destroy();
      if (refetchReservationDetails) {
        refetchReservationDetails();
      }
    },
  });

  const onCancelHandle = React.useCallback(() => {
    modalRef.current?.destroy();
  }, [modalRef]);

  const onCancelReservationHandle = () => {
    if (reservationDetail?.id) {
      cancelReservationMutation.mutate({
        id: reservationDetail.id,
        status: UserUpdateStatus.cancelled,
      });
    }
  };

  return (
    <Box>
      <Typography paddingTop={'34px'} paddingBottom={'44px'}>
        The reservation will be canceled and removed from your list, the user will be notified
      </Typography>

      <Box display={'flex'} alignItems={'center'} gap={'20px'}>
        <LoadingButton
          fullWidth
          variant='outlined'
          size='large'
          sx={{ paddingY: '12px' }}
          onClick={onCancelHandle}
        >
          Keep
        </LoadingButton>
        <LoadingButton
          fullWidth
          loading={cancelReservationMutation.isPending}
          variant='contained'
          type='submit'
          size='large'
          sx={{
            backgroundColor: '#e05757',
            paddingY: '12px',
            ':hover': {
              backgroundColor: '#e05757',
            },
          }}
          onClick={onCancelReservationHandle}
        >
          Delete
        </LoadingButton>
      </Box>
    </Box>
  );
};
