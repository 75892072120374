import * as React from 'react';

import { Box } from '@mui/material';

import { pick } from 'lodash';

import { NoRequirementIcon, StoryIcon, UserIcon } from '../../../components/Icons';
import InstagramTag, { InstagramTagProps } from '../../../components/InstagramTag/InstagramTag';
import { Offer } from '../../../graphql/API';

const requirements = [
  'postOnInstagramFeed',
  'postOnInstagramReels',
  'postOnInstagramStories',
  'postOnInstagramStories3x',
  'includeSelfInPost',
] as const;

type Requirement = (typeof requirements)[number] | 'noRequirement';

export type RequirementItem = {
  Icon: typeof StoryIcon;
  label: React.ReactNode;
  InstagramTag?: React.ComponentType<InstagramTagProps>;
};

// eslint-disable-next-line react-refresh/only-export-components
const ReqLabel: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Box component='span' display='flex'>
    {children}
  </Box>
);

const dictRequirement: Record<Requirement, RequirementItem> = {
  includeSelfInPost: {
    Icon: UserIcon,
    label: (
      <ReqLabel>
        Include <b>&nbsp;yourself&nbsp;</b> in the post
      </ReqLabel>
    ),
    InstagramTag,
  },
  postOnInstagramFeed: {
    Icon: StoryIcon,
    label: (
      <ReqLabel>
        Post on <b>&nbsp;Feed&nbsp;</b>
      </ReqLabel>
    ),
    InstagramTag,
  },
  postOnInstagramStories: {
    Icon: StoryIcon,
    label: (
      <ReqLabel>
        Post on <b>&nbsp;Stories&nbsp;</b>
      </ReqLabel>
    ),
    InstagramTag,
  },
  postOnInstagramReels: {
    Icon: StoryIcon,
    label: (
      <ReqLabel>
        Post on <b>&nbsp;Reels&nbsp;</b>
      </ReqLabel>
    ),
    InstagramTag,
  },
  postOnInstagramStories3x: {
    Icon: StoryIcon,
    label: (
      <ReqLabel>
        Post on <b>&nbsp;Stories X3&nbsp;</b>
      </ReqLabel>
    ),
    InstagramTag,
  },
  noRequirement: {
    Icon: NoRequirementIcon,
    label: <ReqLabel>No Requirements</ReqLabel>,
  },
};

const getRequirements = (data: Offer) => {
  const values = pick(data, requirements);

  const reqs = Object.entries(values)
    .map(([k, v]) => (v ? k : undefined))
    .filter(Boolean) as Requirement[];

  if (reqs.length === 0) {
    reqs.push('noRequirement');
  }

  return reqs.map((x) => dictRequirement[x]);
};

const useRequirement = (data: Offer | undefined) => {
  return React.useMemo(() => (data ? getRequirements(data) : []), [data]);
};

export default useRequirement;
