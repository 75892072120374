import { VenueCreateInviteInput } from '../../graphql/API';
import { executeGql } from '../../utils/gql-utils';

const venueCreateInviteMutation = /* GraphQL */ `
  mutation venueCreateInvite($input: VenueCreateInviteInput!) {
    venueCreateInvite(input: $input)
  }
`;

export const venueCreateInviteApi = async (input: VenueCreateInviteInput): Promise<void> => {
  await executeGql<void>(venueCreateInviteMutation, { input });
};
