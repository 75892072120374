import * as React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Dayjs } from 'dayjs';

import Button from '../../../components/Button/Button';
import { CalendarIcon } from '../../../components/Icons';

type DateTimeProps = {
  reservedDate?: Dayjs;
  value: Dayjs;
  endValue?: Dayjs;
  onEditHandle?: () => void;
  showEditBtn?: boolean;
};

const DateTime: React.FC<DateTimeProps> = ({
  reservedDate,
  value,
  endValue,
  onEditHandle,
  showEditBtn = false,
}) => {
  const endTimeRange = React.useMemo(() => {
    if (endValue) {
      return `${value.format('h:mm A')} - ${endValue.format('h:mm A')}`;
    }

    return value.format('h:mm A');
  }, [value, endValue]);

  if (!value.isValid()) {
    return '/';
  }

  return (
    <Typography
      variant='body2'
      fontWeight='medium'
      component='div'
      display='flex'
      justifyContent={showEditBtn ? 'space-between' : ''}
      alignItems={'center'}
    >
      {reservedDate ? (
        <Box display='flex'>
          <CalendarIcon sx={{ fontSize: '20px', mr: 1 }} />{' '}
          {reservedDate.format('dddd, MMM D, YYYY')}
        </Box>
      ) : (
        <Box display='flex'>
          <CalendarIcon sx={{ fontSize: '20px', mr: 1 }} /> {value.format('dddd, MMM D, YYYY')}
        </Box>
      )}
      
      <Box display='flex' ml={showEditBtn ? 0 : 4}>
        <AccessTimeIcon sx={{ fontSize: '20px', mr: 1 }} /> {endTimeRange}
      </Box>

      {showEditBtn && (
        <Button
          variant='outlined'
          sx={{ paddingY: '2px', borderRadius: '5px' }}
          onClick={onEditHandle}
        >
          Edit
        </Button>
      )}
    </Typography>
  );
};

export default DateTime;
