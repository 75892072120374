import * as React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type BenefitProps = {
  text: string;
  color?: string;
};

const Benefit: React.FC<BenefitProps> = ({ color, text }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 12,
        px: 2,
        py: 1,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
      }}
    >
      <CheckIcon sx={{ color, fontSize: '1.2rem' }} />

      <Stack ml={2}>
        <Typography
          variant='subtitle2'
          sx={{
            lineHeight: 1,
            mb: 0.2,
          }}
        >
          {text}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Benefit;
