import * as React from 'react';

import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ShowFnOutput } from 'mui-modal-provider';

import { venueCreateInviteApi } from '../../../../api/invites/venueCreateInviteApi';
import { User, VenueCreateInviteInput } from '../../../../graphql/API';
import { extractSubscriptionPlan } from '../../../../pages/SubscriptionPlans/common/helper';
import { updateCredit } from '../../../../redux/features/venue/venueSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { datetimeWithTimezone, timeToDayJs } from '../../../../utils/date';
import { BaseDialogProps } from '../../Base';
import { CreateInviteContext } from './CreateInvite.context';
import { initialState, reducer } from './CreateInvite.reducer';
import { calculateInviteCost } from './util';

export type CreateInviteProviderProps = {
  users: User[];
  modalRef: React.MutableRefObject<ShowFnOutput<BaseDialogProps> | undefined>;
  onSuccess: () => void;
  premiumCredits?: number;
  nonPremiumCredits?: number;
};

const toUtcTime = (time: string, timezoneId?: string): string =>
  datetimeWithTimezone({
    datetime: timeToDayJs(time).format('YYYY-MM-DD HH:mm'),
    timezoneId,
  })
    .utc()
    .format('HH:mm') ?? '';

const CreateInviteProvider: React.FC<React.PropsWithChildren<CreateInviteProviderProps>> = ({
  children,
  users,
  modalRef,
  onSuccess,
  premiumCredits = 0,
  nonPremiumCredits = 0,
}) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState(users),
    premiumCredits,
    nonPremiumCredits,
  });

  const venueState = useAppSelector((state) => state.venue.value);
  const { subscription } = useAppSelector((state) => state.subscription.value);
  const appDispatch = useAppDispatch();
  const mutation = useMutation({
    mutationFn: venueCreateInviteApi,
    onSuccess: () => {
      onSuccess();
    },
  });

  const subscriptionPlan = React.useMemo(() => {
    const [plan] = extractSubscriptionPlan(subscription?.sku ?? '');

    return plan;
  }, [subscription]);

  const submitForm = React.useCallback(async () => {
    const input: VenueCreateInviteInput = {
      userID: state.users.map((x) => Number(x.id)),
      offerID: Number(state.offer?.id),
      availableDates: state.dates?.map((x) => x.format('YYYY-MM-DD')) ?? [],
      startTime: toUtcTime(
        state.startTime?.format('HH:mm') ?? '',
        venueState?.timeZoneId ?? undefined,
      ),
      endTime: toUtcTime(state.endTime?.format('HH:mm') ?? '', venueState?.timeZoneId ?? undefined),
      venueID: Number(venueState?.id),
    };

    modalRef.current?.update({ title: undefined });
    dispatch({ type: 'next' });

    await mutation.mutateAsync(input);

    appDispatch(updateCredit(subscriptionPlan === 'plus' ? -premiumCredits : -nonPremiumCredits));
  }, [
    state.users,
    state.offer?.id,
    state.dates,
    state.startTime,
    state.endTime,
    venueState?.timeZoneId,
    venueState?.id,
    modalRef,
    mutation,
    premiumCredits,
    nonPremiumCredits,
    appDispatch,
    subscriptionPlan,
  ]);

  return (
    <CreateInviteContext.Provider
      value={{
        state,
        dispatch,
        mutation,
        submitForm,
      }}
    >
      {children}
    </CreateInviteContext.Provider>
  );
};

export default CreateInviteProvider;
