import dayjs from 'dayjs';

import { User } from '../../../../graphql/API';
import { Action, State } from './types';

export const initialState = (users: User[]): State => ({
  step: 0,
  users,
  startTime: dayjs()
    .startOf('minute')
    .add(5 - (dayjs().minute() % 5), 'minute'),
  endTime: dayjs().set('hour', 23).set('minute', 0).set('second', 0),
});

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'next': {
      return {
        ...state,
        ...action.payload,
        step: Math.min(Math.max(state.step + 1, 0), 3),
      };
    }
    case 'prev':
      return {
        ...state,
        step: Math.max(Math.min(state.step - 1, 3), 0),
      };
    case 'update':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
