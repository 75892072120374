import * as React from 'react';

import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiPaper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

type PaperProps = {
  title: string;
  center?: boolean;
  action: React.ReactNode;
};

const Paper: React.FC<React.PropsWithChildren<PaperProps>> = ({
  children,
  title,
  center,
  action,
}) => {
  return (
    <MuiPaper
      sx={{
        boxShadow: '0px 0px 20px #00000014',
        border: '1px solid #F1F1F1',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: center ? 'center' : undefined,
        height: '100%',
      }}
    >
      <Typography variant='subtitle1' fontWeight='medium'>
        {title}
      </Typography>
      <Divider sx={{ mt: 1, mb: 2, width: '100%' }} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: center ? 'center' : undefined,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: center ? 'center' : undefined,
          }}
        >
          {children}
        </Box>
        <Box mt={5}>{action}</Box>
      </Box>
    </MuiPaper>
  );
};

export default Paper;
