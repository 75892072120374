import { Stripe, loadStripe } from '@stripe/stripe-js';

import config from '../../../config';

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(config.stripKey);
  }
  return stripePromise;
};

export default getStripe;
