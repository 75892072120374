import { VennueAccessRequestItem, VenueListAccessRequetsFilter } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueListAccessRequestsQuery = /* GraphQL */ `
  query VenueListAccessRequests($filter: VenueListAccessRequetsFilter!) {
    venueListAccessRequests(filter: $filter) {
      items {
        offerID
        status
        userID
        user {
          firstname
          lastname
          gender
          picture {
            thumbnail
            small
            medium
            large
          }
          tags
        }
        offer {
          title
        }
      }
    }
  }
`;

export const venueListAccessRequestsApi = async (
  filter: VenueListAccessRequetsFilter,
): Promise<VennueAccessRequestItem[]> => {
  const result = await executeGql<VennueAccessRequestItem[]>(venueListAccessRequestsQuery, {
    filter,
  });
  return processGqlResult<VennueAccessRequestItem[]>('venueListAccessRequests.items', result);
};
