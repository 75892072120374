import * as React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import styled from '@emotion/styled';

type TitleWithDescriptionProps = {
  title: string;
};

const TitleWithDescription: React.FC<React.PropsWithChildren<TitleWithDescriptionProps>> = ({
  title,
  children,
}) => (
  <Box>
    <Title>
      {title}
    </Title>
    <Typography>{children}</Typography>
  </Box>
);

const ManageContentTips: React.FC = () => (
  <Stack spacing={5} paddingY='42px' paddingX='36px'>
    <TitleWithDescription title='What is content?'>
      Photos that Beautypass members give you in addition to what is required for your offer.
    </TitleWithDescription>

    <TitleWithDescription title='And the post?'>
      Content is uploaded here while their main post is published on Instagram.{' '}
    </TitleWithDescription>

    <TitleWithDescription title='What can I do?'>
      Save content and repost it on your Instagram page to promote your business.{' '}
    </TitleWithDescription>

    <TitleWithDescription title='License'>
      It is forbidden to modify, edit to use this content for any commercial purpose outside of
      Instagram (i.e. ads and campaigns are not allowed).{' '}
    </TitleWithDescription>
  </Stack>
);

export default ManageContentTips;

const Title = styled.h6`
  font-size: 20px;
  font-weight: 600;
  color: #111111;
  margin: 0 0 8px 0;
`;
