import * as React from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';

import styled from '@emotion/styled';
import { useField } from 'formik';
import { isEqual } from 'lodash';

import TimePicker from '../../../components/Formiks/CustomTimePicker/CustomTimePicker';
import DatePicker, {
  SlotProps as DateSlotProps,
} from '../../../components/Formiks/DatePicker/DatePicker';
import { OfferCategory } from '../../../graphql/API';
import { Method } from '../OfferForm';
import { AttentionError } from './AttentionError';
import SectionTitle from './SectionTitle';
import SideBySide from './SideBySide';
import { FormValue } from './schema';

type Props = {
  offerType: string;
  method: Method;
  initialValues: FormValue;
};

const slotProps: DateSlotProps = {
  textField: {
    size: 'small',
    fullWidth: true,
  },
};

const DateTimeSection: React.FC<Props> = ({ offerType, method, initialValues }) => {
  const [categoryField] = useField<OfferCategory>('category');
  const [startDateField] = useField('startDate');
  const [endDateField] = useField('endDate');
  const [startTimeField] = useField('startTime');
  const [endTimeField] = useField('endTime');

  return (
    <Box width='100%'>
      {offerType === 'public' ? (
        <>
          <SectionTitle title='Date and time' />

          <Stack spacing={3} sx={{ width: '100%' }}>
            <SideBySide ratio={categoryField.value === 'event' ? 12 : 6}>
              <CustomBox>
                <label htmlFor='' className='offer-form-label'>
                  {categoryField.value === 'event' ? 'Date' : 'Start date'}
                </label>
                <DatePicker
                  name='startDate'
                  slotProps={slotProps as DateSlotProps}
                  className='offer-input-field'
                />
                {method === 'update' && !isEqual(initialValues.startDate, startDateField.value) && (
                  <AttentionError />
                )}
              </CustomBox>
              {categoryField.value !== 'event' && (
                <CustomBox>
                  <label htmlFor='' className='offer-form-label'>
                    End date
                  </label>
                  <DatePicker
                    name='endDate'
                    slotProps={slotProps as DateSlotProps}
                    className='offer-input-field'
                  />
                  {method === 'update' && !isEqual(initialValues.endDate, endDateField.value) && (
                    <AttentionError />
                  )}
                </CustomBox>
              )}
            </SideBySide>

            <SideBySide>
              <CustomBox>
                <label htmlFor='' className='offer-form-label'>
                  Start time
                </label>
                <TimePicker name='startTime' className='offer-time-input-field' />
                {method === 'update' && initialValues.startTime !== startTimeField.value && (
                  <AttentionError />
                )}
              </CustomBox>
              <CustomBox>
                <label htmlFor='' className='offer-form-label'>
                  End time
                </label>
                <TimePicker name='endTime' className='offer-time-input-field' />
                {method === 'update' && initialValues.endTime !== endTimeField.value && (
                  <AttentionError />
                )}
              </CustomBox>
            </SideBySide>
          </Stack>
        </>
      ) : null}
    </Box>
  );
};

export default DateTimeSection;
const CustomBox = styled.div`
  display: flex;
  flex-direction: column;
  fieldset {
    border: 1px solid #e6e6e6;
  }
`;
