import * as React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import icons from '../../../themes/icons';

const Container = styled(Box)(() => ({
  position: 'absolute',
  top: 8,
  left: 8,
  backgroundColor: 'rgba(0, 0, 0, .8)',
  color: 'white',
  borderRadius: '50px',
  padding: '5px 16px',
  boxShadow: '0px 0px 4px 0px #00000040',
}));

type HighlightProps = {
  privateOffer: boolean;
  eventOffer: boolean;
};

const Highlight: React.FC<HighlightProps> = ({ privateOffer, eventOffer }) => {
  return (
    <Container
      sx={{
        border: eventOffer
          ? '1px solid #FFCE50'
          : privateOffer
            ? '1px solid #7B7BFF'
            : '1px solid #6CC2AD',
      }}
    >
      <Stack direction='row' alignItems='center' spacing={1}>
        {eventOffer ? (
          <icons.EventOfferIcon style={{ width: '22px', height: '22px' }} />
        ) : privateOffer ? (
          <icons.CollabOfferIcon style={{ width: '22px', height: '22px' }} />
        ) : (
          <icons.RecruitOfferIcon style={{ width: '22px', height: '22px' }} />
        )}

        <Typography
          variant='caption'
          component='div'
          fontSize={'15px'}
          fontWeight={500}
          color={eventOffer ? '#FFCE50' : privateOffer ? '#9B9BFF' : '#37BB90'}
          padding={0}
        >
          {eventOffer ? 'Event' : privateOffer ? 'Collab offer' : 'Recurring offer'}
        </Typography>
      </Stack>
    </Container>
  );
};

export default Highlight;
