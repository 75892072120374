import * as React from 'react';

import BaseDialog, { BaseDialogProps } from '../Base';
import CreditTips from './CreditTips';

const CreditTipsDialog: React.FC<BaseDialogProps> = (props) => {
  return (
    <BaseDialog {...props}>
      <CreditTips />
    </BaseDialog>
  );
};

export default CreditTipsDialog;
