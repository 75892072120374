import { Offer, VenueListOffersFilter } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueListOffersQuery = /* GraphQL */ `
  query venueListOffers($filter: VenueListOffersFilter!) {
    venueListOffers(filter: $filter) {
      items {
        id
        title
        marketName
        status
        totalReservations
        totalCheckins
        totalUniqueVisitors
        private
        createdAt
        startDate
        endDate
        startTime
        endTime
        availableDays
        timeZoneId
        category
        picture {
          thumbnail
          small
          medium
        }
        postOnInstagramStories
        postOnInstagramStories3x
        postOnInstagramFeed
        postOnInstagramReels
      }
    }
  }
`;

export const venueListOffersApi = async (filter: VenueListOffersFilter): Promise<Offer[]> => {
  const result = await executeGql<Offer[]>(venueListOffersQuery, { filter });
  return processGqlResult<Offer[]>('venueListOffers.items', result);
};
