/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const adminListMarkets = /* GraphQL */ `query AdminListMarkets($filter: AdminListMarketsFilter!) {
  adminListMarkets(filter: $filter) {
    items {
      id
      name
      totalActiveVenues
      totalVenues
      totalPremiumVenues
      totalActiveModels
      totalUsers
      totalPremiumUsers
      percentActiveModels
      totalActiveOffers
      totalActiveVenueWithOffers
      totalPremiumOffers
      totalSpots
      totalReservations
      percentBookedLastFewDays
      percentBookedAllTime
      valueActiveOfferSpots
      totalReach
      stateProvince
      countryCode
      country
      currency
      status
      unitSystem
      createdAt
      tipEnabled
      totalVenuesLeads
      totalApplicationUsers
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListMarketsQueryVariables,
  APITypes.AdminListMarketsQuery
>;
export const adminGetMarket = /* GraphQL */ `query AdminGetMarket($id: String!) {
  adminGetMarket(id: $id) {
    id
    name
    venues {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    totalActiveVenues
    totalVenues
    totalPremiumVenues
    totalActiveModels
    totalUsers
    totalPremiumUsers
    percentActiveModels
    totalActiveOffers
    totalActiveVenueWithOffers
    totalPremiumOffers
    totalSpots
    totalReservations
    percentBookedLastFewDays
    percentBookedAllTime
    valueActiveOfferSpots
    totalReach
    stateProvince
    countryCode
    country
    currency
    status
    location {
      lat
      lon
      __typename
    }
    timezone {
      dstOffset
      rawOffset
      timeZoneId
      timeZoneName
      __typename
    }
    unitSystem
    createdAt
    tipEnabled
    totalVenuesLeads
    totalApplicationUsers
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetMarketQueryVariables,
  APITypes.AdminGetMarketQuery
>;
export const adminGetMarketStatistics = /* GraphQL */ `query AdminGetMarketStatistics($filter: AdminGetMarketStatisticsFilter!) {
  adminGetMarketStatistics(filter: $filter) {
    totalActiveVenues
    totalBlockedVenues
    totalPendingVenues
    totalRejectedVenues
    totalInactiveVenues
    totalDeletedVenues
    totalActiveVenuesWithNoOffers
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetMarketStatisticsQueryVariables,
  APITypes.AdminGetMarketStatisticsQuery
>;
export const adminGetMarketStatisticModels = /* GraphQL */ `query AdminGetMarketStatisticModels($filter: AdminGetMarketStatisticsFilter!) {
  adminGetMarketStatisticModels(filter: $filter) {
    totalActiveModels
    totalPendingModels
    totalBlockedModels
    totalRejectedModels
    totalSuspendedModels
    totalNoInstagramModels
    totalDeletedModels
    totalScoutingEnabled
    totalScoutingPlanEnabled
    totalNoScoutingEnabled
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetMarketStatisticModelsQueryVariables,
  APITypes.AdminGetMarketStatisticModelsQuery
>;
export const adminListVenues = /* GraphQL */ `query AdminListVenues($filter: AdminListVenuesFilter!) {
  adminListVenues(filter: $filter) {
    items {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListVenuesQueryVariables,
  APITypes.AdminListVenuesQuery
>;
export const adminListVenuesCount = /* GraphQL */ `query AdminListVenuesCount($filter: AdminListVenuesFilter!) {
  adminListVenuesCount(filter: $filter)
}
` as GeneratedQuery<
  APITypes.AdminListVenuesCountQueryVariables,
  APITypes.AdminListVenuesCountQuery
>;
export const adminGetVenue = /* GraphQL */ `query AdminGetVenue($id: String!) {
  adminGetVenue(id: $id) {
    id
    offers {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    reservations {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    approvedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    marketName
    marketID
    marketCurrency
    market {
      id
      name
      totalActiveVenues
      totalVenues
      totalPremiumVenues
      totalActiveModels
      totalUsers
      totalPremiumUsers
      percentActiveModels
      totalActiveOffers
      totalActiveVenueWithOffers
      totalPremiumOffers
      totalSpots
      totalReservations
      percentBookedLastFewDays
      percentBookedAllTime
      valueActiveOfferSpots
      totalReach
      stateProvince
      countryCode
      country
      currency
      status
      unitSystem
      createdAt
      tipEnabled
      totalVenuesLeads
      totalApplicationUsers
      __typename
    }
    type
    phone
    email
    name
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    contactFirstname
    contactLastname
    contactPosition
    location {
      lat
      lon
      __typename
    }
    address1
    address2
    city
    postalCode
    stateProvince
    country
    countryCode
    dependentLocality
    timeZoneId
    website
    instagramHandle
    referredBy
    description
    sideLetter
    approvalDate
    device
    totalActiveOffers
    totalOffers
    totalFavourites
    totalFavouritesByActiveUsers
    totalSpots
    totalReservations
    totalCheckins
    totalContentSaved
    totalImpressions
    percentBooked
    totalReach
    totalNoshow
    distance
    lastSeen
    lastBrowser
    lastPlatform
    lastSynced
    createdAt
    updatedAt
    status
    offerReservationStatus
    stats
    enabledPushNotifications
    enabledLocationAccess
    offerUpdatedAt
    reservationUpdatedAt
    closedDates
    checkInRadius
    platform
    notes
    enabledEmailNotifications
    currency
    tags
    appVersion
    tncVersion
    newContentAvailable
    availableDownloads
    unreadNotificationCount
    masterVenueID
    views
    formattedAddress
    tipEnabled
    premium
    lifestyleMembersAccess
    selectMembersAccess
    accessRequestsCount
    hasPermissionRequiredOffers
    hasSetLifestyleAccessType
    hasSetSelectAccessType
    plan
    termsAccepted
    referralInstagramHandle
    referredInstagramUserId
    totalAgencyUserInvites
    totalAgencyUserSignedInvites
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetVenueQueryVariables,
  APITypes.AdminGetVenueQuery
>;
export const venueAdminGetVenue = /* GraphQL */ `query VenueAdminGetVenue {
  venueAdminGetVenue {
    id
    offers {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    reservations {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    approvedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    marketName
    marketID
    marketCurrency
    market {
      id
      name
      totalActiveVenues
      totalVenues
      totalPremiumVenues
      totalActiveModels
      totalUsers
      totalPremiumUsers
      percentActiveModels
      totalActiveOffers
      totalActiveVenueWithOffers
      totalPremiumOffers
      totalSpots
      totalReservations
      percentBookedLastFewDays
      percentBookedAllTime
      valueActiveOfferSpots
      totalReach
      stateProvince
      countryCode
      country
      currency
      status
      unitSystem
      createdAt
      tipEnabled
      totalVenuesLeads
      totalApplicationUsers
      __typename
    }
    type
    phone
    email
    name
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    contactFirstname
    contactLastname
    contactPosition
    location {
      lat
      lon
      __typename
    }
    address1
    address2
    city
    postalCode
    stateProvince
    country
    countryCode
    dependentLocality
    timeZoneId
    website
    instagramHandle
    referredBy
    description
    sideLetter
    approvalDate
    device
    totalActiveOffers
    totalOffers
    totalFavourites
    totalFavouritesByActiveUsers
    totalSpots
    totalReservations
    totalCheckins
    totalContentSaved
    totalImpressions
    percentBooked
    totalReach
    totalNoshow
    distance
    lastSeen
    lastBrowser
    lastPlatform
    lastSynced
    createdAt
    updatedAt
    status
    offerReservationStatus
    stats
    enabledPushNotifications
    enabledLocationAccess
    offerUpdatedAt
    reservationUpdatedAt
    closedDates
    checkInRadius
    platform
    notes
    enabledEmailNotifications
    currency
    tags
    appVersion
    tncVersion
    newContentAvailable
    availableDownloads
    unreadNotificationCount
    masterVenueID
    views
    formattedAddress
    tipEnabled
    premium
    lifestyleMembersAccess
    selectMembersAccess
    accessRequestsCount
    hasPermissionRequiredOffers
    hasSetLifestyleAccessType
    hasSetSelectAccessType
    plan
    termsAccepted
    referralInstagramHandle
    referredInstagramUserId
    totalAgencyUserInvites
    totalAgencyUserSignedInvites
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueAdminGetVenueQueryVariables,
  APITypes.VenueAdminGetVenueQuery
>;
export const adminGetVenueCreditLedger = /* GraphQL */ `query AdminGetVenueCreditLedger($filter: VenueCreditLedgerFilter!) {
  adminGetVenueCreditLedger(filter: $filter) {
    items {
      credits
      createdAt
      updatedAt
      title
      subtitle
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetVenueCreditLedgerQueryVariables,
  APITypes.AdminGetVenueCreditLedgerQuery
>;
export const venueAdminGetOffer = /* GraphQL */ `query VenueAdminGetOffer($id: String!) {
  venueAdminGetOffer(id: $id) {
    id
    title
    venueID
    venue {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    venueName
    marketName
    marketCurrency
    marketID
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    description
    category
    subCategory
    availableTo
    gender
    reservations {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    offerValue
    offerValueUSD
    startDate
    endDate
    startTime
    endTime
    durationInHours
    spots
    waitTime
    availableDays
    dressCode
    includeSelfInPost
    includeServiceOrProductInPost
    instagramTag
    postOnInstagramFeed
    postOnInstagramStories
    postOnInstagramReels
    postOnInstagramStories3x
    yelpReview
    suggestedTip
    tipType
    reasonRejected
    totalReservations
    totalCheckins
    percentBooked
    totalUniqueVisitors
    location {
      lat
      lon
      __typename
    }
    timeZoneId
    lastSynced
    createdAt
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    stats {
      spotCount
      reservationCount
      checkInCount
      __typename
    }
    offerData
    status
    startDateTime
    additionalreq
    minFollowers
    confirmWithEmail
    confirmWithCall
    effectiveStartDateTime
    effectiveEndDateTime
    originalOfferID
    depositCost
    premium
    private
    views
    lifestyleMembersAccess
    selectMembersAccess
    totalSavedContent
    address
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueAdminGetOfferQueryVariables,
  APITypes.VenueAdminGetOfferQuery
>;
export const adminListOfferHistory = /* GraphQL */ `query AdminListOfferHistory($filter: AdminListOfferHistoryFilter!) {
  adminListOfferHistory(filter: $filter) {
    items {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListOfferHistoryQueryVariables,
  APITypes.AdminListOfferHistoryQuery
>;
export const adminListOffers = /* GraphQL */ `query AdminListOffers($filter: AdminListOffersFilter!) {
  adminListOffers(filter: $filter) {
    items {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListOffersQueryVariables,
  APITypes.AdminListOffersQuery
>;
export const adminListOffersCount = /* GraphQL */ `query AdminListOffersCount($filter: AdminListOffersFilter!) {
  adminListOffersCount(filter: $filter)
}
` as GeneratedQuery<
  APITypes.AdminListOffersCountQueryVariables,
  APITypes.AdminListOffersCountQuery
>;
export const venueAdminListOffers = /* GraphQL */ `query VenueAdminListOffers($filter: AdminListOffersFilter!) {
  venueAdminListOffers(filter: $filter) {
    items {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueAdminListOffersQueryVariables,
  APITypes.VenueAdminListOffersQuery
>;
export const adminGetOffer = /* GraphQL */ `query AdminGetOffer($id: String!) {
  adminGetOffer(id: $id) {
    id
    title
    venueID
    venue {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    venueName
    marketName
    marketCurrency
    marketID
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    description
    category
    subCategory
    availableTo
    gender
    reservations {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    offerValue
    offerValueUSD
    startDate
    endDate
    startTime
    endTime
    durationInHours
    spots
    waitTime
    availableDays
    dressCode
    includeSelfInPost
    includeServiceOrProductInPost
    instagramTag
    postOnInstagramFeed
    postOnInstagramStories
    postOnInstagramReels
    postOnInstagramStories3x
    yelpReview
    suggestedTip
    tipType
    reasonRejected
    totalReservations
    totalCheckins
    percentBooked
    totalUniqueVisitors
    location {
      lat
      lon
      __typename
    }
    timeZoneId
    lastSynced
    createdAt
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    stats {
      spotCount
      reservationCount
      checkInCount
      __typename
    }
    offerData
    status
    startDateTime
    additionalreq
    minFollowers
    confirmWithEmail
    confirmWithCall
    effectiveStartDateTime
    effectiveEndDateTime
    originalOfferID
    depositCost
    premium
    private
    views
    lifestyleMembersAccess
    selectMembersAccess
    totalSavedContent
    address
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetOfferQueryVariables,
  APITypes.AdminGetOfferQuery
>;
export const adminGetTotalOfferByCategory = /* GraphQL */ `query AdminGetTotalOfferByCategory(
  $filter: AdminGetTotalOfferByCategoryFilter
) {
  adminGetTotalOfferByCategory(filter: $filter)
}
` as GeneratedQuery<
  APITypes.AdminGetTotalOfferByCategoryQueryVariables,
  APITypes.AdminGetTotalOfferByCategoryQuery
>;
export const venueGetOfferStatistics = /* GraphQL */ `query VenueGetOfferStatistics($filter: VenueGetOfferStatisticsRequest!) {
  venueGetOfferStatistics(filter: $filter) {
    totalActiveOffers
    totalCancelledOffers
    totalPendingOffers
    totalRejectedOffers
    totalEndedOffers
    totalInactiveOffers
    totalDeletedOffers
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetOfferStatisticsQueryVariables,
  APITypes.VenueGetOfferStatisticsQuery
>;
export const listAllSupportCityManagerUsers = /* GraphQL */ `query ListAllSupportCityManagerUsers {
  listAllSupportCityManagerUsers {
    data {
      username
      email
      marketIds
      cognitoGroups
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAllSupportCityManagerUsersQueryVariables,
  APITypes.ListAllSupportCityManagerUsersQuery
>;
export const listAllCohorts = /* GraphQL */ `query ListAllCohorts {
  listAllCohorts {
    lapsed0UsersLt1k
    lapsed0Users1kTo10k
    lapsed0Users10kTo50k
    lapsed0Users50kTo100k
    lapsed0UsersGt100k
    lapsedUsersLt1k
    lapsedUsers1kTo10k
    lapsedUsers10kTo50k
    lapsedUsers50kTo100k
    lapsedUsersGt100k
    casualUsersLt1k
    casualUsers1kTo10k
    casualUsers10kTo50k
    casualUsers50kTo100k
    casualUsersGt100k
    powerUsersLt1k
    powerUsers1kTo10k
    powerUsers10kTo50k
    powerUsers50kTo100k
    powerUsersGt100k
    usersRetentionPerMonth
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAllCohortsQueryVariables,
  APITypes.ListAllCohortsQuery
>;
export const adminListUsers = /* GraphQL */ `query AdminListUsers($filter: AdminListUsersFilter!) {
  adminListUsers(filter: $filter) {
    items {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListUsersQueryVariables,
  APITypes.AdminListUsersQuery
>;
export const adminListPortfolioContent = /* GraphQL */ `query AdminListPortfolioContent($filter: AdminListPortfolioContentFilter!) {
  adminListPortfolioContent(filter: $filter) {
    items {
      id
      userID
      portfolio
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListPortfolioContentQueryVariables,
  APITypes.AdminListPortfolioContentQuery
>;
export const adminListVenuePortfolioContent = /* GraphQL */ `query AdminListVenuePortfolioContent(
  $filter: AdminListVenuePortfolioContentFilter!
) {
  adminListVenuePortfolioContent(filter: $filter) {
    items {
      id
      venueID
      portfolio
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListVenuePortfolioContentQueryVariables,
  APITypes.AdminListVenuePortfolioContentQuery
>;
export const userListVenuePortfolioContent = /* GraphQL */ `query UserListVenuePortfolioContent(
  $filter: UserListVenuePortfolioContentFilter!
) {
  userListVenuePortfolioContent(filter: $filter) {
    items {
      id
      venueID
      portfolio
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListVenuePortfolioContentQueryVariables,
  APITypes.UserListVenuePortfolioContentQuery
>;
export const venueListUserPortfolioContent = /* GraphQL */ `query VenueListUserPortfolioContent(
  $filter: VenueListUserPortfolioContentFilter!
) {
  venueListUserPortfolioContent(filter: $filter) {
    items {
      id
      userID
      portfolio
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListUserPortfolioContentQueryVariables,
  APITypes.VenueListUserPortfolioContentQuery
>;
export const venueListUserReservationContent = /* GraphQL */ `query VenueListUserReservationContent(
  $filter: VenueListUserReservationContentFilter!
) {
  venueListUserReservationContent(filter: $filter) {
    items {
      id
      seen
      downloaded
      createdAt
      updatedAt
      userID
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListUserReservationContentQueryVariables,
  APITypes.VenueListUserReservationContentQuery
>;
export const userListUserReservationContent = /* GraphQL */ `query UserListUserReservationContent(
  $filter: UserListUserReservationContentFilter!
) {
  userListUserReservationContent(filter: $filter) {
    items {
      id
      seen
      downloaded
      createdAt
      updatedAt
      userID
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListUserReservationContentQueryVariables,
  APITypes.UserListUserReservationContentQuery
>;
export const venueListUsers = /* GraphQL */ `query VenueListUsers($filter: VenueListUsersFilter!) {
  venueListUsers(filter: $filter) {
    items {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListUsersQueryVariables,
  APITypes.VenueListUsersQuery
>;
export const venueListUserFvourites = /* GraphQL */ `query VenueListUserFvourites($filter: VenueListUserFvouritesFilter!) {
  venueListUserFvourites(filter: $filter) {
    items {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListUserFvouritesQueryVariables,
  APITypes.VenueListUserFvouritesQuery
>;
export const agencyListUsers = /* GraphQL */ `query AgencyListUsers($filter: AgencyListUsersFilter!) {
  agencyListUsers(filter: $filter) {
    items {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AgencyListUsersQueryVariables,
  APITypes.AgencyListUsersQuery
>;
export const agencyListCountries = /* GraphQL */ `query AgencyListCountries($filter: AgencyListCountriesFilter!) {
  agencyListCountries(filter: $filter) {
    items {
      name
      totalUsers
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AgencyListCountriesQueryVariables,
  APITypes.AgencyListCountriesQuery
>;
export const agencyListMarkets = /* GraphQL */ `query AgencyListMarkets($filter: AgencyListCountriesFilter!) {
  agencyListMarkets(filter: $filter) {
    items {
      name
      totalUsers
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AgencyListMarketsQueryVariables,
  APITypes.AgencyListMarketsQuery
>;
export const adminListUserHistory = /* GraphQL */ `query AdminListUserHistory($filter: AdminListUserHistoryFilter!) {
  adminListUserHistory(filter: $filter) {
    items {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListUserHistoryQueryVariables,
  APITypes.AdminListUserHistoryQuery
>;
export const adminListUsersCount = /* GraphQL */ `query AdminListUsersCount($filter: AdminListUsersFilter!) {
  adminListUsersCount(filter: $filter)
}
` as GeneratedQuery<
  APITypes.AdminListUsersCountQueryVariables,
  APITypes.AdminListUsersCountQuery
>;
export const adminGetUser = /* GraphQL */ `query AdminGetUser($id: String!) {
  adminGetUser(id: $id) {
    id
    email
    suspensions {
      id
      userID
      startDate
      endDate
      reason
      detail
      paymentAmount
      status
      lastSynced
      createdAt
      __typename
    }
    penalties {
      id
      userID
      reservationID
      type
      status
      createdAt
      currency
      paymentAmount
      timeZoneId
      __typename
    }
    reservations {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    phone
    name
    country
    firstname
    lastname
    birthdate
    instagramHandle
    instagramFollowers
    instagramUpdatedAt
    failedInstaCheck
    profession
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    gender
    compositeCard {
      __typename
    }
    agency
    motherAgency
    timezone
    currency
    homeCountry
    homeStateProvince
    homeCity
    city
    marketName
    marketID
    market {
      id
      name
      totalActiveVenues
      totalVenues
      totalPremiumVenues
      totalActiveModels
      totalUsers
      totalPremiumUsers
      percentActiveModels
      totalActiveOffers
      totalActiveVenueWithOffers
      totalPremiumOffers
      totalSpots
      totalReservations
      percentBookedLastFewDays
      percentBookedAllTime
      valueActiveOfferSpots
      totalReach
      stateProvince
      countryCode
      country
      currency
      status
      unitSystem
      createdAt
      tipEnabled
      totalVenuesLeads
      totalApplicationUsers
      __typename
    }
    currentCity
    location {
      lat
      lon
      __typename
    }
    credits
    approvalDate
    lastSeen
    device
    totalCheckins
    totalCheckinLastMonth
    totalOfferValueRedeemed
    totalReservations
    totalWarnings
    totalSuspensions
    totalNoShow
    totalActiveNoShow
    totalCancellation
    totalNoPost
    totalPenalties
    totalActivePenalties
    totalPortfolioContents
    totalContentUploaded
    offerCategoriesRedeemed
    timeOnApp
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    reviewedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    acceptedCodeOfConduct
    enabledPushNotifications
    enabledLocationAccess
    lastSynced
    createdAt
    updatedAt
    status
    reactivationDate
    instagramDuplicates
    firstnameDuplicates
    lastnameDuplicates
    birthdateDuplicates
    instagramVerificationRequested
    platform
    stripeId
    totalRequirementsSubmitted
    tags
    appVersion
    noshowCredits
    tncVersion
    hasPendingNoshowCredits
    agencyProfileUrl
    freelancerBodyProfile {
      height
      waist
      bust
      hips
      __typename
    }
    freelancerPictures {
      __typename
    }
    freelancer
    reservationRewardClaimed
    instagramVerified
    points
    premium
    savedByVenue
    inviteCredits
    newInvitesAvailable
    invitesAvailable
    invitesReceived
    webProfilePrivate
    rating
    totalRatings
    tiktokHandle
    xHandle
    youtubeHandle
    highRated
    rank
    scoutingEnabled
    profileVideo
    scoutingPlanEnabled
    inviteStatus
    agencyInviteStatus
    hasDeletedInstagram
    hasDeletedEmail
    lifestyle
    signedByAgency {
      id
      name
      __typename
    }
    profileVideoWithThumbnail {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    potentialMatches
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetUserQueryVariables,
  APITypes.AdminGetUserQuery
>;
export const adminListPenalties = /* GraphQL */ `query AdminListPenalties($filter: AdminListPenaltiesFilter!) {
  adminListPenalties(filter: $filter) {
    items {
      id
      userID
      reservationID
      type
      status
      createdAt
      currency
      paymentAmount
      timeZoneId
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListPenaltiesQueryVariables,
  APITypes.AdminListPenaltiesQuery
>;
export const adminGetPenalty = /* GraphQL */ `query AdminGetPenalty($id: String!) {
  adminGetPenalty(id: $id) {
    id
    userID
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    reservationID
    reservation {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    type
    status
    createdAt
    currency
    paymentAmount
    timeZoneId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetPenaltyQueryVariables,
  APITypes.AdminGetPenaltyQuery
>;
export const adminListReservations = /* GraphQL */ `query AdminListReservations($filter: AdminListReservationsFilter!) {
  adminListReservations(filter: $filter) {
    items {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListReservationsQueryVariables,
  APITypes.AdminListReservationsQuery
>;
export const adminListReservationContent = /* GraphQL */ `query AdminListReservationContent(
  $filter: AdminListReservationContentsFilter!
) {
  adminListReservationContent(filter: $filter) {
    items {
      id
      reservationID
      seen
      downloaded
      byAdmin
      byVenue
      createdAt
      updatedAt
      userfirstname
      userlastname
      userInstagramHandle
      userInstagramFollowers
      userID
      venueID
      venueName
      offerTitle
      content
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListReservationContentQueryVariables,
  APITypes.AdminListReservationContentQuery
>;
export const adminListReservationContentCount = /* GraphQL */ `query AdminListReservationContentCount(
  $filter: AdminListReservationContentsFilter!
) {
  adminListReservationContentCount(filter: $filter) {
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListReservationContentCountQueryVariables,
  APITypes.AdminListReservationContentCountQuery
>;
export const supportListReservationsForReview = /* GraphQL */ `query SupportListReservationsForReview(
  $filter: AdminListSupportReservationsFilter
) {
  supportListReservationsForReview(filter: $filter) {
    items {
      id
      marketName
      marketID
      offerID
      offerTitle
      userID
      name
      venueID
      venueName
      status
      checkInDateTime
      instagramTag
      userInstagramHandle
      validation
      validationRequested
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      suggestedTip
      warnings
      reviewStaff
      postLink
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SupportListReservationsForReviewQueryVariables,
  APITypes.SupportListReservationsForReviewQuery
>;
export const supportCountReservationsForReview = /* GraphQL */ `query SupportCountReservationsForReview(
  $filter: AdminCountSupportReservationsFilter
) {
  supportCountReservationsForReview(filter: $filter) {
    items {
      reviewStaff
      count
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SupportCountReservationsForReviewQueryVariables,
  APITypes.SupportCountReservationsForReviewQuery
>;
export const venueAdminListReservations = /* GraphQL */ `query VenueAdminListReservations($filter: AdminListReservationsFilter!) {
  venueAdminListReservations(filter: $filter) {
    items {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueAdminListReservationsQueryVariables,
  APITypes.VenueAdminListReservationsQuery
>;
export const adminGetReservation = /* GraphQL */ `query AdminGetReservation($id: String!) {
  adminGetReservation(id: $id) {
    id
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    marketName
    marketID
    offerID
    offerCategory
    offer {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    offerTitle
    offerValue
    offerStartTime
    offerEndTime
    offerEndDateTime
    timeZoneId
    userID
    name
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    venueID
    venue {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    venueName
    venueLocation {
      lat
      lon
      __typename
    }
    venueAddress1
    venueAddress2
    venueCity
    venueCountry
    note
    location {
      lat
      lon
      __typename
    }
    status
    reservationDate
    actionDate
    checkInDateTime
    createdAt
    updatedAt
    venuePicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    offerPicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    userPicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    canSubmitRequirements
    requirementsSubmitted
    instagramTag
    noShowStatus
    currency
    payoutAmount
    validation
    validationRequested
    penaltyType
    effectiveStartDateTime
    effectiveEndDateTime
    offerPostType
    postLink
    inviteID
    privateOffer
    requirementsSubmittedDateTime
    rated
    ratedByVenue
    rating
    offerValueSnapshot
    dressCodeSnapshot
    suggestedTipSnapshot
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetReservationQueryVariables,
  APITypes.AdminGetReservationQuery
>;
export const venueAdminGetReservation = /* GraphQL */ `query VenueAdminGetReservation($id: String!) {
  venueAdminGetReservation(id: $id) {
    id
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    marketName
    marketID
    offerID
    offerCategory
    offer {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    offerTitle
    offerValue
    offerStartTime
    offerEndTime
    offerEndDateTime
    timeZoneId
    userID
    name
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    venueID
    venue {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    venueName
    venueLocation {
      lat
      lon
      __typename
    }
    venueAddress1
    venueAddress2
    venueCity
    venueCountry
    note
    location {
      lat
      lon
      __typename
    }
    status
    reservationDate
    actionDate
    checkInDateTime
    createdAt
    updatedAt
    venuePicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    offerPicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    userPicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    canSubmitRequirements
    requirementsSubmitted
    instagramTag
    noShowStatus
    currency
    payoutAmount
    validation
    validationRequested
    penaltyType
    effectiveStartDateTime
    effectiveEndDateTime
    offerPostType
    postLink
    inviteID
    privateOffer
    requirementsSubmittedDateTime
    rated
    ratedByVenue
    rating
    offerValueSnapshot
    dressCodeSnapshot
    suggestedTipSnapshot
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueAdminGetReservationQueryVariables,
  APITypes.VenueAdminGetReservationQuery
>;
export const adminListInvitesCount = /* GraphQL */ `query AdminListInvitesCount($filter: AdminListInvitesFilter!) {
  adminListInvitesCount(filter: $filter)
}
` as GeneratedQuery<
  APITypes.AdminListInvitesCountQueryVariables,
  APITypes.AdminListInvitesCountQuery
>;
export const adminListInvites = /* GraphQL */ `query AdminListInvites($filter: AdminListInvitesFilter!) {
  adminListInvites(filter: $filter) {
    items {
      id
      userID
      offerID
      expiry
      createdAt
      updatedAt
      status
      availableDates
      startTime
      endTime
      seen
      marketID
      marketName
      timeZoneId
      offerTitle
      offerUpdatedAt
      userName
      userUpdatedAt
      venueID
      venueName
      location
      venueUpdatedAt
      instagramTag
      effectiveStartDateTime
      reservationDate
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListInvitesQueryVariables,
  APITypes.AdminListInvitesQuery
>;
export const adminGetInvite = /* GraphQL */ `query AdminGetInvite($id: String!) {
  adminGetInvite(id: $id) {
    id
    userID
    offerID
    expiry
    createdAt
    updatedAt
    status
    availableDates
    startTime
    endTime
    seen
    marketID
    marketName
    timeZoneId
    offerTitle
    offerUpdatedAt
    userName
    userUpdatedAt
    venueID
    venueName
    location
    venueUpdatedAt
    instagramTag
    venuePicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    offerPicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    userPicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    effectiveStartDateTime
    reservationDate
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetInviteQueryVariables,
  APITypes.AdminGetInviteQuery
>;
export const adminListSuspensions = /* GraphQL */ `query AdminListSuspensions($filter: AdminListSuspensionsFilter!) {
  adminListSuspensions(filter: $filter) {
    items {
      id
      userID
      startDate
      endDate
      reason
      detail
      paymentAmount
      status
      lastSynced
      createdAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListSuspensionsQueryVariables,
  APITypes.AdminListSuspensionsQuery
>;
export const adminListSuspensionsTotals = /* GraphQL */ `query AdminListSuspensionsTotals {
  adminListSuspensionsTotals {
    totalActive
    totalArchived
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListSuspensionsTotalsQueryVariables,
  APITypes.AdminListSuspensionsTotalsQuery
>;
export const adminGetSuspensionStatistics = /* GraphQL */ `query AdminGetSuspensionStatistics(
  $filter: AdminGetSuspensionStatisticsFilter!
) {
  adminGetSuspensionStatistics(filter: $filter) {
    totalPenaltyCount
    totalNoshowCount
    totalAdminCount
    totalNoInstagramCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetSuspensionStatisticsQueryVariables,
  APITypes.AdminGetSuspensionStatisticsQuery
>;
export const adminGetSuspension = /* GraphQL */ `query AdminGetSuspension($id: String!) {
  adminGetSuspension(id: $id) {
    id
    suspendedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    userID
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    startDate
    endDate
    reason
    detail
    paymentAmount
    status
    lastSynced
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetSuspensionQueryVariables,
  APITypes.AdminGetSuspensionQuery
>;
export const adminTotalsGetToday = /* GraphQL */ `query AdminTotalsGetToday {
  adminTotalsGetToday
}
` as GeneratedQuery<
  APITypes.AdminTotalsGetTodayQueryVariables,
  APITypes.AdminTotalsGetTodayQuery
>;
export const adminTotalsGetMonthly = /* GraphQL */ `query AdminTotalsGetMonthly {
  adminTotalsGetMonthly
}
` as GeneratedQuery<
  APITypes.AdminTotalsGetMonthlyQueryVariables,
  APITypes.AdminTotalsGetMonthlyQuery
>;
export const adminGetDashboardData = /* GraphQL */ `query AdminGetDashboardData {
  adminGetDashboardData {
    totalActiveUsers
    totalActiveUsersLastMonth
    totalReach
    newUserApplications
    newApprovedUsers
    totalPendingUsers
    totalRejectedUsers
    totalActiveVenues
    totalActiveOffers
    totalValueOfActiveOffersSpots
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetDashboardDataQueryVariables,
  APITypes.AdminGetDashboardDataQuery
>;
export const adminGetAdvancedDashboardData = /* GraphQL */ `query AdminGetAdvancedDashboardData(
  $fromDate: String!
  $toDate: String!
  $by: String!
) {
  adminGetAdvancedDashboardData(fromDate: $fromDate, toDate: $toDate, by: $by) {
    totals {
      activeModelsLastMonth
      activeOffers
      activeVenues
      activeVenuesWithOffer
      reach
      redeemedOfferValue
      __typename
    }
    lines {
      date
      creditPurchaseByModel
      creditPurchaseByVenue
      modelApplicationsPending
      modelApplicationsActive
      modelApplicationsRejected
      venuesApproved
      venuesBlocked
      offersValue
      reservationsReserved
      reservationsCheckedIn
      reservationsCancelled
      reservationsNoShow
      premiumModel
      premiumVenue
      premiumExpiredModel
      __typename
    }
    totalActiveOffers
    totalActiveVenues
    totalPremiumVenues
    totalStarterVenues
    totalPlusVenues
    totalPlusFreeVenues
    totalStarterFreeVenues
    totalActiveUsers
    totalPremiumUsers
    totalRejectedUsers
    totalPendingUsers
    totalModelCreditsInMonth
    totalVenueCreditsInMonth
    totalUsersBpSelect
    totalUsersBpLifestyle
    totalScoutingActiveUsers
    totalScoutingRejectedUsers
    totalValueOfActiveOffersSpots
    totalModelCreditsInFromToDate
    totalVenueCreditsInFromToDate
    totalPremiumVenuesInFromToDate
    totalPremiumUsersInFromToDate
    totalPremiumExpiredUsersInFromToDate
    modelApplicationsInFromToDate
    venueApplicationsInFromToDate
    reservationsInFromToDate
    registeredFreelancerModelsInFromToDate
    registeredModelsInFromToDate
    totalPremiumVenuesWithoutSubscription
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetAdvancedDashboardDataQueryVariables,
  APITypes.AdminGetAdvancedDashboardDataQuery
>;
export const adminListCampaigns = /* GraphQL */ `query AdminListCampaigns($filter: AdminListCampaignFilter!) {
  adminListCampaigns(filter: $filter) {
    items {
      id
      title
      type
      content
      status
      selectedUserIDList
      selectedVenueIDList
      selectedMarketIDList
      selectAllMarkets
      selectedEntities
      date
      createdAt
      updatedAt
      venueID
      offerID
      screen
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListCampaignsQueryVariables,
  APITypes.AdminListCampaignsQuery
>;
export const adminGetCampaign = /* GraphQL */ `query AdminGetCampaign($id: String!) {
  adminGetCampaign(id: $id) {
    id
    title
    type
    content
    status
    selectedUserIDList
    selectedVenueIDList
    selectedMarketIDList
    selectAllMarkets
    selectedEntities
    date
    createdAt
    updatedAt
    venueID
    offerID
    screen
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetCampaignQueryVariables,
  APITypes.AdminGetCampaignQuery
>;
export const adminListFeedback = /* GraphQL */ `query AdminListFeedback($filter: AdminListFeedbackFilter) {
  adminListFeedback(filter: $filter) {
    id
    message
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    venue {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    createdAt
    updatedAt
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListFeedbackQueryVariables,
  APITypes.AdminListFeedbackQuery
>;
export const adminListUserComplaint = /* GraphQL */ `query AdminListUserComplaint($filter: AdminListUserComplaintFilter) {
  adminListUserComplaint(filter: $filter) {
    id
    userID
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    type
    subtype
    instagramHandle
    penaltyID
    noshowID
    rawData
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListUserComplaintQueryVariables,
  APITypes.AdminListUserComplaintQuery
>;
export const adminListVenueComplaint = /* GraphQL */ `query AdminListVenueComplaint($filter: AdminListUserComplaintFilter) {
  adminListVenueComplaint(filter: $filter) {
    id
    userID
    venueID
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    type
    subtype
    rawData
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListVenueComplaintQueryVariables,
  APITypes.AdminListVenueComplaintQuery
>;
export const adminListUserComplaintCount = /* GraphQL */ `query AdminListUserComplaintCount($filter: AdminListUserComplaintFilter) {
  adminListUserComplaintCount(filter: $filter)
}
` as GeneratedQuery<
  APITypes.AdminListUserComplaintCountQueryVariables,
  APITypes.AdminListUserComplaintCountQuery
>;
export const adminListVenueComplaintCount = /* GraphQL */ `query AdminListVenueComplaintCount($filter: AdminListUserComplaintFilter) {
  adminListVenueComplaintCount(filter: $filter)
}
` as GeneratedQuery<
  APITypes.AdminListVenueComplaintCountQueryVariables,
  APITypes.AdminListVenueComplaintCountQuery
>;
export const adminGetUnreadUserComplaintCount = /* GraphQL */ `query AdminGetUnreadUserComplaintCount($filter: AdminListUserComplaintFilter) {
  adminGetUnreadUserComplaintCount(filter: $filter)
}
` as GeneratedQuery<
  APITypes.AdminGetUnreadUserComplaintCountQueryVariables,
  APITypes.AdminGetUnreadUserComplaintCountQuery
>;
export const adminListUserComplaintTypesCount = /* GraphQL */ `query AdminListUserComplaintTypesCount(
  $filter: AdminListUserComplaintTypesCountFilter
) {
  adminListUserComplaintTypesCount(filter: $filter) {
    type
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListUserComplaintTypesCountQueryVariables,
  APITypes.AdminListUserComplaintTypesCountQuery
>;
export const adminListUserComplaintUserIDs = /* GraphQL */ `query AdminListUserComplaintUserIDs(
  $filter: AdminListUserComplaintUserIDsFilter
) {
  adminListUserComplaintUserIDs(filter: $filter) {
    complaintID
    userID
    complaintType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListUserComplaintUserIDsQueryVariables,
  APITypes.AdminListUserComplaintUserIDsQuery
>;
export const adminListVenueComplaintTypesCount = /* GraphQL */ `query AdminListVenueComplaintTypesCount(
  $filter: AdminListUserComplaintTypesCountFilter
) {
  adminListVenueComplaintTypesCount(filter: $filter) {
    type
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListVenueComplaintTypesCountQueryVariables,
  APITypes.AdminListVenueComplaintTypesCountQuery
>;
export const adminListVenueComplaintVenueIDs = /* GraphQL */ `query AdminListVenueComplaintVenueIDs(
  $filter: AdminListUserComplaintUserIDsFilter
) {
  adminListVenueComplaintVenueIDs(filter: $filter) {
    complaintID
    venueID
    complaintType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListVenueComplaintVenueIDsQueryVariables,
  APITypes.AdminListVenueComplaintVenueIDsQuery
>;
export const venueListVenueComplaintCount = /* GraphQL */ `query VenueListVenueComplaintCount($filter: AdminListUserComplaintFilter) {
  venueListVenueComplaintCount(filter: $filter)
}
` as GeneratedQuery<
  APITypes.VenueListVenueComplaintCountQueryVariables,
  APITypes.VenueListVenueComplaintCountQuery
>;
export const adminListAgencyInvites = /* GraphQL */ `query AdminListAgencyInvites($filter: AgencyListInvitesFilter!) {
  adminListAgencyInvites(filter: $filter) {
    items {
      id
      userID
      venueID
      status
      createdAt
      updatedAt
      marketName
      seen
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListAgencyInvitesQueryVariables,
  APITypes.AdminListAgencyInvitesQuery
>;
export const adminGetAnalytics = /* GraphQL */ `query AdminGetAnalytics($filter: GetAnalyticsRequest!) {
  adminGetAnalytics(filter: $filter) {
    followers
    engagemenRate
    credibilityScore
    credibilityClass
    averageLikes
    averageComments
    averageReelViews
    genderDistribution {
      female
      male
      __typename
    }
    countries {
      name
      code
      followers
      __typename
    }
    priceEstimate {
      min
      max
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetAnalyticsQueryVariables,
  APITypes.AdminGetAnalyticsQuery
>;
export const venueGetMarket = /* GraphQL */ `query VenueGetMarket($id: String!) {
  venueGetMarket(id: $id) {
    id
    name
    venues {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    totalActiveVenues
    totalVenues
    totalPremiumVenues
    totalActiveModels
    totalUsers
    totalPremiumUsers
    percentActiveModels
    totalActiveOffers
    totalActiveVenueWithOffers
    totalPremiumOffers
    totalSpots
    totalReservations
    percentBookedLastFewDays
    percentBookedAllTime
    valueActiveOfferSpots
    totalReach
    stateProvince
    countryCode
    country
    currency
    status
    location {
      lat
      lon
      __typename
    }
    timezone {
      dstOffset
      rawOffset
      timeZoneId
      timeZoneName
      __typename
    }
    unitSystem
    createdAt
    tipEnabled
    totalVenuesLeads
    totalApplicationUsers
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetMarketQueryVariables,
  APITypes.VenueGetMarketQuery
>;
export const venueGetAnalytics = /* GraphQL */ `query VenueGetAnalytics($filter: GetAnalyticsRequest) {
  venueGetAnalytics(filter: $filter) {
    followers
    engagemenRate
    credibilityScore
    credibilityClass
    averageLikes
    averageComments
    averageReelViews
    genderDistribution {
      female
      male
      __typename
    }
    countries {
      name
      code
      followers
      __typename
    }
    priceEstimate {
      min
      max
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetAnalyticsQueryVariables,
  APITypes.VenueGetAnalyticsQuery
>;
export const venueGetVenue = /* GraphQL */ `query VenueGetVenue($id: String!) {
  venueGetVenue(id: $id) {
    id
    offers {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    reservations {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    approvedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    marketName
    marketID
    marketCurrency
    market {
      id
      name
      totalActiveVenues
      totalVenues
      totalPremiumVenues
      totalActiveModels
      totalUsers
      totalPremiumUsers
      percentActiveModels
      totalActiveOffers
      totalActiveVenueWithOffers
      totalPremiumOffers
      totalSpots
      totalReservations
      percentBookedLastFewDays
      percentBookedAllTime
      valueActiveOfferSpots
      totalReach
      stateProvince
      countryCode
      country
      currency
      status
      unitSystem
      createdAt
      tipEnabled
      totalVenuesLeads
      totalApplicationUsers
      __typename
    }
    type
    phone
    email
    name
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    contactFirstname
    contactLastname
    contactPosition
    location {
      lat
      lon
      __typename
    }
    address1
    address2
    city
    postalCode
    stateProvince
    country
    countryCode
    dependentLocality
    timeZoneId
    website
    instagramHandle
    referredBy
    description
    sideLetter
    approvalDate
    device
    totalActiveOffers
    totalOffers
    totalFavourites
    totalFavouritesByActiveUsers
    totalSpots
    totalReservations
    totalCheckins
    totalContentSaved
    totalImpressions
    percentBooked
    totalReach
    totalNoshow
    distance
    lastSeen
    lastBrowser
    lastPlatform
    lastSynced
    createdAt
    updatedAt
    status
    offerReservationStatus
    stats
    enabledPushNotifications
    enabledLocationAccess
    offerUpdatedAt
    reservationUpdatedAt
    closedDates
    checkInRadius
    platform
    notes
    enabledEmailNotifications
    currency
    tags
    appVersion
    tncVersion
    newContentAvailable
    availableDownloads
    unreadNotificationCount
    masterVenueID
    views
    formattedAddress
    tipEnabled
    premium
    lifestyleMembersAccess
    selectMembersAccess
    accessRequestsCount
    hasPermissionRequiredOffers
    hasSetLifestyleAccessType
    hasSetSelectAccessType
    plan
    termsAccepted
    referralInstagramHandle
    referredInstagramUserId
    totalAgencyUserInvites
    totalAgencyUserSignedInvites
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetVenueQueryVariables,
  APITypes.VenueGetVenueQuery
>;
export const venueGetPreSignedUrl = /* GraphQL */ `query VenueGetPreSignedUrl($ext: String) {
  venueGetPreSignedUrl(ext: $ext)
}
` as GeneratedQuery<
  APITypes.VenueGetPreSignedUrlQueryVariables,
  APITypes.VenueGetPreSignedUrlQuery
>;
export const venueGetPaymentIntent = /* GraphQL */ `query VenueGetPaymentIntent($input: VenuePaymentIntentInput!) {
  venueGetPaymentIntent(input: $input) {
    customerId
    merchantDisplayName
    customerEphemeralKeySecret
    paymentIntentClientSecret
    applePay {
      merchantCountryCode
      __typename
    }
    googlePay {
      merchantCountryCode
      testEnv
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetPaymentIntentQueryVariables,
  APITypes.VenueGetPaymentIntentQuery
>;
export const venueGetAccountBalance = /* GraphQL */ `query VenueGetAccountBalance($filter: VenueAccountBalanceRequest) {
  venueGetAccountBalance(filter: $filter) {
    balance
    accountId
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetAccountBalanceQueryVariables,
  APITypes.VenueGetAccountBalanceQuery
>;
export const venueGetVerificationUrl = /* GraphQL */ `query VenueGetVerificationUrl($filter: VenueAccountBalanceRequest) {
  venueGetVerificationUrl(filter: $filter) {
    onBoardingURL
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetVerificationUrlQueryVariables,
  APITypes.VenueGetVerificationUrlQuery
>;
export const venueGetSubscriptionSession = /* GraphQL */ `query VenueGetSubscriptionSession($priceId: String) {
  venueGetSubscriptionSession(priceId: $priceId)
}
` as GeneratedQuery<
  APITypes.VenueGetSubscriptionSessionQueryVariables,
  APITypes.VenueGetSubscriptionSessionQuery
>;
export const venueCancelSubscription = /* GraphQL */ `query VenueCancelSubscription($subscriptionId: String) {
  venueCancelSubscription(subscriptionId: $subscriptionId)
}
` as GeneratedQuery<
  APITypes.VenueCancelSubscriptionQueryVariables,
  APITypes.VenueCancelSubscriptionQuery
>;
export const venueListPricing = /* GraphQL */ `query VenueListPricing {
  venueListPricing {
    items {
      id
      object
      active
      billing_scheme
      created
      currency
      custom_unit_amount
      nickname
      product
      unit_amount
      metadata
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListPricingQueryVariables,
  APITypes.VenueListPricingQuery
>;
export const venueGetCollabEstimate = /* GraphQL */ `query VenueGetCollabEstimate($filter: VenueGetCollabEstimateFilter!) {
  venueGetCollabEstimate(filter: $filter) {
    totalPremiumCredits
    totalNonPremiumCredits
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetCollabEstimateQueryVariables,
  APITypes.VenueGetCollabEstimateQuery
>;
export const venueListUserEstimatedPrice = /* GraphQL */ `query VenueListUserEstimatedPrice($filter: VenueListUserPriceEstimatedFilter) {
  venueListUserEstimatedPrice(filter: $filter) {
    items {
      userID
      estimatedPrice
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListUserEstimatedPriceQueryVariables,
  APITypes.VenueListUserEstimatedPriceQuery
>;
export const venueListOffers = /* GraphQL */ `query VenueListOffers($filter: VenueListOffersFilter!) {
  venueListOffers(filter: $filter) {
    items {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListOffersQueryVariables,
  APITypes.VenueListOffersQuery
>;
export const venueListAccessRequests = /* GraphQL */ `query VenueListAccessRequests($filter: VenueListAccessRequetsFilter!) {
  venueListAccessRequests(filter: $filter) {
    items {
      offerID
      userID
      status
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListAccessRequestsQueryVariables,
  APITypes.VenueListAccessRequestsQuery
>;
export const venueListInviteOffers = /* GraphQL */ `query VenueListInviteOffers($filter: VenueListInviteOffersFilter!) {
  venueListInviteOffers(filter: $filter) {
    items {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListInviteOffersQueryVariables,
  APITypes.VenueListInviteOffersQuery
>;
export const venueListInvites = /* GraphQL */ `query VenueListInvites($filter: VenueListInvitesFilter!) {
  venueListInvites(filter: $filter) {
    items {
      id
      userID
      offerID
      expiry
      createdAt
      updatedAt
      status
      availableDates
      startTime
      endTime
      seen
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListInvitesQueryVariables,
  APITypes.VenueListInvitesQuery
>;
export const venueListRatedUsers = /* GraphQL */ `query VenueListRatedUsers($filter: VenueListRatedUsersFilter!) {
  venueListRatedUsers(filter: $filter) {
    items {
      id
      firstname
      instagramHandle
      instagramFollowers
      updatedAt
      lastname
      totalRatings
      averageRatings
      tags
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListRatedUsersQueryVariables,
  APITypes.VenueListRatedUsersQuery
>;
export const agencyListInvites = /* GraphQL */ `query AgencyListInvites($filter: AgencyListInvitesFilter!) {
  agencyListInvites(filter: $filter) {
    items {
      id
      userID
      venueID
      status
      createdAt
      updatedAt
      marketName
      seen
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AgencyListInvitesQueryVariables,
  APITypes.AgencyListInvitesQuery
>;
export const agencyGetInvitesCount = /* GraphQL */ `query AgencyGetInvitesCount($filter: AgencyListInvitesFilter!) {
  agencyGetInvitesCount(filter: $filter) {
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AgencyGetInvitesCountQueryVariables,
  APITypes.AgencyGetInvitesCountQuery
>;
export const venueListOfferImages = /* GraphQL */ `query VenueListOfferImages($filter: VenueListOfferImagesFilter!) {
  venueListOfferImages(filter: $filter) {
    items {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListOfferImagesQueryVariables,
  APITypes.VenueListOfferImagesQuery
>;
export const venueGetOffer = /* GraphQL */ `query VenueGetOffer($id: String!) {
  venueGetOffer(id: $id) {
    id
    title
    venueID
    venue {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    venueName
    marketName
    marketCurrency
    marketID
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    description
    category
    subCategory
    availableTo
    gender
    reservations {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    offerValue
    offerValueUSD
    startDate
    endDate
    startTime
    endTime
    durationInHours
    spots
    waitTime
    availableDays
    dressCode
    includeSelfInPost
    includeServiceOrProductInPost
    instagramTag
    postOnInstagramFeed
    postOnInstagramStories
    postOnInstagramReels
    postOnInstagramStories3x
    yelpReview
    suggestedTip
    tipType
    reasonRejected
    totalReservations
    totalCheckins
    percentBooked
    totalUniqueVisitors
    location {
      lat
      lon
      __typename
    }
    timeZoneId
    lastSynced
    createdAt
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    stats {
      spotCount
      reservationCount
      checkInCount
      __typename
    }
    offerData
    status
    startDateTime
    additionalreq
    minFollowers
    confirmWithEmail
    confirmWithCall
    effectiveStartDateTime
    effectiveEndDateTime
    originalOfferID
    depositCost
    premium
    private
    views
    lifestyleMembersAccess
    selectMembersAccess
    totalSavedContent
    address
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetOfferQueryVariables,
  APITypes.VenueGetOfferQuery
>;
export const venueGetInsights = /* GraphQL */ `query VenueGetInsights($id: String) {
  venueGetInsights(id: $id) {
    totalReach7Days
    totalReach7DaysChange
    totalReach30Days
    totalReach30DaysChange
    totalReachEver
    totalCheckin7Days
    totalCheckin7DaysChange
    totalCheckin30Days
    totalCheckin30DaysChange
    totalCheckinEver
    totalReservations7Days
    totalReservations7DaysChange
    totalReservations30Days
    totalReservations30DaysChange
    totalReservationsEver
    totalNewVisits7Days
    totalNewVisits7DaysChange
    totalNewVisits30Days
    totalNewVisits30DaysChange
    totalNewVisitsEver
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetInsightsQueryVariables,
  APITypes.VenueGetInsightsQuery
>;
export const venueGetCreditLedger = /* GraphQL */ `query VenueGetCreditLedger($filter: VenueCreditLedgerFilter!) {
  venueGetCreditLedger(filter: $filter) {
    items {
      credits
      createdAt
      updatedAt
      title
      subtitle
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetCreditLedgerQueryVariables,
  APITypes.VenueGetCreditLedgerQuery
>;
export const venueGetUser = /* GraphQL */ `query VenueGetUser($filter: VenueGetUserFilter!) {
  venueGetUser(filter: $filter) {
    id
    email
    suspensions {
      id
      userID
      startDate
      endDate
      reason
      detail
      paymentAmount
      status
      lastSynced
      createdAt
      __typename
    }
    penalties {
      id
      userID
      reservationID
      type
      status
      createdAt
      currency
      paymentAmount
      timeZoneId
      __typename
    }
    reservations {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    phone
    name
    country
    firstname
    lastname
    birthdate
    instagramHandle
    instagramFollowers
    instagramUpdatedAt
    failedInstaCheck
    profession
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    gender
    compositeCard {
      __typename
    }
    agency
    motherAgency
    timezone
    currency
    homeCountry
    homeStateProvince
    homeCity
    city
    marketName
    marketID
    market {
      id
      name
      totalActiveVenues
      totalVenues
      totalPremiumVenues
      totalActiveModels
      totalUsers
      totalPremiumUsers
      percentActiveModels
      totalActiveOffers
      totalActiveVenueWithOffers
      totalPremiumOffers
      totalSpots
      totalReservations
      percentBookedLastFewDays
      percentBookedAllTime
      valueActiveOfferSpots
      totalReach
      stateProvince
      countryCode
      country
      currency
      status
      unitSystem
      createdAt
      tipEnabled
      totalVenuesLeads
      totalApplicationUsers
      __typename
    }
    currentCity
    location {
      lat
      lon
      __typename
    }
    credits
    approvalDate
    lastSeen
    device
    totalCheckins
    totalCheckinLastMonth
    totalOfferValueRedeemed
    totalReservations
    totalWarnings
    totalSuspensions
    totalNoShow
    totalActiveNoShow
    totalCancellation
    totalNoPost
    totalPenalties
    totalActivePenalties
    totalPortfolioContents
    totalContentUploaded
    offerCategoriesRedeemed
    timeOnApp
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    reviewedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    acceptedCodeOfConduct
    enabledPushNotifications
    enabledLocationAccess
    lastSynced
    createdAt
    updatedAt
    status
    reactivationDate
    instagramDuplicates
    firstnameDuplicates
    lastnameDuplicates
    birthdateDuplicates
    instagramVerificationRequested
    platform
    stripeId
    totalRequirementsSubmitted
    tags
    appVersion
    noshowCredits
    tncVersion
    hasPendingNoshowCredits
    agencyProfileUrl
    freelancerBodyProfile {
      height
      waist
      bust
      hips
      __typename
    }
    freelancerPictures {
      __typename
    }
    freelancer
    reservationRewardClaimed
    instagramVerified
    points
    premium
    savedByVenue
    inviteCredits
    newInvitesAvailable
    invitesAvailable
    invitesReceived
    webProfilePrivate
    rating
    totalRatings
    tiktokHandle
    xHandle
    youtubeHandle
    highRated
    rank
    scoutingEnabled
    profileVideo
    scoutingPlanEnabled
    inviteStatus
    agencyInviteStatus
    hasDeletedInstagram
    hasDeletedEmail
    lifestyle
    signedByAgency {
      id
      name
      __typename
    }
    profileVideoWithThumbnail {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    potentialMatches
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetUserQueryVariables,
  APITypes.VenueGetUserQuery
>;
export const venueListReservations = /* GraphQL */ `query VenueListReservations($filter: VenueListReservationsFilter!) {
  venueListReservations(filter: $filter) {
    items {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListReservationsQueryVariables,
  APITypes.VenueListReservationsQuery
>;
export const venueListReservationContent = /* GraphQL */ `query VenueListReservationContent(
  $filter: VenueListReservationsContentFilter!
) {
  venueListReservationContent(filter: $filter) {
    items {
      userID
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListReservationContentQueryVariables,
  APITypes.VenueListReservationContentQuery
>;
export const venueListReservationContentV2 = /* GraphQL */ `query VenueListReservationContentV2(
  $filter: VenueListReservationsContentFilter!
) {
  venueListReservationContentV2(filter: $filter) {
    items {
      id
      content
      seen
      downloaded
      byVenue
      updatedAt
      createdAt
      downloadCredits
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListReservationContentV2QueryVariables,
  APITypes.VenueListReservationContentV2Query
>;
export const venueGetReservation = /* GraphQL */ `query VenueGetReservation($id: String!) {
  venueGetReservation(id: $id) {
    id
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    marketName
    marketID
    offerID
    offerCategory
    offer {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    offerTitle
    offerValue
    offerStartTime
    offerEndTime
    offerEndDateTime
    timeZoneId
    userID
    name
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    venueID
    venue {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    venueName
    venueLocation {
      lat
      lon
      __typename
    }
    venueAddress1
    venueAddress2
    venueCity
    venueCountry
    note
    location {
      lat
      lon
      __typename
    }
    status
    reservationDate
    actionDate
    checkInDateTime
    createdAt
    updatedAt
    venuePicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    offerPicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    userPicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    canSubmitRequirements
    requirementsSubmitted
    instagramTag
    noShowStatus
    currency
    payoutAmount
    validation
    validationRequested
    penaltyType
    effectiveStartDateTime
    effectiveEndDateTime
    offerPostType
    postLink
    inviteID
    privateOffer
    requirementsSubmittedDateTime
    rated
    ratedByVenue
    rating
    offerValueSnapshot
    dressCodeSnapshot
    suggestedTipSnapshot
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetReservationQueryVariables,
  APITypes.VenueGetReservationQuery
>;
export const adminListVenueBlockedUsers = /* GraphQL */ `query AdminListVenueBlockedUsers($id: Int!) {
  adminListVenueBlockedUsers(id: $id) {
    items {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListVenueBlockedUsersQueryVariables,
  APITypes.AdminListVenueBlockedUsersQuery
>;
export const venueListVenueBlockedUsers = /* GraphQL */ `query VenueListVenueBlockedUsers($id: Int!) {
  venueListVenueBlockedUsers(id: $id) {
    items {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      blockedDate
      tiktokHandle
      xHandle
      youtubeHandle
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListVenueBlockedUsersQueryVariables,
  APITypes.VenueListVenueBlockedUsersQuery
>;
export const venueListVenueBlockedUsers2 = /* GraphQL */ `query VenueListVenueBlockedUsers2($filter: VenueListVenueBlockedUsersRequest!) {
  venueListVenueBlockedUsers2(filter: $filter) {
    items {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      blockedDate
      tiktokHandle
      xHandle
      youtubeHandle
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListVenueBlockedUsers2QueryVariables,
  APITypes.VenueListVenueBlockedUsers2Query
>;
export const venueListVenues = /* GraphQL */ `query VenueListVenues($input: VenueListVenuesRequest!) {
  venueListVenues(input: $input) {
    items {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListVenuesQueryVariables,
  APITypes.VenueListVenuesQuery
>;
export const venueListNotifications = /* GraphQL */ `query VenueListNotifications($filter: VenueListNotificationsFilter!) {
  venueListNotifications(filter: $filter) {
    items {
      id
      venueID
      userID
      type
      thumbnail
      title
      content
      status
      metadata
      redirectCTA
      createdAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListNotificationsQueryVariables,
  APITypes.VenueListNotificationsQuery
>;
export const venueGetUnreadNotificationCount = /* GraphQL */ `query VenueGetUnreadNotificationCount($venueID: String!) {
  venueGetUnreadNotificationCount(venueID: $venueID)
}
` as GeneratedQuery<
  APITypes.VenueGetUnreadNotificationCountQueryVariables,
  APITypes.VenueGetUnreadNotificationCountQuery
>;
export const userListMarkets = /* GraphQL */ `query UserListMarkets($filter: UserListMarketsFilter!) {
  userListMarkets(filter: $filter) {
    items {
      id
      name
      currency
      unitSystem
      offersRedeemed
      imageUrl
      tipEnabled
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListMarketsQueryVariables,
  APITypes.UserListMarketsQuery
>;
export const userGetMarket = /* GraphQL */ `query UserGetMarket($id: String!) {
  userGetMarket(id: $id) {
    id
    name
    currency
    location {
      lat
      lon
      __typename
    }
    timezone {
      dstOffset
      rawOffset
      timeZoneId
      timeZoneName
      __typename
    }
    unitSystem
    offersRedeemed
    imageUrl
    tipEnabled
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetMarketQueryVariables,
  APITypes.UserGetMarketQuery
>;
export const userGetMarketByLocation = /* GraphQL */ `query UserGetMarketByLocation($location: LocationInput!) {
  userGetMarketByLocation(location: $location) {
    id
    name
    currency
    location {
      lat
      lon
      __typename
    }
    timezone {
      dstOffset
      rawOffset
      timeZoneId
      timeZoneName
      __typename
    }
    unitSystem
    offersRedeemed
    imageUrl
    tipEnabled
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetMarketByLocationQueryVariables,
  APITypes.UserGetMarketByLocationQuery
>;
export const userListVenues = /* GraphQL */ `query UserListVenues($filter: UserListVenuesFilter!) {
  userListVenues(filter: $filter) {
    items {
      id
      marketName
      marketID
      type
      phone
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      description
      distance
      status
      offerReservationStatus
      closedDates
      approvalDate
      earliestOfferDate
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      userFavourite
      formattedAddress
      totalSavedContent
      offerCount
      topInMarket
      totalPositiveRatings
      totalRatings
      userMuted
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListVenuesQueryVariables,
  APITypes.UserListVenuesQuery
>;
export const userListFeaturedOffers = /* GraphQL */ `query UserListFeaturedOffers($filter: UserListFeaturedOffersFilter!) {
  userListFeaturedOffers(filter: $filter) {
    items {
      offerID
      offerTitle
      isNew
      availableSpots
      venueID
      venueName
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListFeaturedOffersQueryVariables,
  APITypes.UserListFeaturedOffersQuery
>;
export const userListRatings = /* GraphQL */ `query UserListRatings($filter: UserListRatingsFilter!) {
  userListRatings(filter: $filter) {
    items {
      userUpdatedAt
      userID
      venueID
      reservationID
      feedback
      positive
      createdAt
      updatedAt
      userFirstname
      userLastname
      agencyProfileUrl
      effectiveStartDateTime
      offerTitle
      offerID
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListRatingsQueryVariables,
  APITypes.UserListRatingsQuery
>;
export const userGetVenue = /* GraphQL */ `query UserGetVenue($id: String!) {
  userGetVenue(id: $id) {
    id
    offers {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    marketName
    marketID
    market {
      id
      name
      totalActiveVenues
      totalVenues
      totalPremiumVenues
      totalActiveModels
      totalUsers
      totalPremiumUsers
      percentActiveModels
      totalActiveOffers
      totalActiveVenueWithOffers
      totalPremiumOffers
      totalSpots
      totalReservations
      percentBookedLastFewDays
      percentBookedAllTime
      valueActiveOfferSpots
      totalReach
      stateProvince
      countryCode
      country
      currency
      status
      unitSystem
      createdAt
      tipEnabled
      totalVenuesLeads
      totalApplicationUsers
      __typename
    }
    type
    phone
    name
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    contactFirstname
    contactLastname
    contactPosition
    location {
      lat
      lon
      __typename
    }
    address1
    address2
    city
    postalCode
    stateProvince
    country
    countryCode
    dependentLocality
    timeZoneId
    website
    instagramHandle
    description
    distance
    status
    offerReservationStatus
    closedDates
    approvalDate
    earliestOfferDate
    totalCheckins
    totalCheckinLastMonth
    totalOfferValueRedeemed
    userFavourite
    formattedAddress
    totalSavedContent
    offerCount
    topInMarket
    totalPositiveRatings
    totalRatings
    userMuted
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetVenueQueryVariables,
  APITypes.UserGetVenueQuery
>;
export const userGetAgency = /* GraphQL */ `query UserGetAgency($id: String!) {
  userGetAgency(id: $id) {
    id
    offers {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    reservations {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    approvedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    marketName
    marketID
    marketCurrency
    market {
      id
      name
      totalActiveVenues
      totalVenues
      totalPremiumVenues
      totalActiveModels
      totalUsers
      totalPremiumUsers
      percentActiveModels
      totalActiveOffers
      totalActiveVenueWithOffers
      totalPremiumOffers
      totalSpots
      totalReservations
      percentBookedLastFewDays
      percentBookedAllTime
      valueActiveOfferSpots
      totalReach
      stateProvince
      countryCode
      country
      currency
      status
      unitSystem
      createdAt
      tipEnabled
      totalVenuesLeads
      totalApplicationUsers
      __typename
    }
    type
    phone
    email
    name
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    contactFirstname
    contactLastname
    contactPosition
    location {
      lat
      lon
      __typename
    }
    address1
    address2
    city
    postalCode
    stateProvince
    country
    countryCode
    dependentLocality
    timeZoneId
    website
    instagramHandle
    referredBy
    description
    sideLetter
    approvalDate
    device
    totalActiveOffers
    totalOffers
    totalFavourites
    totalFavouritesByActiveUsers
    totalSpots
    totalReservations
    totalCheckins
    totalContentSaved
    totalImpressions
    percentBooked
    totalReach
    totalNoshow
    distance
    lastSeen
    lastBrowser
    lastPlatform
    lastSynced
    createdAt
    updatedAt
    status
    offerReservationStatus
    stats
    enabledPushNotifications
    enabledLocationAccess
    offerUpdatedAt
    reservationUpdatedAt
    closedDates
    checkInRadius
    platform
    notes
    enabledEmailNotifications
    currency
    tags
    appVersion
    tncVersion
    newContentAvailable
    availableDownloads
    unreadNotificationCount
    masterVenueID
    views
    formattedAddress
    tipEnabled
    premium
    lifestyleMembersAccess
    selectMembersAccess
    accessRequestsCount
    hasPermissionRequiredOffers
    hasSetLifestyleAccessType
    hasSetSelectAccessType
    plan
    termsAccepted
    referralInstagramHandle
    referredInstagramUserId
    totalAgencyUserInvites
    totalAgencyUserSignedInvites
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetAgencyQueryVariables,
  APITypes.UserGetAgencyQuery
>;
export const venueListPortfolioContent = /* GraphQL */ `query VenueListPortfolioContent($id: String!) {
  venueListPortfolioContent(id: $id) {
    items {
      id
      venueID
      portfolio
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListPortfolioContentQueryVariables,
  APITypes.VenueListPortfolioContentQuery
>;
export const venueListPortfolioContentV2 = /* GraphQL */ `query VenueListPortfolioContentV2($filter: VenueListPortfolioContentFilter!) {
  venueListPortfolioContentV2(filter: $filter) {
    items {
      id
      venueID
      portfolio
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListPortfolioContentV2QueryVariables,
  APITypes.VenueListPortfolioContentV2Query
>;
export const userListOffers = /* GraphQL */ `query UserListOffers($filter: UserListOffersFilter!) {
  userListOffers(filter: $filter) {
    items {
      id
      title
      venueID
      venueName
      marketName
      marketID
      description
      category
      subCategory
      availableTo
      gender
      startDate
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      suggestedTip
      tipType
      status
      startDateTime
      endDateTime
      timeZoneId
      additionalreq
      minFollowers
      checkInDateTime
      requirementsSubmitted
      reservationId
      confirmWithEmail
      confirmWithCall
      currency
      validation
      penaltyType
      penaltyId
      reservationCreatedAt
      warnings
      effectiveStartDateTime
      effectiveEndDateTime
      depositCost
      premium
      isNew
      availableSpots
      userAccessRequestStatus
      totalSavedContent
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListOffersQueryVariables,
  APITypes.UserListOffersQuery
>;
export const userListInvites = /* GraphQL */ `query UserListInvites($filter: UserListInvitesFilter!) {
  userListInvites(filter: $filter) {
    items {
      id
      userID
      offerID
      expiry
      createdAt
      updatedAt
      status
      availableDates
      startTime
      endTime
      seen
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListInvitesQueryVariables,
  APITypes.UserListInvitesQuery
>;
export const userGetInvite = /* GraphQL */ `query UserGetInvite($filter: UserGetInviteFilter!) {
  userGetInvite(filter: $filter) {
    id
    userID
    offerID
    expiry
    createdAt
    updatedAt
    status
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    offer {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    reservation {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    availableDates
    startTime
    endTime
    seen
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetInviteQueryVariables,
  APITypes.UserGetInviteQuery
>;
export const userListOfferByVenue = /* GraphQL */ `query UserListOfferByVenue($filter: UserListOfferByVenueFilter!) {
  userListOfferByVenue(filter: $filter) {
    items {
      id
      title
      venueID
      venueName
      marketName
      marketID
      description
      category
      subCategory
      availableTo
      gender
      startDate
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      suggestedTip
      tipType
      status
      startDateTime
      endDateTime
      timeZoneId
      additionalreq
      minFollowers
      checkInDateTime
      requirementsSubmitted
      reservationId
      confirmWithEmail
      confirmWithCall
      currency
      validation
      penaltyType
      penaltyId
      reservationCreatedAt
      warnings
      effectiveStartDateTime
      effectiveEndDateTime
      depositCost
      premium
      isNew
      availableSpots
      userAccessRequestStatus
      totalSavedContent
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListOfferByVenueQueryVariables,
  APITypes.UserListOfferByVenueQuery
>;
export const userGetOffer = /* GraphQL */ `query UserGetOffer($filter: UserOfferInput!) {
  userGetOffer(filter: $filter) {
    id
    title
    venueID
    venue {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    venueName
    marketName
    marketID
    description
    category
    subCategory
    availableTo
    gender
    startDate
    spots
    waitTime
    availableDays
    dressCode
    includeSelfInPost
    includeServiceOrProductInPost
    instagramTag
    postOnInstagramFeed
    postOnInstagramStories
    postOnInstagramReels
    postOnInstagramStories3x
    suggestedTip
    tipType
    location {
      lat
      lon
      __typename
    }
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    status
    startDateTime
    endDateTime
    timeZoneId
    additionalreq
    minFollowers
    checkInDateTime
    requirementsSubmitted
    reservationId
    confirmWithEmail
    confirmWithCall
    currency
    validation
    penaltyType
    penaltyId
    reservationCreatedAt
    warnings
    effectiveStartDateTime
    effectiveEndDateTime
    depositCost
    premium
    isNew
    availableSpots
    userAccessRequestStatus
    totalSavedContent
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetOfferQueryVariables,
  APITypes.UserGetOfferQuery
>;
export const userGetOfferV2 = /* GraphQL */ `query UserGetOfferV2($filter: UserOfferInput!) {
  userGetOfferV2(filter: $filter) {
    success
    message
    offer {
      id
      title
      venueID
      venueName
      marketName
      marketID
      description
      category
      subCategory
      availableTo
      gender
      startDate
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      suggestedTip
      tipType
      status
      startDateTime
      endDateTime
      timeZoneId
      additionalreq
      minFollowers
      checkInDateTime
      requirementsSubmitted
      reservationId
      confirmWithEmail
      confirmWithCall
      currency
      validation
      penaltyType
      penaltyId
      reservationCreatedAt
      warnings
      effectiveStartDateTime
      effectiveEndDateTime
      depositCost
      premium
      isNew
      availableSpots
      userAccessRequestStatus
      totalSavedContent
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetOfferV2QueryVariables,
  APITypes.UserGetOfferV2Query
>;
export const userGetUser = /* GraphQL */ `query UserGetUser($id: String!) {
  userGetUser(id: $id) {
    id
    email
    suspensions {
      id
      userID
      startDate
      endDate
      reason
      detail
      paymentAmount
      status
      lastSynced
      createdAt
      __typename
    }
    penalties {
      id
      userID
      reservationID
      type
      status
      createdAt
      currency
      paymentAmount
      timeZoneId
      __typename
    }
    reservations {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    phone
    name
    country
    firstname
    lastname
    birthdate
    instagramHandle
    instagramFollowers
    instagramUpdatedAt
    failedInstaCheck
    profession
    picture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    gender
    compositeCard {
      __typename
    }
    agency
    motherAgency
    timezone
    currency
    homeCountry
    homeStateProvince
    homeCity
    city
    marketName
    marketID
    market {
      id
      name
      totalActiveVenues
      totalVenues
      totalPremiumVenues
      totalActiveModels
      totalUsers
      totalPremiumUsers
      percentActiveModels
      totalActiveOffers
      totalActiveVenueWithOffers
      totalPremiumOffers
      totalSpots
      totalReservations
      percentBookedLastFewDays
      percentBookedAllTime
      valueActiveOfferSpots
      totalReach
      stateProvince
      countryCode
      country
      currency
      status
      unitSystem
      createdAt
      tipEnabled
      totalVenuesLeads
      totalApplicationUsers
      __typename
    }
    currentCity
    location {
      lat
      lon
      __typename
    }
    credits
    approvalDate
    lastSeen
    device
    totalCheckins
    totalCheckinLastMonth
    totalOfferValueRedeemed
    totalReservations
    totalWarnings
    totalSuspensions
    totalNoShow
    totalActiveNoShow
    totalCancellation
    totalNoPost
    totalPenalties
    totalActivePenalties
    totalPortfolioContents
    totalContentUploaded
    offerCategoriesRedeemed
    timeOnApp
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    reviewedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    acceptedCodeOfConduct
    enabledPushNotifications
    enabledLocationAccess
    lastSynced
    createdAt
    updatedAt
    status
    reactivationDate
    instagramDuplicates
    firstnameDuplicates
    lastnameDuplicates
    birthdateDuplicates
    instagramVerificationRequested
    platform
    stripeId
    totalRequirementsSubmitted
    tags
    appVersion
    noshowCredits
    tncVersion
    hasPendingNoshowCredits
    agencyProfileUrl
    freelancerBodyProfile {
      height
      waist
      bust
      hips
      __typename
    }
    freelancerPictures {
      __typename
    }
    freelancer
    reservationRewardClaimed
    instagramVerified
    points
    premium
    savedByVenue
    inviteCredits
    newInvitesAvailable
    invitesAvailable
    invitesReceived
    webProfilePrivate
    rating
    totalRatings
    tiktokHandle
    xHandle
    youtubeHandle
    highRated
    rank
    scoutingEnabled
    profileVideo
    scoutingPlanEnabled
    inviteStatus
    agencyInviteStatus
    hasDeletedInstagram
    hasDeletedEmail
    lifestyle
    signedByAgency {
      id
      name
      __typename
    }
    profileVideoWithThumbnail {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    potentialMatches
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetUserQueryVariables,
  APITypes.UserGetUserQuery
>;
export const userGetAnalytics = /* GraphQL */ `query UserGetAnalytics {
  userGetAnalytics {
    followers
    engagemenRate
    credibilityScore
    credibilityClass
    averageLikes
    averageComments
    averageReelViews
    genderDistribution {
      female
      male
      __typename
    }
    countries {
      name
      code
      followers
      __typename
    }
    priceEstimate {
      min
      max
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetAnalyticsQueryVariables,
  APITypes.UserGetAnalyticsQuery
>;
export const userListPortfolioContent = /* GraphQL */ `query UserListPortfolioContent {
  userListPortfolioContent {
    items {
      id
      userID
      portfolio
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListPortfolioContentQueryVariables,
  APITypes.UserListPortfolioContentQuery
>;
export const userListPortfolioContentV2 = /* GraphQL */ `query UserListPortfolioContentV2($filter: ListPortfolioContentFilter!) {
  userListPortfolioContentV2(filter: $filter) {
    items {
      id
      userID
      portfolio
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListPortfolioContentV2QueryVariables,
  APITypes.UserListPortfolioContentV2Query
>;
export const userGetSetupIntent = /* GraphQL */ `query UserGetSetupIntent {
  userGetSetupIntent {
    customerId
    merchantDisplayName
    customerEphemeralKeySecret
    setupIntentClientSecret
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetSetupIntentQueryVariables,
  APITypes.UserGetSetupIntentQuery
>;
export const userGetPaymentIntent = /* GraphQL */ `query UserGetPaymentIntent($input: PaymentIntentInput!) {
  userGetPaymentIntent(input: $input) {
    customerId
    merchantDisplayName
    customerEphemeralKeySecret
    paymentIntentClientSecret
    applePay {
      merchantCountryCode
      __typename
    }
    googlePay {
      merchantCountryCode
      testEnv
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetPaymentIntentQueryVariables,
  APITypes.UserGetPaymentIntentQuery
>;
export const userGetAccountBalance = /* GraphQL */ `query UserGetAccountBalance($input: UserAccountBalanceRequest!) {
  userGetAccountBalance(input: $input) {
    balance
    accountId
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetAccountBalanceQueryVariables,
  APITypes.UserGetAccountBalanceQuery
>;
export const userGetVerificationUrl = /* GraphQL */ `query UserGetVerificationUrl($input: UserAccountBalanceRequest!) {
  userGetVerificationUrl(input: $input) {
    onBoardingURL
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetVerificationUrlQueryVariables,
  APITypes.UserGetVerificationUrlQuery
>;
export const userListSubscriptions = /* GraphQL */ `query UserListSubscriptions($filter: UserSubscriptionsFilter!) {
  userListSubscriptions(filter: $filter) {
    items {
      id
      subscriptionId
      userID
      sku
      productGroup
      price
      convertedPrice
      currency
      convertedCurrency
      platform
      expirationDate
      status
      isSubscriptionRenewable
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListSubscriptionsQueryVariables,
  APITypes.UserListSubscriptionsQuery
>;
export const userListReservations = /* GraphQL */ `query UserListReservations($filter: UserListReservationsFilter!) {
  userListReservations(filter: $filter) {
    items {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerStartTime
      offerEndTime
      offerEndDateTime
      offerDescription
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      reservationDate
      checkInDateTime
      createdAt
      requirementsSubmitted
      status
      currency
      payoutAmount
      validation
      penaltyType
      warnings
      effectiveStartDateTime
      effectiveEndDateTime
      penaltyId
      contentUploaded
      depositCost
      offerPostType
      requirementsSubmittedDateTime
      premium
      inviteID
      privateOffer
      offerDressCode
      offerSuggestedTip
      offerCurrency
      offerPostInclude
      offerIncludeSelfInPost
      offerIncludeServiceOrProductInPost
      offerConfirmWithCall
      venueInstagramHandle
      rated
      ratedByVenue
      totalSavedContent
      offerValueUSD
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListReservationsQueryVariables,
  APITypes.UserListReservationsQuery
>;
export const userListRedeemedCategories = /* GraphQL */ `query UserListRedeemedCategories {
  userListRedeemedCategories {
    items {
      category
      totalRedeemed
      totalPoints
      offers
      offerThumbnails
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListRedeemedCategoriesQueryVariables,
  APITypes.UserListRedeemedCategoriesQuery
>;
export const userListRedeemedOffersByCategory = /* GraphQL */ `query UserListRedeemedOffersByCategory(
  $filter: UserListReservationsByCategoryFilter!
) {
  userListRedeemedOffersByCategory(filter: $filter) {
    items {
      offerTitle
      offerID
      venueName
      points
      startDateTime
      offerThumbnail
      timeZoneId
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListRedeemedOffersByCategoryQueryVariables,
  APITypes.UserListRedeemedOffersByCategoryQuery
>;
export const userListReservationContent = /* GraphQL */ `query UserListReservationContent($filter: UserListReservationsContentFilter!) {
  userListReservationContent(filter: $filter) {
    items {
      id
      seen
      updatedAt
      createdAt
      userID
      userFirstname
      userLastname
      userUpdatedAt
      offerID
      offerTitle
      offerUpdatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListReservationContentQueryVariables,
  APITypes.UserListReservationContentQuery
>;
export const userGetReservation = /* GraphQL */ `query UserGetReservation($id: String!) {
  userGetReservation(id: $id) {
    id
    marketName
    marketID
    offerID
    offerCategory
    offer {
      id
      title
      venueID
      venueName
      marketName
      marketCurrency
      marketID
      description
      category
      subCategory
      availableTo
      gender
      offerValue
      offerValueUSD
      startDate
      endDate
      startTime
      endTime
      durationInHours
      spots
      waitTime
      availableDays
      dressCode
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      postOnInstagramFeed
      postOnInstagramStories
      postOnInstagramReels
      postOnInstagramStories3x
      yelpReview
      suggestedTip
      tipType
      reasonRejected
      totalReservations
      totalCheckins
      percentBooked
      totalUniqueVisitors
      timeZoneId
      lastSynced
      createdAt
      offerData
      status
      startDateTime
      additionalreq
      minFollowers
      confirmWithEmail
      confirmWithCall
      effectiveStartDateTime
      effectiveEndDateTime
      originalOfferID
      depositCost
      premium
      private
      views
      lifestyleMembersAccess
      selectMembersAccess
      totalSavedContent
      address
      __typename
    }
    offerTitle
    offerStartTime
    offerEndTime
    offerEndDateTime
    offerDescription
    timeZoneId
    userID
    name
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    venueID
    venue {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    venueName
    venueLocation {
      lat
      lon
      __typename
    }
    venueAddress1
    venueAddress2
    venueCity
    venueCountry
    note
    location {
      lat
      lon
      __typename
    }
    reservationDate
    checkInDateTime
    createdAt
    venuePicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    offerPicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    userPicture {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    requirementsSubmitted
    status
    currency
    payoutAmount
    validation
    penaltyType
    warnings
    effectiveStartDateTime
    effectiveEndDateTime
    penaltyId
    contentUploaded
    depositCost
    offerPostType
    requirementsSubmittedDateTime
    premium
    inviteID
    privateOffer
    offerDressCode
    offerSuggestedTip
    offerCurrency
    offerPostInclude
    offerIncludeSelfInPost
    offerIncludeServiceOrProductInPost
    offerConfirmWithCall
    venueInstagramHandle
    rated
    ratedByVenue
    totalSavedContent
    offerValueUSD
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetReservationQueryVariables,
  APITypes.UserGetReservationQuery
>;
export const userGetOfferCategoriesRedeemedByUser = /* GraphQL */ `query UserGetOfferCategoriesRedeemedByUser($userID: String!) {
  userGetOfferCategoriesRedeemedByUser(userID: $userID)
}
` as GeneratedQuery<
  APITypes.UserGetOfferCategoriesRedeemedByUserQueryVariables,
  APITypes.UserGetOfferCategoriesRedeemedByUserQuery
>;
export const userGetTotals = /* GraphQL */ `query UserGetTotals($userID: String!) {
  userGetTotals(userID: $userID) {
    offerRedeemed
    noShow
    penalty
    noshowCredits
    points
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetTotalsQueryVariables,
  APITypes.UserGetTotalsQuery
>;
export const userGetCreditLedger = /* GraphQL */ `query UserGetCreditLedger($filter: CreditLedgerFilter!) {
  userGetCreditLedger(filter: $filter) {
    items {
      userID
      reservationId
      credits
      createdAt
      updatedAt
      title
      subtitle
      pending
      balance
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetCreditLedgerQueryVariables,
  APITypes.UserGetCreditLedgerQuery
>;
export const adminGetUserCreditLedger = /* GraphQL */ `query AdminGetUserCreditLedger($filter: CreditLedgerFilter!) {
  adminGetUserCreditLedger(filter: $filter) {
    items {
      userID
      reservationId
      credits
      createdAt
      updatedAt
      title
      subtitle
      pending
      balance
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetUserCreditLedgerQueryVariables,
  APITypes.AdminGetUserCreditLedgerQuery
>;
export const userListPenalties = /* GraphQL */ `query UserListPenalties($filter: UserListPenaltiesFilter!) {
  userListPenalties(filter: $filter) {
    items {
      id
      userID
      reservationID
      type
      status
      createdAt
      currency
      paymentAmount
      timeZoneId
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListPenaltiesQueryVariables,
  APITypes.UserListPenaltiesQuery
>;
export const userGetPenalty = /* GraphQL */ `query UserGetPenalty($id: String!) {
  userGetPenalty(id: $id) {
    id
    userID
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    reservationID
    reservation {
      id
      marketName
      marketID
      offerID
      offerCategory
      offerTitle
      offerValue
      offerStartTime
      offerEndTime
      offerEndDateTime
      timeZoneId
      userID
      name
      venueID
      venueName
      venueAddress1
      venueAddress2
      venueCity
      venueCountry
      note
      status
      reservationDate
      actionDate
      checkInDateTime
      createdAt
      updatedAt
      canSubmitRequirements
      requirementsSubmitted
      instagramTag
      noShowStatus
      currency
      payoutAmount
      validation
      validationRequested
      penaltyType
      effectiveStartDateTime
      effectiveEndDateTime
      offerPostType
      postLink
      inviteID
      privateOffer
      requirementsSubmittedDateTime
      rated
      ratedByVenue
      rating
      offerValueSnapshot
      dressCodeSnapshot
      suggestedTipSnapshot
      __typename
    }
    type
    status
    createdAt
    currency
    paymentAmount
    timeZoneId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetPenaltyQueryVariables,
  APITypes.UserGetPenaltyQuery
>;
export const userListSuspensions = /* GraphQL */ `query UserListSuspensions($filter: UserListSuspensionsFilter!) {
  userListSuspensions(filter: $filter) {
    items {
      id
      userID
      startDate
      endDate
      reason
      detail
      paymentAmount
      status
      lastSynced
      createdAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListSuspensionsQueryVariables,
  APITypes.UserListSuspensionsQuery
>;
export const userGetSuspension = /* GraphQL */ `query UserGetSuspension($id: String!) {
  userGetSuspension(id: $id) {
    id
    suspendedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    editedBy {
      id
      email
      firstname
      lastname
      group
      picture
      createdAt
      __typename
    }
    userID
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    startDate
    endDate
    reason
    detail
    paymentAmount
    status
    lastSynced
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetSuspensionQueryVariables,
  APITypes.UserGetSuspensionQuery
>;
export const userListNotifications = /* GraphQL */ `query UserListNotifications($filter: UserListNotificationsFilter!) {
  userListNotifications(filter: $filter) {
    items {
      id
      userID
      venueID
      type
      thumbnail
      title
      content
      status
      metadata
      redirectCTA
      createdAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListNotificationsQueryVariables,
  APITypes.UserListNotificationsQuery
>;
export const userGetUnreadNotificationCount = /* GraphQL */ `query UserGetUnreadNotificationCount($userID: String!) {
  userGetUnreadNotificationCount(userID: $userID)
}
` as GeneratedQuery<
  APITypes.UserGetUnreadNotificationCountQueryVariables,
  APITypes.UserGetUnreadNotificationCountQuery
>;
export const serviceGetInstagram = /* GraphQL */ `query ServiceGetInstagram($username: String) {
  serviceGetInstagram(username: $username)
}
` as GeneratedQuery<
  APITypes.ServiceGetInstagramQueryVariables,
  APITypes.ServiceGetInstagramQuery
>;
export const serviceEmailExist = /* GraphQL */ `query ServiceEmailExist($email: AWSEmail!) {
  serviceEmailExist(email: $email)
}
` as GeneratedQuery<
  APITypes.ServiceEmailExistQueryVariables,
  APITypes.ServiceEmailExistQuery
>;
export const serviceSendForgotPassword = /* GraphQL */ `query ServiceSendForgotPassword($email: AWSEmail!, $fromVenueWeb: Boolean) {
  serviceSendForgotPassword(email: $email, fromVenueWeb: $fromVenueWeb)
}
` as GeneratedQuery<
  APITypes.ServiceSendForgotPasswordQueryVariables,
  APITypes.ServiceSendForgotPasswordQuery
>;
export const serviceInstagramExist = /* GraphQL */ `query ServiceInstagramExist($instagram: String!) {
  serviceInstagramExist(instagram: $instagram)
}
` as GeneratedQuery<
  APITypes.ServiceInstagramExistQueryVariables,
  APITypes.ServiceInstagramExistQuery
>;
export const serviceGooglePlacesAutocomplete = /* GraphQL */ `query ServiceGooglePlacesAutocomplete(
  $place: String!
  $placeType: GooglePlaceType
) {
  serviceGooglePlacesAutocomplete(place: $place, placeType: $placeType)
}
` as GeneratedQuery<
  APITypes.ServiceGooglePlacesAutocompleteQueryVariables,
  APITypes.ServiceGooglePlacesAutocompleteQuery
>;
export const serviceGoogleGetPlace = /* GraphQL */ `query ServiceGoogleGetPlace($placeID: String!) {
  serviceGoogleGetPlace(placeID: $placeID)
}
` as GeneratedQuery<
  APITypes.ServiceGoogleGetPlaceQueryVariables,
  APITypes.ServiceGoogleGetPlaceQuery
>;
export const serviceGetPreSignedUrl = /* GraphQL */ `query ServiceGetPreSignedUrl($ext: String) {
  serviceGetPreSignedUrl(ext: $ext)
}
` as GeneratedQuery<
  APITypes.ServiceGetPreSignedUrlQueryVariables,
  APITypes.ServiceGetPreSignedUrlQuery
>;
export const serviceGetCurrentMarketByCoordinates = /* GraphQL */ `query ServiceGetCurrentMarketByCoordinates($location: LocationInput!) {
  serviceGetCurrentMarketByCoordinates(location: $location) {
    id
    name
    venues {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    totalActiveVenues
    totalVenues
    totalPremiumVenues
    totalActiveModels
    totalUsers
    totalPremiumUsers
    percentActiveModels
    totalActiveOffers
    totalActiveVenueWithOffers
    totalPremiumOffers
    totalSpots
    totalReservations
    percentBookedLastFewDays
    percentBookedAllTime
    valueActiveOfferSpots
    totalReach
    stateProvince
    countryCode
    country
    currency
    status
    location {
      lat
      lon
      __typename
    }
    timezone {
      dstOffset
      rawOffset
      timeZoneId
      timeZoneName
      __typename
    }
    unitSystem
    createdAt
    tipEnabled
    totalVenuesLeads
    totalApplicationUsers
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServiceGetCurrentMarketByCoordinatesQueryVariables,
  APITypes.ServiceGetCurrentMarketByCoordinatesQuery
>;
export const listLogItems = /* GraphQL */ `query ListLogItems($input: ListLogItemInput) {
  listLogItems(input: $input) {
    nextToken
    items {
      id
      type
      resource
      message
      timestamp
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLogItemsQueryVariables,
  APITypes.ListLogItemsQuery
>;
export const listLogResources = /* GraphQL */ `query ListLogResources {
  listLogResources
}
` as GeneratedQuery<
  APITypes.ListLogResourcesQueryVariables,
  APITypes.ListLogResourcesQuery
>;
export const adminListVenueLeads = /* GraphQL */ `query AdminListVenueLeads($filter: AdminListVenueLeadsFilter!) {
  adminListVenueLeads(filter: $filter) {
    items {
      id
      status
      userID
      name
      website
      instagram
      contactName
      contactNumber
      contactEmail
      marketName
      marketID
      recommendedByFirstname
      recommendedByLastname
      recommendedByInstagramHandle
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListVenueLeadsQueryVariables,
  APITypes.AdminListVenueLeadsQuery
>;
export const adminListCurrencies = /* GraphQL */ `query AdminListCurrencies {
  adminListCurrencies {
    items {
      code
      rate
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListCurrenciesQueryVariables,
  APITypes.AdminListCurrenciesQuery
>;
export const venueListCurrencies = /* GraphQL */ `query VenueListCurrencies {
  venueListCurrencies {
    items {
      code
      rate
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListCurrenciesQueryVariables,
  APITypes.VenueListCurrenciesQuery
>;
export const venueListSubscriptions = /* GraphQL */ `query VenueListSubscriptions($filter: VenueSubscriptionsFilter!) {
  venueListSubscriptions(filter: $filter) {
    items {
      id
      subscriptionId
      venueID
      sku
      productGroup
      price
      convertedPrice
      currency
      convertedCurrency
      platform
      expirationDate
      status
      isSubscriptionRenewable
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListSubscriptionsQueryVariables,
  APITypes.VenueListSubscriptionsQuery
>;
export const userGetAgencyInvite = /* GraphQL */ `query UserGetAgencyInvite($filter: AgencyInviteGetRequest) {
  userGetAgencyInvite(filter: $filter) {
    id
    userID
    venueID
    status
    createdAt
    updatedAt
    user {
      id
      email
      phone
      name
      country
      firstname
      lastname
      birthdate
      instagramHandle
      instagramFollowers
      instagramUpdatedAt
      failedInstaCheck
      profession
      gender
      agency
      motherAgency
      timezone
      currency
      homeCountry
      homeStateProvince
      homeCity
      city
      marketName
      marketID
      currentCity
      credits
      approvalDate
      lastSeen
      device
      totalCheckins
      totalCheckinLastMonth
      totalOfferValueRedeemed
      totalReservations
      totalWarnings
      totalSuspensions
      totalNoShow
      totalActiveNoShow
      totalCancellation
      totalNoPost
      totalPenalties
      totalActivePenalties
      totalPortfolioContents
      totalContentUploaded
      offerCategoriesRedeemed
      timeOnApp
      acceptedCodeOfConduct
      enabledPushNotifications
      enabledLocationAccess
      lastSynced
      createdAt
      updatedAt
      status
      reactivationDate
      instagramDuplicates
      firstnameDuplicates
      lastnameDuplicates
      birthdateDuplicates
      instagramVerificationRequested
      platform
      stripeId
      totalRequirementsSubmitted
      tags
      appVersion
      noshowCredits
      tncVersion
      hasPendingNoshowCredits
      agencyProfileUrl
      freelancer
      reservationRewardClaimed
      instagramVerified
      points
      premium
      savedByVenue
      inviteCredits
      newInvitesAvailable
      invitesAvailable
      invitesReceived
      webProfilePrivate
      rating
      totalRatings
      tiktokHandle
      xHandle
      youtubeHandle
      highRated
      rank
      scoutingEnabled
      profileVideo
      scoutingPlanEnabled
      inviteStatus
      agencyInviteStatus
      hasDeletedInstagram
      hasDeletedEmail
      lifestyle
      potentialMatches
      __typename
    }
    marketName
    seen
    venue {
      id
      marketName
      marketID
      marketCurrency
      type
      phone
      email
      name
      contactFirstname
      contactLastname
      contactPosition
      address1
      address2
      city
      postalCode
      stateProvince
      country
      countryCode
      dependentLocality
      timeZoneId
      website
      instagramHandle
      referredBy
      description
      sideLetter
      approvalDate
      device
      totalActiveOffers
      totalOffers
      totalFavourites
      totalFavouritesByActiveUsers
      totalSpots
      totalReservations
      totalCheckins
      totalContentSaved
      totalImpressions
      percentBooked
      totalReach
      totalNoshow
      distance
      lastSeen
      lastBrowser
      lastPlatform
      lastSynced
      createdAt
      updatedAt
      status
      offerReservationStatus
      stats
      enabledPushNotifications
      enabledLocationAccess
      offerUpdatedAt
      reservationUpdatedAt
      closedDates
      checkInRadius
      platform
      notes
      enabledEmailNotifications
      currency
      tags
      appVersion
      tncVersion
      newContentAvailable
      availableDownloads
      unreadNotificationCount
      masterVenueID
      views
      formattedAddress
      tipEnabled
      premium
      lifestyleMembersAccess
      selectMembersAccess
      accessRequestsCount
      hasPermissionRequiredOffers
      hasSetLifestyleAccessType
      hasSetSelectAccessType
      plan
      termsAccepted
      referralInstagramHandle
      referredInstagramUserId
      totalAgencyUserInvites
      totalAgencyUserSignedInvites
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetAgencyInviteQueryVariables,
  APITypes.UserGetAgencyInviteQuery
>;
export const adminListConversations = /* GraphQL */ `query AdminListConversations($filter: AdminListConversationsFilter!) {
  adminListConversations(filter: $filter) {
    nextPageKey
    items {
      fromUserId
      toUserId
      conversationId
      userType
      messageTime
      fromUserMessageCount
      toUserMessageCount
      firstname
      lastname
      instagramHandle
      instagramFollowers
      premium
      plan
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListConversationsQueryVariables,
  APITypes.AdminListConversationsQuery
>;
export const adminGetConversation = /* GraphQL */ `query AdminGetConversation($filter: AdminGetConversationFilter!) {
  adminGetConversation(filter: $filter) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    fromUserMessageCount
    toUserMessageCount
    images {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    firstname
    lastname
    instagramHandle
    instagramFollowers
    premium
    plan
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGetConversationQueryVariables,
  APITypes.AdminGetConversationQuery
>;
export const venueGetConversation = /* GraphQL */ `query VenueGetConversation($filter: VenueGetConversationFilter!) {
  venueGetConversation(filter: $filter) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    fromUserMessageCount
    toUserMessageCount
    images {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    firstname
    lastname
    instagramHandle
    instagramFollowers
    premium
    plan
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueGetConversationQueryVariables,
  APITypes.VenueGetConversationQuery
>;
export const userGetConversation = /* GraphQL */ `query UserGetConversation {
  userGetConversation {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    fromUserMessageCount
    toUserMessageCount
    images {
      thumbnail
      small
      medium
      large
      video
      __typename
    }
    firstname
    lastname
    instagramHandle
    instagramFollowers
    premium
    plan
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserGetConversationQueryVariables,
  APITypes.UserGetConversationQuery
>;
export const adminListChatMessages = /* GraphQL */ `query AdminListChatMessages($filter: AdminListChatMessagesFilter!) {
  adminListChatMessages(filter: $filter) {
    nextPageKey
    items {
      fromUserId
      toUserId
      conversationId
      userType
      messageTime
      message
      messageType
      received
      seen
      rejected
      url
      title
      thumbnail
      complaintID
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminListChatMessagesQueryVariables,
  APITypes.AdminListChatMessagesQuery
>;
export const venueListChatMessages = /* GraphQL */ `query VenueListChatMessages($filter: VenueListChatMessagesFilter!) {
  venueListChatMessages(filter: $filter) {
    nextPageKey
    items {
      fromUserId
      toUserId
      conversationId
      userType
      messageTime
      message
      messageType
      received
      seen
      rejected
      url
      title
      thumbnail
      complaintID
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VenueListChatMessagesQueryVariables,
  APITypes.VenueListChatMessagesQuery
>;
export const userListChatMessages = /* GraphQL */ `query UserListChatMessages($filter: UserListChatMessagesFilter!) {
  userListChatMessages(filter: $filter) {
    nextPageKey
    items {
      fromUserId
      toUserId
      conversationId
      userType
      messageTime
      message
      messageType
      received
      seen
      rejected
      url
      title
      thumbnail
      complaintID
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserListChatMessagesQueryVariables,
  APITypes.UserListChatMessagesQuery
>;
