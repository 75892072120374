import * as React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Icons from '../../../../themes/icons';

type BenefitProps = {
  title: string;
  description?: string;
  color?: string;
  tooltip?: string;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(17, 17, 17, 0.80)',
    minWidth: '253px',
    maxWidth: '253px',
    fontSize: '12px',
    boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.10)',
  },
}));

const Benefit: React.FC<BenefitProps> = ({ color, title, description, tooltip }) => {
  return (
    <Box display='flex' flexDirection='row'>
      <CheckIcon sx={{ color, fontSize: '1.2rem' }} />

      <Box ml={2} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Typography variant='subtitle1' sx={{ lineHeight: 1, fontSize: '15px', fontWeight: '400' }}>
          {title}
        </Typography>
        {tooltip && (
          <HtmlTooltip
            placement='top'
            arrow
            title={
              <React.Fragment>
                <Typography
                  color='inherit'
                  padding={'12px'}
                  sx={{ backgroundColor: '#fff', textAlign: 'left', fontSize: '12px' }}
                >
                  {tooltip}
                </Typography>
              </React.Fragment>
            }
          >
            <Icons.InfoLightIcon />
          </HtmlTooltip>
        )}
      </Box>
      {description && (
        <Typography component='div' sx={{ lineHeight: 1.3, fontSize: '15px' }}>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default Benefit;
