import * as React from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';

import { extractSubscriptionPlan } from '../../../../pages/SubscriptionPlans/common/helper';
import { useAppSelector } from '../../../../redux/hooks';
import { formatNumber } from '../../../../utils/formatter';
// import Button from '../../../Button/Button';
import SvgIcon, { StoryIcon } from '../../../Icons';
import List from '../../../List/Base/List';
import { useCreateInvite } from '../context/CreateInvite.context';
import { calculateInviteCost } from '../context/util';

type Requirement = 'story' | '3 stories' | 'feed' | 'reel' | 'no requirement';

type RequirementProps = {
  requirement: 'story' | '3 stories' | 'feed' | 'reel' | 'no requirement';
};

const OfferRequirement: React.FC<RequirementProps> = ({ requirement }) => {
  const offerRequirement: Record<Requirement, Record<string, React.ReactNode>> = {
    story: {
      title: (
        <Typography variant='body2'>
          Post on <b>Story</b>
        </Typography>
      ),
      icon: <StoryIcon sx={{ mr: 2 }} />,
    },
    '3 stories': {
      title: (
        <Typography variant='body2'>
          Post on <b>3 Stories</b>
        </Typography>
      ),
      icon: <StoryIcon sx={{ mr: 2 }} />,
    },
    feed: {
      title: (
        <Typography variant='body2'>
          Post on <b>Feed</b>
        </Typography>
      ),
      icon: <SvgIcon name='Feed' sx={{ mr: 2 }} />,
    },
    reel: {
      title: (
        <Typography variant='body2'>
          Post on <b>Reel</b>
        </Typography>
      ),
      icon: <SvgIcon name='Reel' sx={{ mr: 2 }} />,
    },
    'no requirement': {
      title: <Typography variant='body2'>No Requirement</Typography>,
      icon: <SvgIcon name='NoRequirement' sx={{ mr: 2 }} />,
    },
  };
  return (
    <Box
      display='inline-flex'
      alignItems='center'
      py={2}
      pl={2}
      pr={10}
      sx={(theme) => ({
        borderRadius: theme.spacing(1),
        border: '2px solid #BA8AFF',
        background:
          'transparent linear-gradient(65deg, #FCEEC3 0%, #DEC5FC 49%, #D7EAEA 100%) 0% 0% no-repeat padding-box;',
      })}
    >
      {offerRequirement[requirement].icon}
      {offerRequirement[requirement].title}
    </Box>
  );
};

const Section: React.FC<React.PropsWithChildren<{ title: string }>> = ({ title, children }) => (
  <Box>
    <Typography fontWeight='bold'>{title}</Typography>
    {children}
  </Box>
);

const Step3: React.FC = () => {
  const credits = useAppSelector((state) => state.venue.value?.availableDownloads);
  const { subscription } = useAppSelector((state) => state.subscription.value);
  const { state, submitForm } = useCreateInvite();

  const noRequirements =
    !state.offer?.postOnInstagramStories &&
    !state.offer?.postOnInstagramStories3x &&
    !state.offer?.postOnInstagramFeed &&
    !state.offer?.postOnInstagramReels;

  const { balance } = React.useMemo(() => {
    const inviteCost = calculateInviteCost(state.users);
    const balance = (credits ?? 0) - inviteCost;

    return { inviteCost, balance };
  }, [state, credits]);

  const subscriptionPlan = React.useMemo(() => {
    const [plan] = extractSubscriptionPlan(subscription?.sku ?? '');

    return plan;
  }, [subscription]);

  return (
    <Box
      sx={{
        marginBlock: 2,
        maxHeight: 'calc(600px - 250px)',
        overflowY: 'auto',
      }}
    >
      <Stack spacing={3}>
        <Section title='You will receive'>
          {state.offer?.postOnInstagramStories && <OfferRequirement requirement='story' />}
          {state.offer?.postOnInstagramStories3x && <OfferRequirement requirement='3 stories' />}
          {state.offer?.postOnInstagramFeed && <OfferRequirement requirement='feed' />}
          {state.offer?.postOnInstagramReels && <OfferRequirement requirement='reel' />}
          {noRequirements && <OfferRequirement requirement='no requirement' />}
        </Section>

        <Section title='From'>
          <List
            items={state.users.map((x) => ({
              avatar: String(x.picture?.thumbnail),
              primaryText: x.firstname,
              secondaryText: `${formatNumber(x.instagramFollowers ?? 0)} Followers`,
              secondarySlot: (
                <Typography variant='body1'>
                  Cost: <b>{x.inviteCredits} credits</b>
                </Typography>
              ),
            }))}
          />
        </Section>

        <Section title='For your offer'>
          <List
            items={(state.offer ? [state.offer] : []).map((x) => ({
              avatar: String(x.picture?.thumbnail),
              primaryText: String(x.title),
              secondaryText: (
                <Box component='span' sx={{ color: 'black' }}>
                  {state.dates?.length} selected dates
                </Box>
              ),
            }))}
          />
        </Section>
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          boxShadow: '0px 0px 20px #18232E26;',
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        <Grid container>
          <Grid item xs={5}>
            <Stack px={3} py={3}>
              <Box display='flex' alignItems='flex-start' gap={1}>
                <Typography fontWeight='normal' fontSize={'15px'}>
                  Total cost:
                </Typography>
                <Typography fontWeight='normal' fontSize={'15px'}>
                  <Box component='span' fontWeight={'600'}>
                    {subscriptionPlan === 'plus' ? state.premiumCredits : state.nonPremiumCredits}
                  </Box>
                  &nbsp;Credits
                </Typography>
              </Box>
              <Box marginTop={'15px'}>
                <Button
                  id='btn-offer-submit'
                  variant='contained'
                  size='large'
                  disabled={balance < 0}
                  onClick={submitForm}
                  sx={{
                    minWidth: '100%',
                    paddingY: '14px',
                  }}
                >
                  Confirm and Invite
                </Button>
              </Box>
            </Stack>
          </Grid>
          {subscriptionPlan === 'plus' ? null : (
            <>
              {state.premiumCredits &&
                state.nonPremiumCredits &&
                state.premiumCredits < state.nonPremiumCredits && (
                  <>
                    <Grid item xs={2}>
                      <Stack
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        height={'100%'}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          flexDirection={'column'}
                        >
                          <Divider orientation='vertical' />
                          <Typography sx={{ fontSize: '11px', color: '#A9A9A9' }}>OR</Typography>
                          <Divider orientation='vertical' />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={5}>
                      <Stack px={3} py={3}>
                        <Box
                          display='flex'
                          alignItems='flex-start'
                          justifyContent={'space-between'}
                        >
                          <Box display='flex' alignItems='flex-start' gap={0.5}>
                            <Typography fontWeight='normal' fontSize={'15px'}>
                              Total cost:
                            </Typography>
                            <Typography fontWeight='normal' fontSize={'15px'}>
                              <Box component='span' color={'lightseagreen'} fontWeight={'600'}>
                                {state.premiumCredits}
                              </Box>
                              &nbsp;Credits
                            </Typography>
                          </Box>
                          <Box display='flex' alignItems='flex-start' gap={0.5}>
                            <Typography fontWeight='normal' fontSize={'15px'}>
                              Save
                            </Typography>
                            <Typography fontWeight='normal' fontSize={'15px'}>
                              <Box component='span' fontWeight={'600'}>
                                {state.premiumCredits &&
                                  state.nonPremiumCredits &&
                                  state.nonPremiumCredits - state.premiumCredits}
                              </Box>
                              &nbsp;Credits
                            </Typography>
                          </Box>
                        </Box>
                        <Box marginTop={'15px'}>
                          <Button
                            component={Link}
                            to='/plans'
                            id='btn-premium-plans'
                            startIcon={
                              <SvgIcon name='Plus' sx={{ fill: 'url(#linear-gradient)' }} />
                            }
                            variant='outlined'
                            sx={{
                              minWidth: '100%',
                              paddingY: '14px',
                              borderColor: '#E28DDE',
                              ':hover': {
                                borderColor: '#E28DDE',
                              },
                              color: '#E28DDE',
                            }}
                          >
                            Get plus
                          </Button>
                        </Box>
                      </Stack>
                    </Grid>
                  </>
                )}
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Step3;
