import * as React from 'react';

import { Alert, Box, Grid, Link, Typography } from '@mui/material';

import { Elements } from '@stripe/react-stripe-js';
import { useMutation } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';

import { venueGetPaymentIntentApi } from '../../../api/stripe/venueGetPaymentIntentApi';
import CreditTipsDialog from '../../../components/Dialogs/CreditTips/CreditTipsDialog';
import useBackdrop from '../../../contexts/Backgrop/useBackdrop';
import { VenuePaymentIntentInput } from '../../../graphql/API';
import useUrlQuery from '../../../hooks/useUrlQuery';
import { useAppSelector } from '../../../redux/hooks';
import Checkout from './Checkout';
import CreditOptionBox from './CreditOptionBox';
import Paper from './Paper';
import getStripe from './getStripe';
import { options } from './options';

const CreditPanel: React.FC = () => {
  const venueState = useAppSelector((state) => state.venue.value);

  const q = useUrlQuery();

  const queries = React.useMemo(
    () => ({
      status: q.get('redirect_status') as 'succeeded',
      credits: Number(q.get('credits') ?? '0'),
    }),
    [q],
  );

  const { showModal } = useModal();
  const { toggleBackdrop } = useBackdrop();
  const [clientSecret, setClientSecret] = React.useState<string>();

  const creditMutation = useMutation({
    mutationFn: async (input: VenuePaymentIntentInput) => {
      const paymentIntent = await venueGetPaymentIntentApi(input);

      return paymentIntent;
    },
    onMutate: () => {
      toggleBackdrop(true);
    },
    onSuccess: (pi) => {
      setClientSecret(pi?.paymentIntentClientSecret);
    },
    onSettled: () => {
      toggleBackdrop(false);
    },
  });

  const handleOpenDialog = React.useCallback(() => {
    showModal(CreditTipsDialog, { title: 'Beautypass credits', width: '600px' });
  }, [showModal]);

  const onClick = React.useCallback<React.ChangeEventHandler<HTMLButtonElement>>(
    (e) => {
      const v = e.currentTarget.value;
      creditMutation.mutate({ availableDownloads: Number(v) });
    },
    [creditMutation],
  );

  if (clientSecret) {
    return (
      <Elements stripe={getStripe()} options={{ clientSecret }}>
        <Checkout credits={creditMutation.variables?.availableDownloads ?? undefined} />
      </Elements>
    );
  }

  return (
    <Box>
      {queries.status === 'succeeded' && (
        <Alert severity='success' sx={{ mb: 2 }}>
          You have successfully bought <b>{queries.credits}</b> credits
        </Alert>
      )}

      <Paper
        sx={{
          padding: '40px',
          boxShadow: '0px 0px 30px #0000000D',
          borderRadius: '12px',
          border: '1px solid #F1F1F1',
        }}
      >
        <Box component='img' src='/png/wallet-bg.png' sx={{ width: '75px' }} />

        <Box sx={{ ml: 4, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography variant='h5' fontWeight='bold' fontSize={'24px'}>
            {venueState?.availableDownloads} Credits
          </Typography>
          <Typography variant='subtitle1' fontSize={'18px'} fontWeight='500'>
            Use credits to send invites to members and download content
          </Typography>

          <Link
            onClick={handleOpenDialog}
            fontWeight='bold'
            variant='subtitle1'
            sx={{ mt: 3, cursor: 'pointer', fontSize: '18px' }}
          >
            Learn more about credits
          </Link>
        </Box>
      </Paper>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        {options.map((x) => (
          <Grid item xs={4} key={x.credit}>
            <CreditOptionBox option={x} onClick={onClick} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

//http://localhost:3000/credits?credits=3&payment_intent=pi_3NbcS1JwGfgtrYUh1JBXBFmt&payment_intent_client_secret=pi_3NbcS1JwGfgtrYUh1JBXBFmt_secret_uYZXLpEn4msLVSUCVwBxjRLVG&redirect_status=succeeded

export default CreditPanel;
