import React from 'react';

import { Box, Grid, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';

import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';

import { venueGetAnalyticsApi } from '../../../api/analytics/venueGetAnalyticsApi';
import icons from '../../../themes/icons';
import { formatNumber } from '../../../utils/formatter';
import { LoadingBackdrop } from '../../LoadingBackdrop/LoadingBackdrop';
import BaseDialog, { BaseDialogProps } from '../Base';
import { CountryItem } from './components/CountryItem';
import { FigureCard } from './components/FigureCard';
import { FollowersList } from './components/FollowersList';
import { IGCard } from './components/IGCard';
import { PieChart } from './components/PieChart';
import { FigureDescription, FigureHeading } from './components/StyledComponents';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: '365px',
    maxWidth: '365px',
    fontSize: '15px',
    boxShadow: '0px -2px 20px 0px #00000014',
  },
}));

type IGStatsProps = {
  userID: string;
} & BaseDialogProps;

export const IGStatsDialog: React.FC<IGStatsProps> = (props) => {
  const { data, isFetching } = useQuery({
    queryKey: ['venue-get-analytics'],
    queryFn: () => venueGetAnalyticsApi({ userID: props.userID }),
    refetchOnWindowFocus: false,
    enabled: Boolean(props.userID),
  });

  const authenticityScoreColor = React.useMemo(
    () =>
      data?.credibilityScore
        ? Math.round((data?.credibilityScore || 0) * 100) >= 75
          ? '#37BB90'
          : Math.round((data?.credibilityScore || 0) * 100) >= 65
            ? '#FABE77'
            : '#FF4D4D'
        : '',
    [data?.credibilityScore],
  );

  return (
    <BaseDialog width='1042px' height='824px' {...props}>
      <Box sx={{ paddingY: '32px', paddingX: '32px', position: 'relative' }}>
        <LoadingBackdrop loading={isFetching} />
        {!isFetching && (!data || (data?.followers && data.followers < 1000)) && (
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography>data not available.</Typography>
          </Box>
        )}
        {!isFetching && data && (
          <Box>
            <Typography
              fontSize={13}
              fontWeight={600}
              color='#ACACAC'
              textTransform={'uppercase'}
              marginBottom={'10px'}
            >
              Followers metrics
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={4.5}>
                <IGCard>
                  <Box padding={'32px 26px'}>
                    <Box
                      display={'flex'}
                      alignItems={'start'}
                      justifyContent={'space-between'}
                      paddingBottom={'32px'}
                      borderBottom={'1px solid #EBEBEB'}
                    >
                      <Box display={'flex'} flexDirection={'column'} gap={1} alignItems={'center'}>
                        <FigureHeading>{formatNumber(data?.followers ?? 0)}</FigureHeading>
                        <FigureDescription>Followers</FigureDescription>
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} gap={1} alignItems={'center'}>
                        <FigureHeading>
                          {data?.engagemenRate && (data?.engagemenRate * 100).toFixed(2)}%
                        </FigureHeading>
                        <FigureDescription>Engagement Rate</FigureDescription>
                      </Box>
                    </Box>
                    <Box marginTop={'18px'}>
                      <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                color='inherit'
                                padding={'12px'}
                                sx={{ backgroundColor: '#fff', textAlign: 'left' }}
                              >
                                This score estimates the quality of a creator's audience by
                                examining the ratio of follower types (fake, real, and businesses)
                                and their reachability (mass followers versus authentic ones).
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <icons.InfoIcon />
                        </HtmlTooltip>

                        <Typography fontWeight={500} fontSize={'15px'}>
                          Audience Authenticity Score
                        </Typography>
                      </Box>
                      <Box
                        marginTop={'17px'}
                        display={'flex'}
                        alignItems={'flex-end'}
                        gap={3}
                        minHeight={'100px'}
                      >
                        {data?.credibilityScore && (
                          <AudienceScoreBar
                            precentNumber={Math.round((data?.credibilityScore || 0) * 100)}
                            color={authenticityScoreColor}
                          />
                        )}
                        <Box>
                          <FigureHeading color={authenticityScoreColor}>
                            {Math.round((data?.credibilityScore || 0) * 100)}%
                          </FigureHeading>
                          <FigureDescription color={authenticityScoreColor}>
                            {data?.credibilityClass}
                          </FigureDescription>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </IGCard>
              </Grid>
              <Grid item xs={7.5}>
                <IGCard>
                  <Box
                    padding={'24px'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    gap={'5px'}
                  >
                    <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              color='inherit'
                              padding={'12px'}
                              sx={{ backgroundColor: '#fff', textAlign: 'justify' }}
                            >
                              This estimate represents the minimum fair price of a creator based on
                              their follower count and engagement rate for 3 stories or 1 feed post.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <icons.InfoIcon />
                      </HtmlTooltip>
                      <Typography fontWeight={500} fontSize={'15px'}>
                        Price Estimate
                      </Typography>
                    </Box>
                    <FigureHeading color='#37BB90'>
                      ${data?.priceEstimate?.min?.toFixed(0)} - $
                      {data?.priceEstimate?.max?.toFixed(0)}
                    </FigureHeading>
                  </Box>
                </IGCard>
                <Grid container spacing={2} marginTop={'1px'}>
                  <Grid item xs={4}>
                    <FigureCard
                      figure={data?.averageLikes ?? 0}
                      desc='Average Likes'
                      type='heart'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FigureCard
                      figure={data?.averageComments ?? 0}
                      desc='Average Comments'
                      type='comment'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FigureCard
                      figure={data?.averageReelViews ?? 0}
                      desc='Average Reels Views'
                      type='video'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4.5}>
                <IGCard>
                  <Box padding={'24px'}>
                    <FigureDescription fontWeight={500}>
                      Followers location by country
                    </FigureDescription>
                    <CountriesContainer>
                      {data?.countries
                        ?.slice(0, 3)
                        ?.map((country) => (
                          <CountryItem
                            code={country?.code || ''}
                            name={country?.name || ''}
                            followers={country?.followers || 0}
                          />
                        ))}
                    </CountriesContainer>
                  </Box>
                </IGCard>
              </Grid>
              <Grid item xs={7.5}>
                <IGCard>
                  <Box padding={'32px'} display={'flex'} alignItems={'start'} gap={'50px'}>
                    <PieChart
                      data={[
                        { name: 'Male', value: data?.genderDistribution?.male || 0 },
                        { name: 'Female', value: data?.genderDistribution?.female || 0 },
                      ]}
                    />
                    <FollowersList
                      male={data?.genderDistribution?.male || 0}
                      female={data?.genderDistribution?.female || 0}
                    />
                  </Box>
                </IGCard>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </BaseDialog>
  );
};

const AudienceScoreBar = styled.div<{ precentNumber?: number; color?: string }>`
  background-color: ${(props) => props.color};
  width: 38px;
  min-height: ${(props) =>
    props.precentNumber ? `calc(${props.precentNumber}px + 3px)` : '100px'};
  box-shadow: 0px 0px 25px 0px #0000000a;
  border: 1px solid #f7f7f7;
  border-radius: 6px;
`;

const CountriesContainer = styled.div`
  margin-top: 22px;
  overflow: auto;
  min-height: 200px;
  max-height: 200px;
  &:-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;
