import React from 'react';

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { venueListChatMessagesApi } from '../../api/chat/venueListChatMessagesApi';
import { ChatMessagesList, VenueListChatMessagesFilter } from '../../graphql/API';

const PAGE_LIMIT = 20;

type Props = {
  conversationId: string;
};

const INITIAL_STATE = {
  startKey: null,
  limit: PAGE_LIMIT,
};

export const useChatListQuery = ({ conversationId }: Props) => {
  const [filters, setFilters] = React.useState<VenueListChatMessagesFilter>({
    ...INITIAL_STATE,
    conversationId,
  });

  const {
    isLoading,
    isFetching,
    data: messagesData,
    isError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<ChatMessagesList>({
    initialPageParam: undefined,
    queryKey: ['chat-list', { ...filters }],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, apiFilters] = queryKey as [string, VenueListChatMessagesFilter];
      const data = await venueListChatMessagesApi({
        ...apiFilters,
        startKey: (pageParam || null) as string,
      });
      return data;
    },
    getNextPageParam: (lastGroup) => (lastGroup.nextPageKey ? lastGroup.nextPageKey : undefined),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    enabled: Boolean(conversationId),
  });

  const data = React.useMemo(
    () => messagesData?.pages.flatMap((x) => x.items) ?? [],
    [messagesData],
  );

  return {
    data,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    filters,
    setFilters,
  };
};
