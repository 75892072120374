import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Alert, Box, Stack, Typography } from '@mui/material';

import Button from '../../../Button/Button';

export type ActionDownloadProps = { successMessage: boolean; onDownload: () => void };

const DescriptionLine: React.FC<React.PropsWithChildren<{ icon: React.ReactNode }>> = ({
  icon,
  children,
}) => (
  <Typography
    component='div'
    variant='body2'
    sx={{ display: 'flex', alignItems: 'center', color: '#5F5F60' }}
  >
    {icon}
    <Box sx={{ ml: 1 }}>{children}</Box>
  </Typography>
);

const ActionDownload: React.FC<ActionDownloadProps> = ({ successMessage, onDownload }) => {
  return (
    <Box>
      <Box
        sx={{
          px: 3,
          py: 2,
          backgroundColor: '#F7F7F7',
          border: '1px solid #EBE8E8',
          borderRadius: 1,
        }}
      >
        <Typography fontWeight='bold'>Licence</Typography>

        <Stack spacing={2} sx={{ mt: 2 }}>
          <DescriptionLine icon={<DoneIcon sx={{ color: '#37BB90' }} />}>
            You can save content and repost it on your Instagram page to promote your business.
          </DescriptionLine>

          <DescriptionLine icon={<CloseIcon sx={{ color: '#D04040' }} />}>
            It is forbidden to modify, edit to use this content for any commercial purpose outside
            of Instagram
          </DescriptionLine>
        </Stack>
      </Box>

      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
        sx={{
          mt: 4,
          '> *': { width: '50%' },
        }}
      >
        <Button variant='contained' onClick={onDownload}>
          Download
        </Button>
        {successMessage && (
          <Alert icon={false} severity='success' sx={{ py: 0, justifyContent: 'center' }}>
            Content saved!
          </Alert>
        )}
      </Stack>
    </Box>
  );
};

export default ActionDownload;
