import * as React from 'react';

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { venueListReservationsApi } from '../../api/reservations/venueListReservationsApi';
import { Reservation, VenueListReservationsFilter } from '../../graphql/API';
import { QueryResult } from './types';

const PAGE_LIMIT = 50;

const useReservationQuery = (
  filters: Partial<VenueListReservationsFilter> | undefined,
  venueId: string | undefined,
): QueryResult<Reservation[]> => {
  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } = useInfiniteQuery<
    Reservation[]
  >({
    initialPageParam: 0,
    queryKey: ['list-reservation', venueId, filters],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, venueID, queryFilters] = queryKey as [
        string,
        string,
        Partial<VenueListReservationsFilter>,
      ];
      const offset = <number>pageParam * PAGE_LIMIT;

      return venueListReservationsApi({ ...queryFilters, limit: PAGE_LIMIT, offset, venueID });
    },
    getNextPageParam: (lastGroup, groups) =>
      lastGroup.length === PAGE_LIMIT ? groups.length : undefined,
    placeholderData: keepPreviousData,
    enabled: !!venueId,
    refetchOnWindowFocus: false,
  });

  const flatData = React.useMemo(() => data?.pages.flatMap((x) => x) ?? [], [data]);

  return { data: flatData, isFetching, isLoading, fetchNextPage, hasNextPage };
};

export default useReservationQuery;
