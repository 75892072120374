import * as React from 'react';

import MuiPaper, { PaperProps } from '@mui/material/Paper';

const Paper: React.FC<React.PropsWithChildren<PaperProps>> = ({ children, sx }) => {
  return (
    <MuiPaper
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        p: 2,
        border: '1px solid #F1F1F1',
        boxShadow: 'box-shadow: 0px 0px 30px #0000000D',
        ...sx,
      }}
    >
      {children}
    </MuiPaper>
  );
};

export default Paper;
