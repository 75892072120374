import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Container } from '@mui/material';

import useUrlQuery from '../../hooks/useUrlQuery';
import CreditPanel from './components/CreditPanel';

type ToggleValue = 'credit' | 'premium';

const Credit: React.FC = () => {
  const q = useUrlQuery();
  const nav = useNavigate();

  useEffect(() => {
    const isCancel = (q.get('cancel') as string | undefined) === 'true';
    const v = (q.get('type') as ToggleValue | undefined) ?? 'credit';
    if (v === 'premium') {
      const qs = isCancel ? '' : `?message_type=welcome&${q.toString()}`;
      nav(`/plans${qs}`);
    }
  }, [q, nav]);

  return (
    <Box
      sx={{
        flex: 1,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FEFEFE',
      }}
    >
      <Container maxWidth='md' sx={{ mt: 4 }}>
        <CreditPanel />
      </Container>
    </Box>
  );
};

export default Credit;
