import * as React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { InputAdornment, MenuItem, TextField, TextFieldProps } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';

const INTERVAL_MINUTE = 5;

const intervalMinutes = (hour: number, amPM: string) =>
  [...Array(60 / INTERVAL_MINUTE)].map(
    (_, i) => `${hour}:${(i * 5).toLocaleString('en-US', { minimumIntegerDigits: 2 })} ${amPM}`,
  );

const hourMinutes = [...Array(24)]
  .map((_, i) => {
    const mod = i % 12;
    const hour = mod === 0 ? 12 : mod;

    const amPM = i < 12 ? 'AM' : 'PM'; // Adjusted logic

    return intervalMinutes(hour, amPM);
  })
  .flat();

export type TimePickerProps = {
  onSelectChange?: (value: Dayjs) => void;
  defaultValue?: Dayjs;
  name?: string;
  shouldDisaledThePreviousTime?: boolean;
} & TextFieldProps;

const format = 'h:mm A';

const toValue = (djs: Dayjs) => djs.format(format);

const currentDate = new Date();
const currentHour = currentDate.getHours();
const currentMinute = currentDate.getMinutes();
const isPM = currentHour >= 12;

const TimePicker: React.FC<TimePickerProps> = ({
  name,
  defaultValue,
  onSelectChange,
  shouldDisaledThePreviousTime = false,
  ...restProps
}) => {
  const [value, setValue] = React.useState<string>(toValue(defaultValue ?? dayjs()));
  const handleChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const v = e.target.value;
      const djs = dayjs(v, format);
      setValue(v);
      onSelectChange && onSelectChange(djs);
    },
    [onSelectChange],
  );

  return (
    <TextField
      {...restProps}
      id={`select-timepicker-${name}`}
      sx={{ minWidth: 150 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <AccessTimeIcon />
          </InputAdornment>
        ),
      }}
      SelectProps={{
        MenuProps: { sx: { maxHeight: 500 } },
        IconComponent: () => null,
      }}
      select
      size='small'
      onChange={handleChange}
      value={value}
    >
      {shouldDisaledThePreviousTime
        ? hourMinutes.map((x, i) => {
            const [time, period] = x.split(' ');
            // eslint-disable-next-line prefer-const
            let [hour, minute] = time.split(':').map(Number);

            if (period === 'PM' && hour !== 12) {
              hour += 12;
            }

            const disable =
              (isPM && period === 'AM') || // Disable AM times if current time is PM
              currentHour > hour ||
              (currentHour === hour && currentMinute >= minute);

            return (
              <MenuItem key={`time-${i}`} value={x} disabled={disable}>
                {x}
              </MenuItem>
            );
          })
        : hourMinutes.map((x, i) => (
            <MenuItem key={`time-${i}`} value={x}>
              {x}
            </MenuItem>
          ))}
    </TextField>
  );
};

export default TimePicker;
